// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Tooltip,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
var notSearch = true;
function Connections(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [connection, setConnection] = useState("");
  const [searchedConnections, setSearchedConnections] = useState("");
  const [connectionsData, setConnectionsData] = useState("");
  const [relation, setRelation] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (Props.searchedConnections) {
      setSearchedConnections(Props.searchedConnections);
    }
    if (Props.clientInfo) {
      setConnectionsData(Props.clientInfo.connections);
    }
  }, [Props]);

  const userOwner = localStorage.getItem("userOwner");

  const { Option } = Select;
  const connectionsList = [];

  if (
    searchedConnections &&
    searchedConnections.clients &&
    searchedConnections.clients.length > 0
  ) {
    for (let i = 0; i < searchedConnections.clients.length; i++) {
      connectionsList.push(
        <Option key={searchedConnections.clients[i].id}>
          {searchedConnections.clients[i].firstName +
            " " +
            searchedConnections.clients[i].lastName}
        </Option>
      );
    }
  }

  const onSearch = (val) => {
    console.log("search:", val);
    if (val.length > 2 && notSearch) {
      notSearch = false;
      Props.onSearchClient(val)
        .then((res) => {
          notSearch = true;
        })
        .catch((err) => {
          notSearch = true;
        });
    }
  };

  const removeConnection = (connection) => {
    let data = {
      id: connection.id,
      delete: true,
    };
    Props.onRemoveConnection(data, connection.linkClientName);
  };

  const onChangeHandler = (value) => {
    setConnection(value);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let selectedClient =
      searchedConnections &&
      searchedConnections.clients &&
      searchedConnections.clients.find((obj) => obj.id === connection);
    let data = {
      clientId: Props.clientInfo.id,
      linkClientId: connection,
      relation: relation,
    };

    Props.onAddConnection(data, selectedClient);
    setIsModalVisible(false);
    setTimeout(() => {
      setConnection("");
    }, 3000);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div className="profile-additional-box">
        <div className="form-cont">
          <div className="profile-down-arrow-cont" style={{ marginTop: 10 }}>
            <img src={Images.whiteArrow} className="profile-down-arrow-icon" />
          </div>
          <div className="input-cont-width" style={{ display: "flex" }}>
            <div style={{ width: "93%" }}>
              <p className="label">Connection</p>
              <Form.Item>
                <div className="input-border" style={{ border: 0 }}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={onChangeHandler}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={connection}
                  >
                    {connectionsList}
                  </Select>
                </div>
              </Form.Item>
              <div>
                {connectionsData &&
                  connectionsData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="profile-tags"
                        style={{ cursor: "pointer" }}
                      >
                        <Tooltip placement="right" title={item.relation}>
                          <div className="button-first-second-row">
                            <div onClick={() => removeConnection(item)}>
                              <CloseOutlined style={{ fontSize: "10px" }} />
                            </div>
                            <div
                              onClick={() => {
                                Props.onGetClientProfile(
                                  item.linkClientId
                                ).then(() => {
                                  window.location.reload();
                                });
                              }}
                              className="tags-text"
                            >
                              <span>{item.linkClientName}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    );
                  })}
                {/*<div className="button-second">
                  <img
                    src={Images.crossWhite}
                    className="btn-cross-icon"
                  />
                  <span className="btn-fst-scnd-text">Ana Aoi</span>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Relation"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="SAVE"
      >
        <div className="profile-input-border">
          <input
            className="profile-input"
            placeholder=""
            type="text"
            value={relation}
            style={{ width: "100%" }}
            onChange={(e) => setRelation(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Connections;
