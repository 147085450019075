import React from "react";
import Select from "react-select";
import HeaderBar from "../Components/Header/HeaderBar";
import add from "../images/add.png";
import edit from "../images/edit.png";
import rightNav from "../images/log-right-nav-1.png";
import rightNavTwo from "../images/log-right-nav-2.png";
import rightNavThr from "../images/log-right-nav-3.png";
import rightNavFour from "../images/log-right-nav-4.png";
import rightNavFiv from "../images/log-right-nav-5.png";
import rightNavSix from "../images/log-right-nav-6.png";
import rightNavSev from "../images/log-right-nav-7.png";
import rightNavEight from "../images/log-right-nav-8.png";
import rightNavNine from "../images/log-right-nav-9.png";
import "./ClientsStyles.css";
import Sidebar from "../Components/SideBar";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import { Images } from "../Themes";
import { Button, Spin } from "antd";

import headOption from "../Components/Header/HeaderTabOptions";
import ClientActivity from "../Components/ClientActivity/ClientActivity";
import ProfileSideBar from "../Components/ProfileSideBar";

// const headOption = [
//   {
//     tabName: "Client Profile",
//     imageName: Images.clientProfile,
//     linkName: "/profile"
//   },
//   { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
//   { tabName: "Admission", imageName: Images.admission, linkName: "/admission" },
//   { tabName: "Documents", imageName: Images.documents, linkName: "/documents" },
//   { tabName: "Email", imageName: Images.email, linkName: "/client-email" },
//   {
//     tabName: "Activities",
//     imageName: Images.activities,
//     linkName: "/activities"
//   },
//   {
//     tabName: "File Notes",
//     imageName: Images.documents,
//     linkName: "/file-notes"
//   },
//   {
//     tabName: "Accounts",
//     imageName: Images.accounts,
//     linkName: "/client-account"
//   },
//   { tabName: "Questionnaire", imageName: Images.questionnare, linkName: "" },
//   {  tabName: "Chat", imageName: Images.supplier, linkName: "" },
//   { tabName: "Print Case", imageName: Images.print, linkName: "/print-case" }
// ];

class Activities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      selectedOption: null,
      headerOptions: [],
      profileData: null,
      loading: true,
    };
    this.props.onSetActiveKey(null);
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    let selectedTab = {
      headName: "Client Profile",
      headPath: "/profile",
    };
    this.props.onSetClientTab(selectedTab);
    this.props.getClientSource();
    this.props.onSetClientTab(selectedTab);
    if (clientprofileid) {
      this.props
        .onGetClientActivity(clientprofileid)
        .then((res) => {
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  }

  componentDidMount() {
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          // { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }
    // var data = JSON.parse(sessionStorage.getItem("profileData"));
    // this.setState({ profileData: data });

    let familyId = sessionStorage.getItem("familyId");
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      this.props.onGetClientProfile(clientProfileIdMain);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data);
    }
  }

  componentDidUpdate(prevProps) {
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (
      prevProps.clientProfileData != this.props.clientProfileData &&
      memberType == "client"
    ) {
      this.setProfileDataRdx(this.props.clientProfileData);
    } else if (
      prevProps.partnerProfileData != this.props.partnerProfileData &&
      memberType == "spouse"
    ) {
      this.setProfileDataRdx(this.props.partnerProfileData);
    } else if (
      prevProps.clientFamilyData != this.props.clientFamilyData &&
      memberType == "child"
    ) {
      let findClient =
        this.props.clientFamilyData &&
        this.props.clientFamilyData.items.find(
          (obj) => obj.id == clientprofileid
        );
      if (findClient) {
        this.setProfileDataRdx(findClient);
      }
    }
    if (
      (prevProps.profileDataRdx && !this.state.profileData) ||
      prevProps.profileDataRdx != this.props.profileDataRdx
    ) {
      this.setState({ profileData: JSON.parse(this.props.profileDataRdx) });
    }
  }

  setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  showAlert = () => {
    alert("show alert detail");
  };

  render() {
    const { selectedOption, headerOptions, profileData, loading } = this.state;
    const {
      clientActivityData,
      visaStatusData,
      onUpdateCaseStatus,
      onGetVisaApplication,
      visaAppData,
      employerJobHistoryCurrentRes,
      onGetClientJobHistoryCurrent,
      onSetActiveKey,
      onGetVisaStatus,
      activeKey,
      onGetVisaType,
      onGetReminder,
      onGetAdmissionProgram,
      onGetAdmissionStatuses,
      onGetVisaTypeByCountry,
    } = this.props;
    return (
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div className="page-container">
              {headerOptions && (
                <HeaderBarTabs data={headerOptions} activeTab="Activities" />
              )}
              <div
                className="profile-container"
                style={{ paddingTop: 0, margin: 10 }}
              >
                <div
                  className={
                    activeKey
                      ? "content-width-open-sidebar"
                      : "content-width-close-sidebar"
                  }
                >
                  {profileData ? (
                    <div style={{ width: "100%" }}>
                      <p>Client Name: {profileData && profileData.fullName}</p>
                    </div>
                  ) : (
                    <div style={{ height: 30 }} />
                  )}
                  <div className="cl-act-container" style={{ margin: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: 10,
                      }}
                    >
                      <div className="profile-print-box">
                        <img
                          src={Images.printWhite}
                          className="profile-print-icon"
                        />
                      </div>
                    </div>
                    {clientActivityData &&
                      clientActivityData.map((clientActivity, index) => {
                        return (
                          <ClientActivity clientActivity={clientActivity} />
                        );
                      })}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    width: activeKey ? "438px" : "115px",
                    marginLeft: 15,
                  }}
                >
                  <ProfileSideBar
                    onGetClientTag={this.props.onGetClientTag}
                    clientTagRes={this.props.clientTagRes}
                    getClientRes={this.props.getClientRes}
                    onGetProfileClientTag={this.props.onGetProfileClientTag}
                    onAddProfileClientTag={this.props.onAddProfileClientTag}
                    addProfielTagRes={this.props.addProfielTagRes}
                    onRemoveProfileClientTag={
                      this.props.onRemoveProfileClientTag
                    }
                    removeProfileTagRes={this.props.removeProfileTagRes}
                    visaStatusData={visaStatusData}
                    onUpdateCaseStatus={onUpdateCaseStatus}
                    visaAppData={visaAppData}
                    onGetVisaApplication={onGetVisaApplication}
                    onGetClientJobHistoryCurrent={onGetClientJobHistoryCurrent}
                    employerJobHistoryCurrentRes={employerJobHistoryCurrentRes}
                    onSetActiveKey={onSetActiveKey}
                    activeKey={activeKey}
                    onGetClientTask={this.props.onGetClientTask}
                    onUpdateTask={this.props.onUpdateTask}
                    onGetAllUsers={this.props.onGetAllUsers}
                    onAddTaskFollower={this.props.onAddTaskFollower}
                    onRemoveTasks={this.props.onRemoveTasks}
                    onAddDailyTasks={this.props.onAddDailyTasks}
                    onGetClientFamily={this.props.onGetClientFamily}
                    onUpdateCompletedTask={this.props.onUpdateCompletedTask}
                    onAddTaskFileNote={this.props.onAddTaskFileNote}
                    onAddTaskComment={this.props.onAddTaskComment}
                    onGetTaskComments={this.props.onGetTaskComments}
                    onGetTaskFollowers={this.props.onGetTaskFollowers}
                    onGetReminder={onGetReminder}
                    onGetVisaStatus={onGetVisaStatus}
                    onGetAdmissionProgram={onGetAdmissionProgram}
                    onGetAdmissionStatuses={onGetAdmissionStatuses}
                    onGetVisaType={onGetVisaType}
                    onGetVisaTypeByCountry={onGetVisaTypeByCountry}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default Activities;
