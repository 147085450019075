import React from "react";
import mehronCross from "../svgs/mehron-cross.svg";
import grayCross from "../svgs/gray-cross.svg";
import personFill from "../svgs/person-fill.svg";
import rightArrow from "../svgs/right-arrow.svg";
import downArrowGray from "../svgs/down-arrow-gray.svg";
import arrowBlue from "../svgs/arrow-blue.svg";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import "./CaseManagementStyles.css";
import { Images } from "../Themes";
import {
  Spin,
  Dropdown,
  Menu,
  Button,
  Modal,
  Form,
  DatePicker,
  Input,
  Popconfirm,
  AutoComplete,
  Select,
  Pagination,
  Popover,
  Switch,
  Row,
  Col,
  message,
} from "antd";
import { DownOutlined, CloseCircleFilled } from "@ant-design/icons";
import ProgressBar from "../Components/Shared/Progressbar";
import {
  onChangeVisaCaseStatus,
  onChangeAdmissionCaseStatus,
  onChangeChecklistVisaStatus,
  onSelectVisaChecklist,
  onChangeChecklistAdmissionStatus,
  onSelectAdmissionChecklist,
} from "./dropdownEventHandlers";
import { onHover, onMouseOuts } from "./mouseEvents";
import { openNotificationWithIcon } from "../Common/reUseFunction";
import headOption from "../Components/Header/HeaderTabOptions";
import moment from "moment";
import { updateClientProfilesState } from "../store/Actions";
import ProfileSideBar from "../Components/ProfileSideBar";
import activityData from "../Components/ClientActivity/ActivityData";
const { Option } = Select;
const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

class CaseManagement extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      selectedOption: null,
      clients: [],
      ProcessingPersons: [],
      tempClients: [],
      visaStatus: [],
      admissionCaseStatus: [],
      checkLists: [],
      visaTypeData: [],
      headerOptions: [],
      searchOptions: [],
      clientsData: [],
      caseStatusData: {},
      admissionStatusData: {},
      newTaskObj: {},
      newVisa: {},
      NewVisaOptions: [],
      NewVisaCategoryOptions: [],
      newAdmission: {},
      NewAdmissionOptions: {
        schoolTypes: [],
        schools: [],
        schoolLevels: [],
      },
      advanceSearchObj: {
        pageSize: 10,
        pageNumber: 1,
        ezmId: "",
        status: "",
        dateFrom: "1900-01-01T00:00:00+00",
        dateTo: "1900-01-01T00:00:00+00",
        visaType: 0,
        caseStatusId: 0,
      },
      currentPage: 1,
      pageSize: 10,
      totalPages: 1,
      isModalVisible: false,
      isAdmissionCaseModalVisible: false,
      isTaskModalOpen: false,
      isRescheduleTaskModalOpen: false,
      pageLoading: false,
      isAddNewVisaModalOpen: false,
      visaModalLoading: false,
      isAddNewAdmissionModalOpen: false,
      admissionModalLoading: false,
      isAddCaseModalOpen: false,
      triggerTask: false,
      memberType: "Client",
      progress: false,
      profileData: null,
    };
    this.props.onSetActiveKey(null);
  }
  triggerCmsForTask = () => {
    let { onGetClientProfiles } = this.props;
    let clId = sessionStorage.getItem("clientprofileid");
    let cId = clId ? clId : null;
    let payload = {
      searchParam: "-",
      pageSize: 10,
      pageNumber: 1,
      subjectId: cId,
    };
    this.setState({ pageLoading: true });
    onGetClientProfiles(payload);
  };

  componentDidMount() {
    let clId = sessionStorage.getItem("clientprofileid");
    let cId = clId ? clId : null;
    let payload = {
      searchParam: "-",
      pageSize: 10,
      pageNumber: 1,
    };
    if (window.location.pathname == "/Case-management") {
      payload.subjectId = cId;
    } else {
      this.props.onSetActiveKey(false);
    }
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          // { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }
    const {
      onGetClientProfiles,
      onGetCaseStatuses,
      onGetAdmissionCaseStatus,
      onGetCheckList,
      onGetSchoolTypesData,
      onGetProcessingPersons,
      onGetVisaType,
      onGetAllClients,
      onGetBVisaCountries,
    } = this.props;
    this.setState({ pageLoading: true });
    onGetClientProfiles(payload);
    onGetCaseStatuses();
    onGetAdmissionCaseStatus();
    onGetCheckList();
    onGetSchoolTypesData();
    onGetProcessingPersons();
    onGetVisaType(localStorage.getItem("selectedBranchId"));
    onGetAllClients();
    onGetBVisaCountries();
    // var data = JSON.parse(sessionStorage.getItem("profileData"));
    // this.setState({ profileData: data });

    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      this.props.onGetClientProfile(clientProfileIdMain);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data);
    }
  }

  componentDidUpdate(prevProps) {
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (
      prevProps.clientProfileData != this.props.clientProfileData &&
      memberType == "client"
    ) {
      this.setProfileDataRdx(this.props.clientProfileData);
    } else if (
      prevProps.partnerProfileData != this.props.partnerProfileData &&
      memberType == "spouse"
    ) {
      this.setProfileDataRdx(this.props.partnerProfileData);
    } else if (
      prevProps.clientFamilyData != this.props.clientFamilyData &&
      memberType == "child"
    ) {
      let findClient =
        this.props.clientFamilyData &&
        this.props.clientFamilyData.items.find(
          (obj) => obj.id == clientprofileid
        );
      if (findClient) {
        this.setProfileDataRdx(findClient);
      }
    }

    if (
      (prevProps.profileDataRdx && !this.state.profileData) ||
      prevProps.profileDataRdx != this.props.profileDataRdx
    ) {
      this.setState({ profileData: JSON.parse(this.props.profileDataRdx) });
    }
  }

  setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    !this.state.pageLoading && this.setState({ pageLoading: true });
    nextProps &&
      nextProps.clientProfilesError &&
      this.setState({ pageLoading: false });
    let clientProfiles =
      nextProps && nextProps.clientProfiles && nextProps.clientProfiles.clients;
    let visaStatus =
      nextProps && nextProps.visaStatus && nextProps.visaStatus.items;
    let admissionCaseStatus =
      nextProps &&
      nextProps.admissionCaseStatus &&
      nextProps.admissionCaseStatus.items;
    let checkLists =
      nextProps && nextProps.checkList && nextProps.checkList.items;
    let schoolTypesData =
      nextProps && nextProps.schoolTypesData && nextProps.schoolTypesData.items;
    let totalPages =
      nextProps && nextProps.clientProfiles && nextProps.clientProfiles.count;
    let processingPersons =
      nextProps &&
      nextProps.processingPersons &&
      nextProps.processingPersons.users;
    let visaTypeData =
      nextProps && nextProps.visaTypeData && nextProps.visaTypeData.items;
    let clientsData =
      nextProps && nextProps.clientsData && nextProps.clientsData.items;

    console.log(clientsData);

    clientProfiles &&
      this.setState({
        clients: clientProfiles,
        pageLoading: clientProfiles ? false : true,
        totalPages: totalPages,
      });
    visaStatus &&
      this.setState({
        visaStatus: visaStatus,
      });
    admissionCaseStatus &&
      this.setState({ admissionCaseStatus: admissionCaseStatus });
    checkLists && this.setState({ checkLists: checkLists });
    schoolTypesData &&
      this.setState({
        NewAdmissionOptions: {
          ...this.state.NewAdmissionOptions,
          schoolTypes: schoolTypesData,
        },
      });
    processingPersons &&
      this.setState({ ProcessingPersons: processingPersons });
    visaTypeData && this.setState({ visaTypeData: visaTypeData });
    clientsData && this.setState({ clientsData: clientsData });
  }
  componentWillUnmount = () => {
    this.props.dispatch(updateClientProfilesState(undefined));
  };
  filterProgressData = (data) => {
    let progress = 0;
    let values = 0;

    if (!data.checkList) data.checkList = { items: [] };
    let checkListData = data.checkList.items;
    checkListData.map((item) => {
      item.progress = 0;
      let tasks =
        item.tasks.items && item.tasks.items.length ? item.tasks.items : [];
      let done = tasks.filter((task) => task.isCompleted);
      item.progress = done.length
        ? parseFloat(((done.length * 100) / tasks.length).toFixed(2))
        : 0;
      values += item.progress;
    });
    progress =
      values / checkListData.length ? values / checkListData.length : 0;
    data.progress = parseFloat(progress.toFixed(2));
    return data.progress;
  };

  handleCommentChange = (val) => {
    this.setState({ val }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  content = ({ image }) => (
    <div className="cm-popover">
      <img
        src={image && image.length ? image : personFill}
        class="cm-profile-fill"
      />
    </div>
  );
  content2 = ({ imgURL }) => (
    <div className="cm-popover">
      <img
        src={imgURL && imgURL.length ? imgURL : personFill}
        class="cm-profile-fill"
      />
    </div>
  );
  onChangeStatus = (status, id) => {
    this.setState({ pageLoading: true });
    this.props
      .onUpdateClientActiveStatus({ id: id, status: status })
      .then(() => {
        let { clients } = this.state;
        let data = clients.filter((el) => el.id !== id);
        this.setState({ clients: data, pageLoading: false });
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };
  getClientProfileInfo = (client, clientIndex) => {
    const { visaStatus, admissionCaseStatus } = this.state;
    const {
      firstName,
      lastName,
      imageBlobUrl,
      visas,
      programs,
      clientId,
      id,
      ezmId,
      processingPerson,
      status,
      memberType,
      familyId,
    } = client;
    return (
      <>
        <div class="top-row" style={{ marginTop: 0 }}>
          <div class="top-container client-card">
            <div class="cm-profile-cont">
              <img
                src={imageBlobUrl.length ? imageBlobUrl : Images.dummyUserImage}
                class="cm-profile-img"
              />
            </div>
            <div style={{ marginLeft: 20 }}>
              <div class="label-name-cont" style={{ height: 24 }}>
                {processingPerson && processingPerson.length ? (
                  <span class="label-blue">Processing Person</span>
                ) : null}
                {processingPerson.map((el) => (
                  <div class="cm-profile-fill-cont" style={{ marginLeft: 20 }}>
                    <Popover
                      style={{ width: 65 }}
                      placement="bottom"
                      content={this.content(el)}
                      title={el.fullName}
                    >
                      <img
                        src={
                          el.image && el.image.length
                            ? el.image
                            : Images.personFill
                        }
                        class="cm-profile-fill"
                        style={{ width: 12, height: 12, borderRadius: 50 }}
                      />
                    </Popover>
                  </div>
                ))}
              </div>

              {window.location.pathname !== "/Case-management" && (
                <div>
                  <Popconfirm
                    title={`Are you sure to ${
                      JSON.parse(status) ? "deactivate" : "activate"
                    } this case?`}
                    onConfirm={() => {
                      this.onChangeStatus(!JSON.parse(status), id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Deactivate"
                      checked={JSON.parse(status)}
                      style={{
                        backgroundColor: JSON.parse(status)
                          ? "#3ab449"
                          : "#9d0b0e",
                      }}
                    />
                  </Popconfirm>
                </div>
              )}
              <div
                onClick={() =>
                  this.openClientFile(id, clientId, memberType, familyId)
                }
              >
                <span
                  style={{
                    marginTop: 6,
                    marginBottom: 6,
                    color: "#000000",
                    fontWeight: "600",
                    cursor: "pointer",
                    fontSize: 14,
                  }}
                >
                  {firstName && firstName} {lastName && lastName}
                </span>
              </div>

              <div>
                <span class="cm-id-text">{ezmId && ezmId}</span>
              </div>
            </div>
          </div>
          <div class="top-row-button">
            <div class="cm-blue-btn">
              <Button
                onClick={() => this.openAddNewVisaModal(id)}
                type="primary"
                class="cm-blue-btn-text"
              >
                ADD NEW VISA
              </Button>
            </div>
            <div class="cm-blue-btn">
              <Button
                onClick={() => this.openNewAdmissionModal(id)}
                type="primary"
                class="cm-blue-btn-text"
              >
                ADD NEW ADMISSION
              </Button>
            </div>
          </div>
          {window.location.pathname !== "/Case-management" && (
            <div class="cm-cross">
              <Popconfirm
                title="Are you sure to remove this case from here?"
                onConfirm={() => {
                  this.removeClient(client);
                }}
                okText="Yes"
                cancelText="No"
              >
                <CloseCircleFilled className="remove-client" />
              </Popconfirm>
            </div>
          )}
          {visas.items.length
            ? visas.items.map((visaItem, visaCaseInd) => (
                <>
                  <div class="cm-visa-cont">
                    <div class="cm-student-visa-cnt">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: 300,
                        }}
                      >
                        <Popconfirm
                          title="Are you sure you want to delete visa from client file as well?"
                          onConfirm={() => {
                            this.removeCase(
                              visaItem,
                              clientIndex,
                              visaCaseInd,
                              "V"
                            );
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <img
                            src={mehronCross}
                            style={{ width: 13, height: 13 }}
                          />
                        </Popconfirm>
                        <span
                          class="cm-student-visa-text"
                          style={{ marginLeft: 30 }}
                        >
                          {visaItem.branchVisaTypeName}
                        </span>
                      </div>
                      <div class="cm-status-approved">
                        <Dropdown
                          overlay={this.menu(
                            visaStatus,
                            visaItem,
                            clientIndex,
                            visaCaseInd
                          )}
                        >
                          <Button
                            style={{
                              backgroundColor: visaItem.visaStatusColor,
                              fontSize: 14,
                            }}
                          >
                            {visaItem.visaStatusName} <DownOutlined />
                          </Button>
                        </Dropdown>
                      </div>
                      {visaItem.checkList && visaItem.checkList.items ? (
                        <div style={{ width: 350, display: "flex" }}>
                          <ProgressBar
                            bgcolor="#CCCCCC"
                            completed={this.filterProgressData(visaItem)}
                            color="#9D0C0E"
                          />
                          {visaItem.isCaseExpand ? (
                            <img
                              onClick={() => this.expandVisaCase(visaItem)}
                              src={arrowBlue}
                              class="cm-down-arrow"
                              style={{ transform: `rotate(180deg)` }}
                            />
                          ) : (
                            <img
                              onClick={() => this.expandVisaCase(visaItem)}
                              src={downArrowGray}
                              class="cm-down-arrow"
                            />
                          )}
                        </div>
                      ) : (
                        <div style={{ width: 350, textAlign: "right" }}>
                          <Switch
                            checkedChildren="Active"
                            unCheckedChildren="Progress"
                            checked={false}
                            style={{
                              backgroundColor: "#a8a8a8",
                            }}
                            onChange={() => this.expandVisaCase(visaItem)}
                          />
                          {visaItem.isCaseExpand ? (
                            <img
                              onClick={() => this.expandVisaCase(visaItem)}
                              src={arrowBlue}
                              class="cm-down-arrow"
                              style={{ transform: `rotate(180deg)` }}
                            />
                          ) : (
                            <img
                              onClick={() => this.expandVisaCase(visaItem)}
                              src={downArrowGray}
                              class="cm-down-arrow"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {visaItem.isCaseExpand ? (
                    <>
                      <div
                        class="cm-student-visa-cnt"
                        style={{
                          backgroundColor: "transparent",
                          marginLeft: 20,
                          marginRight: 20,
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {this.getVisaSubCaseDetail(visaItem)}
                      </div>
                      {visaItem.isSubCaseExpand ? (
                        <div class="cm-visa-cont">
                          {this.getVisaCaseCheckList(
                            visaItem,
                            clientId,
                            id,
                            clientIndex,
                            visaCaseInd,
                            memberType,
                            firstName,
                            lastName
                          )}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              ))
            : null}
          {programs.items.length
            ? programs.items.map((programItem, programCaseInd) => (
                <>
                  <div class="cm-visa-cont">
                    <div class="cm-student-visa-cnt">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: 300,
                        }}
                      >
                        <Popconfirm
                          title="Are you sure you want to delete admission from client file as well?"
                          onConfirm={() => {
                            this.removeCase(
                              programItem,
                              clientIndex,
                              programCaseInd,
                              "A"
                            );
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <img
                            src={mehronCross}
                            style={{ width: 13, height: 13 }}
                          />
                        </Popconfirm>
                        <span
                          class="cm-student-visa-text"
                          style={{ marginLeft: 30 }}
                        >
                          {programItem.schoolName}
                        </span>
                      </div>
                      <div class="cm-status-approved">
                        <Dropdown
                          overlay={this.admissionDropdownMenu(
                            admissionCaseStatus,
                            programItem,
                            clientIndex,
                            programCaseInd
                          )}
                        >
                          <Button
                            style={{
                              backgroundColor: programItem.color
                                ? programItem.color
                                : "#FFC0CB",
                            }}
                          >
                            {programItem.programStatusName} <DownOutlined />
                          </Button>
                        </Dropdown>
                      </div>
                      {programItem.checkList && programItem.checkList.items ? (
                        <div style={{ width: 350, display: "flex" }}>
                          <ProgressBar
                            bgcolor="#CCCCCC"
                            completed={this.filterProgressData(programItem)}
                            color="#9D0C0E"
                          />
                          {programItem.isCaseExpand ? (
                            <img
                              onClick={() =>
                                this.expandAdmissionCase(programItem)
                              }
                              src={arrowBlue}
                              class="cm-down-arrow"
                              style={{ transform: `rotate(180deg)` }}
                            />
                          ) : (
                            <img
                              onClick={() =>
                                this.expandAdmissionCase(programItem)
                              }
                              src={downArrowGray}
                              class="cm-down-arrow"
                            />
                          )}
                        </div>
                      ) : (
                        <div style={{ width: 350, textAlign: "right" }}>
                          <Switch
                            checkedChildren="Active"
                            unCheckedChildren="Progress"
                            checked={false}
                            style={{
                              backgroundColor: "#a8a8a8",
                            }}
                            onChange={() =>
                              this.expandAdmissionCase(programItem)
                            }
                          />
                          {programItem.isCaseExpand ? (
                            <img
                              onClick={() =>
                                this.expandAdmissionCase(programItem)
                              }
                              src={arrowBlue}
                              class="cm-down-arrow"
                              style={{ transform: `rotate(180deg)` }}
                            />
                          ) : (
                            <img
                              onClick={() =>
                                this.expandAdmissionCase(programItem)
                              }
                              src={downArrowGray}
                              class="cm-down-arrow"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {programItem.isCaseExpand ? (
                    <>
                      <div
                        class="cm-student-visa-cnt"
                        style={{
                          backgroundColor: "transparent",
                          marginLeft: 20,
                          marginRight: 20,
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {this.getAdmissionSubCaseDetail(programItem)}
                      </div>
                      {/* <div>
                        <span class="cm-checklist-label">Check Lists</span>
                      </div> */}
                      {programItem.isSubCaseExpand ? (
                        <div class="cm-visa-cont">
                          {this.getAdmissionCaseCheckList(
                            programItem,
                            clientId,
                            id,
                            clientIndex,
                            programCaseInd,
                            memberType,
                            firstName,
                            lastName
                          )}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              ))
            : null}
        </div>
      </>
    );
  };

  /* ------------------------
  ---------------------------
  Start: Visa cases checklist
  ---------------------------
  ---------------------------*/

  getVisaSubCaseDetail = (visaItem) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: 350,
          }}
        >
          <span
            class="cm-student-visa-text"
            style={{
              marginLeft: 15,
              fontWeight: "600",
              color: "#000",
              fontSize: 14,
            }}
          >
            {visaItem.branchVisaTypeName}
          </span>
        </div>
        {/* <div>
          <span
            style={{
              color: "#2A5E85",
              fontSize: 12,
              fontWeight: "600",
            }}
          >
            Status
          </span>
          <div class="cm-status-completed">
            <span class="cm-status-text">
              {this.filterProgressData(visaItem) > 99 ? "Completed" : "Active"}
            </span>
          </div>
        </div> */}
        <div style={{ width: 350, display: "flex" }}>
          <ProgressBar
            bgcolor="#00ADEF"
            completed={visaItem.progress}
            color="#FFFFFF"
          />
          {visaItem.isSubCaseExpand ? (
            <img
              onClick={() => this.expandVisaSubCase(visaItem)}
              src={arrowBlue}
              class="cm-down-arrow"
              style={{ transform: `rotate(180deg)` }}
            />
          ) : (
            <img
              onClick={() => this.expandVisaSubCase(visaItem)}
              src={downArrowGray}
              class="cm-down-arrow"
            />
          )}
        </div>
      </>
    );
  };

  getVisaCaseCheckList = (
    visaItem,
    clientId,
    id,
    clientIndex,
    visaCaseInd,
    memberType,
    firstName,
    LastName
  ) => {
    const { checkLists, visaStatus } = this.state;
    const { checkList, newVisaCaseChecklistObj } = visaItem;
    const { items } = checkList;
    const subjectId = id;
    return (
      <>
        <div
          class="cm-student-visa-cnt"
          style={{
            paddingTop: 15,
            paddingRight: 30,
            paddingBottom: 15,
            paddingLeft: 30,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: 300,
            }}
          >
            <span class="cm-header-text">Client Status</span>
          </div>
          <div>
            <span class="cm-header-text">Total Tasks</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: 350,
            }}
          >
            <span class="cm-header-text">Checklist Progress</span>
          </div>
        </div>
        {items
          ? items.map((list, checklistIndex) => (
              <div
                class="cm-student-visa-cnt"
                style={{
                  paddingTop: 10,
                  paddingRight: 30,
                  paddingBottom: 10,
                  paddingLeft: 30,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                  }}
                >
                  <Popconfirm
                    title="Are you sure to delete this checklist and all associated tasks with it?"
                    onConfirm={() => {
                      this.removeCaseChecklist(list, items, checklistIndex);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a href="#">
                      <img
                        src={mehronCross}
                        style={{ width: 13, height: 13 }}
                      />
                    </a>
                  </Popconfirm>
                  <span class="cm-clintlist-content" style={{ marginLeft: 30 }}>
                    {list.statusName}
                  </span>
                </div>
                <div style={{ width: 30 }}>
                  <span class="cm-clintlist-content">
                    {list.tasks.items ? list.tasks.items.length : 0}
                  </span>
                </div>
                <div style={{ width: 350 }}>
                  <ProgressBar
                    bgcolor="#00ADEF"
                    completed={list.progress}
                    color="#FFFFFF"
                  />
                </div>
              </div>
            ))
          : null}
        <div class="cm-childlist-table">
          <div class="cm-main-table">
            {items
              ? items.map((list, checklistInd) => (
                  <>
                    <div class="cm-header">
                      <div class="header-tab-50">
                        <span
                          style={{
                            marginLeft: 10,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          {`${list.statusName} : ${list.checkListName}`}
                        </span>
                      </div>

                      <div class="header-tab-20">
                        <Popconfirm
                          title="Are you sure to delete all tasks?"
                          onConfirm={() => {
                            this.removeCaseTasks(list, memberType);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <a href="#">
                            <img
                              src={mehronCross}
                              class="cm-icon-size"
                              style={{ marginLeft: 5 }}
                            />
                          </a>
                        </Popconfirm>
                        <img
                          src={Images.blueAdd}
                          class="cm-icon-size"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            this.setState({
                              isTaskModalOpen: true,
                              newTaskObj: {
                                subjectId: subjectId,
                                isPontential: false,
                                isCompleted: false,
                                caseCheckListId: list.id,
                                checkListId: list.id,
                                clientIndex: clientIndex,
                                caseInd: visaCaseInd,
                                casetype: "V",
                                checklistInd,
                                memberType: memberType,
                              },
                            });
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          this.expandVisaChecklistTask(list);
                        }}
                        class="header-tab-5"
                      >
                        {list.isChecklistTaskExpand ? (
                          <img
                            src={arrowBlue}
                            class="cm-down-arrow"
                            style={{ transform: `rotate(180deg)` }}
                          />
                        ) : (
                          <img src={downArrowGray} class="cm-down-arrow" />
                        )}
                      </div>
                    </div>

                    {list.tasks.items && !list.isChecklistTaskExpand
                      ? list.tasks.items.map((task) => (
                          <>
                            <div
                              className="cm-table-content-row"
                              style={{ marginBottom: 0 }}
                            >
                              <div className="content-index-50">
                                <div class="cm-greenbox-cont">
                                  <div
                                    onClick={() => {
                                      task.memberType = memberType;
                                      this.onCompleteTask(
                                        task,
                                        id,
                                        clientId,
                                        firstName,
                                        LastName
                                      );
                                    }}
                                    onMouseOver={(e) => onHover(e, task)}
                                    onMouseOut={(e) => onMouseOuts(e, task)}
                                    class="cm-green-box"
                                    style={{
                                      backgroundColor: task.isCompleted
                                        ? "#3ab449"
                                        : "white",
                                    }}
                                  ></div>
                                  <span
                                    onClick={() => {
                                      task.isTaskCommentOpen = !task.isTaskCommentOpen;
                                      if (!task.isTaskCommentOpen)
                                        return this.setState({
                                          clients: this.state.clients,
                                        });
                                      this.setState({
                                        pageLoading: true,
                                      });
                                      this.getTaskComments(task);
                                    }}
                                    class="cm-task-text"
                                  >
                                    {task.taskTitle
                                      ? task.taskTitle
                                      : task.name}
                                  </span>
                                </div>
                              </div>

                              <div class="content-index-20">
                                <img
                                  src={Images.brownCalendar}
                                  class="cm-icon-size"
                                  style={{ marginLeft: 5 }}
                                  onClick={() =>
                                    this.setState({
                                      isRescheduleTaskModalOpen: true,
                                      newTaskObj: task,
                                    })
                                  }
                                />
                                <Popconfirm
                                  title="Are you sure to delete this task?"
                                  onConfirm={() => {
                                    this.removeCaseSingleTask(list, task);
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <a href="#">
                                    <img
                                      src={mehronCross}
                                      class="cm-icon-size"
                                      style={{ marginLeft: 5 }}
                                    />
                                  </a>
                                </Popconfirm>
                                {task.isCompleted ? (
                                  <Popconfirm
                                    title="Are you sure to reset this task?"
                                    onConfirm={() => {
                                      task.isCompleted = false;
                                      task.memberType = memberType;
                                      this.onUpdateTask(task);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a href="#">
                                      <img
                                        src={Images.refreshArrow}
                                        class="cm-icon-size"
                                        style={{ marginLeft: 5 }}
                                      />
                                    </a>
                                  </Popconfirm>
                                ) : null}
                              </div>

                              <div className="content-index-5"></div>
                            </div>
                            {task.isTaskCommentOpen && (
                              <>
                                <div
                                  className="cm-table-content-row"
                                  style={{
                                    marginTop: 0,
                                    alignItems: "flex-start",
                                    border: 0,
                                  }}
                                >
                                  <div>
                                    <div
                                      className="content-index-5"
                                      style={{ marginTop: 5, marginLeft: 0 }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <span class="cm-followers-text">
                                          Followers
                                        </span>
                                        {task.users &&
                                          task.users.items &&
                                          task.users.items.map((el) => (
                                            <div
                                              class="cm-profile-fill-cont"
                                              style={{ marginLeft: 20 }}
                                            >
                                              <Popover
                                                style={{ width: 65 }}
                                                placement="bottom"
                                                content={this.content2(el)}
                                                title={el.fullName}
                                              >
                                                <a href="#">
                                                  <img
                                                    src={
                                                      el.imgURL
                                                        ? el.imgURL
                                                        : personFill
                                                    }
                                                    class="cm-profile-fill"
                                                  />
                                                </a>
                                              </Popover>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="content-index-5"
                                    style={{ marginTop: 3 }}
                                    onClick={() => {
                                      task.isTaskCommentOpen = !task.isTaskCommentOpen;
                                      this.setState({
                                        cliens: this.state.clients,
                                      });
                                    }}
                                  >
                                    <img
                                      src={grayCross}
                                      class="cm-icon-size"
                                      style={{ marginLeft: 5 }}
                                    />
                                  </div>
                                </div>
                                <span class="task-description-cm">
                                  {task.taskDescription}
                                </span>
                                <textarea
                                  value={task.comment ? task.comment : ""}
                                  onChange={(e) => {
                                    task.comment = e.target.value;
                                    this.setState({
                                      clients: this.state.clients,
                                    });
                                  }}
                                  rows={5}
                                  class="cm-textarea"
                                />

                                <div class="cm-gray-btn-cont">
                                  <div></div>
                                  <Button
                                    disabled={
                                      task.comment
                                        ? task.comment.length
                                          ? false
                                          : true
                                        : true
                                    }
                                    style={{
                                      backgroundColor: task.comment
                                        ? task.comment.length
                                          ? "#0071ba"
                                          : "grey"
                                        : "grey",
                                      color: "#fff",
                                      height: 40,
                                      borderRadius: 5,
                                    }}
                                    onClick={() => this.addTaskComment(task)}
                                    // class="cm-gray-btn"
                                  >
                                    <span class="cm-gray-btn-text">
                                      Comment
                                    </span>
                                    {/* <img
                                      src={rightArrow}
                                      class="cm-icon-size"
                                      style={{ marginLeft: 10 }}
                                    /> */}
                                  </Button>
                                </div>
                                {task.comments ? (
                                  <div
                                    className="cm-table-content-rows"
                                    style={{ marginBottom: 0, marginLeft: 30 }}
                                  >
                                    {task.comments.map((el) => (
                                      <>
                                        <div class="comment-bg">
                                          <div class="cm-profile-boxes">
                                            <div class="cm-profile-fill-conts">
                                              {/* {el.imgURL ? ( */}
                                              <img
                                                src={
                                                  el.imgURL
                                                    ? el.imgURL
                                                    : Images.personFill
                                                }
                                                class="cm-profile-fills"
                                              />
                                              {/* ) : null} */}
                                            </div>
                                            <div
                                              style={{ alignSelf: "center" }}
                                            >
                                              <span>{el.userName}</span>
                                            </div>
                                          </div>
                                          <div style={{ border: "none" }}>
                                            <div>
                                              <span class="cm-task-comment">
                                                {el.description}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </>
                        ))
                      : null}
                  </>
                ))
              : null}
          </div>

          <div class="cm-client-cl-status">
            <Row>
              <Col span={10}>
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Select Client Status
                  </p>
                  <div class="select-options">
                    <Select
                      showSearch
                      style={{ borderRadius: 0, textAlign: "start" }}
                      placeholder="Please Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(status) =>
                        onChangeChecklistVisaStatus(this, visaItem, status)
                      }
                      value={
                        newVisaCaseChecklistObj &&
                        newVisaCaseChecklistObj.statusName
                          ? newVisaCaseChecklistObj.statusName
                          : null
                      }
                    >
                      {visaStatus.map(({ name }) => (
                        <Option value={name}>{name}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Col>
              <Col span={10}>
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Select Check List
                  </p>
                  <div class="select-options">
                    <Select
                      showSearch
                      style={{ borderRadius: 0, textAlign: "start" }}
                      placeholder="Please Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children[1]
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(list) =>
                        onSelectVisaChecklist(
                          this,
                          JSON.parse(list),
                          id,
                          visaItem
                        )
                      }
                      value={
                        newVisaCaseChecklistObj &&
                        newVisaCaseChecklistObj.checkListName
                          ? newVisaCaseChecklistObj.checkListName
                          : null
                      }
                    >
                      {checkLists.map((list) => (
                        <Option value={JSON.stringify(list)}>
                          {" "}
                          {`${list.description}:${list.name}`}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Col>
              <Col span={4}>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.attachCheckListWithVisaCase(
                        visaItem,
                        newVisaCaseChecklistObj,
                        memberType
                      )
                    }
                    class="cm-add-btn-text cm-add-btn cm-add-btn-cont"
                    style={{ marginTop: 35 }}
                    disabled={
                      newVisaCaseChecklistObj &&
                      newVisaCaseChecklistObj.statusName &&
                      newVisaCaseChecklistObj.checkListName
                        ? false
                        : true
                    }
                  >
                    ADD
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  /* ------------------------
  ---------------------------
  End: Visa cases checklist
  ---------------------------
  ---------------------------*/

  /* ------------------------
  ---------------------------
  Start: Admission cases checklist
  ---------------------------
  ---------------------------*/

  getAdmissionSubCaseDetail = (programItem) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: 350,
          }}
        >
          <span
            class="cm-student-visa-text"
            style={{
              marginLeft: 15,
              fontWeight: "600",
              color: "#000",
              fontSize: 14,
            }}
          >
            {programItem.schoolName}
          </span>
        </div>
        {/* <div>
          <span
            style={{
              color: "#2A5E85",
              fontSize: 12,
              fontWeight: "600",
            }}
          >
            Status
          </span>
          <div class="cm-status-completed">
            <span class="cm-status-text">Active</span>
          </div>
        </div> */}
        <div style={{ width: 350, display: "flex" }}>
          <ProgressBar
            bgcolor="#00ADEF"
            completed={programItem.progress}
            color="#FFFFFF"
          />
          {programItem.isSubCaseExpand ? (
            <img
              onClick={() => this.expandAdmissionSubCase(programItem)}
              src={arrowBlue}
              class="cm-down-arrow"
              style={{ transform: `rotate(180deg)` }}
            />
          ) : (
            <img
              onClick={() => this.expandAdmissionSubCase(programItem)}
              src={downArrowGray}
              class="cm-down-arrow"
            />
          )}
        </div>
      </>
    );
  };

  getAdmissionCaseCheckList = (
    programItem,
    clientId,
    id,
    clientIndex,
    programCaseInd,
    memberType,
    firstName,
    lastName
  ) => {
    const { checkLists, admissionCaseStatus } = this.state;
    const { newAdmissionCaseChecklistObj, checkList } = programItem;
    const { items } = checkList;
    const subjectId = id;
    return (
      <>
        <div
          class="cm-student-visa-cnt"
          style={{
            paddingTop: 20,
            paddingRight: 10,
            paddingBottom: 20,
            paddingLeft: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: 300,
            }}
          >
            <span class="cm-header-text">Client Status</span>
          </div>
          <div>
            <span class="cm-header-text">Total Tasks</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: 350,
            }}
          >
            <span class="cm-header-text">Checklist Progress</span>
          </div>
        </div>
        {items
          ? items.map((list, checklistIndex) => (
              <div
                class="cm-student-visa-cnt"
                style={{
                  paddingTop: 20,
                  paddingRight: 10,
                  paddingBottom: 20,
                  paddingLeft: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                  }}
                >
                  <Popconfirm
                    title="Are you sure to delete this checklist and all associated tasks with it?"
                    onConfirm={() => {
                      this.removeCaseChecklist(list, items, checklistIndex);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a href="#">
                      <img
                        src={mehronCross}
                        style={{ width: 13, height: 13 }}
                      />
                    </a>
                  </Popconfirm>
                  <span class="cm-clintlist-content" style={{ marginLeft: 30 }}>
                    {list.statusName}
                  </span>
                </div>
                <div>
                  <span class="cm-clintlist-content">
                    {list.tasks.items ? list.tasks.items.length : 0}
                  </span>
                </div>
                <div style={{ width: 350 }}>
                  <ProgressBar
                    bgcolor="#00ADEF"
                    completed={list.progress}
                    color="#FFFFFF"
                  />
                </div>
              </div>
            ))
          : null}
        <div class="cm-childlist-table">
          <div class="cm-main-table">
            {items
              ? items.map((list, checklistInd) => (
                  <>
                    <div class="cm-header">
                      <div class="header-tab-50">
                        <span
                          style={{
                            marginLeft: 10,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          {`${list.statusName} : ${list.checkListName}`}
                        </span>
                      </div>

                      <div class="header-tab-20">
                        <Popconfirm
                          title="Are you sure to delete all tasks?"
                          onConfirm={() => {
                            this.removeCaseTasks(list, memberType);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <a href="#">
                            <img
                              src={mehronCross}
                              class="cm-icon-size"
                              style={{ marginLeft: 5 }}
                            />
                          </a>
                        </Popconfirm>
                        <img
                          src={Images.blueAdd}
                          class="cm-icon-size"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            this.setState({
                              isTaskModalOpen: true,
                              newTaskObj: {
                                subjectId: subjectId,
                                isPontential: false,
                                isCompleted: false,
                                caseCheckListId: list.id,
                                // currentTask: list.tasks.items,
                                checkListId: list.id,
                                clientIndex: clientIndex,
                                caseInd: programCaseInd,
                                casetype: "A",
                                checklistInd,
                                memberType: memberType,
                              },
                            });
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          this.expandVisaChecklistAdmission(list);
                        }}
                        class="header-tab-5"
                      >
                        {list.isChecklistTaskExpand ? (
                          <img
                            src={arrowBlue}
                            class="cm-down-arrow"
                            style={{ transform: `rotate(180deg)` }}
                          />
                        ) : (
                          <img src={downArrowGray} class="cm-down-arrow" />
                        )}
                      </div>
                    </div>

                    {list.tasks.items && !list.isChecklistTaskExpand
                      ? list.tasks.items.map((task) => (
                          <>
                            <div
                              className="cm-table-content-row"
                              style={{ marginBottom: 0, marginLeft: 55 }}
                            >
                              <div className="content-index-50">
                                <div class="cm-greenbox-cont">
                                  <div
                                    onClick={() => {
                                      task.memberType = memberType;

                                      this.onCompleteTask(
                                        task,
                                        id,
                                        clientId,
                                        firstName,
                                        lastName
                                      );
                                    }}
                                    onMouseOver={(e) => onHover(e, task)}
                                    onMouseOut={(e) => onMouseOuts(e, task)}
                                    class="cm-green-box"
                                    style={{
                                      backgroundColor: task.isCompleted
                                        ? "#3ab449"
                                        : "white",
                                    }}
                                  ></div>
                                  <span
                                    onClick={() => {
                                      task.isTaskCommentOpen = !task.isTaskCommentOpen;
                                      if (!task.isTaskCommentOpen)
                                        return this.setState({
                                          clients: this.state.clients,
                                        });
                                      this.setState({
                                        pageLoading: true,
                                      });
                                      this.getTaskComments(task);
                                    }}
                                    class="cm-task-text"
                                  >
                                    {task.taskTitle
                                      ? task.taskTitle
                                      : task.name}
                                  </span>
                                </div>
                              </div>

                              <div class="content-index-20">
                                <img
                                  src={Images.brownCalendar}
                                  class="cm-icon-size"
                                  style={{ marginLeft: 5 }}
                                  onClick={() =>
                                    this.setState({
                                      isRescheduleTaskModalOpen: true,
                                      newTaskObj: task,
                                    })
                                  }
                                />
                                <Popconfirm
                                  title="Are you sure to delete this task?"
                                  onConfirm={() => {
                                    this.removeCaseSingleTask(list, task);
                                  }}
                                  // onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <a href="#">
                                    <img
                                      src={mehronCross}
                                      class="cm-icon-size"
                                      style={{ marginLeft: 5 }}
                                    />
                                  </a>
                                </Popconfirm>
                                {task.isCompleted ? (
                                  <Popconfirm
                                    title="Are you sure to reset this task?"
                                    onConfirm={() => {
                                      task.isCompleted = false;
                                      task.memberType = memberType;
                                      this.onUpdateTask(task);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a href="#">
                                      <img
                                        src={Images.refreshArrow}
                                        class="cm-icon-size"
                                        style={{ marginLeft: 5 }}
                                      />
                                    </a>
                                  </Popconfirm>
                                ) : null}
                              </div>

                              <div className="content-index-5"></div>
                            </div>
                            {task.isTaskCommentOpen && (
                              <>
                                <div
                                  className="cm-table-content-row"
                                  style={{
                                    marginTop: 0,
                                    alignItems: "flex-start",
                                    border: 0,
                                  }}
                                >
                                  <div>
                                    <div
                                      className="content-index-5"
                                      style={{ marginTop: 5, marginLeft: 0 }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <span class="cm-followers-text">
                                          Followers
                                        </span>
                                        {task.users &&
                                          task.users.items &&
                                          task.users.items.map((el) => (
                                            <div
                                              class="cm-profile-fill-cont"
                                              style={{ marginLeft: 20 }}
                                            >
                                              <Popover
                                                style={{ width: 65 }}
                                                placement="bottom"
                                                content={this.content2(el)}
                                                title={el.fullName}
                                              >
                                                <a href="#">
                                                  <img
                                                    src={
                                                      el.imgURL
                                                        ? el.imgURL
                                                        : personFill
                                                    }
                                                    class="cm-profile-fill"
                                                  />
                                                </a>
                                              </Popover>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    onClick={() => {
                                      task.isTaskCommentOpen = !task.isTaskCommentOpen;
                                      this.setState({
                                        clients: this.state.clients,
                                      });
                                    }}
                                    className="content-index-5"
                                    style={{ marginTop: 3 }}
                                  >
                                    <img
                                      src={grayCross}
                                      class="cm-icon-size"
                                      style={{ marginLeft: 5 }}
                                    />
                                  </div>
                                </div>
                                <span class="task-description-cm">
                                  {task.taskDescription}
                                </span>

                                <textarea
                                  value={task.comment ? task.comment : ""}
                                  onChange={(e) => {
                                    task.comment = e.target.value;
                                    this.setState({
                                      clients: this.state.clients,
                                    });
                                  }}
                                  rows={5}
                                  class="cm-textarea"
                                />

                                <div class="cm-gray-btn-cont">
                                  <div></div>
                                  <Button
                                    disabled={
                                      task.comment
                                        ? task.comment.length
                                          ? false
                                          : true
                                        : true
                                    }
                                    style={{
                                      backgroundColor: task.comment
                                        ? task.comment.length
                                          ? "#0071ba"
                                          : "grey"
                                        : "grey",
                                      color: "#fff",
                                      height: 40,
                                      borderRadius: 5,
                                    }}
                                    onClick={() => this.addTaskComment(task)}
                                  >
                                    <span class="cm-gray-btn-text">
                                      Comment
                                    </span>
                                    {/* <img
                                      src={rightArrow}
                                      class="cm-icon-size"
                                      style={{ marginLeft: 10 }}
                                    /> */}
                                  </Button>
                                </div>
                                {task.comments ? (
                                  <div
                                    className="cm-table-content-rows"
                                    style={{ marginBottom: 0 }}
                                  >
                                    {task.comments.map((el) => (
                                      <>
                                        <div class="comment-bg">
                                          <div class="cm-profile-boxes">
                                            <div class="cm-profile-fill-conts">
                                              {/* {el.imgURL ? ( */}
                                              <img
                                                src={
                                                  el.imgURL
                                                    ? el.imgURL
                                                    : Images.personFill
                                                }
                                                class="cm-profile-fills"
                                              />
                                              {/* ) : null} */}
                                            </div>
                                            <div
                                              style={{ alignSelf: "center" }}
                                            >
                                              <span>{el.userName}</span>
                                            </div>
                                          </div>
                                          <div style={{ border: "none" }}>
                                            <div>
                                              <span class="cm-task-comment">
                                                {el.description}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </>
                        ))
                      : null}
                  </>
                ))
              : null}
          </div>

          <div class="cm-client-cl-status">
            <Row>
              <Col span={10}>
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Select Client Status
                  </p>
                  <div class="select-options">
                    <Select
                      showSearch
                      style={{ borderRadius: 0, textAlign: "start" }}
                      placeholder="Please Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(name) =>
                        onChangeChecklistAdmissionStatus(
                          this,
                          programItem,
                          name
                        )
                      }
                      value={
                        newAdmissionCaseChecklistObj &&
                        newAdmissionCaseChecklistObj.statusName
                          ? newAdmissionCaseChecklistObj.statusName
                          : null
                      }
                    >
                      {admissionCaseStatus.map(({ name }) => (
                        <Option value={name}>{name}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Col>
              <Col span={10}>
                <div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Select Check List
                  </p>
                  <div class="select-options">
                    <Select
                      showSearch
                      style={{ borderRadius: 0, textAlign: "start" }}
                      placeholder="Please Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(list) =>
                        onSelectAdmissionChecklist(
                          this,
                          programItem,
                          JSON.parse(list),
                          id
                        )
                      }
                      value={
                        newAdmissionCaseChecklistObj &&
                        newAdmissionCaseChecklistObj.checkListName
                          ? newAdmissionCaseChecklistObj.checkListName
                          : null
                      }
                    >
                      {checkLists.map((list) => (
                        <Option value={JSON.stringify(list)}>
                          {`${list.description}:${list.name}`}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Col>
              <Col span={4}>
                <div style={{ textAlign: "center" }}>
                  <Button
                    disabled={
                      newAdmissionCaseChecklistObj &&
                      newAdmissionCaseChecklistObj.statusName &&
                      newAdmissionCaseChecklistObj.checkListName
                        ? false
                        : true
                    }
                    type="primary"
                    onClick={() =>
                      this.attachCheckListWithAdmissionCase(
                        programItem,
                        newAdmissionCaseChecklistObj,
                        memberType
                      )
                    }
                    class="cm-add-btn-text cm-add-btn cm-add-btn-cont"
                    style={{ marginTop: 35 }}
                  >
                    ADD
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  /* ------------------------
  ---------------------------
  End: Admission cases checklist
  ---------------------------
  ---------------------------*/

  /* -------------------------------------
  ----------------------------------------
  Start: Expanding Functionality for visa
  ----------------------------------------
  ----------------------------------------*/
  expandVisaCase = (visaItem) => {
    let val = visaItem["isCaseExpand"];
    if (val) {
      visaItem["isCaseExpand"] = !val;
      return this.setState({ clients: this.state.clients });
    }
    visaItem["newVisaCaseChecklistObj"] = undefined;
    visaItem["isSubCaseExpand"] = true;
    this.setState({ pageLoading: true });
    this.getChecklistForVisaForExpand(visaItem, "V");
  };
  getChecklistForVisaForExpand = (visaItem, casetype, expandVal) => {
    this.props
      .onGetVisaCaseCheckList(visaItem.id, casetype)
      .then((res) => {
        visaItem["checkList"] = res.payload;
        visaItem["isCaseExpand"] = !expandVal;
        this.filterProgressData(visaItem);
        this.setState({
          clients: this.state.clients,
          pageLoading: false,
        });
      })
      .catch((er) => {
        this.setState({
          clients: this.state.clients,
          pageLoading: false,
        });
      });
  };

  expandVisaSubCase = (visaItem) => {
    let val = visaItem["isSubCaseExpand"];
    visaItem["isSubCaseExpand"] = !val;
    this.setState({ clients: this.state.clients });
  };

  expandVisaChecklistTask = (task) => {
    task.isChecklistTaskExpand = !task.isChecklistTaskExpand;
    this.setState({ clients: this.state.clients });
  };

  /* -------------------------------------
  ----------------------------------------
  End: Expanding Functionality for visa
  ----------------------------------------
  ----------------------------------------*/

  /* -------------------------------------
  ----------------------------------------
  Start: Expanding Functionality for Admission
  ----------------------------------------
  ----------------------------------------*/

  expandAdmissionCase = (programItem) => {
    let val = programItem["isCaseExpand"];
    if (val) {
      programItem["isCaseExpand"] = !val;
      return this.setState({ clients: this.state.clients });
    }
    programItem["newAdmissionCaseChecklistObj"] = undefined;
    programItem["isSubCaseExpand"] = true;
    this.setState({ pageLoading: true });
    this.getChecklistForAdmissionForExpand(programItem, "A");
  };
  getChecklistForAdmissionForExpand = (programItem, casetype, expandVal) => {
    this.props
      .onGetVisaCaseCheckList(programItem.id, casetype)
      .then((res) => {
        programItem["checkList"] = res.payload;
        programItem["isCaseExpand"] = !expandVal;
        this.filterProgressData(programItem);
        this.setState({
          clients: this.state.clients,
          pageLoading: false,
        });
      })
      .catch((er) => {
        this.setState({
          clients: this.state.clients,
          pageLoading: false,
        });
        console.log(er);
      });
  };
  expandAdmissionSubCase = (programItem) => {
    programItem["isSubCaseExpand"] = !programItem["isSubCaseExpand"];
    this.setState({ clients: this.state.clients });
  };
  expandVisaChecklistAdmission = (task) => {
    task.isChecklistTaskExpand = !task.isChecklistTaskExpand;
    this.setState({ clients: this.state.clients });
  };

  /* -------------------------------------
  ----------------------------------------
  End: Expanding Functionality for Admission
  ----------------------------------------
  ----------------------------------------*/

  getChecklistForVisa = (visaItem, casetype) => {
    this.props
      .onGetVisaCaseCheckList(visaItem.id, casetype)
      .then((res) => {
        visaItem["checkList"] = res.payload;
        this.filterProgressData(visaItem);
        this.setState({
          clients: this.state.clients,
          pageLoading: false,
        });
      })
      .catch((er) => {
        this.setState({
          clients: this.state.clients,
          pageLoading: false,
        });
      });
  };
  getChecklistForAdmission = (programItem, casetype) => {
    this.props
      .onGetVisaCaseCheckList(programItem.id, casetype)
      .then((res) => {
        programItem["checkList"] = res.payload;
        this.filterProgressData(programItem);
        this.setState({
          clients: this.state.clients,
          pageLoading: false,
        });
      })
      .catch((er) => {
        this.setState({
          clients: this.state.clients,
          pageLoading: false,
        });
        console.log(er);
      });
  };
  /*-----------------
  Start:DropDown menus
  -------------------
   */

  menu = (visaStatus, visaItem, clientIndex, visaCaseInd) => (
    <Menu style={{ overflow: "auto", height: 300 }}>
      {visaStatus.map((visa) => (
        <Menu.Item
          onClick={() =>
            onChangeVisaCaseStatus(
              this,
              visa,
              visaItem,
              clientIndex,
              visaCaseInd
            )
          }
        >
          {visa.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  admissionDropdownMenu = (
    admissionStatuses,
    programItem,
    clientIndex,
    programCaseInd
  ) => (
    <Menu style={{ overflow: "auto", height: 200 }}>
      {admissionStatuses.map((admission) => (
        <Menu.Item
          onClick={() =>
            onChangeAdmissionCaseStatus(
              this,
              admission,
              programItem,
              clientIndex,
              programCaseInd
            )
          }
        >
          {admission.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  checkListVisaStatusMenu = (visaItem, visaStatus) => (
    <Menu style={{ overflow: "auto", height: 200 }}>
      {visaStatus.map(({ name }) => (
        <Menu.Item
          onClick={() => onChangeChecklistVisaStatus(this, visaItem, name)}
        >
          {name}
        </Menu.Item>
      ))}
    </Menu>
  );
  checkListNameMenu = (checkList, clientId, visaItem) => (
    <Menu style={{ overflow: "auto", height: "auto" }}>
      {checkList.map((list) => (
        <Menu.Item
          onClick={() => onSelectVisaChecklist(this, list, clientId, visaItem)}
        >
          {`${list.description}:${list.name}`}
        </Menu.Item>
      ))}
    </Menu>
  );

  checkListAdmissionStatusMenu = (programItem, admissionStatus) => (
    <Menu style={{ overflow: "auto", height: 200 }}>
      {admissionStatus.map(({ name }) => (
        <Menu.Item
          onClick={() =>
            onChangeChecklistAdmissionStatus(this, programItem, name)
          }
        >
          {name}
        </Menu.Item>
      ))}
    </Menu>
  );
  checkListNameMenuAdmission = (checkList, clientId, programItem) => (
    <Menu style={{ overflow: "auto", height: 200 }}>
      {checkList.map((list) => (
        <Menu.Item
          onClick={() =>
            onSelectAdmissionChecklist(this, programItem, list, clientId)
          }
        >
          {`${list.description}:${list.name}`}
        </Menu.Item>
      ))}
    </Menu>
  );

  /*-----------------
  End :DropDown menus
  -------------------
   */

  /*-----------------
  -------------------
  Start :Date Handlers
  -------------------
  -------------------
   */

  handleOk = () => {
    this.setState({
      isModalVisible: false,
      isAdmissionCaseModalVisible: false,
      isRescheduleTaskModalOpen: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      isAdmissionCaseModalVisible: false,
      isRescheduleTaskModalOpen: false,
    });
  };

  onChangeDate = (type, val) => {
    let selectedDate = val.toJSON();
    this.setState({
      caseStatusData: {
        ...this.state.caseStatusData,
        [type]: selectedDate,
      },
    });
  };
  onChangeAdmissionDate = (type, val) => {
    let selectedDate = val.toJSON();
    this.setState({
      admissionStatusData: {
        ...this.state.admissionStatusData,
        [type]: selectedDate,
      },
    });
  };

  /*-----------------
  -------------------
  END :Date Handlers
  -------------------
  -------------------
   */
  updateCaseStatus = () => {
    this.setState({ pageLoading: true });
    let {
      date,
      id,
      branchVisaTypeId,
      caseStatusId,
      clientIndex,
      visaCaseInd,
      selectedVisa,
      approveDate,
      expiryDate,
      assessmentCompletedDate,
    } = this.state.caseStatusData;
    let { caseStatusData } = this.state;
    this.props
      .onUpdateCaseStatus(caseStatusData)
      .then(() => {
        if (selectedVisa.name === "Complete") {
          this.state.clients[clientIndex].visas.items.splice(visaCaseInd, 1);
          return this.setState(
            {
              clients: this.state.clients,
              pageLoading: false,
              caseStatusData: {},
              isModalVisible: false,
            },
            () => this.resetTaskForm()
          );
        }
        this.state.clients[clientIndex].visas.items[visaCaseInd][
          "visaStatusName"
        ] = selectedVisa.name;
        this.state.clients[clientIndex].visas.items[visaCaseInd][
          "caseStatusId"
        ] = selectedVisa.id;
        this.state.clients[clientIndex].visas.items[visaCaseInd][
          "visaStatusColor"
        ] = selectedVisa.color;
        this.setState(
          {
            clients: this.state.clients,
            pageLoading: false,
            caseStatusData: {},
            isModalVisible: false,
          },
          () => {
            this.resetTaskForm();
            openNotificationWithIcon(
              "success",
              "Visa Case status updated successfully!"
            );
          }
        );
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };
  updateAdmissionCaseStatus = () => {
    this.setState({ pageLoading: true });
    const { admissionStatusData } = this.state;

    let { programStatus, color, programStatusName } = admissionStatusData;
    let programCaseInd = admissionStatusData.programCaseInd;
    let clientIndex = admissionStatusData.clientIndex;
    delete admissionStatusData.programCaseInd;
    delete admissionStatusData.clientIndex;
    this.props
      .onUpdateAdmissionCaseStatus(admissionStatusData)
      .then((res) => {
        this.state.clients[clientIndex].programs.items[programCaseInd][
          "programStatusName"
        ] = programStatusName;
        this.state.clients[clientIndex].programs.items[programCaseInd][
          "programStatus"
        ] = programStatus;
        this.state.clients[clientIndex].programs.items[programCaseInd][
          "color"
        ] = color;
        this.setState(
          {
            clients: this.state.clients,
            pageLoading: false,
            admissionStatusData: {},
            isAdmissionCaseModalVisible: false,
          },
          () => {
            this.resetTaskForm();
            openNotificationWithIcon(
              "success",
              "Admission Case status updated successfully!"
            );
          }
        );
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };

  attachCheckListWithVisaCase = (visaItem, data, memberType) => {
    this.setState({ pageLoading: true });
    this.props
      .onAttachCaseCheckListWithCase(data)
      .then((res) => {
        visaItem["newVisaCaseChecklistObj"] = undefined;
        this.setState({ memberType: memberType, triggerTask: true });
        openNotificationWithIcon("success", "Checklist added successfully!");
        this.getChecklistForVisa(visaItem, "V");
      })
      .catch((error) => {
        this.setState({ pageLoading: false });
        console.log(error);
      });
  };

  attachCheckListWithAdmissionCase = (programItem, data, memberType) => {
    this.setState({ pageLoading: true });
    this.props
      .onAttachCaseCheckListWithCase(data)
      .then((res) => {
        programItem["newAdmissionCaseChecklistObj"] = undefined;
        this.setState({ memberType: memberType, triggerTask: true });
        openNotificationWithIcon("success", "Checklist added successfully!");
        this.getChecklistForAdmission(programItem, "A");
      })
      .catch((error) => {
        this.setState({ pageLoading: false });
        console.log(error);
      });
  };
  onChangeTaskHandler = (type, val) => {
    this.setState({ newTaskObj: { ...this.state.newTaskObj, [type]: val } });
  };

  addNewTask = () => {
    this.setState({ pageLoading: true });
    let {
      subjectId,
      isPontential,
      isCompleted,
      caseCheckListId,
      taskTitle,
      taskDescription,
      taskDate,
      checkListId,
      clientIndex,
      caseInd,
      checklistInd,
      casetype,
      memberType,
    } = this.state.newTaskObj;
    let taskObj = {
      subjectId,
      isPontential,
      isCompleted,
      caseCheckListId,
      taskTitle,
      taskDescription,
      taskDate,
    };
    this.props
      .onAddCaseCheckListTask(taskObj)
      .then(() => {
        this.props
          .onGetCheckListTasks(checkListId)
          .then((res) => {
            openNotificationWithIcon("success", "Task added successfully!");
            if (casetype === "V") {
              this.state.clients[clientIndex].visas.items[
                caseInd
              ].checkList.items[checklistInd].tasks.items = res.payload.items;
            }
            if (casetype === "A") {
              this.state.clients[clientIndex].programs.items[
                caseInd
              ].checkList.items[checklistInd].tasks.items = res.payload.items;
            }
            this.setState(
              {
                pageLoading: false,
                isTaskModalOpen: false,
                newTaskObj: {},
                clients: this.state.clients,
                triggerTask: true,
                memberType: memberType,
              },
              () => this.resetTaskForm()
            );
          })
          .catch((er) => {});
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };
  resetTaskForm = () => {
    if (this.formRef && this.formRef.current)
      this.formRef.current.resetFields();
  };

  onCompleteTask = (task, clientId, userClientId, firstName, LastName) => {
    if (task.isCompleted) return;
    this.setState({ pageLoading: true });
    this.props
      .onCompleteChecklistTask({ id: task.id })
      .then(() => {
        let userName = localStorage.getItem("userName");
        let taskName = task.taskTitle ? task.taskTitle : task.name;
        let myData = {
          notClientPart: true,
          linkedClient: userClientId,
          clientName: firstName + " " + LastName,
          logMessage:
            "Checklist task " + taskName + " is completed by " + userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Checklist tasks",
          invoiceId: "0",
        };
        activityData(myData);
        task.isCompleted = true;
        this.setState({
          clients: this.state.clients,
          pageLoading: false,
          triggerTask: true,
          memberType: task.memberType,
        });
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  onUpdateTask = (task) => {
    this.setState({ pageLoading: true });
    this.props
      .onUpdateCaseCheckListTask(task)
      .then(() => {
        this.setState(
          {
            clients: this.state.clients,
            pageLoading: false,
            isRescheduleTaskModalOpen: false,
            triggerTask: true,
            memberType: task.memberType,
          },
          () => this.resetTaskForm()
        );
      })
      .catch(() => {
        task.isCompleted = true;
        this.setState({ pageLoading: false });
      });
  };
  removeClient = (client) => {
    this.setState({
      pageLoading: true,
    });
    this.props
      .onRemoveCmsClientOrAdd({
        // id: client.id,
        // delete: true,
        id: client.id,
        isCMS: false,
      })
      .then((res) => {
        let { clients } = this.state;
        let data = clients.filter((cl) => cl.id !== client.id);
        this.setState({ clients: data, pageLoading: false }, () => {
          openNotificationWithIcon("success", "Client Removed!");
        });
      })
      .catch((error) => {
        this.setState(
          {
            pageLoading: false,
          },
          () =>
            openNotificationWithIcon(
              "error",
              "Error",
              "Client could't removed!"
            )
        );
      });
  };

  removeCase = (subjectCase, clientIndex, caseInd, caseType) => {
    this.setState({
      pageLoading: true,
    });
    let userId = localStorage.getItem("userId");
    if (caseType === "V") {
      this.props
        .onRemoveCmsCase({
          id: subjectCase.id,
          delete: true,
        })
        .then((res) => {
          this.state.clients[clientIndex].visas.items.splice(caseInd, 1);
          this.setState({ clients: this.state.clients, pageLoading: false });
        })
        .catch((error) => {
          this.setState(
            {
              pageLoading: false,
            },
            () =>
              openNotificationWithIcon(
                "error",
                "Error",
                "Case could't removed!"
              )
          );
        });
    }
    if (caseType === "A") {
      this.props
        .onRemoveCmsAdmissionCase({
          id: subjectCase.id,
          delete: true,
        })
        .then((res) => {
          this.state.clients[clientIndex].programs.items.splice(caseInd, 1);
          this.setState({ clients: this.state.clients, pageLoading: false });
        })
        .catch((error) => {
          this.setState(
            {
              pageLoading: false,
            },
            () =>
              openNotificationWithIcon(
                "error",
                "Error",
                "Case could't removed!"
              )
          );
        });
    }
  };

  removeCaseChecklist = (currentChecklist, checklists, checklistIndex) => {
    this.setState({ pageLoading: true });
    let userId = localStorage.getItem("userId");
    this.props
      .onRemoveCmsChecklist({
        id: currentChecklist.id,
        delete: true,
        taskIds: [],
      })
      .then((res) => {
        checklists.splice(checklistIndex, 1);
        this.setState({ clients: this.state.clients, pageLoading: false });
      })
      .catch((er) => this.setState({ pageLoading: false }));
  };

  removeCaseTasks = (list, memberType) => {
    let tasks = list.tasks.items;
    let taskCheck = tasks && tasks.length;
    if (!taskCheck)
      return openNotificationWithIcon("error", "Error", "Tasks is empty!");
    this.setState({ pageLoading: true });
    let ids = tasks.map((task) => task.id);
    this.props
      .onRemoveCmsTasks({
        ids: ids,
        delete: true,
      })
      .then(() => {
        list.tasks.items = [];
        this.setState(
          {
            clients: this.state.clients,
            pageLoading: false,
            triggerTask: true,
            memberType: memberType,
          },
          () => openNotificationWithIcon("success", "Tasks Deleted!")
        );
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };
  removeCaseSingleTask = (list, task) => {
    this.setState({ pageLoading: true });
    let userId = localStorage.getItem("userId");
    this.props
      .onRemoveCmsTasks({
        ids: [task.id],
        delete: true,
      })
      .then((res) => {
        list.tasks.items = list.tasks.items.filter((tk) => tk.id !== task.id);
        this.setState(
          {
            clients: this.state.clients,
            pageLoading: false,
            triggerTask: true,
          },
          () => openNotificationWithIcon("success", "Task Deleted!")
        );
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };

  addTaskComment = (task) => {
    this.setState({ pageLoading: true });
    let { id, comment } = task;
    this.props
      .onAddTaskComment({
        taskId: id,
        description: comment,
      })
      .then(() => {
        openNotificationWithIcon("success", "Comment added!");
        this.getTaskComments(task);
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };

  getTaskComments = (task) => {
    this.props
      .onGetTaskComment(task.id)
      .then((res) => {
        let comm = res.payload && res.payload.items;
        if (comm) {
          task.comments = comm;
          task.comment = "";
          this.setState({ cliets: this.state.clients, pageLoading: false });
        }
        this.setState({ pageLoading: false });
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };
  disabledDate(current) {
    return moment().add(-1, "days") >= current;
  }

  handleSearch = (value) => {
    !value.length && this.setState({ searchOptions: [], tempClients: [] });
    if (value.length > 2) {
      setTimeout(() => {
        this.props
          .onSearchClientProfiles({
            searchParam: value,
            pageSize: 10,
            pageNumber: 1,
            totalPages: 1,
          })
          .then((res) => {
            let result = this.searchResult(res.payload.clients);
            this.setState({
              searchOptions: result,
              tempClients: res.payload.clients,
            });
          });
      }, 500);
    }
  };
  onSelectClientForSearch = (client, b) => {
    let { tempClients } = this.state;
    let mainClient = tempClients.filter(
      (el) => el.clientNumber === b.clientNumber
    );
    if (mainClient.length) this.setState({ clients: mainClient });
  };
  searchResult = (searchedResult) =>
    searchedResult.map((_, idx) => {
      return {
        value: `${_.firstName} ${_.lastName}`,
        label: (
          <div className="search-client-card-cont">
            <div>
              <div class="image-search-result">
                <img
                  src={_.imageBlobUrl || Images.dummyUserImage}
                  class="profile-img"
                  style={{ width: 60, height: 60 }}
                />
              </div>
            </div>
            <div>
              <span>{`${_.firstName} ${_.lastName}`}</span>
              <div style={{ display: "flex" }}>
                <div>
                  <span class="date-text">EZM ID :</span>
                </div>
                <div>
                  <span class="date-text">&nbsp;{_.clientNumber}</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <span class="date-text">INZ ID :</span>
                </div>
                <div>
                  <span class="date-text">&nbsp;</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <span class="date-text">DOB :</span>
                </div>
                <div>
                  <span class="date-text">
                    &nbsp;{moment(_.dateOfBirth).format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <span class="date-text">Email :</span>
                </div>
                <div>
                  <span class="date-text">&nbsp;{_.email}</span>
                </div>
              </div>
              <div style={{ display: "flex", marginBottom: 10 }}>
                <div>
                  <span class="date-text">Mobile :</span>
                </div>
                <div>
                  <span class="date-text">&nbsp;{_.mobile}</span>
                </div>
              </div>
            </div>
          </div>
        ),
        clientNumber: _.clientNumber,
      };
    });

  addNewVisaModal = ({
    isAddNewVisaModalOpen,
    visaModalLoading,
    NewVisaOptions,
    newVisa,
    NewVisaCategoryOptions,
  }) => (
    <Modal
      title={"Add New Visa"}
      visible={isAddNewVisaModalOpen}
      onOk={() => {
        this.resetTaskForm();
        this.setState({ isAddNewVisaModalOpen: false });
      }}
      onCancel={() => {
        this.resetTaskForm();
        this.setState({ isAddNewVisaModalOpen: false });
      }}
      header={true}
      footer={false}
      width={620}
    >
      <Spin spinning={visaModalLoading} size="small">
        <div style={{ width: "100%" }}>
          <div
            style={{
              overflow: "inherit",
              paddingBottom: 6,
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 5,
            }}
          >
            <Form
              ref={this.formRef}
              {...layout}
              name="basic"
              onFinish={() => this.startNewApplication()}
            >
              <Form.Item
                colon={false}
                labelAlign="left"
                label="Countries :"
                name="Country"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "Required!" }]}
              >
                {/* eslint-disable-next-line react/jsx-no-undef */}

                <Select
                  showSearch
                  style={{ width: "100%", borderRadius: 0 }}
                  placeholder="Select Country"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={this.onSelectCountry.bind(this)}
                >
                  {this.props.brnchVisaCountriesData &&
                    this.props.brnchVisaCountriesData.map((country, ind) => (
                      <Option value={country.countryId}>
                        {country.countryName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                colon={false}
                name="Visa"
                labelAlign="left"
                label="Visas :"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "Required!" }]}
              >
                {/* eslint-disable-next-line react/jsx-no-undef */}

                <Select
                  showSearch
                  style={{ width: "100%", borderRadius: 0 }}
                  placeholder="Select Visa"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={this.onSelectVisa.bind(this)}
                >
                  {NewVisaOptions.map((el) => (
                    <Option value={JSON.stringify(el)}>
                      {el.visaTypeName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {newVisa.country &&
              newVisa.country === "AUSTRALIA" &&
              newVisa.branchVisaTypeId ? (
                <Form.Item
                  colon={false}
                  name="Categories"
                  style={{ width: "100%" }}
                  labelAlign="left"
                  label="Categories :"
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    placeholder="Select Category"
                    onChange={this.onChangeVisaCategory}
                    value={newVisa.category}
                  >
                    {NewVisaCategoryOptions.map((el) => (
                      <Option value={el.id}>{el.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
              {newVisa.country && newVisa.country === "AUSTRALIA" ? (
                <Form.Item
                  colon={false}
                  name="Destinations"
                  style={{ width: "100%" }}
                  labelAlign="left"
                  label="Destinations :"
                  // rules={[{ required: true, message: "Please Select Visa!" }]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Select
                    showSearch
                    placeholder="Select Destinations"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    onChange={this.onChangeDestination}
                  >
                    <Option value="1">New South Wales</Option>
                    <Option value="2">Queensland</Option>
                    <Option value="6">South Australia</Option>
                    <Option value="4">Tasmania</Option>
                    <Option value="3">Victoria</Option>
                    <Option value="5">Western Australia</Option>
                  </Select>
                </Form.Item>
              ) : null}
              <Form.Item
                labelAlign="left"
                label="Start Date"
                name="date"
                style={{ width: "100%" }}
              >
                <DatePicker
                  // disabledDate={this.disabledDate}
                  calendarIcon={null}
                  clearIcon={null}
                  onChange={(e) => this.onChangeVisaDate(e.toJSON())}
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
              <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                <Button
                  disabled={visaModalLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </Modal>
  );
  addNewAdmissionModal = ({
    isAddNewAdmissionModalOpen,
    admissionModalLoading,
    NewAdmissionOptions,
    newAdmission,
  }) => (
    <Modal
      title={"Add New Admission"}
      visible={isAddNewAdmissionModalOpen}
      onOk={() => {
        this.resetTaskForm();
        this.setState({ isAddNewAdmissionModalOpen: false });
      }}
      onCancel={() => {
        this.resetTaskForm();
        this.setState({ isAddNewAdmissionModalOpen: false });
      }}
      header={true}
      footer={false}
      width={620}
    >
      <Spin spinning={admissionModalLoading} size="small">
        <div style={{ width: "100%" }}>
          <div
            style={{
              overflow: "inherit",
              paddingBottom: 6,
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 5,
            }}
          >
            <Form
              ref={this.formRef}
              {...layout}
              name="basic"
              onFinish={() => this.startNewAdmissionApplication()}
            >
              <div style={{ padding: 10 }}>
                <Form.Item
                  name="schoolType"
                  label="School Type"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Type"
                    style={{ width: "100%" }}
                    onChange={this.onChangeSchoolType}
                  >
                    {NewAdmissionOptions.schoolTypes.map((el) => (
                      <Option value={el.id}>{el.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              {newAdmission.schoolTypeId && (
                <div style={{ padding: 10, paddingTop: 0 }}>
                  <Form.Item
                    name="school"
                    label="School"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select School"
                      style={{ width: "100%" }}
                      onChange={this.onChangeSchool}
                    >
                      {NewAdmissionOptions.schools.map((el) => (
                        <Option value={el.id}>{el.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
              {newAdmission.schoolId && (
                <div style={{ padding: 10, paddingTop: 0 }}>
                  <Form.Item
                    name="schoolLevel"
                    label="School Level"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select Level"
                      style={{ width: "100%" }}
                      onChange={this.onChangeLevel}
                    >
                      {NewAdmissionOptions.schoolLevels.map((el) => (
                        <Option value={el.id}>{el.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
              <div style={{ padding: 10, paddingTop: 0 }}>
                <Form.Item
                  name="program"
                  label="Program"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      this.onChangeAdmissionFields(
                        "enrolledProgram",
                        e.target.value
                      )
                    }
                    placeholder=""
                    type="text"
                  />
                </Form.Item>
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <Form.Item
                  name="studentNo"
                  label="Student No"
                  labelAlign="left"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Required!",
                  //   },
                  // ]}
                >
                  <Input
                    onChange={(e) =>
                      this.onChangeAdmissionFields("studentNo", e.target.value)
                    }
                    placeholder=""
                    type="text"
                  />
                </Form.Item>
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <Form.Item
                  name="fee"
                  label="Fee"
                  labelAlign="left"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Required!",
                  //   },
                  // ]}
                >
                  <Input
                    onChange={(e) =>
                      this.onChangeAdmissionFields("fee", e.target.value)
                    }
                    placeholder=""
                    type="text"
                  />
                </Form.Item>
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <Form.Item
                  name="description"
                  label="Description"
                  labelAlign="left"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Required!",
                  //   },
                  // ]}
                >
                  <Input
                    onChange={(e) =>
                      this.onChangeAdmissionFields(
                        "description",
                        e.target.value
                      )
                    }
                    placeholder=""
                    type="text"
                  />
                </Form.Item>
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <Form.Item
                  labelAlign="left"
                  label="Start Date"
                  name="date"
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Required!",
                  //   },
                  // ]}
                >
                  <DatePicker
                    calendarIcon={null}
                    clearIcon={null}
                    onChange={(e) =>
                      this.onChangeAdmissionFields("caseStart", e.toJSON())
                    }
                    format="DD/MM/YYYY"
                    placeholder="Select Date"
                  />
                </Form.Item>
              </div>
              <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                <Button
                  disabled={admissionModalLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </Modal>
  );
  openAddNewVisaModal = (clientId) => {
    let userId = localStorage.getItem("userId");
    this.setState({
      isAddNewVisaModalOpen: true,
      newVisa: {
        subjectId: clientId,
        category: 0,
        destination: 0,
        userId: userId,
      },
    });
  };

  onSelectCountry = (val) => {
    let { newVisa } = this.state;
    var findCountry =
      this.props.brnchVisaCountriesData &&
      this.props.brnchVisaCountriesData.find((obj) => obj.countryId === val);
    this.setState(
      {
        visaModalLoading: true,
        newVisa: {
          ...newVisa,
          country: (findCountry && findCountry.countryName) || "",
        },
      },
      () => {
        if (newVisa.branchVisaTypeId)
          this.formRef.current.setFieldsValue({ Visa: "" });
      }
    );
    this.props
      .onGetVisaTypeByCountry(val)
      .then((res) => {
        let data = res.payload && res.payload.items;
        if (data)
          this.setState({ visaModalLoading: false, NewVisaOptions: data });
        else this.setState({ visaModalLoading: false, NewVisaOptions: [] });
      })
      .catch(() => {
        this.setState({ visaModalLoading: false });
      });
  };

  onSelectVisa = (val) => {
    let visa = JSON.parse(val);
    this.setState(
      {
        newVisa: {
          ...this.state.newVisa,
          branchVisaTypeId: visa.id,
          visaTypeName: visa.visaTypeName,
        },
      },
      () => {
        if (this.state.newVisa.country === "AUSTRALIA") {
          this.setState({ visaModalLoading: true });
          this.props
            .onGetCategory(visa.id)
            .then((res) => {
              let data = res.payload && res.payload.items;
              if (data && data.length) {
                this.setState(
                  {
                    NewVisaCategoryOptions: data,
                    visaModalLoading: false,
                    newVisa: { ...this.state.newVisa, category: 0 },
                  },
                  () =>
                    this.formRef.current.setFieldsValue({
                      Categories: "",
                    })
                );
              } else {
                this.setState(
                  {
                    visaModalLoading: false,
                    NewVisaCategoryOptions: [],
                    newVisa: { ...this.state.newVisa, category: 0 },
                  },
                  () =>
                    this.formRef.current.setFieldsValue({
                      Categories: "",
                    })
                );
              }
            })
            .catch((er) => this.setState({ visaModalLoading: false }));
        }
      }
    );
  };
  onChangeVisaCategory = (val) => {
    this.setState({
      newVisa: {
        ...this.state.newVisa,
        category: val,
      },
    });
  };
  onChangeDestination = (val) => {
    this.setState({
      newVisa: {
        ...this.state.newVisa,
        destination: val,
      },
    });
  };
  onChangeVisaDate = (date) => {
    this.setState({ newVisa: { ...this.state.newVisa, startDate: date } });
  };

  startNewApplication = () => {
    this.setState({ visaModalLoading: true });
    let { clients } = this.state;
    let {
      subjectId,
      branchVisaTypeId,
      visaTypeName,
      startDate,
      country,
      category,
      destination,
      userId,
    } = this.state.newVisa;
    let data = {
      subjectId: subjectId,
      branchVisaTypeId: branchVisaTypeId,
      caseStatusId: 1,
      startDate: startDate || new Date(),
      isPaid: false,
      isSigned: false,
      casePriority: "Medium",
      country: country,
      expiryDate: new Date(),
      approveDate: new Date(),
      visaTypeName: visaTypeName,
      isCompleted: false,
      subCategory: parseInt(category),
      destination: parseInt(destination),
      applicationCountry: country === "AUTRAILIA" ? 1 : 0,
    };
    this.props
      .onStartNewApplication(data)
      .then(() => {
        this.props
          .onGetClientAllVisaCases(subjectId)
          .then((res) => {
            let items = res.payload && res.payload.items;
            let data = clients.map((el) => {
              if (el.id === subjectId) {
                let currentData = el.visas.items;
                let ids = el.visas.items.map((el) => el.id);
                let data = items.filter((el) => !ids.includes(el.id));
                currentData.push(data[0]);
                this.props.onRemoveCmsClientOrAdd({ id: el.id, isCMS: true });
                if (!JSON.parse(el.status))
                  this.props.onUpdateClientActiveStatus({
                    id: el.id,
                    status: true,
                  });
              }
              return el;
            });
            this.setState(
              {
                clients: data,
                visaModalLoading: false,
                isAddNewVisaModalOpen: false,
              },
              () => {
                this.resetTaskForm();
                openNotificationWithIcon("success", "Visa Added");
              }
            );
          })
          .catch(() =>
            this.setState({ visaModalLoading: false }, () =>
              openNotificationWithIcon("error", "Error", "Could't add visa!")
            )
          );
      })
      .catch((er) =>
        this.setState({ visaModalLoading: false }, () =>
          openNotificationWithIcon("error", "Error", "Could't add visa!")
        )
      );
  };

  openNewAdmissionModal = (clientId) => {
    this.setState({
      isAddNewAdmissionModalOpen: true,
      newAdmission: { clientId: clientId },
    });
  };
  onChangeSchoolType = (val) => {
    let { newAdmission } = this.state;
    this.setState(
      {
        newAdmission: { ...newAdmission, schoolTypeId: val },
        admissionModalLoading: true,
      },
      () => {
        this.props
          .onGetAllSchools(val)
          .then((res) => {
            let data = res.payload && res.payload.items;
            if (data)
              this.setState({
                NewAdmissionOptions: {
                  ...this.state.NewAdmissionOptions,
                  schools: data,
                },
                admissionModalLoading: false,
              });
          })
          .catch(() => this.setState({ admissionModalLoading: false }));
      }
    );
  };
  onChangeSchool = (val) => {
    let { newAdmission } = this.state;
    this.setState(
      {
        newAdmission: { ...newAdmission, schoolId: val },
      },
      () => {
        this.props
          .onGetSchoolLevel(val)
          .then((res) => {
            let data = res.payload && res.payload.items;
            if (data)
              this.setState({
                NewAdmissionOptions: {
                  ...this.state.NewAdmissionOptions,
                  schoolLevels: data,
                },
                admissionModalLoading: false,
              });
          })
          .catch(() => this.setState({ admissionModalLoading: false }));
      }
    );
  };
  onChangeLevel = (val) => {
    let { newAdmission } = this.state;
    this.setState({
      newAdmission: { ...newAdmission, commissionId: val },
    });
  };
  onChangeAdmissionFields = (type, data) => {
    let { newAdmission } = this.state;
    this.setState({ newAdmission: { ...newAdmission, [type]: data } });
  };
  startNewAdmissionApplication = () => {
    this.setState({ admissionModalLoading: true });
    let userId = localStorage.getItem("userId");
    let {
      schoolId,
      clientId,
      description,
      enrolledProgram,
      studentNo,
      commissionId,
      fee,
      schoolTypeId,
      caseStart,
    } = this.state.newAdmission;
    let data = {
      clientId: clientId,
      schoolId: schoolId,
      programId: 0,
      programStatus: 8,
      description: description ? description : "",
      appliedDate: "1900-01-01T00:00:00+00",
      addmissionDate: "1900-01-01T00:00:00+00",
      enrolledProgram: enrolledProgram,
      studentNo: studentNo ? studentNo : "",
      fee: parseInt(fee) ? parseInt(fee) : 0,
      hideCMS: false,
      date: "1900-01-01T00:00:00+00",
      caseStart: caseStart || new Date().toISOString(),
      caseEnd: "1900-01-01T00:00:00+00",
      courseEffectiveDate: "1900-01-01T00:00:00+00",
      aipDate: "1900-01-01T00:00:00+00",
      aipSubmitted: false,
      visaApprovedDate: "1900-01-01T00:00:00+00",
      ftsSubmitted: false,
      ftsDate: "1900-01-01T00:00:00+00",
      visaId: 0,
      conditionalOfferDate: "1900-01-01T00:00:00+00",
      formalOfferDate: "1900-01-01T00:00:00+00",
      paidDate: "1900-01-01T00:00:00+00",
      declineDate: "1900-01-01T00:00:00+00",
      startDate: caseStart || new Date().toISOString(),
      deletedDate: "1900-01-01T00:00:00+00",
      commissionId: parseInt(commissionId),
      schoolTypeId: parseInt(schoolTypeId),
    };
    this.props
      .onAddClientAdmission(data)
      .then((res) => {
        this.props.onGetClientAdmissionProgram(clientId).then((res) => {
          let items = res.payload && res.payload.items;
          let data = this.state.clients.map((el) => {
            if (el.id === clientId) {
              el.programs.items.push(items[items.length - 1]);
              this.props.onRemoveCmsClientOrAdd({ id: el.id, isCMS: true });
              if (!JSON.parse(el.status))
                this.props.onUpdateClientActiveStatus({
                  id: el.id,
                  status: true,
                });
            }
            return el;
          });
          this.setState(
            {
              clients: data,
              admissionModalLoading: false,
              isAddNewAdmissionModalOpen: false,
            },
            () => {
              this.resetTaskForm();
              openNotificationWithIcon("success", "Admission Added");
            }
          );
        });
      })
      .catch((er) => {
        this.setState({ admissionModalLoading: false });
      });
  };
  onPageChange = (currentPage, pageSize) => {
    this.setState({
      pageLoading: true,
      currentPage: currentPage,
      pageSize: pageSize,
    });
    this.props.onGetClientProfiles({
      searchParam: "-",
      pageSize: pageSize,
      pageNumber: currentPage,
    });
  };

  getAdvanceSearch = ({ visaStatus, ProcessingPersons, visaTypeData }) => (
    <div className="advance-search-area">
      <Form
        ref={this.formRef}
        {...layout}
        name="basic"
        onFinish={() => this.onAdvanceSearch()}
      >
        <Row>
          <Col span={8}>
            <div className="cm-form-input" style={{ display: "flex" }}>
              <label>EZM ID :</label>
              <Form.Item name="ezmid" colon={false}>
                <Input
                  style={{ width: 200 }}
                  onChange={(e) =>
                    this.onChangeAdvanceSearch("ezmId", e.target.value)
                  }
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={16}>
            <div className="cm-form-date">
              <label>Date From :</label>
              <Form.Item name="dateFrom" colon={false}>
                <DatePicker
                  onChange={(e) =>
                    this.onChangeAdvanceSearch("dateFrom", e.toJSON())
                  }
                  format="DD/MM/YYYY"
                  placeholder="Select Date"
                />
              </Form.Item>
              <label>Date To :</label>
              <Form.Item name="dateTo" colon={false}>
                <DatePicker
                  onChange={(e) =>
                    this.onChangeAdvanceSearch("dateTo", e.toJSON())
                  }
                  format="DD/MM/YYYY"
                  placeholder="Select Date"
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15, marginTop: 15 }}>
          <Col span={6}>
            <div className="cm-form-input">
              <label>Active & Inactive :</label>
              <Form.Item name="status" colon={false}>
                <Select
                  onChange={(e) => this.onChangeAdvanceSearch("status", e)}
                  defaultValue=""
                  style={{ width: 200 }}
                >
                  <Option value="">All</Option>
                  <Option value="true">Active</Option>
                  <Option value="false">InActive</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={6}>
            <div className="cm-form-input">
              <label>Case Status :</label>
              <Form.Item name="caseStatusId" colon={false}>
                <Select
                  onChange={(e) =>
                    this.onChangeAdvanceSearch("caseStatusId", e)
                  }
                  defaultValue=""
                  style={{ width: 200 }}
                >
                  <Option value="">All</Option>
                  {visaStatus.map((el) => (
                    <Option value={el.id}>{el.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={6}>
            <div className="cm-form-input">
              <label>Visa Type :</label>
              <Form.Item name="visaType" colon={false}>
                <Select
                  onChange={(e) => this.onChangeAdvanceSearch("visaType", e)}
                  defaultValue=""
                  style={{ width: 200 }}
                >
                  <Option value="">All</Option>
                  {visaTypeData.map((el) => (
                    <Option value={el.id}>{el.visaTypeName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={6}>
            <div className="cm-form-input">
              <label>Processing Person :</label>
              <Form.Item name="processingPerson" colon={false}>
                <Select
                  onChange={(e) =>
                    this.onChangeAdvanceSearch("processingPerson", e)
                  }
                  defaultValue=""
                  style={{ width: 200 }}
                >
                  <Option value="">All</Option>
                  {ProcessingPersons.map((el) => (
                    <Option value={el.id}>{el.fullName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "end" }}>
              <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                <Button htmlType="submit">Search</Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
  onChangeAdvanceSearch = (type, value) => {
    let { advanceSearchObj } = this.state;

    this.setState({ advanceSearchObj: { ...advanceSearchObj, [type]: value } });
  };
  onAdvanceSearch = () => {
    let { advanceSearchObj } = this.state;
    this.setState({ pageLoading: true });
    this.props
      .onAdvanceSearchClientProfiles(advanceSearchObj)
      .then((res) => {
        this.setState({
          clients: res.payload.clients,
          pageLoading: false,
          isAdvanceSearch: false,
          advanceSearchObj: {
            pageSize: 10,
            pageNumber: 1,
            ezmId: "",
            status: "",
            dateFrom: "1900-01-01T00:00:00+00",
            dateTo: "1900-01-01T00:00:00+00",
            visaType: 0,
            caseStatusId: 0,
          },
        });
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };
  openAdvanceSearch = (isAdvanceSearch) => {
    this.setState({
      isAdvanceSearch: !isAdvanceSearch,
      advanceSearchObj: {
        pageSize: 10,
        pageNumber: 1,
        ezmId: "",
        status: "",
        dateFrom: "1900-01-01T00:00:00+00",
        dateTo: "1900-01-01T00:00:00+00",
        visaType: 0,
        caseStatusId: 0,
      },
    });
  };
  openClientFile = (id, clientId, memberType, familyId) => {
    localStorage.setItem("clientprofileid", JSON.stringify(id));
    localStorage.setItem("familyId", familyId);

    if (memberType === "Client") {
      localStorage.setItem("clientProfileIdMain", clientId);
      localStorage.setItem("familyId", familyId);
      this.props.history.push({
        pathname: "/profile",
        state: { id: id },
      });
    } else if (memberType === "Spouse") {
      this.props.history.push({
        pathname: "/partner-profile",
        state: { id: clientId, familyId: familyId },
      });
    } else if (memberType === "Child") {
      this.props.history.push({
        pathname: "/client-family-member",
        state: { id: clientId, familyId: familyId },
      });
    }
  };
  getApprovedFields = () => (
    <>
      <Form.Item
        name="approveDate"
        labelAlign="left"
        label="Approve Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select Approve Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("approveDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="expiryDate"
        labelAlign="left"
        label="Expiry Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select Expiry Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("expiryDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
    </>
  );

  getAccessmentFields = () => (
    <Form.Item
      name="assessmentComplete"
      labelAlign="left"
      label="Assesment Completed Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Assessment Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeDate("assessmentCompletedDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getContractSignedFields = () => (
    <Form.Item
      name="contractSignedDate"
      labelAlign="left"
      label="Contract Signed Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Contract Signed Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeDate("contractSignedDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );

  getDeclineFields = () => (
    <>
      <Form.Item
        name="declineDate"
        labelAlign="left"
        label="Decline Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select Decline Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("declineDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="declinedescription"
        labelAlign="left"
        label="Decline Description"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Input
          onChange={(e) =>
            this.setState({
              caseStatusData: {
                ...this.state.caseStatusData,
                declineDescription: e.target.value,
              },
            })
          }
        />
      </Form.Item>
    </>
  );
  getRefusedFields = () => (
    <>
      <Form.Item
        name="refusedDate"
        labelAlign="left"
        label="Refused Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select Refused Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("refusedDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="refusedDescription"
        labelAlign="left"
        label="Refused Description"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Input
          onChange={(e) =>
            this.setState({
              caseStatusData: {
                ...this.state.caseStatusData,
                refusedDescription: e.target.value,
              },
            })
          }
        />
      </Form.Item>
    </>
  );
  getSelectedFields = () => (
    <>
      <Form.Item
        name="eoiSelectDate"
        labelAlign="left"
        label="EOI Selected Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select EOI Selected Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("eoiSelectedDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="eoiitaDate"
        labelAlign="left"
        label="EOI Ita Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select EOI Ita Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("eoiitaDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
    </>
  );

  getPPiFields = () => (
    <>
      <Form.Item
        name="description"
        labelAlign="left"
        label="PPI Description"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Input
          placeholder="Select PPI Description"
          onChange={(e) =>
            this.setState({
              caseStatusData: {
                ...this.state.caseStatusData,
                description: e.target.value,
              },
            })
          }
        />
      </Form.Item>
      <Form.Item
        name="recieveDate"
        labelAlign="left"
        label="PPI Recieved Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select PPI Recieved Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("receivedDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="ppiduedate"
        labelAlign="left"
        label="PPI Due Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select PPI Due Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("dueDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="extendedDate"
        labelAlign="left"
        label="PPI Extended Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select PPI Extended Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("extendDueDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="submitteddate"
        labelAlign="left"
        label="PPI Submitted Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select PPI Submitted Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("submittedDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
    </>
  );
  getStartDateFields = () => (
    <Form.Item
      name="startdate"
      labelAlign="left"
      label="Start Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Start Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("startDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getAppliedDateFields = () => (
    <Form.Item
      name="applieddate"
      labelAlign="left"
      label="Applied Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Applied Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("appliedDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getConditionalDateFields = () => (
    <Form.Item
      name="conditionaldate"
      labelAlign="left"
      label="Conditional Offer Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Conditional Offer Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("conditionalOfferDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getFormalDateFields = () => (
    <Form.Item
      name="formaldate"
      labelAlign="left"
      label="Formal Offer Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Formal Offer Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("formalOfferDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getPaidDateFields = () => (
    <Form.Item
      name="paiddate"
      labelAlign="left"
      label="Paid Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Paid Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("paidDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getDeclineDateFields = () => (
    <Form.Item
      name="declinedate"
      labelAlign="left"
      label="Decline Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Decline Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("declineDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );

  getAddCaseModal = ({ isAddCaseModalOpen, pageLoading, clientsData }) => {
    return (
      <Modal
        title={"Add Case"}
        visible={isAddCaseModalOpen}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isAddCaseModalOpen: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isAddCaseModalOpen: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 250, overflow: "hidden" }}
        width={492}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              overflow: "inherit",
              paddingBottom: 6,
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 5,
            }}
          >
            <Form
              ref={this.formRef}
              {...layout}
              name="basic"
              onFinish={() => this.selectClient(this.state.cId)}
            >
              <Form.Item
                labelAlign="left"
                label="Select Client"
                name="client"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "Required!" }]}
              >
                <Select
                  showSearch
                  style={{ width: 300, borderRadius: 0, textAlign: "start" }}
                  placeholder="Please Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(val) => this.onSelectClient(JSON.parse(val))}
                  // value={
                  //   newVisaCaseChecklistObj &&
                  //   newVisaCaseChecklistObj.statusName
                  //     ? newVisaCaseChecklistObj.statusName
                  //     : null
                  // }
                >
                  {clientsData.map((el) => (
                    <Option
                      // disabled={!el.clientPermission.active}
                      value={JSON.stringify(el)}
                    >{`${el.firstName} ${el.lastName}`}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                <Button
                  disabled={pageLoading}
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#1179af",
                    color: "#fff",
                    height: 32,
                    borderRadius: 5,
                    border: "none",
                  }}
                >
                  {pageLoading && <Spin size="small" />}
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };
  onSelectClient = (val) => {
    if (val.clientPermission && !val.clientPermission.active)
      message.info("This client is inactive, activate first for add here!");

    this.setState({ cId: val });
  };
  selectClient = (val) => {
    if (!val.clientPermission.active)
      return message.info("Inactive client! Please activate to add here");
    this.setState({ pageLoading: true });
    this.props
      .onRemoveCmsClientOrAdd({ id: val.id, isCMS: true })
      .then((res) => {
        this.getAllClient();
        this.setState({ isAddCaseModalOpen: false }, () => {
          this.resetTaskForm();
          openNotificationWithIcon("success", "Client added!");
        });
      })
      .catch((er) => {
        this.setState({ pageLoading: false }, () =>
          openNotificationWithIcon("error", "Error:", "Could't add client!")
        );
      });
  };
  getAllClient = () => {
    this.props.onGetClientProfiles({
      searchParam: "-",
      pageSize: 10,
      pageNumber: 1,
    });
  };
  updateTrigger = () => {
    this.setState({ triggerTask: false });
  };
  getClientWithProgress = (progress) => {
    let payload = {
      searchParam: "-",
      pageSize: 10,
      pageNumber: 1,
    };
    this.setState({ pageLoading: true, progress: !progress }, () =>
      !progress
        ? this.props.onGetClientProfilesWithProgress(payload)
        : this.props.onGetClientProfiles(payload)
    );
  };

  render() {
    const {
      clients,
      pageLoading,
      isModalVisible,
      caseStatusData,
      admissionStatusData,
      isAdmissionCaseModalVisible,
      isTaskModalOpen,
      isRescheduleTaskModalOpen,
      headerOptions,
      searchOptions,
      currentPage,
      pageSize,
      totalPages,
      isAdvanceSearch,
      progress,
      profileData,
    } = this.state;
    const { activeKey } = this.props;
    const {
      onChangeDate,
      updateCaseStatus,
      onChangeAdmissionDate,
      updateAdmissionCaseStatus,
      onChangeTaskHandler,
      addNewTask,
      onUpdateTask,
    } = this;
    return (
      <div>
        <Spin spinning={pageLoading} size="large">
          {headerOptions.length > 0 &&
          window.location.pathname == "/Case-management" ? null : (
            <div style={{ backgroundColor: "white", padding: 10 }}>
              <div className="cm-search-area">
                <AutoComplete
                  dropdownMatchSelectWidth={252}
                  style={{
                    height: 32,
                    width: 450,
                    paddingLeft: 30,
                    paddingRight: 30,
                  }}
                  options={searchOptions}
                  onSelect={this.onSelectClientForSearch}
                  onSearch={this.handleSearch}
                >
                  <Input.Search placeholder="Search Client..." enterButton />
                </AutoComplete>
                <div className="adv-btn">
                  <Button
                    onClick={() => this.openAdvanceSearch(isAdvanceSearch)}
                    htmlType="button"
                  >
                    Advance Search
                  </Button>
                </div>
                <div className="adv-btn">
                  <Button
                    onClick={() => this.setState({ isAddCaseModalOpen: true })}
                    htmlType="button"
                  >
                    Add Case
                  </Button>
                </div>
                <div
                  style={{ marginTop: 5, marginLeft: 15 }}
                  className="adv-btn"
                >
                  <Switch
                    checkedChildren="Disable Progress"
                    unCheckedChildren="Enable Progress"
                    checked={progress}
                    style={{
                      backgroundColor: progress ? "#1281b9" : "#a8a8a8",
                    }}
                    onChange={() => this.getClientWithProgress(progress)}
                  />
                </div>
              </div>
              {isAdvanceSearch ? this.getAdvanceSearch(this.state) : null}
            </div>
          )}
          <div style={{ display: "flex" }}>
            <div class="page-container" style={{ backgroundColor: "#f0f2f5" }}>
              {headerOptions.length > 0 &&
              window.location.pathname == "/Case-management" ? (
                <HeaderBarTabs
                  data={headerOptions}
                  activeTab="Open Case Management"
                />
              ) : null}
              {/* starting card */}
              <div className="profile-container">
                <div
                  className={
                    activeKey
                      ? "content-width-open-sidebar"
                      : "content-width-close-sidebar"
                  }
                  style={{ marginTop: "-10px" }}
                >
                  {window.location.pathname == "/Case-management" &&
                  profileData ? (
                    <div
                      style={{ width: "100%", marginTop: 8, marginLeft: 15 }}
                    >
                      <p>Client Name: {profileData && profileData.fullName}</p>
                    </div>
                  ) : (
                    <div style={{ height: 30 }} />
                  )}
                  {clients && clients.length ? (
                    clients.map((client, clientIndex) =>
                      this.getClientProfileInfo(client, clientIndex)
                    )
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <h1>No Case Available</h1>
                    </div>
                  )}
                </div>
                {headerOptions.length > 0 &&
                window.location.pathname == "/Case-management" ? (
                  <div
                    className=""
                    style={{
                      width: activeKey ? "438px" : "115px",
                      marginTop: -10,
                    }}
                  >
                    <div
                      className="ad-right-sidebar-hide"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setState({ expendedView: true })}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "15px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <ProfileSideBar
                          onGetClientTag={this.props.onGetClientTag}
                          clientTagRes={this.props.clientTagRes}
                          getClientRes={this.props.getClientRes}
                          onGetProfileClientTag={
                            this.props.onGetProfileClientTag
                          }
                          onAddProfileClientTag={
                            this.props.onAddProfileClientTag
                          }
                          addProfielTagRes={this.props.addProfielTagRes}
                          onRemoveProfileClientTag={
                            this.props.onRemoveProfileClientTag
                          }
                          removeProfileTagRes={this.props.removeProfileTagRes}
                          visaStatusData={this.props.visaStatusData}
                          onUpdateCaseStatus={this.props.onUpdateCaseStatus}
                          visaAppData={this.props.visaAppData}
                          onGetVisaApplication={this.props.onGetVisaApplication}
                          schoolTypesData={this.props.schoolTypesData}
                          onGetSchools={this.props.onGetAllSchools}
                          schoolsData={this.props.schoolsData}
                          onGetSchoolLevel={this.props.onGetSchoolLevel}
                          schoolLevelData={this.props.schoolLevelData}
                          onAddClientAdmission={this.props.onAddClientAdmission}
                          onGetAdmissionProgram={
                            this.props.onGetAdmissionProgram
                          }
                          onUpdAdmissionStatus={this.props.onUpdAdmissionStatus}
                          admissionStatusData={this.props.admissionStatusData}
                          admissionProgramData={this.props.admissionProgramData}
                          onGetAdmissionProgram={
                            this.props.onGetAdmissionProgram
                          }
                          onSetActiveKey={this.props.onSetActiveKey}
                          activeKey={this.props.activeKey}
                          onGetClientTask={this.props.onGetClientTask}
                          onUpdateTask={this.props.onUpdateTask}
                          onGetAllUsers={this.props.onGetAllUsers}
                          onAddTaskFollower={this.props.onAddTaskFollower}
                          onRemoveTasks={this.props.onRemoveTasks}
                          onAddDailyTasks={this.props.onAddDailyTasks}
                          onGetClientFamily={this.props.onGetClientFamily}
                          onUpdateCompletedTask={
                            this.props.onUpdateCompletedTask
                          }
                          onAddTaskFileNote={this.props.onAddTaskFileNote}
                          onAddTaskComment={this.props.onAddTaskComment}
                          onGetTaskComments={this.props.onGetTaskComments}
                          onGetTaskFollowers={this.props.onGetTaskFollowers}
                          triggerTask={this.state.triggerTask}
                          updateTrigger={this.updateTrigger}
                          memberType={this.state.memberType}
                          triggerCmsForTask={this.triggerCmsForTask}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <Modal
            title={"Update Status"}
            visible={isModalVisible}
            onOk={() => {
              this.resetTaskForm();
              this.setState({ isModalVisible: false });
            }}
            onCancel={() => {
              this.resetTaskForm();
              this.setState({ isModalVisible: false });
            }}
            header={true}
            footer={false}
            width={620}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  overflow: "inherit",
                  paddingBottom: 6,
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 5,
                }}
              >
                <Form
                  ref={this.formRef}
                  {...layout}
                  name="basic"
                  onFinish={() => updateCaseStatus()}
                >
                  <Form.Item
                    name="date"
                    labelAlign="left"
                    label="Date"
                    style={{ width: "100%" }}
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      calendarIcon={null}
                      clearIcon={null}
                      onChange={(e) => onChangeDate("date", e)}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                  {caseStatusData.selectedVisa &&
                    caseStatusData.selectedVisa.name === "Approved" &&
                    this.getApprovedFields()}
                  {caseStatusData.selectedVisa &&
                    caseStatusData.selectedVisa.name ===
                      "Assessment Completed" &&
                    this.getAccessmentFields()}
                  {caseStatusData.selectedVisa &&
                    caseStatusData.selectedVisa.name === "Contract Signed" &&
                    this.getContractSignedFields()}
                  {caseStatusData.selectedVisa &&
                    caseStatusData.selectedVisa.name === "Declined" &&
                    this.getDeclineFields()}
                  {caseStatusData.selectedVisa &&
                    caseStatusData.selectedVisa.name === "Refused" &&
                    this.getRefusedFields()}
                  {caseStatusData.selectedVisa &&
                    caseStatusData.selectedVisa.name === "Selected" &&
                    this.getSelectedFields()}
                  {caseStatusData.selectedVisa &&
                    caseStatusData.selectedVisa.name ===
                      "Immigration Matters (PPI)" &&
                    this.getPPiFields()}

                  <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                    <Button
                      disabled={pageLoading}
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 10 }}
                    >
                      {pageLoading && <Spin size="small" />}
                      Save
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.resetTaskForm();
                        this.setState({ isModalVisible: false });
                      }}
                    >
                      {pageLoading && <Spin size="small" />}
                      Close
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>
          <Modal
            title={"Update Admission Case Status"}
            visible={isAdmissionCaseModalVisible}
            onOk={() => {
              this.resetTaskForm();
              this.setState({ isAdmissionCaseModalVisible: false });
            }}
            onCancel={() => {
              this.resetTaskForm();
              this.setState({ isAdmissionCaseModalVisible: false });
            }}
            header={true}
            footer={false}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  overflow: "inherit",
                  paddingBottom: 6,
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 5,
                }}
              >
                <Form
                  ref={this.formRef}
                  {...layout}
                  name="basic"
                  onFinish={() => updateAdmissionCaseStatus()}
                >
                  <Form.Item
                    name="date"
                    labelAlign="left"
                    label="Modified Date"
                    style={{ width: "100%" }}
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      calendarIcon={null}
                      clearIcon={null}
                      onChange={(e) => onChangeAdmissionDate("date", e)}
                      value={
                        admissionStatusData ? admissionStatusData.date : ""
                      }
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                  {admissionStatusData &&
                    admissionStatusData.programStatusName === "Start" &&
                    this.getStartDateFields()}
                  {admissionStatusData &&
                    admissionStatusData.programStatusName === "Applied" &&
                    this.getAppliedDateFields()}
                  {admissionStatusData &&
                    admissionStatusData.programStatusName ===
                      "Conditional Offer" &&
                    this.getConditionalDateFields()}
                  {admissionStatusData &&
                    admissionStatusData.programStatusName === "Formal Offer" &&
                    this.getFormalDateFields()}
                  {admissionStatusData &&
                    admissionStatusData.programStatusName === "Paid" &&
                    this.getPaidDateFields()}
                  {admissionStatusData &&
                    admissionStatusData.programStatusName === "Decline" &&
                    this.getDeclineDateFields()}
                  <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                    <Button
                      disabled={pageLoading}
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 10 }}
                    >
                      {pageLoading && <Spin size="small" />}
                      Save
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.resetTaskForm();
                        this.setState({ isAdmissionCaseModalVisible: false });
                      }}
                    >
                      {pageLoading && <Spin size="small" />}
                      Close
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>
          <Modal
            title={"Add Task"}
            visible={isTaskModalOpen}
            onOk={() => {
              this.resetTaskForm();
              this.setState({ isTaskModalOpen: false });
            }}
            onCancel={() => {
              this.resetTaskForm();
              this.setState({ isTaskModalOpen: false });
            }}
            header={true}
            footer={false}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  overflow: "inherit",
                  paddingBottom: 6,
                  // display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 5,
                }}
              >
                <Form
                  ref={this.formRef}
                  {...layout}
                  name="basic"
                  onFinish={() => addNewTask()}
                >
                  <Form.Item
                    name="date"
                    labelAlign="left"
                    label="Due Date"
                    style={{ width: "100%" }}
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      format="DD/MM/YYYY"
                      calendarIcon={null}
                      clearIcon={null}
                      onChange={(e) =>
                        onChangeTaskHandler("taskDate", e.toJSON())
                      }
                      value={
                        admissionStatusData ? admissionStatusData.date : ""
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    labelAlign="left"
                    label="Task Title"
                    name="Title"
                    style={{ width: "100%" }}
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <Input
                      onChange={(e) =>
                        onChangeTaskHandler("taskTitle", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    labelAlign="left"
                    label="Task Description"
                    name="Description"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <TextArea
                      rows={4}
                      onChange={(e) =>
                        onChangeTaskHandler("taskDescription", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                    <Button
                      disabled={pageLoading}
                      type="primary"
                      htmlType="submit"
                    >
                      {pageLoading && <Spin size="small" />}
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>
          <Modal
            title={"Reschedule Task"}
            visible={isRescheduleTaskModalOpen}
            onOk={() => {
              this.resetTaskForm();
              this.setState({ isRescheduleTaskModalOpen: false });
            }}
            onCancel={() => {
              this.resetTaskForm();
              this.setState({ isRescheduleTaskModalOpen: false });
            }}
            header={true}
            footer={false}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  overflow: "inherit",
                  paddingBottom: 6,
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 5,
                }}
              >
                <Form
                  ref={this.formRef}
                  {...layout}
                  name="basic"
                  onFinish={() => onUpdateTask(this.state.newTaskObj)}
                >
                  <Form.Item
                    name="date"
                    labelAlign="left"
                    label="Due Date"
                    style={{ width: "100%" }}
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <DatePicker
                      // disabledDate={this.disabledDate}
                      calendarIcon={null}
                      clearIcon={null}
                      onChange={(e) =>
                        onChangeTaskHandler("taskDate", e.toJSON())
                      }
                      value={
                        admissionStatusData ? admissionStatusData.date : ""
                      }
                      placeholder="Select Date"
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>
                  <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                    <Button
                      disabled={pageLoading}
                      type="primary"
                      htmlType="submit"
                    >
                      {pageLoading && <Spin size="small" />}
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>
          {this.addNewVisaModal(this.state)}
          {this.addNewAdmissionModal(this.state)}
          {this.getAddCaseModal(this.state)}
          <div className="cm-pagination">
            {headerOptions.length > 0 &&
            window.location.pathname == "/Case-management" ? null : (
              <Pagination
                current={currentPage}
                onChange={this.onPageChange}
                total={totalPages}
                defaultPageSize={pageSize}
                pageSizeOptions={["5", "10", "15", "20"]}
              />
            )}
          </div>
        </Spin>
      </div>
    );
  }
}

export default CaseManagement;
