import React from "react";
import "./ClientsStyles.css";
import moment from "moment";
import { message } from "antd";

import HeaderBarTabs from "../Components/Header/HeaderTabs";
import { Images } from "../Themes";
import ProfileTopBarTabs from "../Components/Shared/ProfileTopBar";

import AddQualification from "../Components/Client/AddQualification";
import QualificationCard from "../Components/Client/Qualification";

import headOption from "../Components/Header/HeaderTabOptions";
import activityData from "../Components/ClientActivity/ActivityData";
import ProfileSidebar from "../Components/ProfileSideBar";

const topBar = [
  { tabName: "CLIENT INFORMATION", linkName: "/profile" },
  { tabName: "EMPLOYER INFORMATION", linkName: "/employer" },
  { tabName: "JOB HISTORY", linkName: "/job-history" },
  { tabName: "QUALIFICATION", linkName: "/qualification" },
  { tabName: "OTHER INFO", linkName: "/client-other-info" },
];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class Qualification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      headerOptions: [],
      profileData: null,
    };

    this.props.onSetActiveKey(null);
    let selectedTab = {
      headName: "Client Profile",
      headPath: "/profile",
    };
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.onGetQualification();
    this.props.onSetClientTab(selectedTab);
    // this.props.getClientSource();
    this.props.onSetClientTab(selectedTab);
  }

  componentDidMount() {
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          // { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }
    // var data = JSON.parse(sessionStorage.getItem("profileData"));
    // this.setState({ profileData: data });

    let familyId = sessionStorage.getItem("familyId");
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      this.props.onGetClientProfile(clientProfileIdMain);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data);
    }
  }

  componentDidUpdate(prevProps) {
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (
      prevProps.clientProfileData != this.props.clientProfileData &&
      memberType == "client"
    ) {
      this.setProfileDataRdx(this.props.clientProfileData);
    } else if (
      prevProps.partnerProfileData != this.props.partnerProfileData &&
      memberType == "spouse"
    ) {
      this.setProfileDataRdx(this.props.partnerProfileData);
    } else if (
      prevProps.clientFamilyData != this.props.clientFamilyData &&
      memberType == "child"
    ) {
      let findClient =
        this.props.clientFamilyData &&
        this.props.clientFamilyData.items.find(
          (obj) => obj.id == clientprofileid
        );
      if (findClient) {
        this.setProfileDataRdx(findClient);
      }
    }
    if (
      (prevProps.profileDataRdx && !this.state.profileData) ||
      prevProps.profileDataRdx != this.props.profileDataRdx
    ) {
      this.setState({ profileData: JSON.parse(this.props.profileDataRdx) });
    }
  }

  setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  addQualification = (values) => {
    var userId = localStorage.getItem("userId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let data = {
      clientId: clientprofileid,
      schoolId: "00000000-0000-0000-0000-000000000000",
      title: values.title || "",
      levelId: 0,
      startDate: values.startDate || new Date(),
      endDate: values.endDate || new Date(),
      universityName: values.instituteName || "",
      universityAddress: values.instituteAddress || "",
      country: 0,
      streetNumber: "",
      streetName: "",
      suburb: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      studentID: "",
      website: "",
      qualificationAwarded: true,
      level: values.level || "",
    };
    this.props.onAddQualification(data).then(() => {
      message.success("Qualification added successfully");
      this.props.onGetQualification();
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      if (profileData) {
        let myData = {
          clientName: profileData.fullName,
          logMessage: "Qulaification " + data.title + " added by " + userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(myData);
      }
    });
  };

  updateQualification = (values, id) => {
    var userId = localStorage.getItem("userId");
    console.log("update values", values);
    var findQualification = this.props.qualificationData.items.find(
      (obj) => obj.id === id
    );
    if (findQualification) {
      let data = {
        id: id,
        clientId: findQualification.clientId,
        schoolId: findQualification.schoolId,
        title: values.title,
        levelId: findQualification.levelId,
        startDate: values.startDate || "1900-01-01T00:00:00+00",
        endDate: values.endDate || "1900-01-01T00:00:00+00",
        universityName: values.instituteName,
        universityAddress: values.instituteAddress,
        country: findQualification.country,
        streetNumber: findQualification.streetNumber,
        streetName: findQualification.streetName,
        suburb: findQualification.suburb,
        city: findQualification.city,
        state: findQualification.state,
        zip: findQualification.zip,
        phone: findQualification.phone,
        studentID: findQualification.studentID,
        website: findQualification.website,
        qualificationAwarded: findQualification.qualificationAwarded,
        level: values.level || "",
      };
      this.props.onUpdateQualification(data).then(() => {
        message.success("Qualification updated successfully");
        this.props.onGetQualification();
      });
    }
  };

  render() {
    const { selectedOption, headerOptions, profileData } = this.state;
    const {
      qualificationData,
      clientProfileData,
      visaTypeData,
      userBranchData,
      visaStatusData,
      onUpdateCaseStatus,
      onGetVisaApplication,
      visaAppData,
      employerJobHistoryCurrentRes,
      onGetClientJobHistoryCurrent,
      onSetActiveKey,
      activeKey,
    } = this.props;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            {headerOptions.length > 0 && (
              <HeaderBarTabs data={headerOptions} activeTab="Client Profile" />
            )}
            <ProfileTopBarTabs data={topBar} activeTab="QUALIFICATION" />

            <div
              className="emp-container"
              style={{ display: "flex", paddingBottom: 80, margin: 10 }}
            >
              <div
                className={
                  activeKey
                    ? "content-width-open-sidebar"
                    : "content-width-close-sidebar"
                }
              >
                {profileData ? (
                  <div style={{ width: "100%" }}>
                    <p>Client Name: {profileData && profileData.fullName}</p>
                  </div>
                ) : (
                  <div style={{ height: 30 }} />
                )}
                <div>
                  <div>
                    {qualificationData &&
                      qualificationData.items.map((qualification, index) => {
                        return (
                          <QualificationCard
                            qualificationData={qualification}
                            updateQualification={this.updateQualification}
                            onGetQualification={this.props.onGetQualification}
                          />
                        );
                      })}

                    <div class="emp-middle-cont" style={{ marginLeft: 0 }}>
                      <div class="form-cont">
                        <div style={{ width: "100%" }}>
                          <p class="emp-ace-bold-text">ADD EDUCATION</p>
                        </div>
                      </div>
                      {/*<div className="emp-ace-cont">
                    <div
                      class="form-cont"
                      style={{
                        justifyContent: "space-between",
                        paddingLeft: 0,
                        paddingRight: 0,
                        display: "block",
                        width: "100%"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          justifyContent: "space-between"
                        }}
                      >
                        <p class="medical-label" style={{ fontSize: 11 }}>
                          Title
                        </p>
                        <div class="emp-input-border">
                          <input
                            className="profile-input"
                            placeholder=""
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          justifyContent: "space-between"
                        }}
                      >
                        <p class="medical-label" style={{ fontSize: 11 }}>
                          Level
                        </p>
                        <div class="emp-input-border">
                          <input
                            className="profile-input"
                            placeholder=""
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          justifyContent: "space-between"
                        }}
                      >
                        <p class="medical-label" style={{ fontSize: 11 }}>
                          Start Date
                        </p>
                        <div
                          class="profile-input-border"
                          style={{
                            width: "65%",
                            display: "flex",
                            border: "none",
                            overflow: "inherit"
                          }}
                        >
                          <div
                            class="profile-input-with-icon"
                            style={{
                              marginLeft: 0,
                              overflow: "inherit",
                              display: "flex",
                              height: 30,
                              backgroundColor: "#FFFFFF",
                              paddingRight: 3,
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingRight: 5,
                              width: "100%"
                            }}
                          >
                            <DatePicker
                              calendarIcon={null}
                              clearIcon={null}
                              onChange={this.onChangeDate}
                              value={this.state.date}
                            />
                          </div>
                          <div className="profile-refresh-cont">
                            <img
                              src={Images.calendar}
                              className="profile-calendar-icon"
                              style={{ marginLeft: 0 }}
                            />
                          </div>
                          <div class="profile-refresh-cont">
                            <img
                              src={Images.refreshArrow}
                              style={{ width: 18, height: 18 }}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          justifyContent: "space-between"
                        }}
                      >
                        <p class="medical-label" style={{ fontSize: 11 }}>
                          End Date
                        </p>
                        <div
                          class="profile-input-border"
                          style={{
                            width: "65%",
                            display: "flex",
                            border: "none",
                            overflow: "inherit"
                          }}
                        >
                          <div
                            class="profile-input-with-icon"
                            style={{
                              marginLeft: 0,
                              overflow: "inherit",
                              display: "flex",
                              height: 30,
                              backgroundColor: "#FFFFFF",
                              paddingRight: 3,
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingRight: 5,
                              width: "100%"
                            }}
                          >
                            <DatePicker
                              calendarIcon={null}
                              clearIcon={null}
                              onChange={this.onChangeDate}
                              value={this.state.date}
                            />
                          </div>
                          <div className="profile-refresh-cont">
                            <img
                              src={Images.calendar}
                              className="profile-calendar-icon"
                              style={{ marginLeft: 0 }}
                            />
                          </div>
                          <div class="profile-refresh-cont">
                            <img
                              src={Images.refreshArrow}
                              style={{ width: 18, height: 18 }}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          justifyContent: "space-between"
                        }}
                      >
                        <p class="medical-label" style={{ fontSize: 11 }}>
                          Institute Name
                        </p>
                        <div class="emp-input-border">
                          <input
                            className="profile-input"
                            placeholder=""
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          justifyContent: "space-between"
                        }}
                      >
                        <p class="medical-label" style={{ fontSize: 11 }}>
                          Institute Address
                        </p>
                        <div class="emp-input-border">
                          <input
                            className="profile-input"
                            placeholder=""
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <div />
                        <div
                          className="button-blue-cont"
                          style={{ marginTop: 34, paddingRight: 0, width: 70 }}
                        >
                          <div className="emp-blue-btn">
                            <span style={{ color: "#FFFFFF" }}>ADD</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                      <AddQualification
                        addQualification={this.addQualification}
                        onAddQualification={this.props.onAddQualification}
                        onGetQualification={this.props.onGetQualification}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className=""
                style={{
                  width: activeKey ? "438px" : "115px",
                  marginLeft: 20,
                }}
              >
                <ProfileSidebar
                  onGetClientTag={this.props.onGetClientTag}
                  clientTagRes={this.props.clientTagRes}
                  getClientRes={this.props.getClientRes}
                  onGetProfileClientTag={this.props.onGetProfileClientTag}
                  onAddProfileClientTag={this.props.onAddProfileClientTag}
                  addProfielTagRes={this.props.addProfielTagRes}
                  onRemoveProfileClientTag={this.props.onRemoveProfileClientTag}
                  removeProfileTagRes={this.props.removeProfileTagRes}
                  visaStatusData={visaStatusData}
                  onUpdateCaseStatus={onUpdateCaseStatus}
                  visaAppData={visaAppData}
                  onGetVisaApplication={onGetVisaApplication}
                  onGetClientJobHistoryCurrent={onGetClientJobHistoryCurrent}
                  employerJobHistoryCurrentRes={employerJobHistoryCurrentRes}
                  onSetActiveKey={onSetActiveKey}
                  activeKey={activeKey}
                  onGetClientTask={this.props.onGetClientTask}
                  onUpdateTask={this.props.onUpdateTask}
                  onGetAllUsers={this.props.onGetAllUsers}
                  onAddTaskFollower={this.props.onAddTaskFollower}
                  onRemoveTasks={this.props.onRemoveTasks}
                  onAddDailyTasks={this.props.onAddDailyTasks}
                  onGetClientFamily={this.props.onGetClientFamily}
                  onUpdateCompletedTask={this.props.onUpdateCompletedTask}
                  onAddTaskFileNote={this.props.onAddTaskFileNote}
                  onAddTaskComment={this.props.onAddTaskComment}
                  onGetTaskComments={this.props.onGetTaskComments}
                  onGetTaskFollowers={this.props.onGetTaskFollowers}
                />
              </div>

              {false && (
                <div style={{ paddingRight: 45 }}>
                  <div
                    class="sus-form-container"
                    style={{
                      width: "50%",
                      minHeight: 130,
                      marginLeft: 100,
                      paddingLeft: 55,
                      paddingRight: 55,
                      border: 1,
                      borderStyle: "solid",
                      borderColor: "#D9D9D9",
                    }}
                  >
                    <div
                      className="sus-add-cont-row"
                      style={{ marginTop: 10, marginRight: -40 }}
                    >
                      <div></div>
                      <div onClick={this.openModal}>
                        <img src={Images.plusIcon} style={{ width: 20 }} />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: -40,
                        marginRight: -40,
                      }}
                    >
                      <div className="sus-table"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Qualification;
