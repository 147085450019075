import React from "react";
import "./ClientsStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import { Images } from "../Themes";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import ProfileSideBar from "../Components/ProfileSideBar";

import LinkMemberCard from "../Components/ClientVisa/LinkMemberCard";
import headOption from "../Components/Header/HeaderTabOptions";
import activityData from "../Components/ClientActivity/ActivityData";

import { DownOutlined, FileDoneOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { PaperClipOutlined } from "@ant-design/icons";

import {
  Select,
  Form,
  Input,
  Button,
  DatePicker,
  message,
  Spin,
  Modal,
  Table,
  Tooltip,
} from "antd";

import moment from "moment";
import { setActiveKey } from "../store/Actions";
import { apiRefresh } from "../services/api";

const { TextArea } = Input;

const { Option } = Select;

var users = [];

const dateFormat = "DD/MM/YYYY";

class VisaFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "document",
      extend: false,
      activeTabNewVisa: false,
      showAppDates: false,
      linkFamily: false,
      checked: false,
      showDocuments: false,
      showEmail: false,
      showVisaInfo: false,
      visaInfoData: null,
      loadVisaInfo: true,
      expendedView: true,
      newApplicationWith: null,
      checkIndex: null,
      headerOptions: [],

      eoiitaDate: "",
      eoiSelectedDate: "",

      profileData: null,

      visaDelModal: false,
      caseHistoryDelModal: false,
      visaObj: null,
      visaStatusId: "",
      showPPIDetail: false,
      caseInd: null,
      showFileNotes: false,
      onFilterData: [],
      visaResState: [],
      countryId: null,
      countryName: "",
      loading: false,
      emailContent: null,
      showDetailPopup: false,
      emailContent: "",
      oldId: null,
      emailColumns: [
        {
          title: "",
          key: "index",
          width: "20px",
          render: (text, record, index) => index + 1,
        },
        {
          title: "",
          key: "index",
          width: "70px",
          render: (text, record) => {
            return (
              <div>
                {record.importText.toLowerCase() == "inbox" ? (
                  <span
                    className="sent-email"
                    style={{ backgroundColor: "#2EC7EA" }}
                  >
                    Inbox Imported
                  </span>
                ) : record.autoImport === true ? (
                  <span
                    className="draft"
                    style={{ backgroundColor: "#FCBB3A" }}
                  >
                    Sent Imported
                  </span>
                ) : record.isSent === true ? (
                  <span className="sent-email">Sent Email</span>
                ) : record.draft === true ? (
                  <span className="draft">Draft</span>
                ) : (
                  record.import && (
                    <span
                      className="draft"
                      style={{ backgroundColor: "#FCBB3A" }}
                    >
                      Imported
                    </span>
                  )
                )}
              </div>
            );
          },
        },
        {
          title: "Date",
          key: "createdDate",
          width: "50px",
          dataIndex: "createdDate",
          render: (text, record) => {
            return <span>{moment(text).format("DD/MM/YYYY hh:mm:ss")}</span>;
          },
        },
        {
          title: "From",
          dataIndex: "from",
          // defaultSortOrder: "descend",
          ellipsis: true,
          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.from.localeCompare(b.from),
          width: "50px",
          sorter: (a, b) => a.age - b.age,
          render: (text, record) => {
            return (
              <div>
                <span style={{ fontSize: "12px" }}>
                  {record && record.from}
                </span>
              </div>
            );
          },
        },

        {
          title: "To",
          dataIndex: "to",
          // defaultSortOrder: "descend",

          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.to.localeCompare(b.to),
          width: "50px",
          sorter: (a, b) => a.age - b.age,
          render: (text, record) => {
            return (
              <div>
                <span style={{ fontSize: "12px" }}>{record && record.to}</span>
              </div>
            );
          },
        },
        {
          title: "Subject",
          dataIndex: "subject",
          // defaultSortOrder: "descend",

          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.subject.localeCompare(b.subject),
          width: "70px",
          sorter: (a, b) => a.age - b.age,
          render: (text, record) => {
            return (
              <div>
                <span style={{ fontSize: "12px" }}>
                  {record && record.subject}
                </span>
              </div>
            );
          },
        },
        {
          title: "Action",
          width: "60px",
          render: (text, record) => {
            return (
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* {record.emailAttachment.length > 0 && <PaperClipOutlined />} */}
                  <div
                    className="cv-action-icons-border"
                    style={{ marginLeft: 5, cursor: "pointer" }}
                    onClick={() => {
                      // setEmailContent(record);
                      var getEmailOpt = {
                        url: `v1/imap/ClientEmailById/${record.id}/${record.clientId}`,
                      };

                      getEmailOpt.types = [
                        "MOVE_DOCUMENTS_SUCCESS",
                        "MOVE_DOCUMENTS_FAILURE",
                      ];
                      this.setState({ loading: true });

                      apiRefresh
                        .get(getEmailOpt)
                        .then((res) => {
                          if (res.emailHistories[0].emailMessage) {
                            res.emailHistories[0].emailMessage = res.emailHistories[0].emailMessage.replace(
                              "float: left;",
                              ""
                            );
                            res.emailHistories[0].emailMessage = res.emailHistories[0].emailMessage.replace(
                              "float:left;",
                              ""
                            );
                          }
                          this.setState({
                            loading: false,
                            emailContent: res.emailHistories[0],
                            showDetailPopup: true,
                          });
                          // setEmailContent(res.emailHistories[0]);
                          // setShowDetailPopup(true);
                        })
                        .catch((err) => {
                          this.setState({ loading: false });
                        });
                      this.setState({ oldId: record.oldId });
                      // setOldId(record.oldId);
                      // if (record.draft) {
                      //   setDraft(true);
                      // }
                    }}
                  >
                    <img src={Images.detailEmail} className="cv-action-icon" />
                  </div>
                  {/* <div
                    className="cv-action-icons-border"
                    style={{ marginLeft: 5, cursor: "pointer" }}
                    onClick={() => {
                      // setShowDetailPopup(true);
                      // setEmailContent(record);
                      if (record.draft) {
                        // setDraft(true);
                      }
                    }}
                  >
                    <img src={Images.detailEmail} className="cv-action-icon" />
                  </div> */}
                  {/* <div
                    className="cv-action-icons-border"
                    style={{ marginLeft: 5, cursor: "pointer" }}
                    onClick={
                      () => {}
                      // showLinkVisa(record)
                    }
                  >
                    <img
                      src={Images.multimediaBlue}8
                      className="cv-action-icon"
                    />
                  </div> */}
                  {/* <div
                    onClick={() => confirmation(record && record.id)}
                    className="cv-action-icons-border"
                    style={{ marginLeft: 5, cursor: "pointer" }}
                  >
                    <img src={Images.deleteIcon} className="cv-action-icon" />
                  </div> */}
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.props.onSetActiveKey(null);

    this.props.onGetTeamMember().then((res) => {
      var members = res.payload;
      if (members.users) {
        for (var i = 0; i < members.users.length; i++) {
          users.push(
            <Option key={members.users[i].id}>
              {members.users[i].fullName}
            </Option>
          );
        }
      }
    });
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let data = {
      clientId: clientProfileIdMain,
      familyId: familyId,
    };
    this.props.onGetClientFamily(data);
    this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
    // this.props
    //   .onGetVisaApplication()
    //   .then((res) => {
    //     if (res.payload && res.payload.items) {
    //       this.setState({
    //         visaResState: res.payload.items,
    //         loadVisaInfo: false,
    //       });
    //     } else {
    //       this.setState({
    //         visaResState: [],
    //         loadVisaInfo: false,
    //       });
    //     }
    //   })
    //   .catch(() => {
    //     this.setState({ visaResState: [], loadVisaInfo: false });
    //   });
    this.getVisaApplication();
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      this.props.onGetClientProfile(clientProfileIdMain);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    } else if (memberType == "child") {
      // let data = {
      //   clientId: clientProfileIdMain,
      //   familyId: familyId,
      // };
      // onGetClientFamily(data);
    }
    this.props.onGetVisaStatus();
    this.props.onGetBVisaCountries();
    let countryIdDef = localStorage.getItem("defaultCountryId") || 0;
    this.props.onGetVisaTypeByCountry(countryIdDef);
    this.props.onGetFilteredType().then((res) => {
      this.setState({ onFilterData: res.payload });
    });
  }

  formRef = React.createRef();

  getVisaApplication = () => {
    this.props
      .onGetVisaApplication()
      .then((res) => {
        if (res.payload && res.payload.items) {
          this.setState({
            visaResState: res.payload.items,
            loadVisaInfo: false,
          });
        } else {
          this.setState({
            visaResState: [],
            loadVisaInfo: false,
          });
        }
      })
      .catch(() => {
        this.setState({ visaResState: [], loadVisaInfo: false });
      });
  };

  componentDidMount() {
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          // { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }
    // var data = JSON.parse(this.props.profileDataRdx);
    // this.setState({ profileData: data });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visaAppData !== this.props.visaAppData) {
      if (this.props.visaAppData && this.props.visaAppData.items) {
        this.setState({
          visaResState: this.props.visaAppData.items,
        });
      } else {
        this.setState({
          visaResState: [],
        });
      }
    }
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (
      prevProps.clientProfileData != this.props.clientProfileData &&
      memberType == "client"
    ) {
      this.setProfileDataRdx(this.props.clientProfileData);
    } else if (
      prevProps.partnerProfileData != this.props.partnerProfileData &&
      memberType == "spouse"
    ) {
      this.setProfileDataRdx(this.props.partnerProfileData);
    } else if (
      prevProps.clientFamilyData != this.props.clientFamilyData &&
      memberType == "child"
    ) {
      let findClient =
        this.props.clientFamilyData &&
        this.props.clientFamilyData.items.find(
          (obj) => obj.id == clientprofileid
        );
      if (findClient) {
        this.setProfileDataRdx(findClient);
      }
    }

    if (
      (prevProps.profileDataRdx && !this.state.profileData) ||
      prevProps.profileDataRdx != this.props.profileDataRdx
    ) {
      this.setState({ profileData: JSON.parse(this.props.profileDataRdx) });
    }
    if (this.props.teamMembers && this.props.teamMembers.users) {
      users = [];
      for (var i = 0; i < this.props.teamMembers.users.length; i++) {
        users.push(
          <Option key={this.props.teamMembers.users[i].id}>
            {this.props.teamMembers.users[i].fullName}
          </Option>
        );
      }
    }
    if (this.state.initializeValues) {
      this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({
        studentVisa: this.state.visaInfoData.branchVisaTypeName || "",
        visaDescription: this.state.visaInfoData.visaDescription || "",
        submissionType: this.state.visaInfoData.submissionType || "",
        liaName: this.state.visaInfoData.liaName || "",
        lia: this.state.visaInfoData.liaName || "",
        visaAppNumber: this.state.visaInfoData.visaApplicationNumber || "",
        visaOfficerEmail: this.state.visaInfoData.visaOfficerEmail || "",
        branch: this.state.visaInfoData.branch || "",
        courierName: this.state.visaInfoData.courierName || "",
        trackingNumber: this.state.visaInfoData.trackingNumber || "",
        eoiNumber: this.state.visaInfoData.eioNumber || "",
        returnedDate:
          this.state.visaInfoData.documentReturnedDate ===
          "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(this.state.visaInfoData.documentReturnedDate),
        documentDescription: this.state.visaInfoData.documentDescription || "",
        worth: this.state.visaInfoData.worth,
        saleDate:
          this.state.visaInfoData.saleDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(this.state.visaInfoData.saleDate),
        salesPerson:
          this.state.visaInfoData.salePersonId ===
          "00000000-0000-0000-0000-000000000000"
            ? ""
            : this.state.visaInfoData.salePersonId,
        followupDate:
          this.state.visaInfoData.followUpDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(this.state.visaInfoData.followUpDate),
      });
      this.setState({ initializeValues: false });
    }

    if (prevProps.brnchVisaCountriesData != this.props.brnchVisaCountriesData) {
      let countryIdDef = localStorage.getItem("defaultCountryId");
      var findCountry =
        this.props.brnchVisaCountriesData &&
        this.props.brnchVisaCountriesData.find(
          (obj) => obj.countryId == countryIdDef
        );
      if (!this.state.countryId && findCountry && findCountry.countryId !== 0) {
        this.setState({
          countryName: findCountry.countryName,
          countryId: parseInt(countryIdDef),
        });
      }
    }
  }

  setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeTab = (value) => {
    this.setState({ activeInfoTab: value });
  };

  onChangeTopTab = (value) => {
    this.setState({ activeTab: value });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  addFamilyLink = (subjectCaseId, memberId) => {
    let data = {
      subjectCaseId: subjectCaseId,
      clientId: memberId,
    };
    this.props.onAddFamilyLink(data).then((res) => {
      message.success("Member linked successfully");
      this.props
        .onGetVisaApplication()
        .then((res) => {
          if (res.payload && res.payload.items) {
            this.setState({
              visaResState: res.payload.items,
              loadVisaInfo: false,
            });
          } else {
            this.setState({
              visaResState: [],
              loadVisaInfo: false,
            });
          }
        })
        .catch(() => {
          this.setState({ visaResState: [], loadVisaInfo: false });
        });
    });
  };

  onChangeFamilyCheck = (e, subjectCaseId, memberId) => {
    console.log("checked = ", e.target.checked);
    this.setState({
      checked: e.target.checked,
    });
    this.addFamilyLink(subjectCaseId, memberId);
  };

  onClickStatus = (visaApp) => {
    this.setState({
      showVisaInfo: true,
      expendedView: false,
      visaInfoData: visaApp,
      initializeValues: true,
    });
    if (visaApp) {
      // this.formRef.current.setFieldsValue({
      //   studentVisa: visaApp.branchVisaTypeName,
      //   visaDescription: visaApp.visaDescription,
      //   submissionType: visaApp.submissionType,
      //   liaName: visaApp.liaName,
      //   lia: visaApp.liaName,
      //   visaAppNumber: visaApp.visaApplicationNumber,
      //   caseOfficerEmail: visaApp.caseOfficerName,
      //   branch: visaApp.branch,
      //   courierName: visaApp.courierName,
      //   trackingNumber: visaApp.trackingNumber,
      //   eoiNumber: visaApp.eioNumber,
      //   returnedDate: moment(visaApp.documentReturnedDate),
      //   documentDescription: visaApp.documentDescription,
      //   worth: visaApp.worth,
      //   saleDate: moment(visaApp.saleDate),
      //   salesPerson:
      //     visaApp.salePersonId === "00000000-0000-0000-0000-000000000000"
      //       ? ""
      //       : visaApp.salePersonId,
      //   followupDate: moment(visaApp.followUpDate),
      // });
    }
  };

  removeStatus = (caseHistoryId) => {
    var userId = localStorage.getItem("userId");
    let data = {
      id: caseHistoryId,
      delete: true,
    };
    this.setState({ loadVisaInfo: true });
    this.props
      .onRemoveCaseStatus(data)
      .then((res) => {
        message.success("Case status remove successfully");
        this.props
          .onGetVisaApplication()
          .then((res) => {
            if (res.payload && res.payload.items) {
              this.setState({
                visaResState: res.payload.items,
                loadVisaInfo: false,
              });
            } else {
              this.setState({
                visaResState: [],
                loadVisaInfo: false,
              });
            }
          })
          .catch(() => {
            this.setState({ visaResState: [], loadVisaInfo: false });
          });
        this.setState({ caseHistoryDelModal: false });
      })
      .catch((err) => {
        this.setState({ loadVisaInfo: false });
      });
  };

  removeSubject = (subjectCase) => {
    var userId = localStorage.getItem("userId");
    let data = {
      id: subjectCase.id,
      delete: true,
    };
    this.setState({ loadVisaInfo: true });
    var visaStatus =
      this.props.visaStatusData &&
      this.props.visaStatusData.items.find(
        (obj) => obj.id == subjectCase.caseStatusId
      );
    var statusName = "";
    if (visaStatus) {
      statusName = visaStatus.name;
    }
    this.props
      .onRemoveSubjectCase(data)
      .then((res) => {
        message.success("Visa application remove successfully");
        this.props
          .onGetVisaApplication()
          .then((res) => {
            if (res.payload && res.payload.items) {
              this.setState({
                visaResState: res.payload.items,
                loadVisaInfo: false,
              });
            } else {
              this.setState({
                visaResState: [],
                loadVisaInfo: false,
              });
            }
          })
          .catch(() => {
            this.setState({ visaResState: [], loadVisaInfo: false });
          });
        this.setState({ visaDelModal: false });

        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Application " +
              subjectCase.branchVisaTypeName +
              " with " +
              statusName +
              " removed by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Visa",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((err) => {
        this.setState({ loadVisaInfo: false });
      });
  };

  showRightMenu = () => {
    this.setState({ expendedView: false });
  };

  getCaseDocuments = (caseId) => {
    this.setState({
      linkFamily: false,
      showDocuments: true,
      showEmail: false,
      showAppDates: false,
      expendedView: true,
    });
    this.props.onGetCaseDocument(caseId);
  };

  startNewApp = (country) => {
    this.setState({
      activeTabNewVisa: true,
      newApplicationWith: country,
    });
    // if (country === "nz") {
    //   this.props.onGetVisaTypeByCountry("168");
    // } else if (country === "au") {
    //   this.props.onGetVisaTypeByCountry("28");
    // } else {
    //   this.props.onGetVisaTypeByCountry("53");
    // }
  };

  onChangeEoiItaDate = (value) => {
    this.setState({ eoiItaDate: value });
  };

  deleteFromObject = (keyPart, obj) => {
    for (var k in obj) {
      // Loop through the object
      if (~k.indexOf(keyPart)) {
        // If the current key contains the string we're looking for
        delete obj[k]; // Delete obj[key];
      }
    }
    return obj;
  };

  isEquivalent = (a, b) => {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
      var result = {};
      var keys = Object.keys(bProps);

      for (var key in aProps) {
        if (!keys.includes(key)) {
          result[key] = aProps[key];
        }
      }
      var keyNames = Object.keys(result);
      // for (var i = 0; i < keyNames.length; i++) {
      //   var name = keyNames[i];
      //   delete aProps.keyNames[i];
      // }

      var myObject = [];

      for (var i = 0; i < keyNames.length; i++) {
        myObject = this.deleteFromObject(keyNames[i], aProps);
      }

      console.log(aProps, myObject);
      // return false;
    }
    var propNames = "";

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (
        propName !== "createdDate" &&
        propName !== "modifiedDate" &&
        propName !== "createdBy" &&
        propName !== "modifiedBy" &&
        propName !== "caseHistory" &&
        propName !== "caseLink"
      ) {
        if (a[propName] !== b[propName]) {
          if (propNames === "") {
            propNames += propName;
          } else {
            propNames += ", " + propName;
          }
        }
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return propNames;
  };

  onFinish = (values) => {
    var userId = localStorage.getItem("userId");
    var findPerson = this.props.teamMembers.users.find(
      (obj) => obj.id === values.salesPerson
    );
    if (findPerson) {
      var personName = findPerson.fullName;
    }
    this.setState({ loadVisaInfo: true });
    let data = {
      id: this.state.visaInfoData.id,
      subjectId: this.state.visaInfoData.subjectId,
      branchVisaTypeId: this.state.visaInfoData.branchVisaTypeId,
      caseStatusId: this.state.visaInfoData.caseStatusId,
      startDate:
        this.state.visaInfoData.startDate || "1900-01-01T00:00:00+00:00",
      isPaid: this.state.visaInfoData.isPaid,
      isSigned: this.state.visaInfoData.isSigned,
      casePriority: this.state.visaInfoData.casePriority,
      country: this.state.visaInfoData.country,
      expiryDate:
        this.state.visaInfoData.expiryDate || "1900-01-01T00:00:00+00:00",
      approveDate:
        this.state.visaInfoData.approveDate || "1900-01-01T00:00:00+00:00",
      oldId: this.state.visaInfoData.oldId || "",
      appliedOn: this.state.visaInfoData.appliedOn,
      appliedBy: this.state.visaInfoData.appliedBy || "",
      remarks: this.state.visaInfoData.remarks || "",
      status: this.state.visaInfoData.status || "",
      clientNumber: this.state.visaInfoData.clientNumber || "",
      policeCertificateDate:
        this.state.visaInfoData.policeCertificateDate ||
        "1900-01-01T00:00:00+00:00",
      medicalIssueDate:
        this.state.visaInfoData.medicalIssueDate || "1900-01-01T00:00:00+00:00",
      default: this.state.visaInfoData.default,
      xRayDate: this.state.visaInfoData.xRayDate || "1900-01-01T00:00:00+00:00",
      liaName: values.liaName || values.lia || "",
      visaApplicationNumber: values.visaAppNumber || "",
      caseOfficerName: "",
      branch: values.branch || "",
      closingDate:
        this.state.visaInfoData.closingDate || "1900-01-01T00:00:00+00:00",
      startApplicationDate:
        this.state.visaInfoData.startDate || "1900-01-01T00:00:00+00:00",
      lastActionDate:
        this.state.visaInfoData.lastActionDate || "1900-01-01T00:00:00+00:00",
      policeCertificateDate1:
        this.state.visaInfoData.policeCertificateDate1 ||
        "1900-01-01T00:00:00+00:00",
      policeCertificateDate2:
        this.state.visaInfoData.policeCertificateDate2 ||
        "1900-01-01T00:00:00+00:00",
      courierName: values.courierName || "Any",
      // visaOfficerEmail: this.state.visaInfoData.visaOfficerEmail || "",
      trackingNumber: values.trackingNumber || "",
      visaDescription: values.visaDescription || "Any",
      descriptionLog: this.state.visaInfoData.descriptionLog || "",
      eoiSubmitedDate:
        this.state.visaInfoData.eoiSubmitedDate || "1900-01-01T00:00:00+00:00",
      eoiSelectedDate:
        this.state.visaInfoData.eoiSelectedDate || "1900-01-01T00:00:00+00:00",
      eoiitaDate:
        this.state.visaInfoData.eoiitaDate || "1900-01-01T00:00:00+00:00",
      declineDate:
        this.state.visaInfoData.declineDate || "1900-01-01T00:00:00+00:00",
      declineDescription: this.state.visaInfoData.declineDescription || "",
      refusedDate:
        this.state.visaInfoData.refusedDate || "1900-01-01T00:00:00+00:00",
      refusedDescription: this.state.visaInfoData.refusedDescription || "",
      generic: this.state.visaInfoData.generic,
      hideCMS: this.state.visaInfoData.hideCMS,
      caseStart: this.state.visaInfoData.caseStart,
      caseEnd: this.state.visaInfoData.caseEnd,
      followUpDate: values.followupDate || "1900-01-01T00:00:00+00:00",
      worth: parseInt(values.worth) || 0,
      saleDate: values.saleDate || "1900-01-01T00:00:00+00:00",
      ftsSubmitted: true,
      ftsDate: this.state.visaInfoData.ftsDate || "1900-01-01T00:00:00+00:00",
      aipDate: this.state.visaInfoData.aipDate || "1900-01-01T00:00:00+00:00",
      submissionType: values.submissionType || "",
      documentReturnedDate: values.returnedDate || "1900-01-01T00:00:00+00:00",
      documentDescription: values.documentDescription || "",
      isAusi: this.state.visaInfoData.isAusi,
      subCategory: this.state.visaInfoData.subCategory,
      destination: this.state.visaInfoData.destination,
      contractSignedDate:
        this.state.visaInfoData.contractSignedDate ||
        "1900-01-01T00:00:00+00:00",
      assessmentCompletedDate:
        this.state.visaInfoData.assessmentCompletedDate ||
        "1900-01-01T00:00:00+00:00",
      eioNumber: values.eoiNumber || "",
      medicalExpiryDate:
        this.state.visaInfoData.medicalExpiryDate ||
        "1900-01-01T00:00:00+00:00",
      firDescription: this.state.visaInfoData.firDescription || "",
      firReceivedDate:
        this.state.visaInfoData.firReceivedDate || "1900-01-01T00:00:00+00:00",
      firDueDate:
        this.state.visaInfoData.firDueDate || "1900-01-01T00:00:00+00:00",
      firAdditionalInfoExtendedDate:
        this.state.visaInfoData.firAdditionalInfoExtendedDate ||
        "1900-01-01T00:00:00+00:00",
      liaid: this.state.visaInfoData.liaid,
      employerID: this.state.visaInfoData.employerID,
      contractSigned: this.state.visaInfoData.contractSigned,
      paid: this.state.visaInfoData.paid,
      visaPriority: this.state.visaInfoData.visaPriority || "",
      isCurrent: this.state.visaInfoData.isCurrent,
      salePersonId:
        values.salesPerson || "00000000-0000-0000-0000-000000000000",
      salePersonName: personName || "",
      visaTypeName: this.state.visaInfoData.branchVisaTypeName,
      branchVisaTypeName: this.state.visaInfoData.branchVisaTypeName,
      visaStatusName: this.state.visaInfoData.visaStatusName,
      visaStatusColor: this.state.visaInfoData.visaStatusColor,
      applicationCountry: this.state.visaInfoData.applicationCountry,
      visaOfficerEmail: values.visaOfficerEmail || "",
    };
    var changedFields = this.isEquivalent(this.state.visaInfoData, data);

    console.log(values.followUpDate);

    this.props
      .onUpdateSubjectCase(data)
      .then((res) => {
        this.setState({ loadVisaInfo: false });
        message.success("Visa info saved successfully");
        this.props
          .onGetVisaApplication()
          .then((res) => {
            if (res.payload && res.payload.items) {
              this.setState({
                visaResState: res.payload.items,
                loadVisaInfo: false,
              });
            } else {
              this.setState({
                visaResState: [],
                loadVisaInfo: false,
              });
            }
          })
          .catch(() => {
            this.setState({ visaResState: [], loadVisaInfo: false });
          });
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);

        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Visa Information " + changedFields + " updated by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Visa",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((err) => {
        this.setState({ loadVisaInfo: false });
        message.error("Unable on save visa info");
      });
  };

  onDownloadDoc = (value) => {
    // setLoading(true);
    this.props
      .onGetDocumentDownload(value, value.documentId)
      .then((res) => {
        console.log("document res", res.payload);
        this.downlodDocumentVisa(res.payload, value);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  downlodDocumentVisa = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${value.title}.${value.docuementExtension}`);
  };

  onDocumentPdf = (value) => {
    // setLoading(true);
    this.props
      .onGetDocumentDownload(value)
      .then((res) => {
        // setLoading(false);

        if (
          value.docuementExtension == "pdf" ||
          value.docuementExtension == "png" ||
          value.docuementExtension == "jpg" ||
          value.docuementExtension == "jpeg" ||
          value.docuementExtension == "gif" ||
          value.docuementExtension == "svg"
        ) {
          if (value.docuementExtension == "pdf") {
            const file = new Blob([res.payload], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
          } else {
            const blob = new Blob([res.payload], { type: "image/png" });
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, "_blank");
          }
        } else {
          let blob = new Blob([res.payload], {
            type: "application/octet-stream",
          });
          saveAs(blob, `${value.title}.${value.docuementExtension}`);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  onChangeCountry = (value) => {
    var findCountry =
      this.props.brnchVisaCountriesData &&
      this.props.brnchVisaCountriesData.find((obj) => obj.countryId === value);
    this.setState({
      countryId: value,
      countryName: (findCountry && findCountry.countryName) || "",
    });
  };

  onDownloadAttachment = (attachment) => {
    let formData = new FormData();
    if (this.state.oldId) {
      var getAttachmentOpt = {
        url: `v1/document/GetOldDocumentBytesforAttachment/${attachment.name}/${attachment.blobURL}`,
      };

      getAttachmentOpt.types = [
        "GET_DOCUMENTS_SUCCESS",
        "GET_DOCUMENTS_FAILURE",
      ];
      apiRefresh.getFile(getAttachmentOpt).then((res) => {
        this.downlodDocument(res, attachment);
      });
    } else {
      let branchId = localStorage.getItem("selectedBranchId");
      var getAttachmentOpt = {
        url: `v1/document/GetDocumentBytesforAttachment/${attachment.blobURL}/${branchId}`,
      };

      getAttachmentOpt.types = [
        "GET_DOCUMENTS_SUCCESS",
        "GET_DOCUMENTS_FAILURE",
      ];
      apiRefresh.getFile(getAttachmentOpt).then((res) => {
        this.downlodDocument(res, attachment);
      });
    }
  };

  downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(byte, value.name);
  };

  onChangeExpiryDate = (e, visa) => {
    let data = {
      id: visa.id,
      caseStatusId: visa.caseStatusId,
      approveDate: visa.approveDate,
      expiryDate: e || "",
      date: new Date(),
      branchVisaTypeId: visa.branchVisaTypeId,
      description: visa.description || "",
      receivedDate: visa.receivedDate || "1900-01-01T00:00:00+00:00",
      dueDate: visa.dueDate || "1900-01-01T00:00:00+00:00",
      extendDueDate: visa.extendDueDate || "1900-01-01T00:00:00+00:00",
      submittedDate: visa.submittedDate || "1900-01-01T00:00:00+00:00",
      aipDate: visa.aipDate || "1900-01-01T00:00:00+00:00",
      eoiSubmitedDate: visa.eoiSubmitedDate || "1900-01-01T00:00:00+00:00",
      followUpDate: visa.followUpDate || "1900-01-01T00:00:00+00:00",
      eoiSelectedDate: visa.eoiSelectedDate || "1900-01-01T00:00:00+00:00",
      eoiitaDate: visa.eoiitaDate || "1900-01-01T00:00:00+00:00",
      declineDate: visa.declineDate || "1900-01-01T00:00:00+00:00",
      declineDescription: visa.declineDescription || "",
      assessmentCompletedDate:
        visa.assessmentCompletedDate || "1900-01-01T00:00:00+00:00",
      contractSigned: visa.contractSigned,
      contractSignedDate:
        visa.contractSignedDate || "1900-01-01T00:00:00+00:00",
      refusedDate: visa.refusedDate || "1900-01-01T00:00:00+00:00",
      refusedDescription: visa.refusedDescription || "",
    };
    var updCaseStatus = {
      url: `v1/subject/case/UpdateSubjectCaseStatus`,
    };

    updCaseStatus.types = [
      "UPDATE_CASE_STATUS_SUCCESS",
      "UPDATE_CASE_STATUS_FAILURE",
    ];
    this.setState({ loadVisaInfo: true });
    apiRefresh
      .put(updCaseStatus, data)
      .then((res) => {
        message.success("Expiry Date Updated!");
        this.getVisaApplication();
      })
      .catch((err) => {
        this.setState({ loadVisaInfo: false });
      });
  };

  onChangeApproveDate = (e, visa) => {
    let data = {
      id: visa.id,
      caseStatusId: visa.caseStatusId,
      approveDate: e || "1900-01-01T00:00:00+00:00",
      expiryDate: visa.expiryDate || "1900-01-01T00:00:00+00:00",
      date: new Date(),
      branchVisaTypeId: visa.branchVisaTypeId,
      description: visa.description || "",
      receivedDate: visa.receivedDate || "1900-01-01T00:00:00+00:00",
      dueDate: visa.dueDate || "1900-01-01T00:00:00+00:00",
      extendDueDate: visa.extendDueDate || "1900-01-01T00:00:00+00:00",
      submittedDate: visa.submittedDate || "1900-01-01T00:00:00+00:00",
      aipDate: visa.aipDate || "1900-01-01T00:00:00+00:00",
      eoiSubmitedDate: visa.eoiSubmitedDate || "1900-01-01T00:00:00+00:00",
      followUpDate: visa.followUpDate || "1900-01-01T00:00:00+00:00",
      eoiSelectedDate: visa.eoiSelectedDate || "1900-01-01T00:00:00+00:00",
      eoiitaDate: visa.eoiitaDate || "1900-01-01T00:00:00+00:00",
      declineDate: visa.declineDate || "1900-01-01T00:00:00+00:00",
      declineDescription: visa.declineDescription || "",
      assessmentCompletedDate:
        visa.assessmentCompletedDate || "1900-01-01T00:00:00+00:00",
      contractSigned: visa.contractSigned,
      contractSignedDate:
        visa.contractSignedDate || "1900-01-01T00:00:00+00:00",
      refusedDate: visa.refusedDate || "1900-01-01T00:00:00+00:00",
      refusedDescription: visa.refusedDescription || "",
    };
    var updCaseStatus = {
      url: `v1/subject/case/UpdateSubjectCaseStatus`,
    };

    updCaseStatus.types = [
      "UPDATE_CASE_STATUS_SUCCESS",
      "UPDATE_CASE_STATUS_FAILURE",
    ];

    apiRefresh
      .put(updCaseStatus, data)
      .then((res) => {
        message.success("Expiry Date Updated!");
        this.getVisaApplication();
      })
      .catch((err) => {
        this.setState({ loadVisaInfo: false });
      });
  };

  render() {
    const {
      selectedOption,
      activeTabNewVisa,
      showAppDates,
      linkFamily,
      showDocuments,
      showVisaInfo,
      showEmail,
      expendedView,
      newApplicationWith,
      checkIndex,
      headerOptions,
      eoiItaDate,
      eoiSelectedDate,
      profileData,
      visaDelModal,
      caseHistoryDelModal,
      loadVisaInfo,
      visaObj,
      visaStatusId,
      showPPIDetail,
      caseInd,
      showFileNotes,
      emailColumns,
      visaResState,
      countryId,
      countryName,
      showDetailPopup,
      emailContent,
    } = this.state;
    const {
      visaTypeData,
      onStartNewApplication,
      visaAppData,
      visaStatusData,
      onUpdateCaseStatus,
      teamMembers,
      onGetVisaApplication,
      caseDocumentData,
      partnerProfileData,
      countryVisaTypeData,
      onGetVisaCategory,
      visaCategoryData,
      clientFamilyData,
      onAddFamilyLink,
      onRemoveFamilyLink,
      onSetActiveKey,
      activeKey,
      onUpdatePriority,
      visaPriorityRes,
      onSignedVisa,
      onPaidVisa,
      remindersRes,
      onUpdateCaseHistory,
      visaFileNotesRes,
      onGetVisaStatus,
      onGetReminder,
      onGetAdmissionStatuses,
      onGetAdmissionProgram,
      onGetVisaTypeByCountry,
      onGetVisaType,
      onGetVisaEmails,
      visaEmailRes,
    } = this.props;
    return (
      <div>
        <Spin spinning={loadVisaInfo}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              {headerOptions.length > 0 && (
                <HeaderBarTabs data={headerOptions} activeTab="Visas" />
              )}

              <div
                className="cv-main-container"
                style={{ display: "flex", margin: 10, padding: 0 }}
              >
                <div
                  style={{
                    width:
                      expendedView && !activeKey
                        ? "90%"
                        : showVisaInfo
                        ? "70%"
                        : `calc(100% - 458px)`,
                  }}
                >
                  {profileData ? (
                    <div
                      class="form-cont"
                      style={{
                        paddingLeft: 10,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <p>
                          Client Name: {profileData && profileData.fullName}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div style={{ height: 30 }} />
                  )}{" "}
                  <div
                    className="cv-left-container"
                    style={{ width: "100%", marginTop: 1 }}
                  >
                    <div style={{ width: "50%", marginLeft: "10px" }}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                        onChange={this.onChangeCountry}
                        placeholder="Select Country"
                        value={countryId}
                      >
                        {this.props.brnchVisaCountriesData &&
                          this.props.brnchVisaCountriesData.map(
                            (country, index) => (
                              <Option value={country.countryId}>
                                {country.countryName}
                              </Option>
                            )
                          )}
                      </Select>
                    </div>
                    <div className="cv-top-btn-row">
                      <div
                        className="cv-btn-blue"
                        style={{ cursor: "pointer", width: "100%" }}
                        onClick={() => {
                          this.startNewApp(countryName);
                          onSetActiveKey("3");
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span className="cv-top-lbtn-text">
                            START NEW APPLICATION{" "}
                            {countryName && `(${countryName})`}
                          </span>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span className="cv-top-rbtn-text">Preparing</span>
                        </div>
                      </div>
                    </div>

                    {/* ===== Design from Visa Flow Screen ===== */}

                    {true && (
                      <div>
                        <Modal
                          title={false}
                          style={{ top: 20 }}
                          visible={visaDelModal}
                          onOk={() => this.removeSubject(visaObj)}
                          onCancel={() =>
                            this.setState({ visaDelModal: false })
                          }
                          Header={false}
                        >
                          <h4>Are you sure you want to delete this ?</h4>
                        </Modal>

                        <Modal
                          title={false}
                          style={{ top: 20 }}
                          visible={caseHistoryDelModal}
                          onOk={() => this.removeStatus(visaStatusId)}
                          onCancel={() =>
                            this.setState({ caseHistoryDelModal: false })
                          }
                          Header={false}
                        >
                          <h4>Are you sure you want to delete this ?</h4>
                        </Modal>
                        {visaResState.map((visaApp, index) => {
                          if (!this.state.eoiitaDate) {
                            // this.setState({
                            //   eoiItaDate: moment(visaApp.eoiitaDate),
                            //   eoiSelectedDate: moment(visaApp.eoiSelectedDate),
                            // });
                          }

                          return (
                            <div key={index}>
                              {/* <VisaCard
                              visaApp={visaApp}
                              onRemoveSubjectCase={onRemoveSubjectCase}
                              onGetVisaApplication={onGetVisaApplication}
                            /> */}
                              <div
                                className="cv-gray-cont"
                                style={{
                                  paddingBottom:
                                    showAppDates || showDocuments || linkFamily
                                      ? 0
                                      : 20,
                                }}
                              >
                                <div className="cv-row">
                                  <div style={{ paddingRight: 10 }}>
                                    <div style={{ display: "flex" }}>
                                      <span className="cv-bold-text">
                                        {visaApp.branchVisaTypeName
                                          ? visaApp.branchVisaTypeName.toUpperCase()
                                          : ""}
                                      </span>
                                      {visaApp.country !== "" && (
                                        <div className="cv-green-box">
                                          <span className="cv-green-text">
                                            {visaApp.country
                                              ? visaApp.country.toUpperCase()
                                              : ""}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      {visaApp.caseLinks &&
                                        visaApp.caseLinks.items.length > 0 &&
                                        visaApp.caseLinks.items.map(
                                          (caseLink, linkIndex) => {
                                            return (
                                              <div
                                                key={linkIndex}
                                                className="linked-member-cont"
                                                style={{
                                                  marginLeft:
                                                    linkIndex > 0 ? 5 : 0,
                                                }}
                                              >
                                                <div className="button-first-second-row">
                                                  <span
                                                    className="linked-member-text"
                                                    style={{
                                                      color: "#555555",
                                                    }}
                                                  >
                                                    {caseLink.firstName}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                    {visaApp.visaStatusName === "Approved" && (
                                      <>
                                        <div className="cv-date-row">
                                          <div style={{ width: 175 }}>
                                            <span className="cv-date-label">
                                              Expiry Date :{" "}
                                            </span>
                                          </div>
                                          <DatePicker
                                            format={dateFormat}
                                            value={
                                              visaApp.expiryDate !==
                                              "1900-01-01T00:00:00+00:00"
                                                ? moment(visaApp.expiryDate)
                                                : ""
                                            }
                                            onChange={(e) =>
                                              this.onChangeExpiryDate(
                                                e,
                                                visaApp
                                              )
                                            }
                                          />
                                        </div>
                                        <div
                                          className="cv-date-row"
                                          style={{ marginTop: 5 }}
                                        >
                                          <div style={{ width: 175 }}>
                                            <span className="cv-date-label">
                                              Approved Date :{" "}
                                            </span>
                                          </div>
                                          <DatePicker
                                            format={dateFormat}
                                            value={
                                              visaApp.approveDate !==
                                              "1900-01-01T00:00:00+00:00"
                                                ? moment(visaApp.approveDate)
                                                : ""
                                            }
                                            onChange={(e) =>
                                              this.onChangeApproveDate(
                                                e,
                                                visaApp
                                              )
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                    {visaApp.visaStatusName === "Refused" && (
                                      <>
                                        <div className="cv-date-row">
                                          <div style={{ width: 175 }}>
                                            <span className="cv-date-label">
                                              Refused Date:{" "}
                                            </span>
                                          </div>
                                          <DatePicker
                                            format={dateFormat}
                                            value={
                                              visaApp.refusedDate !==
                                              "1900-01-01T00:00:00+00:00"
                                                ? moment(visaApp.refusedDate)
                                                : ""
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                    {visaApp.visaStatusName ===
                                      "Assessment Completed" && (
                                      <>
                                        <div className="cv-date-row">
                                          <div style={{ width: 175 }}>
                                            <span className="cv-date-label">
                                              Assessment Completed Date:{" "}
                                            </span>
                                          </div>
                                          <DatePicker
                                            format={dateFormat}
                                            value={
                                              visaApp.assessmentCompletedDate !==
                                              "1900-01-01T00:00:00+00:00"
                                                ? moment(
                                                    visaApp.assessmentCompletedDate
                                                  )
                                                : ""
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                    {visaApp.visaStatusName === "Selected" && (
                                      <>
                                        <div className="cv-date-row">
                                          <div style={{ width: 175 }}>
                                            <span className="cv-date-label">
                                              EOI ITA Date:{" "}
                                            </span>
                                          </div>
                                          <DatePicker
                                            value={
                                              visaApp.eoiitaDate !==
                                              "1900-01-01T00:00:00+00:00"
                                                ? moment(visaApp.eoiitaDate)
                                                : ""
                                            }
                                            onChange={this.onChangeEoiItaDate}
                                            format={dateFormat}
                                          />
                                        </div>
                                        <div className="cv-date-row">
                                          <div style={{ width: 175 }}>
                                            <span className="cv-date-label">
                                              EOI Selected Date:{" "}
                                            </span>
                                          </div>
                                          <DatePicker
                                            value={
                                              visaApp.eoiSelectedDate !==
                                              "1900-01-01T00:00:00+00:00"
                                                ? moment(
                                                    visaApp.eoiSelectedDate
                                                  )
                                                : ""
                                            }
                                            onChange={
                                              this.onChangeEoiSelectedDate
                                            }
                                            format={dateFormat}
                                          />
                                        </div>
                                      </>
                                    )}
                                    {visaApp.visaStatusName ===
                                      "Contract Signed Date" && (
                                      <>
                                        <div className="cv-date-row">
                                          <div style={{ width: 175 }}>
                                            <span className="cv-date-label">
                                              Contract Signed Date:{" "}
                                            </span>
                                          </div>
                                          <DatePicker
                                            value={
                                              visaApp.contractSignedDate !==
                                              "1900-01-01T00:00:00+00:00"
                                                ? moment(
                                                    visaApp.contractSignedDate
                                                  )
                                                : ""
                                            }
                                            format={dateFormat}
                                          />
                                        </div>
                                      </>
                                    )}
                                    {visaApp.visaStatusName ===
                                      "Approve in principle" && (
                                      <>
                                        <div className="cv-date-row">
                                          <div style={{ width: 175 }}>
                                            <span className="cv-date-label">
                                              AIP Date:{" "}
                                            </span>
                                          </div>
                                          <DatePicker
                                            value={
                                              visaApp.aipDate !==
                                              "1900-01-01T00:00:00+00:00"
                                                ? moment(visaApp.aipDate)
                                                : ""
                                            }
                                            format={dateFormat}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <div style={{ display: "flex" }}>
                                      <div
                                        className="cv-preparing-box"
                                        style={{
                                          backgroundColor:
                                            visaApp.visaStatusColor,
                                        }}
                                      >
                                        <div
                                          className="cv-imm-cont"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onClickStatus(visaApp)
                                          }
                                        >
                                          <span className="cv-imm-text">
                                            {visaApp.visaStatusName
                                              ? visaApp.visaStatusName.toUpperCase()
                                              : ""}
                                          </span>
                                        </div>
                                        <div className="cv-icons-row">
                                          <Tooltip
                                            placement="topLeft"
                                            title={`Link`}
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                this.setState({
                                                  showEmail: false,
                                                  linkFamily: true,
                                                  showDocuments: false,
                                                  showAppDates: false,
                                                  expendedView: true,
                                                  checkIndex: index,
                                                  showFileNotes: false,
                                                });
                                              }}
                                            >
                                              <img
                                                src={Images.multimedia}
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                }}
                                              />
                                            </div>
                                          </Tooltip>

                                          <Tooltip
                                            placement="topLeft"
                                            title={`File Notes`}
                                          >
                                            <div
                                              onClick={() => {
                                                let clientprofileid = sessionStorage.getItem(
                                                  "clientprofileid"
                                                );
                                                let data = {
                                                  clientId: clientprofileid,
                                                  caseId: visaApp.id,
                                                };
                                                this.props.onGetVisaFileNotes(
                                                  data
                                                );
                                                this.setState({
                                                  showEmail: false,
                                                  linkFamily: false,
                                                  showDocuments: false,
                                                  showAppDates: false,
                                                  expendedView: true,
                                                  checkIndex: index,
                                                  showFileNotes: true,
                                                });
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {/* <img
                                                  src={Images.notesWhite}
                                                  style={{
                                                    width: 15,
                                                    height: 15,
                                                  }}
                                                /> */}
                                              <FileDoneOutlined
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                  color: "#FFF",
                                                }}
                                              />
                                            </div>
                                          </Tooltip>

                                          <Tooltip
                                            placement="topLeft"
                                            title={`Emails`}
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                onGetVisaEmails(visaApp.id);
                                                this.setState({
                                                  showEmail: true,
                                                  linkFamily: false,
                                                  showDocuments: false,
                                                  showAppDates: false,
                                                  expendedView: true,
                                                  checkIndex: index,
                                                  showFileNotes: false,
                                                });
                                              }}
                                            >
                                              <img
                                                src={Images.emailWhite}
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                }}
                                              />
                                            </div>
                                          </Tooltip>
                                          <Tooltip
                                            placement="topLeft"
                                            title={`Documents`}
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                this.getCaseDocuments(
                                                  visaApp.id
                                                );
                                                this.setState({
                                                  showEmail: false,
                                                  linkFamily: false,
                                                  showDocuments: true,
                                                  showAppDates: false,
                                                  expendedView: true,
                                                  checkIndex: index,
                                                  showFileNotes: false,
                                                });
                                              }}
                                            >
                                              <img
                                                src={Images.notesWhite}
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                }}
                                              />
                                            </div>
                                          </Tooltip>

                                          <Tooltip
                                            placement="topLeft"
                                            title={`Detail`}
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                this.setState({
                                                  showAppDates: true,
                                                  linkFamily: false,
                                                  showDocuments: false,
                                                  showEmail: false,
                                                  expendedView: true,
                                                  checkIndex: index,
                                                  showFileNotes: false,
                                                });
                                              }}
                                            >
                                              <DownOutlined
                                                style={{ color: "#FFFFFF" }}
                                              />
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </div>
                                      <div
                                        className="cv-delete-cont"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          this.setState({
                                            visaDelModal: true,
                                            visaObj: visaApp,
                                          });
                                        }}
                                      >
                                        <img
                                          src={Images.deleteGray}
                                          className="cv-dlt-icon"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {(showAppDates ||
                                  linkFamily ||
                                  showDocuments ||
                                  showFileNotes ||
                                  showEmail) &&
                                  checkIndex === index && (
                                    <div className="cv-show-hide-cont">
                                      <div></div>
                                      <div
                                        className="sus-show-btn"
                                        style={{ marginRight: 30 }}
                                        onClick={() =>
                                          this.setState({
                                            showAppDates: false,
                                            linkFamily: false,
                                            showDocuments: false,
                                            showFileNotes: false,
                                          })
                                        }
                                      >
                                        <span className="sus-save-btn-text">
                                          HIDE
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                {showDocuments && checkIndex === index && (
                                  <div className="cv-gray-cont">
                                    <div className="sus-tab-container">
                                      <div
                                        onClick={() =>
                                          this.onChangeTopTab("document")
                                        }
                                        className={
                                          this.state.activeTab == "document"
                                            ? "cv-active-tab"
                                            : "cv-inactive-tab"
                                        }
                                      >
                                        <span
                                          className={
                                            this.state.activeTab == "document"
                                              ? "cv-active-tab-text"
                                              : "cv-inactive-tab-text"
                                          }
                                        >
                                          DOCUMENT
                                        </span>
                                      </div>
                                      {/* <div
                                        onClick={() =>
                                          this.onChangeTopTab(
                                            "documentChecklist"
                                          )
                                        }
                                        className={
                                          this.state.activeTab ==
                                          "documentChecklist"
                                            ? "cv-active-tab"
                                            : "cv-inactive-tab"
                                        }
                                      >
                                        <span
                                          className={
                                            this.state.activeTab ==
                                            "documentChecklist"
                                              ? "cv-active-tab-text"
                                              : "cv-inactive-tab-text"
                                          }
                                        >
                                          SEND DOCUMENT CHECKLIST
                                        </span>
                                      </div> */}
                                    </div>

                                    <div className="cv-white-cont">
                                      <div
                                        className="cv-row"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <div />
                                        <div style={{ display: "flex" }}>
                                          <div className="cv-print-icon-cont">
                                            <img
                                              src={Images.printBlue}
                                              className="profile-print-icon"
                                            />
                                          </div>
                                          <div className="cv-extend-icon-cont">
                                            <img
                                              src={Images.extendIcon}
                                              className="cv-extend-icon"
                                              style={{
                                                transform: `rotate(270deg)`,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div />
                                        <div
                                          style={{
                                            display: "flex",
                                            marginBottom: 10,
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            <span
                                              className="cv-bold-text"
                                              style={{ color: "#75868F" }}
                                            >
                                              Visible To:
                                            </span>
                                          </div>
                                          <div className="cv-circle-lable-cont">
                                            <div className="cv-client-circle"></div>
                                            <span
                                              className="cv-normal-text"
                                              style={{ marginLeft: 5 }}
                                            >
                                              Client
                                            </span>
                                          </div>
                                          {/*<div className="cv-circle-lable-cont">*/}
                                          {/*  <div className="cv-agent-circle"></div>*/}
                                          {/*  <span*/}
                                          {/*    className="cv-normal-text"*/}
                                          {/*    style={{ marginLeft: 5 }}*/}
                                          {/*  >*/}
                                          {/*    Agent*/}
                                          {/*  </span>*/}
                                          {/*</div>*/}
                                          {/*<div className="cv-circle-lable-cont">*/}
                                          {/*  <div className="cv-both-circle"></div>*/}
                                          {/*  <span*/}
                                          {/*    className="cv-normal-text"*/}
                                          {/*    style={{ marginLeft: 5 }}*/}
                                          {/*  >*/}
                                          {/*    Both*/}
                                          {/*  </span>*/}
                                          {/*</div>*/}
                                        </div>
                                      </div>
                                      <div className="cv-doc-head-row">
                                        <div
                                          className="cv-width-55"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            <input
                                              type="checkbox"
                                              className="sus-checkbox"
                                              onChange={this.handleCheck}
                                              defaultChecked={
                                                this.state.checked
                                              }
                                            />
                                          </div>
                                          <div className="cv-doc-inner-index">
                                            <span className="cv-doc-head-text">
                                              #
                                            </span>
                                          </div>
                                          <div className="cv-doc-width">
                                            <span className="cv-doc-head-text">
                                              Document
                                            </span>
                                          </div>
                                        </div>
                                        <div className="cv-width-17">
                                          <span className="cv-doc-head-text">
                                            Title
                                          </span>
                                        </div>
                                        <div className="cv-width-13">
                                          <span className="cv-doc-head-text">
                                            Type
                                          </span>
                                        </div>
                                        <div className="cv-width-15">
                                          <span className="cv-doc-head-text">
                                            Action
                                          </span>
                                        </div>
                                      </div>

                                      {caseDocumentData &&
                                        caseDocumentData.items.map(
                                          (document, index) => {
                                            return (
                                              <div className="cv-doc-row">
                                                <div
                                                  className="cv-width-52"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div>
                                                    <input
                                                      type="checkbox"
                                                      className="sus-checkbox"
                                                      onChange={
                                                        this.handleCheck
                                                      }
                                                      defaultChecked={
                                                        this.state.checked
                                                      }
                                                    />
                                                  </div>
                                                  <div className="cv-doc-inner-index">
                                                    <span
                                                      className="cv-doc-text"
                                                      style={{
                                                        color: "#5B5B5B",
                                                      }}
                                                    >
                                                      {index + 1}
                                                    </span>
                                                  </div>
                                                  <div className="cv-doc-width">
                                                    <div>
                                                      <span
                                                        className="cv-doc-text"
                                                        style={{
                                                          color: "#1081B8",
                                                        }}
                                                      >
                                                        {document.title}
                                                      </span>
                                                    </div>
                                                    <div className="cv-doc-date-text-cont">
                                                      <span className="cv-doc-date-text"></span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="cv-width-20"
                                                  style={{ padding: 1 }}
                                                >
                                                  <div className="cv-title-box">
                                                    <span
                                                      className="cv-normal-text"
                                                      style={{
                                                        fontSize: 9,
                                                        marginLeft: 5,
                                                      }}
                                                    >
                                                      {document.blobFileName}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div
                                                  className="cv-width-13"
                                                  style={{ padding: 1 }}
                                                >
                                                  <div
                                                    className="cv-title-box"
                                                    style={{
                                                      width: "80%",
                                                      border: "none",
                                                    }}
                                                  >
                                                    <div className="selectType">
                                                      <Select
                                                        placeholder="Select"
                                                        // onChange={(value) =>
                                                        //   typeConfirmation(
                                                        //     value,
                                                        //     document
                                                        //   )
                                                        // }
                                                        defaultValue={
                                                          document.documentTypeId ==
                                                          0
                                                            ? -1
                                                            : document.documentTypeId
                                                        }
                                                        value={
                                                          document.documentTypeId
                                                        }
                                                        dropdownClassName="doc-dropdown-width"
                                                      >
                                                        <Option
                                                          key={0}
                                                          value={0}
                                                        >
                                                          Unparented
                                                        </Option>
                                                        {this.state
                                                          .onFilterData &&
                                                          this.state
                                                            .onFilterData
                                                            .items &&
                                                          this.state.onFilterData.items.map(
                                                            (item, index) => (
                                                              <Option
                                                                key={item.id}
                                                                value={item.id}
                                                              >
                                                                {" "}
                                                                {item.name}
                                                              </Option>
                                                            )
                                                          )}
                                                      </Select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="cv-width-15"
                                                  style={{ display: "block" }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.onDownloadDoc(
                                                          document
                                                        )
                                                      }
                                                      className="cv-action-icons-border"
                                                    >
                                                      <img
                                                        src={Images.download}
                                                        className="cv-action-icon"
                                                      />
                                                    </div>
                                                    {/* <div
                                                      className="cv-action-icons-border"
                                                      style={{
                                                        marginLeft: 5,
                                                      }}
                                                      onClick={() =>
                                                        this.onDocumentPdf(
                                                          document
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                        marginLeft: 5,
                                                      }}
                                                    >
                                                      <img
                                                        src={Images.visibility}
                                                        className="cv-action-icon"
                                                      />
                                                    </div> */}
                                                    {false && (
                                                      <div
                                                        className="cv-action-icons-border"
                                                        style={{
                                                          marginLeft: 5,
                                                        }}
                                                      >
                                                        <img
                                                          src={Images.download}
                                                          className="cv-action-icon"
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                  {false && (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        marginTop: 3,
                                                      }}
                                                    >
                                                      <div className="cv-action-icons-border">
                                                        <img
                                                          src={Images.fileNotes}
                                                          className="cv-action-icon"
                                                        />
                                                      </div>
                                                      <div
                                                        className="cv-action-icons-border"
                                                        style={{
                                                          marginLeft: 5,
                                                        }}
                                                      >
                                                        <img
                                                          src={
                                                            Images.multimediaBlue
                                                          }
                                                          className="cv-action-icon"
                                                        />
                                                      </div>
                                                      <div
                                                        className="cv-action-icons-border"
                                                        style={{
                                                          marginLeft: 5,
                                                        }}
                                                      >
                                                        <img
                                                          src={
                                                            Images.deleteIcon
                                                          }
                                                          className="cv-action-icon"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                )}
                                {showEmail && checkIndex === index && (
                                  <div className="cv-gray-cont">
                                    <div className="cv-white-cont">
                                      <div
                                        className="cv-row"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <div />
                                        <div style={{ display: "flex" }}>
                                          {/* <div className="cv-print-icon-cont">
                                              <img
                                                src={Images.printBlue}
                                                className="profile-print-icon"
                                              />
                                            </div> */}
                                          <div className="cv-extend-icon-cont">
                                            <img
                                              src={Images.extendIcon}
                                              className="cv-extend-icon"
                                              style={{
                                                transform: `rotate(270deg)`,
                                              }}
                                            />
                                          </div>
                                          <div className="client-email-popup-body">
                                            <Table
                                              className="border-3 "
                                              // rowSelection={rowSelection1}
                                              // showCount={true}
                                              columns={emailColumns}
                                              dataSource={visaEmailRes}
                                              pagination={false}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {showFileNotes && checkIndex === index && (
                                  <div
                                    className="file-notes-content"
                                    style={{ marginTop: 20 }}
                                  >
                                    {visaFileNotesRes &&
                                      visaFileNotesRes.items.map(
                                        (fileNote, index) => {
                                          var loginUserName = localStorage.getItem(
                                            "userName"
                                          );
                                          return (
                                            <div>
                                              <div
                                                key={index}
                                                className="file-content-index-cont"
                                                style={{
                                                  paddingTop: 8,
                                                  paddingBottom: 8,
                                                  marginBottom: 20,
                                                }}
                                              >
                                                <div className="file-content-index-row">
                                                  <div>
                                                    <div>
                                                      <span className="date-time">
                                                        {moment(
                                                          fileNote.modifiedDate
                                                        ).format(
                                                          "DD MMM YYYY hh:mm A"
                                                        ) + " "}
                                                      </span>
                                                      <span className="title">
                                                        {loginUserName}
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            fileNote.fileNote,
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="delete-cont">
                                                    {/* <div
                                                      className="delete-icon"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        this.setState({
                                                          showOnEdit: true,
                                                          showIndex: index,
                                                          editFileNotesString:
                                                            fileNote.fileNote,
                                                        });
                                                      }}
                                                    >
                                                      <img
                                                        src={Images.notesBlue}
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                        }}
                                                      />
                                                    </div> */}
                                                    {/* <div className="delete-icon">
                                                      <img
                                                        src={Images.blueAdd}
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                        }}
                                                      />
                                                    </div> */}
                                                    {/* <div
                                                      className="delete-icon"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.onRemoveFileNote(
                                                          fileNote.id
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src={Images.deleteIcon}
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                        }}
                                                      />
                                                    </div> */}
                                                  </div>
                                                </div>
                                                {false && (
                                                  <div>
                                                    <div
                                                      style={{
                                                        marginTop: 20,
                                                        marginBottom: 20,
                                                      }}
                                                    >
                                                      {/* <FroalaEditor
                                                          ref={(el) => {
                                                            config = el;
                                                          }}
                                                          config={config}
                                                          tag="textarea"
                                                          model={
                                                            editFileNotesString
                                                          }
                                                          onModelChange={(e) =>
                                                            this.setState({
                                                              editFileNotesString: e,
                                                            })
                                                          }
                                                        /> */}
                                                    </div>
                                                    <div
                                                      className="button-container-cont"
                                                      style={{
                                                        paddingLeft: 0,
                                                        paddingTop: 0,
                                                      }}
                                                    >
                                                      <div
                                                        onClick={() =>
                                                          this.updFileNote(
                                                            fileNote.id
                                                          )
                                                        }
                                                        className="add-file-note-btn margin-top-12"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color: "#FFFFFF",
                                                            fontSize: 10,
                                                          }}
                                                        >
                                                          SAVE
                                                        </span>
                                                      </div>
                                                      <div
                                                        onClick={
                                                          this.handleCancel
                                                        }
                                                        className="add-file-note-btn margin-top-12"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: 20,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color: "#FFFFFF",
                                                            fontSize: 10,
                                                          }}
                                                        >
                                                          CANCEL
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                )}
                              </div>

                              <div className="cv-ass-rows-cont">
                                {linkFamily && checkIndex === index && (
                                  <div className="cv-lf-white-cont">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: 20,
                                        marginLeft: 10,
                                      }}
                                    >
                                      {clientFamilyData &&
                                        clientFamilyData.items.map(
                                          (member, index) => {
                                            var linked = false;
                                            var linkedMember = visaApp.caseLinks.items.find(
                                              (obj) =>
                                                obj.clientId === member.id
                                            );
                                            if (linkedMember) {
                                              linked = true;
                                            }
                                            return (
                                              <div key={index}>
                                                <LinkMemberCard
                                                  member={member}
                                                  linked={linked}
                                                  linkedMember={linkedMember}
                                                  onAddFamilyLink={
                                                    onAddFamilyLink
                                                  }
                                                  onGetVisaApplication={
                                                    onGetVisaApplication
                                                  }
                                                  visaApp={visaApp}
                                                  onRemoveFamilyLink={
                                                    onRemoveFamilyLink
                                                  }
                                                />
                                              </div>
                                            );
                                          }
                                        )}

                                      {/* disable static users */}
                                      {false && (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: 15,
                                          }}
                                        >
                                          <div
                                            class="cm-profile-fill-cont"
                                            style={{ marginLeft: 20 }}
                                          >
                                            <img
                                              src={Images.personFill}
                                              class="cm-profile-fill"
                                            />
                                          </div>
                                          <div className="cv-lf-checkbox-label-cont">
                                            <span className="sus-checkbox-label">
                                              Sam Ban
                                            </span>
                                          </div>
                                          <div>
                                            <input
                                              type="checkbox"
                                              className="sus-checkbox"
                                              onChange={this.handleCheck}
                                              defaultChecked={
                                                this.state.checked
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                      {false && (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: 15,
                                          }}
                                        >
                                          <div
                                            class="cm-profile-fill-cont"
                                            style={{ marginLeft: 20 }}
                                          >
                                            <img
                                              src={Images.personFill}
                                              class="cm-profile-fill"
                                            />
                                          </div>
                                          <div className="cv-lf-checkbox-label-cont">
                                            <span className="sus-checkbox-label">
                                              Lewis Jams
                                            </span>
                                          </div>
                                          <div>
                                            <input
                                              type="checkbox"
                                              className="sus-checkbox"
                                              onChange={this.handleCheck}
                                              defaultChecked={
                                                this.state.checked
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}

                                {showAppDates &&
                                  checkIndex === index &&
                                  visaApp.caseHistory.map(
                                    (caseHistory, caseIndex) => {
                                      return (
                                        <div key={caseIndex}>
                                          <div
                                            className="cv-row"
                                            style={{
                                              marginTop: 10,
                                              alignItems: "center",
                                            }}
                                          >
                                            <div className="cv-ass-row">
                                              <div
                                                className="cv-blue-box"
                                                style={{
                                                  backgroundColor:
                                                    caseHistory.color,
                                                }}
                                              ></div>
                                              <div style={{ display: "flex" }}>
                                                <span className="cv-normal-text">
                                                  {caseHistory.caseStatusName}
                                                </span>
                                              </div>
                                              {/* disable detail button */}
                                              {caseHistory.caseStatusId ===
                                                32 && (
                                                <div
                                                  className="cv-org-cont"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      showPPIDetail: true,
                                                      caseInd: caseIndex,
                                                    })
                                                  }
                                                >
                                                  <span className="cv-org-text">
                                                    DETAILS
                                                  </span>
                                                </div>
                                              )}
                                            </div>

                                            <div className="cv-row">
                                              <DatePicker
                                                value={
                                                  caseHistory.startDate ==
                                                  "1900-01-01T00:00:00+00:00"
                                                    ? ""
                                                    : moment(
                                                        caseHistory.startDate
                                                      )
                                                }
                                                format={dateFormat}
                                                onChange={(
                                                  date,
                                                  dateString
                                                ) => {
                                                  let data = {
                                                    id: caseHistory.id,
                                                    startDate: date,
                                                  };
                                                  this.setState({
                                                    loadVisaInfo: true,
                                                  });
                                                  onUpdateCaseHistory(data)
                                                    .then((res) => {
                                                      this.props
                                                        .onGetVisaApplication()
                                                        .then((res) => {
                                                          if (
                                                            res.payload &&
                                                            res.payload.items
                                                          ) {
                                                            this.setState({
                                                              visaResState:
                                                                res.payload
                                                                  .items,
                                                              loadVisaInfo: false,
                                                            });
                                                          } else {
                                                            this.setState({
                                                              visaResState: [],
                                                              loadVisaInfo: false,
                                                            });
                                                          }
                                                        })
                                                        .catch(() => {
                                                          this.setState({
                                                            visaResState: [],
                                                            loadVisaInfo: false,
                                                          });
                                                        });
                                                    })
                                                    .catch((err) => {
                                                      this.setState({
                                                        loadVisaInfo: false,
                                                      });
                                                    });
                                                }}
                                              />
                                              <span className="cv-normal-text"></span>
                                              <div
                                                className="cv-cross-cont"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  this.setState({
                                                    caseHistoryDelModal: true,
                                                    visaStatusId:
                                                      caseHistory.id,
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={Images.crossWhite}
                                                  className="cv-svg-8"
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          {showPPIDetail &&
                                            caseIndex === caseInd && (
                                              <div
                                                className="cv-show-hide-cont"
                                                style={{ border: 0 }}
                                              >
                                                <div></div>
                                                <div
                                                  className="sus-show-btn"
                                                  style={{
                                                    marginRight: 110,
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      showPPIDetail: false,
                                                      caseInd: null,
                                                    })
                                                  }
                                                >
                                                  <span className="sus-save-btn-text">
                                                    HIDE
                                                  </span>
                                                </div>
                                              </div>
                                            )}

                                          {showPPIDetail &&
                                            caseIndex === caseInd && (
                                              <div className="cv-detail-ppi-date">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    marginTop: 10,
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <p className="medical-label">
                                                    Description
                                                  </p>
                                                  <div
                                                    style={{
                                                      width: "48%",
                                                      paddingLeft: 1,
                                                    }}
                                                  >
                                                    <div>
                                                      <Form.Item>
                                                        <Input
                                                          value={
                                                            caseHistory.description
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    marginTop: 10,
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <p className="medical-label">
                                                    Received Date
                                                  </p>
                                                  <div
                                                    style={{
                                                      width: "48%",
                                                      paddingLeft: 1,
                                                    }}
                                                  >
                                                    <div>
                                                      <Form.Item>
                                                        <DatePicker
                                                          format={dateFormat}
                                                          value={
                                                            caseHistory.receivedDate ===
                                                            "1900-01-01T00:00:00+00:00"
                                                              ? ""
                                                              : moment(
                                                                  caseHistory.receivedDate
                                                                )
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    marginTop: 10,
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <p className="medical-label">
                                                    Due Date
                                                  </p>
                                                  <div
                                                    style={{
                                                      width: "48%",
                                                      paddingLeft: 1,
                                                    }}
                                                  >
                                                    <div>
                                                      <Form.Item>
                                                        <DatePicker
                                                          format={dateFormat}
                                                          value={
                                                            caseHistory.dueDate ===
                                                            "1900-01-01T00:00:00+00:00"
                                                              ? ""
                                                              : moment(
                                                                  caseHistory.dueDate
                                                                )
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    marginTop: 10,
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <p className="medical-label">
                                                    Extend Due Date
                                                  </p>
                                                  <div
                                                    style={{
                                                      width: "48%",
                                                      paddingLeft: 1,
                                                    }}
                                                  >
                                                    <div>
                                                      <Form.Item>
                                                        <DatePicker
                                                          format={dateFormat}
                                                          value={
                                                            caseHistory.extendDueDate ===
                                                            "1900-01-01T00:00:00+00:00"
                                                              ? ""
                                                              : moment(
                                                                  caseHistory.extendDueDate
                                                                )
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    marginTop: 10,
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <p className="medical-label">
                                                    Submitted Date
                                                  </p>
                                                  <div
                                                    style={{
                                                      width: "48%",
                                                      paddingLeft: 1,
                                                    }}
                                                  >
                                                    <div>
                                                      <Form.Item>
                                                        <DatePicker
                                                          format={dateFormat}
                                                          value={
                                                            caseHistory.submittedDate ===
                                                            "1900-01-01T00:00:00+00:00"
                                                              ? ""
                                                              : moment(
                                                                  caseHistory.submittedDate
                                                                )
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {/* ===== Design from Visa Document Screen ===== */}

                    {false && (
                      <div>
                        {false && (
                          <div className="cv-row">
                            <div style={{ margin: 10 }}>
                              <div className="cv-assist-cont">
                                <span className="cv-bold-text">
                                  Investor Plus (Investor 1 Category)
                                </span>
                                <div className="cv-green-box">
                                  <span className="cv-green-text">
                                    NEW ZELAND
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="cv-gray-cont">
                          {false && (
                            <div className="cv-row">
                              <div style={{ width: "50%" }}>
                                <div>
                                  <p style={{ marginTop: 0, marginLeft: 5 }}>
                                    Expiry Date
                                  </p>
                                  <div
                                    class="profile-input-border"
                                    style={{
                                      display: "flex",
                                      backgroundColor: "transparent",
                                      border: "none",
                                      overflow: "inherit",
                                    }}
                                  >
                                    <div
                                      class="profile-input-with-icon"
                                      style={{
                                        overflow: "inherit",
                                        display: "flex",
                                        height: 30,
                                        paddingRight: 3,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingRight: 5,
                                        width: "100%",
                                      }}
                                    >
                                      <DatePicker
                                        format={dateFormat}
                                        calendarIcon={null}
                                        clearIcon={null}
                                        onChange={(e) =>
                                          this.onChangeExpiryDate
                                        }
                                        value={this.state.date}
                                      />
                                      <img
                                        src={Images.calendar}
                                        className="profile-calendar-icon"
                                      />
                                    </div>
                                    <div class="profile-refresh-cont">
                                      <img
                                        src={Images.refreshArrow}
                                        style={{ width: 18, height: 18 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p style={{ marginLeft: 5 }}>Approved Date</p>
                                  <div
                                    class="profile-input-border"
                                    style={{
                                      display: "flex",
                                      backgroundColor: "transparent",
                                      border: "none",
                                      overflow: "inherit",
                                    }}
                                  >
                                    <div
                                      class="profile-input-with-icon"
                                      style={{
                                        overflow: "inherit",
                                        display: "flex",
                                        height: 30,
                                        paddingRight: 3,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingRight: 5,
                                        width: "100%",
                                      }}
                                    >
                                      <DatePicker
                                        format={dateFormat}
                                        calendarIcon={null}
                                        clearIcon={null}
                                        onChange={this.onChangeDate}
                                        value={this.state.date}
                                      />
                                      <img
                                        src={Images.calendar}
                                        className="profile-calendar-icon"
                                      />
                                    </div>
                                    <div class="profile-refresh-cont">
                                      <img
                                        src={Images.refreshArrow}
                                        style={{ width: 18, height: 18 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: "flex", marginTop: 20 }}>
                                <div
                                  className="cv-purpel-box"
                                  style={{
                                    height: 50,
                                    backgroundColor: "#45BE57",
                                  }}
                                >
                                  <div className="cv-imm-cont">
                                    <span className="cv-imm-text">
                                      APPROVED
                                    </span>
                                  </div>
                                  <div className="cv-icons-row">
                                    <div>
                                      <img
                                        src={Images.multimedia}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={Images.interfaceIcon}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={Images.emailWhite}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={Images.interfaceIcon}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="cv-delete-cont"
                                  style={{ marginTop: 0 }}
                                >
                                  <img
                                    src={Images.deleteGray}
                                    className="cv-dlt-icon"
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="sus-tab-container">
                            <div
                              onClick={() => this.onChangeTopTab("document")}
                              className={
                                this.state.activeTab == "document"
                                  ? "cv-active-tab"
                                  : "cv-inactive-tab"
                              }
                            >
                              <span
                                className={
                                  this.state.activeTab == "document"
                                    ? "cv-active-tab-text"
                                    : "cv-inactive-tab-text"
                                }
                              >
                                DOCUMENT
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                this.onChangeTopTab("documentChecklist")
                              }
                              className={
                                this.state.activeTab == "documentChecklist"
                                  ? "cv-active-tab"
                                  : "cv-inactive-tab"
                              }
                            >
                              <span
                                className={
                                  this.state.activeTab == "documentChecklist"
                                    ? "cv-active-tab-text"
                                    : "cv-inactive-tab-text"
                                }
                              >
                                SEND DOCUMENT CHECKLIST
                              </span>
                            </div>
                          </div>

                          <div className="cv-white-cont">
                            <div
                              className="cv-row"
                              style={{ marginBottom: 10 }}
                            >
                              <div />
                              <div style={{ display: "flex" }}>
                                <div className="cv-print-icon-cont">
                                  <img
                                    src={Images.printBlue}
                                    className="profile-print-icon"
                                  />
                                </div>
                                <div className="cv-extend-icon-cont">
                                  <img
                                    src={Images.extendIcon}
                                    className="cv-extend-icon"
                                    style={{ transform: `rotate(270deg)` }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="cv-doc-head-row">
                              <div
                                className="cv-width-55"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    className="sus-checkbox"
                                    onChange={this.handleCheck}
                                    defaultChecked={this.state.checked}
                                  />
                                </div>
                                <div className="cv-doc-inner-index">
                                  <span className="cv-doc-head-text">#</span>
                                </div>
                                <div className="cv-doc-width">
                                  <span className="cv-doc-head-text">
                                    Document
                                  </span>
                                </div>
                              </div>
                              <div className="cv-width-17">
                                <span className="cv-doc-head-text">Title</span>
                              </div>
                              <div className="cv-width-13">
                                <span className="cv-doc-head-text">Type</span>
                              </div>
                              <div className="cv-width-15">
                                <span className="cv-doc-head-text">Action</span>
                              </div>
                            </div>

                            {false && (
                              <div className="cv-doc-row">
                                <div
                                  className="cv-width-52"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="sus-checkbox"
                                      onChange={this.handleCheck}
                                      defaultChecked={this.state.checked}
                                    />
                                  </div>
                                  <div className="cv-doc-inner-index">
                                    <span
                                      className="cv-doc-text"
                                      style={{ color: "#5B5B5B" }}
                                    >
                                      1
                                    </span>
                                  </div>
                                  <div className="cv-doc-width">
                                    <div>
                                      <span
                                        className="cv-doc-text"
                                        style={{ color: "#1081B8" }}
                                      >
                                        Merge-66598939340573894-PDF.pdf
                                      </span>
                                    </div>
                                    <div className="cv-doc-date-text-cont">
                                      <span className="cv-doc-date-text">
                                        12/02/2019 | 20 KB
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="cv-width-20"
                                  style={{ padding: 1 }}
                                >
                                  <div className="cv-title-box">
                                    <span
                                      className="cv-normal-text"
                                      style={{ fontSize: 7, marginLeft: 5 }}
                                    >
                                      show data
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="cv-width-13"
                                  style={{ padding: 1 }}
                                >
                                  <div
                                    className="cv-title-box"
                                    style={{ width: "80%" }}
                                  >
                                    <span
                                      className="cv-normal-text"
                                      style={{ fontSize: 7, marginLeft: 5 }}
                                    >
                                      show data
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="cv-width-15"
                                  style={{ display: "block" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div className="cv-action-icons-border">
                                      <img
                                        src={Images.download}
                                        className="cv-action-icon"
                                      />
                                    </div>
                                    <div
                                      className="cv-action-icons-border"
                                      style={{ marginLeft: 5 }}
                                    >
                                      <img
                                        src={Images.visibility}
                                        className="cv-action-icon"
                                      />
                                    </div>
                                    {false && (
                                      <div
                                        className="cv-action-icons-border"
                                        style={{ marginLeft: 5 }}
                                      >
                                        <img
                                          src={Images.download}
                                          className="cv-action-icon"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="cv-show-hide-cont">
                            <div></div>
                            <div
                              className="sus-show-btn"
                              style={{ marginRight: 30 }}
                            >
                              <span className="sus-save-btn-text">HIDE</span>
                            </div>
                          </div>
                        </div>

                        <div className="cv-row">
                          <div style={{ margin: 10 }}>
                            <div className="cv-assist-cont">
                              <span className="cv-bold-text">
                                Family and Spousal Visas
                              </span>
                              <div className="cv-green-box">
                                <span className="cv-green-text">AUSTRALIA</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="cv-gray-cont">
                          <div className="cv-row">
                            <div>
                              <div>
                                <span className="cv-normal-text">
                                  Child visa (subclass 02)
                                </span>
                              </div>
                              <div className="cv-assist-cont">
                                <span className="cv-normal-text">
                                  Queensland
                                </span>
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div
                                className="cv-purpel-box"
                                style={{
                                  backgroundColor: "#F8940A",
                                  marginTop: 10,
                                }}
                              >
                                <div className="cv-imm-cont">
                                  <span className="cv-imm-text">
                                    IMMIGRATION MATTERS
                                  </span>
                                </div>
                                <div className="cv-icons-row">
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.emailWhite}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="cv-delete-cont">
                                <img
                                  src={Images.deleteGray}
                                  className="cv-dlt-icon"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="cv-show-hide-cont"
                            style={{ marginTop: 10 }}
                          >
                            <div></div>
                            <div
                              className="sus-show-btn"
                              style={{ marginRight: 30 }}
                            >
                              <span className="sus-save-btn-text">HIDE</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* ===== Design from Visa file notes Screen ===== */}
                  </div>
                </div>
                {/* {!expendedView && !showVisaInfo && ( */}
                {/* <div className="" style={{ width: "45%" }}> */}
                {!showVisaInfo && (
                  <div
                    style={{
                      marginLeft: "20px",
                      width: activeKey ? "438px" : "115px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <ProfileSideBar
                        onGetClientTag={this.props.onGetClientTag}
                        clientTagRes={this.props.clientTagRes}
                        getClientRes={this.props.getClientRes}
                        onGetProfileClientTag={this.props.onGetProfileClientTag}
                        onAddProfileClientTag={this.props.onAddProfileClientTag}
                        addProfielTagRes={this.props.addProfielTagRes}
                        onRemoveProfileClientTag={
                          this.props.onRemoveProfileClientTag
                        }
                        removeProfileTagRes={this.props.removeProfileTagRes}
                        activeTabNewVisa={activeTabNewVisa}
                        visaTypeData={countryVisaTypeData}
                        onStartNewApplication={onStartNewApplication}
                        visaStatusData={visaStatusData}
                        onUpdateCaseStatus={onUpdateCaseStatus}
                        visaAppData={visaAppData}
                        onGetVisaApplication={onGetVisaApplication}
                        newApplicationWith={newApplicationWith}
                        visaCountryId={countryId}
                        onGetVisaCategory={onGetVisaCategory}
                        visaCategoryData={visaCategoryData}
                        activeKey={activeKey}
                        onSetActiveKey={onSetActiveKey}
                        onUpdatePriority={onUpdatePriority}
                        visaPriorityRes={visaPriorityRes}
                        onSignedVisa={onSignedVisa}
                        onPaidVisa={onPaidVisa}
                        remindersRes={remindersRes && remindersRes.items}
                        onGetClientTask={this.props.onGetClientTask}
                        onUpdateTask={this.props.onUpdateTask}
                        onGetAllUsers={this.props.onGetAllUsers}
                        onAddTaskFollower={this.props.onAddTaskFollower}
                        onRemoveTasks={this.props.onRemoveTasks}
                        onAddDailyTasks={this.props.onAddDailyTasks}
                        onGetClientFamily={this.props.onGetClientFamily}
                        onUpdateCompletedTask={this.props.onUpdateCompletedTask}
                        onAddTaskFileNote={this.props.onAddTaskFileNote}
                        onAddTaskComment={this.props.onAddTaskComment}
                        onGetTaskComments={this.props.onGetTaskComments}
                        onGetTaskFollowers={this.props.onGetTaskFollowers}
                        onGetVisaStatus={onGetVisaStatus}
                        onGetReminder={onGetReminder}
                        onGetAdmissionProgram={onGetAdmissionProgram}
                        onGetAdmissionStatuses={onGetAdmissionStatuses}
                        onGetVisaType={onGetVisaType}
                        onGetVisaTypeByCountry={onGetVisaTypeByCountry}
                      />
                    </div>
                  </div>
                )}
                {/* </div> */}
                {/* )} */}
                {/* {!showVisaInfo && expendedView && (
                <div className="right-nav-div">
                  <div
                    className="right-nav-contant"
                    style={{ cursor: "pointer" }}
                    onClick={this.showRightMenu}
                  >
                    <div>
                      <img src={rightNav} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Update Visa Status</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavTwo} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Update Admission</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavThr} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Send SMS</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavFour} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Tasks</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavFiv} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Meetings</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavSix} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Reminders</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavSev} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Client Tags</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavEight} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Balance</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavNine} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Time Tracking</span>
                  </div>
                </div>
              )} */}
                {showVisaInfo && (
                  <div className="cv-right-cont">
                    <div className="cv-top-heading">
                      <div>
                        <span className="cv-bold-text">Visa Info</span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({
                            showVisaInfo: false,
                            expendedView: true,
                          })
                        }
                      >
                        <span className="cv-normal-text">Show Menu</span>
                      </div>
                    </div>
                    <div
                      className="cv-gray-cont"
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        paddingTop: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 10,
                      }}
                    >
                      <Form onFinish={this.onFinish} ref={this.formRef}>
                        <div>
                          <p>Visa Type</p>
                          <div class="profile-input-border">
                            <Form.Item name="studentVisa">
                              <Input
                                className="profile-input"
                                style={{ width: "100%" }}
                                placeholder="Student Visa - General"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Visa Description</p>
                          <div class="profile-input-border">
                            <Form.Item name="visaDescription">
                              <input
                                className="profile-input"
                                style={{ width: "100%" }}
                                placeholder=""
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Submission Type</p>
                          <div>
                            <Form.Item name="submissionType">
                              <Select>
                                <Option key="online">ONLINE</Option>
                                <Option key="paper">PAPER</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>LIA Name (If not a user)</p>
                          <div class="profile-input-border">
                            <Form.Item name="liaName">
                              <input
                                className="profile-input"
                                style={{ width: "100%" }}
                                placeholder=""
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>LIA</p>
                          <div>
                            <Form.Item name="lia">
                              <Select>{users}</Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Visa Application Number</p>
                          <div class="profile-input-border">
                            <Form.Item name="visaAppNumber">
                              <input
                                className="profile-input"
                                style={{ width: "100%" }}
                                placeholder=""
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Case Officer Email</p>
                          <div class="profile-input-border">
                            <Form.Item name="visaOfficerEmail">
                              <input
                                className="profile-input"
                                style={{ width: "100%" }}
                                placeholder=""
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Branch</p>
                          <div class="profile-input-border">
                            <Form.Item name="branch">
                              <input
                                className="profile-input"
                                style={{ width: "100%" }}
                                placeholder=""
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Courier Name</p>
                          <div class="profile-input-border">
                            <Form.Item name="courierName">
                              <input
                                className="profile-input"
                                style={{ width: "100%" }}
                                placeholder=""
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Tracking Number</p>
                          <div class="profile-input-border">
                            <Form.Item name="trackingNumber">
                              <Input
                                className="profile-input"
                                style={{ width: "100%" }}
                                placeholder=""
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>EOI Number</p>
                          <div class="profile-input-border">
                            <Form.Item name="eoiNumber">
                              <input
                                className="profile-input"
                                style={{ width: "100%" }}
                                placeholder=""
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Documents Returned Date</p>
                          <div>
                            <Form.Item name="returnedDate">
                              <DatePicker format={dateFormat} />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Description</p>
                          <Form.Item name="documentDescription">
                            <TextArea rows={5} class="cv-textarea" />
                          </Form.Item>
                        </div>
                        <div>
                          <p>Worth</p>
                          <div class="profile-input-border">
                            <Form.Item name="worth">
                              <Input
                                className="profile-input"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Sale Date</p>
                          <div>
                            <Form.Item name="saleDate">
                              <DatePicker format={dateFormat} />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Sales Person</p>
                          <div>
                            <Form.Item name="salesPerson">
                              <Select>{users}</Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <p>Follow-up Date</p>
                          <div>
                            <Form.Item name="followupDate">
                              <DatePicker format={dateFormat} />
                            </Form.Item>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 20,
                          }}
                        >
                          <div></div>
                          <Form.Item>
                            <Button className="button-blue" htmlType="submit">
                              <span style={{ color: "#FFFFFF" }}>Save</span>
                            </Button>
                          </Form.Item>
                          {false && (
                            <div
                              className="sus-save-btn"
                              style={{ paddingTop: 5, paddingBottom: 5 }}
                            >
                              <span className="sus-save-btn-text">SAVE</span>
                            </div>
                          )}
                        </div>
                      </Form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Spin>
        <Modal
          visible={showDetailPopup}
          title={"Email Detail"}
          onCancel={() => {
            this.setState({ showDetailPopup: false });
          }}
          // onOk={onOkLinkButton}
          width="900px"
          footer={false}
        >
          <>
            {emailContent && !emailContent.draft && (
              <div className="client-email-popup-body">
                {emailContent && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="client-email-label">
                          <span>
                            {"Created Date: " +
                              moment(emailContent.createdDate).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}
                          </span>
                        </div>
                        <div
                          className="client-email-label"
                          style={{ marginLeft: 10 }}
                        >
                          <span>{"Created By: " + emailContent.userName}</span>
                        </div>
                      </div>
                      <div>
                        {emailContent.importText.toLowerCase() == "inbox" ? (
                          <span
                            className="sent-email"
                            style={{ backgroundColor: "#2EC7EA" }}
                          >
                            Inbox Imported
                          </span>
                        ) : emailContent.autoImport === true ? (
                          <span
                            className="draft"
                            style={{ backgroundColor: "#FCBB3A" }}
                          >
                            Sent Imported
                          </span>
                        ) : emailContent.isSent === true ? (
                          <span className="sent-email">Sent Email</span>
                        ) : emailContent.draft === true ? (
                          <span className="draft">Draft</span>
                        ) : (
                          emailContent.import && (
                            <span
                              className="draft"
                              style={{ backgroundColor: "#FCBB3A" }}
                            >
                              Imported
                            </span>
                          )
                        )}
                      </div>
                    </div>
                    <div>
                      <span className="client-email-address">
                        {"From: " + emailContent.from}
                      </span>
                    </div>
                    <div className="client-email-to-row">
                      <div>
                        <span className="client-email-address">
                          {"To: " + emailContent.to}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="client-email-address">
                        {"Subject: " + emailContent.subject}
                      </span>
                    </div>
                    <div>
                      <span className="client-email-address">
                        {"CC: " + emailContent.cc}
                      </span>
                    </div>
                    <div>
                      <span className="client-email-address">
                        {"Bcc: " + emailContent.bcc}
                      </span>
                    </div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: emailContent.emailMessage,
                        }}
                      />
                    </div>
                    {emailContent.emailAttachment.length > 0 && (
                      <div
                        className="body-attachment-row"
                        style={{ display: "inline-block" }}
                      >
                        {emailContent.emailAttachment.map(
                          (attachment, index) => {
                            return (
                              <div
                                style={{
                                  margin: 10,
                                  display: "inline-block",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <PaperClipOutlined />

                                  <span
                                    className="body-attachment-text"
                                    style={{
                                      fontSize: 12,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.onDownloadAttachment(attachment)
                                    }
                                  >
                                    {attachment.name}
                                  </span>
                                  {/* <img
                                    src={Images.forward}
                                    style={{
                                      marginLeft: 10,
                                      width: 25,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => moveToDocs(attachment)}
                                  /> */}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            {/* {(showForm || isDraft) && ( */}
            {/* {false && (
              <CreateEmailForm
                resultList={resultList}
                emailContentData={emailContentData}
                onGetLetterTemplates={onGetLetterTemplates}
                LetterTemplatesRes={LetterTemplatesRes}
                onSendEmailLink={onSendEmailLink}
                onAddEmployerEmail={onAddEmployerEmail}
                onAddDraftClient={onAddDraftClient}
                onGetClientProfile={onGetClientProfile}
                clientProfileData={clientProfileData}
                setLoading={setLoading}
                loading={loading}
                onGetClientHistory={onGetClientHistory}
                onUploadAvatar={onUploadAvatar}
                onAddEmailDocument={onAddEmailDocument}
                emailDocumentRes={emailDocumentRes}
                setHandleKey={setHandleKey}
                onGetClientFamilyEmails={onGetClientFamilyEmails}
                clientEmailRes={clientEmailRes}
                onGetEmployerDocument={onGetEmployerDocument}
                employerDocumentRes={employerDocumentRes}
                onGetImapSettingss={onGetImapSettingss}
                imapSettingRes={imapSettingRes}
                onGetSignature={onGetSignature}
                signatureRes={signatureRes}
                onGetPdf={onGetPdf}
                onGetLink={onGetLink}
                onGetDocuments={onGetDocuments}
                documentRes={documentRes}
                inHistoryPopup={true}
                emailContent={emailContent}
                forward={isForward}
                reply={isReply}
                replyAll={isReplyAll}
                isDraft={isDraft}
                handleCancelPopup={handleCancelDetailPopup}
                onDeleteClientEmail={onDeleteClientEmail}
              />
            )} */}
            <div>
              <div
                style={{
                  display: "inline-block",
                  marginLeft: `calc(100% - 70px)`,
                  marginTop: 20,
                }}
              >
                {/* {!showForm && !isDraft && ( */}
                {/* {false && (
                  <div style={{ display: "inline-block" }}>
                    <div style={{ display: "inline-block", margin: 5 }}>
                      <Button
                        type="primary"
                        className="login-form-button save-btn button-blue"
                        onClick={() => {
                          setIsForward(true);
                          setShowForm(true);
                        }}
                      >
                        Forward
                      </Button>
                    </div>
                    <div style={{ display: "inline-block", margin: 5 }}>
                      <Button
                        // onClick={() => DraftData()}
                        type="primary"
                        className="login-form-button save-btn button-blue"
                        onClick={() => {
                          setIsReply(true);
                          setShowForm(true);
                        }}
                      >
                        Reply
                      </Button>
                    </div>
                    <div style={{ display: "inline-block", margin: 5 }}>
                      <Button
                        // onClick={() => DraftData()}
                        type="primary"
                        className="login-form-button save-btn button-blue"
                        onClick={() => {
                          setIsReplyAll(true);
                          setShowForm(true);
                        }}
                      >
                        Reply All
                      </Button>
                    </div>
                  </div>
                )} */}
                {/* </Col> */}
                <div style={{ display: "inline-block", margin: 5 }}>
                  <Button
                    // onClick={() => DraftData()}
                    type="primary"
                    className="login-form-button save-btn button-blue"
                    onClick={() => {
                      // setIsReplyAll(false);
                      // setIsReply(false);
                      // setIsForward(false);
                      // setShowForm(false);
                      // setDraft(false);
                      // setShowDetailPopup(false);
                      this.setState({ showDetailPopup: false });
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
              {/* <Row
                    className="margin-top-12"
                    gutter={10}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Col>
                      {!showForm && !isDraft && (
                        <Row gutter={10}>
                          <Col>
                            <Button
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={() => {
                                setIsForward(true);
                                setShowForm(true);
                              }}
                            >
                              Forward
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              // onClick={() => DraftData()}
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={() => {
                                setIsReply(true);
                                setShowForm(true);
                              }}
                            >
                              Reply
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              // onClick={() => DraftData()}
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={() => {
                                setIsReplyAll(true);
                                setShowForm(true);
                              }}
                            >
                              Reply All
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col>
                      <Button
                        // onClick={() => DraftData()}
                        type="primary"
                        className="login-form-button save-btn button-blue"
                        onClick={() => {
                          setIsReplyAll(false);
                          setIsReply(false);
                          setIsForward(false);
                          setShowForm(false);
                          setDraft(false);
                          setShowDetailPopup(false);
                        }}
                      >
                        Close
                      </Button>
                    </Col>
                  </Row> */}
            </div>
          </>
        </Modal>
      </div>
    );
  }
}

export default VisaFlow;
