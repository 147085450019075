import React from "react";
import "./ClientsStyles.css";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  Select,
  Input,
  Form,
  Button,
  message,
  DatePicker,
  InputNumber,
} from "antd";

import HeaderBarTabs from "../Components/Header/HeaderTabs";
import ProfileTopBarTabs from "../Components/Shared/ProfileTopBar";

import UpdateJobHistoryForm from "../Components/Client/JobHistoryForm";

import { Images } from "../Themes";

import ProfileSideBar from "../Components/ProfileSideBar";

import headOption from "../Components/Header/HeaderTabOptions";
import activityData from "../Components/ClientActivity/ActivityData";

const topBar = [
  { tabName: "CLIENT INFORMATION", linkName: "/profile" },
  { tabName: "EMPLOYER INFORMATION", linkName: "/employer" },
  { tabName: "JOB HISTORY", linkName: "/job-history" },
  { tabName: "QUALIFICATION", linkName: "/qualification" },
  { tabName: "OTHER INFO", linkName: "/client-other-info" },
];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const dateFormat = "DD/MM/YYYY";
const { Option } = Select;

let clientprofileid = sessionStorage.getItem("clientprofileid");
var jobStatuses = [];

class JobHistory extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      loading: false,
      showAddForm: false,
      headerOptions: [],
    };
    this.props.onSetActiveKey(null);
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.onGetClientJobHistory(clientprofileid);
    this.props.onGetClientEmployer();
    this.props.onGetClientJobStatuses().then((res) => {
      if (res.payload.count > 0) {
        for (var i = 0; i < res.payload.statuses.length; i++) {
          jobStatuses.push(
            <Option key={res.payload.statuses[i].id}>
              {res.payload.statuses[i].name}
            </Option>
          );
        }
      }
    });
  }

  componentDidMount() {
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          // { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }
    // var data = JSON.parse(sessionStorage.getItem("profileData"));
    // this.setState({ profileData: data });

    let familyId = sessionStorage.getItem("familyId");
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      this.props.onGetClientProfile(clientProfileIdMain);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data);
    }
  }

  componentDidUpdate(prevProps) {
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (
      prevProps.clientProfileData != this.props.clientProfileData &&
      memberType == "client"
    ) {
      this.setProfileDataRdx(this.props.clientProfileData);
    } else if (
      prevProps.partnerProfileData != this.props.partnerProfileData &&
      memberType == "spouse"
    ) {
      this.setProfileDataRdx(this.props.partnerProfileData);
    } else if (
      prevProps.clientFamilyData != this.props.clientFamilyData &&
      memberType == "child"
    ) {
      let findClient =
        this.props.clientFamilyData &&
        this.props.clientFamilyData.items.find(
          (obj) => obj.id == clientprofileid
        );
      if (findClient) {
        this.setProfileDataRdx(findClient);
      }
    }
    if (
      (prevProps.profileDataRdx && !this.state.profileData) ||
      prevProps.profileDataRdx != this.props.profileDataRdx
    ) {
      this.setState({ profileData: JSON.parse(this.props.profileDataRdx) });
    }
  }

  setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  onFinish = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (clientprofileid) {
      this.setState({ loading: true });
      let data = {
        clientId: clientprofileid,
        employerId: "00000000-0000-0000-0000-000000000000",
        jobId: 0,
        jobTitle: values.jobTitle || "",
        startDate: values.startDate || "1900-01-01T00:00:00+00:00",
        endDate: values.endDate || "1900-01-01T00:00:00+00:00",
        employerName: values.employerName || "",
        employerAddress: values.employerAddress || "",
        payDetails: values.payDetails || "",
        phone: values.phone ? values.phone.toString() : "",
        managerName: values.managerName || "",
        managerEmail: values.managerEmail || "",
        managerMobile: values.managerMobile
          ? values.managerMobile.toString()
          : "",
        positionOffered: values.positionOffered || "",
        jobOffer: values.jobOffer || "",
        companyRole: values.companyRole || "",
        mentionHoursInWeek: values.mentionHoursInWeek || "",
        anzscoCode: values.anzscoCode || "",
        skillLevel: values.skillLevel || "",
        businessNumber: values.businessNumber || "",
        isCurrent: false,
        tradingName: "",
        workStay: values.workStay || "",
        businessType: "",
        employementType: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        city: "",
        state: "",
        zip: "",
        country: 0,
        website: "",
        annualsalary: "",
        ratePerHour: "",
        activityBasedRemuneration: "",
        workExperince: "",
        domicile: "",
        accountDuties: "",
        contactPersonJobTitle: "",
        arrivalDate: "1900-01-01T00:00:00+00",
        jobStatusId: 0,
      };
      this.props
        .onAddEmployerJobHistory(data)
        .then((res) => {
          this.setState({ loading: false });
          message.success("Job history added successfully!");
          this.formRef.current.resetFields();
          this.props.onGetClientJobHistory();
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage: "Job " + data.jobTitle + " added by " + userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Information",
              invoiceId: "0",
            };
            activityData(myData);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Failed to add job history!");
        });
    } else {
      message.error("Please search and select client first!");
    }
  };

  render() {
    const {
      selectedOption,
      showAddForm,
      headerOptions,
      profileData,
    } = this.state;
    const {
      employerJobHistoryRes,
      onGetClientJobHistory,
      onUpdEmployerJobHistory,
      onRemoveEmployerJobHistory,
      clientProfileData,
      visaStatusData,
      onUpdateCaseStatus,
      visaAppData,
      onGetVisaApplication,
      onGetClientJobHistoryCurrent,
      employerJobHistoryCurrentRes,
      clientEmployerRes,
      onAddEmployerJob,
      onSetActiveKey,
      activeKey,
    } = this.props;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            {headerOptions.length > 0 && (
              <HeaderBarTabs data={headerOptions} activeTab="Client Profile" />
            )}
            <ProfileTopBarTabs data={topBar} activeTab="JOB HISTORY" />
            <div
              className="emp-container"
              style={{ paddingBottom: 40, margin: 10 }}
            >
              <div
                className={
                  activeKey
                    ? "content-width-open-sidebar"
                    : "content-width-close-sidebar"
                }
              >
                {profileData ? (
                  <div style={{ width: "100%" }}>
                    <p>Client Name: {profileData && profileData.fullName}</p>
                  </div>
                ) : (
                  <div style={{ height: 30 }} />
                )}

                <div>
                  <div class="ce-add-button-cont" style={{ marginLeft: 0 }}>
                    <Button
                      className="emp-blue-btn"
                      style={{
                        marginRight: 10,
                        color: "#FFFFFF",
                        width: "100%",
                      }}
                      onClick={() => this.setState({ showAddForm: true })}
                    >
                      ADD
                    </Button>
                  </div>
                  {employerJobHistoryRes &&
                    employerJobHistoryRes.items.map((jobHistory, index) => {
                      return (
                        <UpdateJobHistoryForm
                          jobHistory={jobHistory}
                          index={index}
                          onUpdEmployerJobHistory={onUpdEmployerJobHistory}
                          onGetClientJobHistory={onGetClientJobHistory}
                          onRemoveEmployerJobHistory={
                            onRemoveEmployerJobHistory
                          }
                          jobStatuses={jobStatuses}
                          clientEmployerRes={
                            clientEmployerRes && clientEmployerRes
                          }
                          onAddEmployerJob={onAddEmployerJob}
                        />
                      );
                    })}
                  {showAddForm && (
                    <div
                      class="emp-middle-cont"
                      style={{ marginTop: 20, marginLeft: 0 }}
                    >
                      <div class="form-cont">
                        <div style={{ width: "100%" }}>
                          <p class="emp-ace-bold-text">ADD JOB</p>
                        </div>
                      </div>
                      <Form onFinish={this.onFinish} ref={this.formRef}>
                        <div className="emp-ace-cont">
                          <div
                            class="form-cont"
                            style={{
                              justifyContent: "space-between",
                              paddingLeft: 0,
                              paddingRight: 0,
                              display: "block",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Job Title*
                              </p>
                              <div class="emp-input-border">
                                <Form.Item
                                  name="jobTitle"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Employer Name
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="employerName">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Employer Address
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="employerAddress">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Business Number
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="businessNumber">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Start Date
                              </p>
                              <div
                                style={{
                                  width: "65%",
                                  display: "flex",
                                  border: "none",
                                  overflow: "inherit",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: 0,
                                    overflow: "inherit",
                                    display: "flex",
                                    height: 30,
                                    backgroundColor: "#FFFFFF",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Form.Item
                                    name="startDate"
                                    style={{ width: "100%" }}
                                  >
                                    <DatePicker
                                      calendarIcon={null}
                                      clearIcon={null}
                                      onChange={this.onChangeDate}
                                      value={this.state.date}
                                      format={dateFormat}
                                    />
                                  </Form.Item>
                                </div>
                                {/* <div className="profile-refresh-cont">
                              <img
                                src={Images.calendar}
                                className="profile-calendar-icon"
                                style={{ marginLeft: 0 }}
                              />
                            </div> */}
                                {/* <div class="profile-refresh-cont">
                              <img
                                src={Images.refreshArrow}
                                style={{ width: 18, height: 18 }}
                              />
                            </div> */}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                End Date
                              </p>
                              <div
                                style={{
                                  width: "65%",
                                  display: "flex",
                                  border: "none",
                                  overflow: "inherit",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: 0,
                                    overflow: "inherit",
                                    display: "flex",
                                    height: 30,
                                    backgroundColor: "#FFFFFF",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Form.Item
                                    name="endDate"
                                    style={{ width: "100%" }}
                                  >
                                    <DatePicker
                                      calendarIcon={null}
                                      clearIcon={null}
                                      onChange={this.onChangeDate}
                                      value={this.state.date}
                                      format={dateFormat}
                                    />
                                  </Form.Item>
                                </div>
                                {/* <div className="profile-refresh-cont">
                              <img
                                src={Images.calendar}
                                className="profile-calendar-icon"
                                style={{ marginLeft: 0 }}
                              />
                            </div> */}
                                {/* <div class="profile-refresh-cont">
                              <img
                                src={Images.refreshArrow}
                                style={{ width: 18, height: 18 }}
                              />
                            </div> */}
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Pay Details
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="payDetails">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Phone
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="phone">
                                  <InputNumber
                                    className="profile-input input-number-field"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Manager's Name
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="managerName">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Manager's Email
                              </p>
                              <div className="emp-field-div">
                                <Form.Item
                                  name="managerEmail"
                                  rules={[
                                    {
                                      type: "email",
                                      message: "Required!",
                                    },
                                  ]}
                                >
                                  <Input
                                    style={{ width: "100%" }}
                                    className="profile-input emp-input-border"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Manager's Mobile
                              </p>
                              <div class="emp-field-div">
                                <Form.Item name="managerMobile">
                                  <Input
                                    style={{ width: "100%" }}
                                    className="profile-input emp-input-border"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Position Offered
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="positionOffered">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Job Offer
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="jobOffer">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            {/* <div className="ce-field-row">
                            <p class="medical-label" style={{ fontSize: 11 }}>
                              Job Status
                            </p>
                            <div style={{ paddingLeft: 1, width: "65%" }}>
                              <Form.Item name="jobStatusId">
                                <Select>{jobStatuses}</Select>
                              </Form.Item>
                            </div>
                          </div> */}

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Role In Company
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="companyRole">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Working Hours In Week
                              </p>
                              <div style={{ display: "flex", width: "65%" }}>
                                <div style={{ width: "100%" }}>
                                  <Form.Item name="mentionHoursInWeek">
                                    <Input
                                      style={{ width: "100%" }}
                                      className="profile-input emp-input-border"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                ANZSCO Code
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="anzscoCode">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Skill Level
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="skillLevel">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <p class="medical-label" style={{ fontSize: 11 }}>
                                Work Stay
                              </p>
                              <div class="emp-input-border">
                                <Form.Item name="workStay">
                                  <Input
                                    className="profile-input"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div />
                              <div
                                className="button-blue-cont"
                                style={{
                                  marginTop: 34,
                                  paddingRight: 0,
                                  width: 70,
                                }}
                              >
                                {/* <div className="emp-blue-btn">
                              <span style={{ color: "#FFFFFF" }}>ADD</span>
                            </div> */}
                                <Button
                                  loading={this.state.loading}
                                  htmlType="submit"
                                  name="add"
                                  className="emp-blue-btn"
                                  style={{ marginRight: 10, color: "#FFFFFF" }}
                                >
                                  ADD
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
              <div
                className=""
                style={{
                  width: activeKey ? "438px" : "115px",

                  marginLeft: 15,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <ProfileSideBar
                      onGetClientTag={this.props.onGetClientTag}
                      clientTagRes={this.props.clientTagRes}
                      getClientRes={this.props.getClientRes}
                      onGetProfileClientTag={this.props.onGetProfileClientTag}
                      onAddProfileClientTag={this.props.onAddProfileClientTag}
                      addProfielTagRes={this.props.addProfielTagRes}
                      onRemoveProfileClientTag={
                        this.props.onRemoveProfileClientTag
                      }
                      removeProfileTagRes={this.props.removeProfileTagRes}
                      visaStatusData={visaStatusData}
                      onUpdateCaseStatus={onUpdateCaseStatus}
                      visaAppData={visaAppData}
                      onGetVisaApplication={onGetVisaApplication}
                      onGetClientJobHistoryCurrent={
                        onGetClientJobHistoryCurrent
                      }
                      employerJobHistoryCurrentRes={
                        employerJobHistoryCurrentRes
                      }
                      onSetActiveKey={onSetActiveKey}
                      activeKey={activeKey}
                      onGetClientTask={this.props.onGetClientTask}
                      onUpdateTask={this.props.onUpdateTask}
                      onGetAllUsers={this.props.onGetAllUsers}
                      onAddTaskFollower={this.props.onAddTaskFollower}
                      onRemoveTasks={this.props.onRemoveTasks}
                      onAddDailyTasks={this.props.onAddDailyTasks}
                      onGetClientFamily={this.props.onGetClientFamily}
                      onUpdateCompletedTask={this.props.onUpdateCompletedTask}
                      onAddTaskFileNote={this.props.onAddTaskFileNote}
                      onAddTaskComment={this.props.onAddTaskComment}
                      onGetTaskComments={this.props.onGetTaskComments}
                      onGetTaskFollowers={this.props.onGetTaskFollowers}
                    />
                  </div>
                </div>
              </div>
              {false && (
                <div style={{ paddingRight: 45 }}>
                  <div
                    class="sus-form-container"
                    style={{
                      width: "50%",
                      minHeight: 130,
                      marginLeft: 100,
                      paddingLeft: 55,
                      paddingRight: 55,
                      border: 1,
                      borderStyle: "solid",
                      borderColor: "#D9D9D9",
                    }}
                  >
                    <div
                      className="sus-add-cont-row"
                      style={{ marginTop: 10, marginRight: -40 }}
                    >
                      <div></div>
                      <div onClick={this.openModal}>
                        <img src={Images.plusIcon} style={{ width: 20 }} />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: -40,
                        marginRight: -40,
                      }}
                    >
                      <div className="sus-table"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JobHistory;
