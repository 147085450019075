import React, { useState, Fragment, useEffect } from "react";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import { Avatar, Button, Upload, message, Form, Modal, Tooltip } from "antd";
import {
  CloseOutlined,
  LeftCircleFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";
import FroalaEditorCom from "../../../Containers/FroalaEditorCom";
import { DeleteOutline } from "@material-ui/icons";
import { apiRefresh } from "../../../services/api";
// import "froala-editor/js/froala_editor.pkgd.min.js";
// import "react-froala-wysiwyg/FroalaEditorImg;";

const SignatureSettings = ({
  onGetSignature,
  signatureRes,
  onUpdateSignature,
  onSetActiveInnerTab,
  onUploadAvatar,
  onUploadAvatarWithFile,
}) => {
  var userId = localStorage.getItem("userId");

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.addEventListener("load", () => callback(reader.result));
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrlShow, setImageUrlShow] = useState("");
  const [signatureData, setSignatureData] = useState(null);
  const [letterString, setLetterString] = useState(
    signatureRes && signatureRes.length > 0 && signatureRes.signatureText
  );

  const uploadImage = (info, id) => {
    //  this.setState({ loadUploadImage: true });
    if (id) {
      //  this.setState({ imageUpdateId: id });
    }

    let formData = new FormData();
    formData.append("File", info);
    onUploadAvatar(formData).then((res) => {
      setImageUrl(res.payload);
    });
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);

      setImageUrl("");
      return;
    }

    if (info.file.status == "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (img) => {
          setImageUrlShow(img);
        },
        setLoading(false)
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    var userId = localStorage.getItem("userId");
    onGetSignature()
      .then((res) => {
        setImageUrl(res.payload.signature);
        setLetterString(res.payload.signatureText);
        setSignatureData(res && res.payload);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [onGetSignature]);

  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);

    const data = {
      signatureText: letterString ?? "",
      signature: imageUrl,
    };

    onUpdateSignature(data).then(() => {
      onGetSignature()
        .then(() => {
          setLoading(false);
          message.success("Successfully Updated!");
        })
        .catch((err) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    });
  };

  const handleModelChange = (model) => {
    setLetterString(model);
    setSignatureData(model);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  console.log("signatureRessignatureRessignatureRes=========", signatureRes);
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item client-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.signature} className="sus-bottom-icon" />
                </div>
                <span to="/client-tags" className="top-text">
                  Signature
                </span>
                <Tooltip
                  placement="topLeft"
                  title={`Set your email and digital hand signature here. When you send email correspondence to a client we will automatically add your signature for you.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>

              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <Form
              name="signature"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              form={form}
            >
              <div className="email-content-box">
                <div className="Upload-signature">
                  <p>Hand Signature</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Item>
                      <div>
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          action={uploadImage}
                          beforeUpload={beforeUpload}
                          onChange={handleChange}
                        >
                          {imageUrl ? (
                            <>
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                              />
                            </>
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </div>
                    </Form.Item>
                    {imageUrl && (
                      <DeleteOutline
                        style={{ cursor: "pointer", color: "#1281b9" }}
                        onClick={() => {
                          let data = {
                            signatureText: letterString,
                            signature: "",
                          };
                          const remSignatureOpt = {
                            url: `v1/users/UserSignature`,
                          };
                          remSignatureOpt.types = [
                            "REM_SIGNATURE_SUCCESS",
                            "REM_SIGNATURE_FAILURE",
                          ];
                          if (signatureData) {
                            setLoading(true);
                            apiRefresh
                              .put(remSignatureOpt, data)
                              .then(() => {
                                setImageUrl("");
                                setSignatureData(null);
                                setLoading(false);
                              })
                              .catch(() => {
                                setLoading(false);
                              });
                          } else {
                            setImageUrl("");
                            setImageUrlShow("");
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="letter-froala margin-top-20">
                  <p>Signature</p>
                  <Form.Item>
                    <FroalaEditorCom
                      setLoading={(value) => setLoading(value)}
                      model={letterString}
                      onModelChange={handleModelChange}
                    />
                  </Form.Item>
                </div>
                <div className="document-checklist--btn">
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="button-blue"
                    >
                      UPDATE
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default SignatureSettings;
