import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Modal } from "antd";
import queryString from "query-string";
import ClientEmailHistory from "./ClientEmailHistory/ClientEmailHistory";
import { Images } from "./../../Themes";
import CreateEmail from "./CreateEmail/CreateEmail";
import HeaderBarTabs from "./../../Components/Header/HeaderTabs";
import headOption from "../../Components/Header/HeaderTabOptions";

import ProfileSideBar from "../../Components/ProfileSideBar";

const { TabPane } = Tabs;

let clientprofileid = sessionStorage.getItem("clientprofileid");

// const headOption = [
//   {
//     tabName: "Client Profile",
//     imageName: Images.clientProfile,
//     linkName: "/profile"
//   },
//   { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
//   { tabName: "Admission", imageName: Images.admission, linkName: "/admission" },
//   { tabName: "Documents", imageName: Images.documents, linkName: "/documents" },
//   { tabName: "Email", imageName: Images.email, linkName: "/client-email" },
//   {
//     tabName: "Activities",
//     imageName: Images.activities,
//     linkName: "/activities"
//   },
//   {
//     tabName: "File Notes",
//     imageName: Images.documents,
//     linkName: "/file-notes"
//   },
//   {
//     tabName: "Accounts",
//     imageName: Images.accounts,
//     linkName: "/client-account"
//   },
//   { tabName: "Questionnaire", imageName: Images.questionnare, linkName: "" },
//   {  tabName: "Chat", imageName: Images.supplier, linkName: "" },
//   { tabName: "Print Case", imageName: Images.print, linkName: "/print-case" }
// ];

const EmployerEmailTabs = ({
  onGetEmployerDocument,
  employerDocumentRes,

  userDataEmp,

  onAddEmployerDocument,

  onRemoveEmployerDocument,

  onUpdateEmployerDocument,

  onUploadAvatar,
  imageUploadSuccess,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onSendEmailLink,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onAddEmployerEmail,

  onGetEmployerHistory,
  employerHistoryRes,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,

  onGetClientHistory,
  clientHistoryRes,

  onGetClientProfile,
  clientProfileData,

  onAddDraftClient,

  onDeleteClientEmail,

  onAddEmailDocument,
  emailDocumentRes,

  clientTab,

  onGetClientTag,
  clientTagRes,
  getClientRes,
  onGetProfileClientTag,
  onAddProfileClientTag,
  addProfielTagRes,
  onRemoveProfileClientTag,
  removeProfileTagRes,
  visaStatusData,
  onUpdateCaseStatus,
  visaAppData,
  onGetVisaApplication,
  onGetClientJobHistoryCurrent,
  employerJobHistoryCurrentRes,
  onSetActiveKey,
  activeKey,
  onGetClientTask,
  onUpdateTask,
  onGetAllUsers,
  onAddTaskFollower,
  onRemoveTasks,
  onAddDailyTasks,
  onGetClientFamily,
  clientFamilyData,
  onUpdateCompletedTask,
  onAddTaskFileNote,
  onAddTaskComment,
  onGetTaskComments,
  onGetTaskFollowers,

  onGetAdmissionProgram,
  onGetAdmissionStatuses,

  onAutoImportEmailClient,
  onGetAutoImportEmailClient,

  onGetClientFamilyEmails,

  clientEmailRes,

  onGetImapSettingss,
  imapSettingRes,

  onGetSignature,
  signatureRes,

  onGetPdf,
  onGetLink,

  onGetDocuments,
  documentRes,
  onGetDocumentDownload,

  onLinkVisa,
  onGetImapForAll,
  imapForAllRes,
  onGetEmailContent,

  onSetProfileData,
  profileDataRdx,

  onGetPartner,
  partnerProfileData,
  onGetPermProcessingPerson,
  onGetGroups,
}) => {
  const [modalType, setModalType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [handleKey, setHandleKey] = useState("1");
  const [headerOptions, setHeaderOptions] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const [emailContentData, setEmailContentData] = useState("");
  const [resultList, setResultList] = useState([]);

  const initialQueryValue = queryString.parse(location && location.search);

  var selectTabParam =
    initialQueryValue && initialQueryValue.para ? initialQueryValue.para : "";
  useEffect(() => {
    onGetEmailContent &&
      onGetEmailContent().then((res) => {
        let _emailTemp = res && res.payload && res.payload.emailTemplates;
        setResultList(_emailTemp);
      });
    if (selectTabParam == "sendEmail") {
      setHandleKey("2");
    }
  }, []);

  useEffect(() => {
    for (let ind = 0; ind < resultList.length; ind++) {
      if (resultList[ind].emailType === "Notifications") {
        setEmailContentData(
          '<p><p style="color: #000">Dear ' +
            (profileData && profileData.fullName) +
            "</p>" +
            resultList[ind].emailContent
        );
      } else {
        //do nothing
      }
    }
  }, [resultList]);

  useEffect(() => {
    // var data = JSON.parse(sessionStorage.getItem("profileData"));
    // setProfileData(data);

    onSetActiveKey(null);
    let clientId = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      onGetClientProfile(clientId);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      onGetClientFamily(data);
    }
  }, []);
  useEffect(() => {
    // onGetClientProfile(clientProfileIdMain);
    if (clientTab) {
      var options = headOption(clientTab);
      setHeaderOptions(options);
      // this.setState({ headerOptions: options });
    } else {
      setHeaderOptions([
        {
          tabName: "Client Profile",
          imageName: Images.clientProfile,
          linkName: "/profile",
        },
        { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
        {
          tabName: "Admission",
          imageName: Images.admission,
          linkName: "/admission",
        },
        {
          tabName: "Documents",
          imageName: Images.documents,
          linkName: "/documents",
        },
        {
          tabName: "Email",
          imageName: Images.email,
          linkName: "/client-email",
        },
        {
          tabName: "Activities",
          imageName: Images.activities,
          linkName: "/activities",
        },
        {
          tabName: "File Notes",
          imageName: Images.documents,
          linkName: "/file-notes",
        },
        {
          tabName: "Accounts",
          imageName: Images.accounts,
          linkName: "/client-account",
        },
        {
          tabName: "Open Case Management",
          imageName: Images.caseManagement,
          linkName: "/Case-management",
        },
        {
          tabName: "Questionnaire",
          imageName: Images.questionnare,
          linkName: "/client-questionnaire",
        },
        // { tabName: "Chat", imageName: Images.supplier, linkName: "" },
        {
          tabName: "Print Case",
          imageName: Images.print,
          linkName: "/print-case",
        },
      ]);
    }
  }, [clientTab, onGetClientProfile]);

  useEffect(() => {
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (clientProfileData && memberType == "client") {
      setProfileDataRdx(clientProfileData);
    } else if (partnerProfileData && memberType == "spouse") {
      setProfileDataRdx(partnerProfileData);
    } else if (clientFamilyData && memberType == "child") {
      let findClient = clientFamilyData.items.find(
        (obj) => obj.id == clientprofileid
      );
      if (findClient) {
        setProfileDataRdx(findClient);
      }
    }
  }, [clientProfileData, partnerProfileData, clientFamilyData]);

  useEffect(() => {
    if (profileDataRdx) {
      setProfileData(JSON.parse(profileDataRdx));
    }
  }, [profileDataRdx]);

  const setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  const showModal = (modalTypeName) => {
    setModalType(modalTypeName);
    setIsModalVisible(true);
    // setAddReminders(true);
    // setUpdateTitle(id);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const callback = (key) => {
    setHandleKey(key);
  };

  return (
    <Fragment>
      {headerOptions.length > 0 && (
        <HeaderBarTabs data={headerOptions} activeTab="Email" />
      )}
      <div style={{ display: "flex", margin: 10 }}>
        <div
          className={
            activeKey
              ? "content-width-open-sidebar"
              : "content-width-close-sidebar"
          }
        >
          {profileData ? (
            <div style={{ width: "100%" }}>
              <p>Client Name: {profileData && profileData.fullName}</p>
            </div>
          ) : (
            <div style={{ height: 30 }} />
          )}
          <div
            className={"employerin-box employer-manag-tabs"}
            style={{
              margin: 10,
              marginTop: 0,
              marginLeft: 0,
            }}
          >
            <div
              className="bg-white "
              style={{
                padding: 30,
                marginBottom: "40px",
              }}
            >
              <Tabs
                activeKey={handleKey}
                type="card"
                size={"small"}
                className="mar-r employer-doc-tab"
                onChange={(key) => callback(key)}
              >
                <TabPane tab="HISTORY" key="1">
                  <div
                    style={{
                      border: "2px solid #c9c9ca",
                      padding: "20px",
                      backgroundColor: "#f0f2f5b8",
                    }}
                  >
                    <ClientEmailHistory
                      resultList={resultList}
                      emailContentData={emailContentData}
                      onGetEmployerDocument={onGetEmployerDocument}
                      employerDocumentRes={employerDocumentRes}
                      userDataEmp={userDataEmp}
                      showModal={showModal}
                      onGetEmployerHistory={onGetEmployerHistory}
                      employerHistoryRes={employerHistoryRes}
                      onGetAutoEmailImport={onGetAutoEmailImport}
                      emailImportRes={emailImportRes}
                      onAddAutoEmailImport={onAddAutoEmailImport}
                      onGetClientHistory={onGetClientHistory}
                      clientHistoryRes={clientHistoryRes}
                      setLoading={setLoading}
                      loading={loading}
                      onDeleteClientEmail={onDeleteClientEmail}
                      onAutoImportEmailClient={onAutoImportEmailClient}
                      onGetAutoImportEmailClient={onGetAutoImportEmailClient}
                      onGetClientFamily={onGetClientFamily}
                      clientFamilyData={clientFamilyData}
                      onGetLetterTemplates={onGetLetterTemplates}
                      LetterTemplatesRes={LetterTemplatesRes}
                      onSendEmailLink={onSendEmailLink}
                      onAddEmployerEmail={onAddEmployerEmail}
                      onAddDraftClient={onAddDraftClient}
                      onGetClientProfile={onGetClientProfile}
                      clientProfileData={clientProfileData}
                      onUploadAvatar={onUploadAvatar}
                      onAddEmailDocument={onAddEmailDocument}
                      emailDocumentRes={emailDocumentRes}
                      onGetClientFamilyEmails={onGetClientFamilyEmails}
                      clientEmailRes={clientEmailRes}
                      onGetImapSettingss={onGetImapSettingss}
                      imapSettingRes={imapSettingRes}
                      onGetSignature={onGetSignature}
                      signatureRes={signatureRes}
                      onGetPdf={onGetPdf}
                      onGetLink={onGetLink}
                      onGetDocuments={onGetDocuments}
                      documentRes={documentRes}
                      onGetVisaApplication={onGetVisaApplication}
                      visaAppData={visaAppData}
                      onLinkVisa={onLinkVisa}
                      profileData={profileData}
                      onGetImapForAll={onGetImapForAll}
                      imapForAllRes={imapForAllRes}
                      onGetPermProcessingPerson={onGetPermProcessingPerson}
                      onGetGroups={onGetGroups}
                    />
                  </div>
                </TabPane>
                <TabPane tab="CREATE" key="2">
                  {handleKey === "1" ? null : (
                    <div
                      style={{
                        border: "2px solid #c9c9ca",
                        padding: "20px",
                        backgroundColor: "#f0f2f5b8",
                      }}
                    >
                      <CreateEmail
                        resultList={resultList}
                        emailContentData={emailContentData}
                        onGetDocumentChecklist={onGetDocumentChecklist}
                        docChecklistRes={docChecklistRes}
                        onGetEmployerCheckList={onGetEmployerCheckList}
                        employerCheckListRes={employerCheckListRes}
                        onAddEmployerCheckList={onAddEmployerCheckList}
                        location={location}
                        history={history}
                        handleKey={handleKey}
                        onSendEmailLink={onSendEmailLink}
                        onGetLetterTemplates={onGetLetterTemplates}
                        LetterTemplatesRes={LetterTemplatesRes}
                        onAddEmployerEmail={onAddEmployerEmail}
                        onAddDraftClient={onAddDraftClient}
                        onGetClientProfile={onGetClientProfile}
                        clientProfileData={clientProfileData}
                        setLoading={setLoading}
                        loading={loading}
                        onGetClientHistory={onGetClientHistory}
                        onUploadAvatar={onUploadAvatar}
                        onAddEmailDocument={onAddEmailDocument}
                        emailDocumentRes={emailDocumentRes}
                        setHandleKey={setHandleKey}
                        onGetClientFamilyEmails={onGetClientFamilyEmails}
                        clientEmailRes={clientEmailRes}
                        onGetEmployerDocument={onGetEmployerDocument}
                        employerDocumentRes={employerDocumentRes}
                        onGetImapSettingss={onGetImapSettingss}
                        imapSettingRes={imapSettingRes}
                        onGetSignature={onGetSignature}
                        signatureRes={signatureRes}
                        onGetPdf={onGetPdf}
                        onGetLink={onLinkVisa}
                        onGetDocuments={onGetDocuments}
                        documentRes={documentRes}
                        onGetDocumentDownload={onGetDocumentDownload}
                        onDeleteClientEmail={onDeleteClientEmail}
                        profileData={profileData}
                        onGetPermProcessingPerson={onGetPermProcessingPerson}
                        onGetGroups={onGetGroups}
                      />
                    </div>
                  )}
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div style={{ width: activeKey ? "438px" : "115px", marginLeft: 15 }}>
          <ProfileSideBar
            onGetClientTag={onGetClientTag}
            clientTagRes={clientTagRes}
            getClientRes={getClientRes}
            onGetProfileClientTag={onGetProfileClientTag}
            onAddProfileClientTag={onAddProfileClientTag}
            addProfielTagRes={addProfielTagRes}
            onRemoveProfileClientTag={onRemoveProfileClientTag}
            removeProfileTagRes={removeProfileTagRes}
            visaStatusData={visaStatusData}
            onUpdateCaseStatus={onUpdateCaseStatus}
            visaAppData={visaAppData}
            onGetVisaApplication={onGetVisaApplication}
            onGetClientJobHistoryCurrent={onGetClientJobHistoryCurrent}
            employerJobHistoryCurrentRes={employerJobHistoryCurrentRes}
            onSetActiveKey={onSetActiveKey}
            activeKey={activeKey}
            onGetClientTask={onGetClientTask}
            onUpdateTask={onUpdateTask}
            onGetAllUsers={onGetAllUsers}
            onAddTaskFollower={onAddTaskFollower}
            onRemoveTasks={onRemoveTasks}
            onAddDailyTasks={onAddDailyTasks}
            onGetClientFamily={onGetClientFamily}
            onUpdateCompletedTask={onUpdateCompletedTask}
            onAddTaskFileNote={onAddTaskFileNote}
            onAddTaskComment={onAddTaskComment}
            onGetTaskComments={onGetTaskComments}
            onGetTaskFollowers={onGetTaskFollowers}
            onGetAdmissionProgram={onGetAdmissionProgram}
            onGetAdmissionStatuses={onGetAdmissionStatuses}
          />
        </div>
      </div>

      {/* {isModalVisible && (
        <div className="reminder-model">
          <Modal
            className="reminder-model-main"
            title={
              (modalType === "add-documents" && "ADD DOCUMENTS") ||
              (modalType === "update-documents" && "UPDATE DOCUMENTS")
            }
            visible={isModalVisible}
            onCancel={handleCancel}
            // Header={true}
            footer={false}
          >
            {modalType === "add-documents" && (
              <AddEmployerDocuments
                onAddEmployerDocument={onAddEmployerDocument}
                onUploadAvatar={onUploadAvatar}
                imageUploadSuccess={imageUploadSuccess}
              />
            )}

            {modalType === "update-documents" && (
              <UpdateEmployerDocuments
                onAddEmployerDocument={onAddEmployerDocument}
              />
            )}
          </Modal>
        </div>
      )} */}
    </Fragment>
  );
};

export default EmployerEmailTabs;
