import React from "react";
import Select from "react-select";
import "./ClientsStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link, withRouter } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import Invoices from "./Invoices";
import OutgoingPayment from "./OutgoingPayments";
import AccountsFlow from "./AccountsFlow";
import DatePicker from "react-date-picker";

import ProgressBar from "../Components/Shared/Progressbar";
import AddInvoice from "./AddInvoice";
import { bindActionCreators } from "redux";
import {
  deleteInvoice,
  getClientFamily,
  getClientProfile,
  getClientTag,
  getCompany,
  getInvoiceInfo,
  getParentInvoiceListing,
  getPartnerData,
  setActiveKey,
  setInvoicesDataNull,
  setProfileData,
} from "../store/Actions";
import { connect } from "react-redux";
import headOption from "../Components/Header/HeaderTabOptions";
import { Col, Spin } from "antd";
import ProfileSideBar from "../Components/ProfileSideBar";
import Templates from "./Templates";
import AddTemplateInvoice from "./AddTemplateInvoice";
import AgentCommission from "./AgentCommission";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

// const headOption = [
//   {
//     tabName: "Client Profile",
//     imageName: Images.clientProfile,
//     linkName: "/profile",
//   },
//   { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
//   { tabName: "Admission", imageName: Images.admission, linkName: "/admission" },
//   { tabName: "Documents", imageName: Images.documents, linkName: "/documents" },
//   { tabName: "Email", imageName: Images.email, linkName: "/client-email" },
//   {
//     tabName: "Activities",
//     imageName: Images.activities,
//     linkName: "/activities",
//   },
//   {
//     tabName: "File Notes",
//     imageName: Images.documents,
//     linkName: "/file-notes",
//   },
//   {
//     tabName: "Accounts",
//     imageName: Images.accounts,
//     linkName: "/client-account",
//   },
//   {
//     tabName: "Open Case Management",
//     imageName: Images.caseManagement,
//     linkName: "case-management",
//   },
//   { tabName: "Questionnaire", imageName: Images.questionnare, linkName: "" },
//   {  tabName: "Chat", imageName: Images.supplier, linkName: "" },
//   { tabName: "Print Case", imageName: Images.print, linkName: "/print-case" },
// ];

class ClientAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMainTab: "document",
      activeTab: "templates",
      extend: false,
      addInvoice: "",
      parentInvoiceId: 0,
      childrenTotalAmount: 0,
      parentTotalAmount: 0,
      headerOptions: [],
      loading: false,
      loadingOutgoingPayments: false,
      loadingAccountsFlow: false,
      loadingTemplates: false,
      isAddInvoiceModuleVisible: false,
      profileData: null,
    };
  }

  componentDidMount() {
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          // { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }

    // var data = JSON.parse(sessionStorage.getItem("profileData"));
    // this.setState({ profileData: data });
    let familyId = sessionStorage.getItem("familyId");
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      this.props.onGetClientProfile(clientProfileIdMain);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data);
    }
    this.props.getCompany();
  }

  componentDidUpdate(prevProps) {
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (
      prevProps.clientProfileData != this.props.clientProfileData &&
      memberType == "client"
    ) {
      this.setProfileDataRdx(this.props.clientProfileData);
    } else if (
      prevProps.partnerProfileData != this.props.partnerProfileData &&
      memberType == "spouse"
    ) {
      this.setProfileDataRdx(this.props.partnerProfileData);
    } else if (
      prevProps.clientFamilyData != this.props.clientFamilyData &&
      memberType == "child"
    ) {
      let findClient =
        this.props.clientFamilyData &&
        this.props.clientFamilyData.items.find(
          (obj) => obj.id == clientprofileid
        );
      if (findClient) {
        this.setProfileDataRdx(findClient);
      }
    }
    if (
      (prevProps.profileDataRdx && !this.state.profileData) ||
      prevProps.profileDataRdx != this.props.profileDataRdx
    ) {
      this.setState({ profileData: JSON.parse(this.props.profileDataRdx) });
    }
  }

  setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeMainTab = (value) => {
    this.setState({ activeMainTab: value });
  };

  onChangeTab = (value) => {
    if (value === "invoices") {
      this.setState({ loading: true }, () => {
        this.setState({ addInvoice: "invoices", activeTab: value }, () => {
          this.setState({ loading: false });
        });
      });
    } else {
      this.setState(
        {
          loading: false,
          loadingOutgoingPayments: true,
          loadingAccountsFlow: true,
          loadingTemplates: true,
          isAddInvoiceModuleVisible: false,
        },
        () => {
          this.setState({ activeTab: value }, () => {
            this.setState({
              loadingOutgoingPayments: false,
              loadingAccountsFlow: false,
              loadingTemplates: false,
              isAddInvoiceModuleVisible: false,
            });
          });
        }
      );
    }
  };

  getChildInfo = (
    parentInvoiceId,
    childrenTotalAmount,
    parentTotalAmount,
    addInvoice
  ) => {
    this.setState({
      parentInvoiceId: parentInvoiceId,
      childrenTotalAmount: childrenTotalAmount,
      parentTotalAmount: parentTotalAmount,
      addInvoice: addInvoice,
    });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  getShowInvoice = (stateVar, data, id) => {
    this.setState({
      isAddInvoiceModuleVisible: stateVar,
      data: data,
      activeTab: "templates",
      recordId: id,
    });
  };

  render() {
    const { selectedOption, headerOptions, profileData } = this.state;
    const { activeKey, onSetActiveKey } = this.props;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            {headerOptions.length > 0 && (
              <HeaderBarTabs data={headerOptions} activeTab="Accounts" />
            )}

            <div style={{ display: "flex", margin: 10 }}>
              <div
                className={
                  activeKey
                    ? "content-width-open-sidebar"
                    : "content-width-close-sidebar"
                }
              >
                {profileData ? (
                  <div style={{ width: "100%", marginTop: 8, marginLeft: 15 }}>
                    <p>Client Name: {profileData && profileData.fullName}</p>
                  </div>
                ) : (
                  <div style={{ height: 30 }} />
                )}
                <div
                  className="sus-tab-container"
                  style={{ marginLeft: 1, marginTop: 0 }}
                >
                  <div
                    onClick={() => this.onChangeTab("templates")}
                    style={{ borderLeft: "none", cursor: "pointer" }}
                    className={
                      this.state.activeTab == "templates"
                        ? "sus-active-tab-schools"
                        : "sus-inactive-tab-schools"
                    }
                  >
                    <span
                      className={
                        this.state.activeTab == "templates"
                          ? "sus-active-tab-text-school"
                          : "sus-inactive-tab-text-school"
                      }
                    >
                      TEMPLATE
                    </span>
                  </div>
                  <div
                    onClick={() => this.onChangeTab("invoices")}
                    style={{ cursor: "pointer", borderLeft: "2px solid white" }}
                    className={
                      this.state.activeTab == "invoices"
                        ? "sus-active-tab-schools"
                        : "sus-inactive-tab-schools"
                    }
                  >
                    <span
                      className={
                        this.state.activeTab == "invoices"
                          ? "sus-active-tab-text-school"
                          : "sus-inactive-tab-text-school"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      INVOICES
                    </span>
                  </div>
                  <div
                    onClick={() => this.onChangeTab("outgoingPayment")}
                    style={{ cursor: "pointer", borderLeft: "2px solid white" }}
                    className={
                      this.state.activeTab == "outgoingPayment"
                        ? "sus-active-tab-schools"
                        : "sus-inactive-tab-schools"
                    }
                  >
                    <span
                      className={
                        this.state.activeTab == "outgoingPayment"
                          ? "sus-active-tab-text-school"
                          : "sus-inactive-tab-text-school"
                      }
                    >
                      OUTGOING PAYMENTS
                    </span>
                  </div>
                  <div
                    onClick={() => this.onChangeTab("accountsFlow")}
                    style={{ cursor: "pointer", borderLeft: "2px solid white" }}
                    className={
                      this.state.activeTab == "accountsFlow"
                        ? "sus-active-tab-schools"
                        : "sus-inactive-tab-schools"
                    }
                  >
                    <span
                      className={
                        this.state.activeTab == "accountsFlow"
                          ? "sus-active-tab-text-school"
                          : "sus-inactive-tab-text-school"
                      }
                    >
                      ACCOUNTS FLOW
                    </span>
                  </div>
                  {localStorage.getItem("userBranchPermissions") &&
                    JSON.parse(
                      decodeURIComponent(
                        escape(
                          window.atob(
                            localStorage.getItem("userBranchPermissions")
                          )
                        )
                      )
                    ).branchPermission.find(
                      (x) => x.name.toLowerCase() == "agent"
                    ).status &&
                    localStorage.getItem("userSystemPermissions") &&
                    JSON.parse(
                      decodeURIComponent(
                        escape(
                          window.atob(
                            localStorage.getItem("userSystemPermissions")
                          )
                        )
                      )
                    ).find(
                      (x) => x.role.toLowerCase() == "agent portal accounts"
                    ).status == 1 && (
                      <div
                        onClick={() => this.onChangeTab("agentCommission")}
                        style={{
                          cursor: "pointer",
                          borderLeft: "2px solid white",
                        }}
                        className={
                          this.state.activeTab == "agentCommission"
                            ? "sus-active-tab-schools"
                            : "sus-inactive-tab-schools"
                        }
                      >
                        <span
                          className={
                            this.state.activeTab == "agentCommission"
                              ? "sus-active-tab-text-school"
                              : "sus-inactive-tab-text-school"
                          }
                        >
                          AGENT COMMISSION
                        </span>
                      </div>
                    )}
                </div>

                <div
                  className="ca-container"
                  style={{
                    width: "100%",
                    marginLeft: 0,
                  }}
                >
                  <div>
                    {/* ===== Design from Template tab Screen ===== */}

                    {this.state.loadingTemplates ? (
                      <div className={"spinner"}>
                        <Spin size="large" />
                      </div>
                    ) : this.state.activeTab === "templates" ? (
                      this.state.isAddInvoiceModuleVisible ? (
                        <AddTemplateInvoice
                          data={this.state.data}
                          recordId={this.state.recordId}
                        />
                      ) : (
                        <Templates getShowInvoice={this.getShowInvoice} />
                      )
                    ) : null}

                    {/* ===== Design from Invoice listng tab Screen ===== */}
                    {this.state.loading ? (
                      <div className={"spinner"}>
                        <Spin size="large" />
                      </div>
                    ) : this.state.addInvoice === "invoices" &&
                      this.state.activeTab === "invoices" ? (
                      <Invoices getChildInfo={this.getChildInfo} />
                    ) : null}
                    {this.state.addInvoice === "add" &&
                    this.state.activeTab === "invoices" ? (
                      <AddInvoice
                        parentInvoiceId={this.state.parentInvoiceId}
                        childrenTotalAmount={this.state.childrenTotalAmount}
                        parentTotalAmount={this.state.parentTotalAmount}
                        getChildInfo={this.getChildInfo}
                      />
                    ) : null}

                    {/* ===== Design from Add New Invoice tab Screen ===== */}

                    {this.state.addInvoice == "addNewInvoice" && (
                      <div>
                        <div className="ca-label-cont">
                          <span className="ca-label-text">NEW INVOICE</span>
                        </div>
                        <div className="ca-form-cont">
                          <div className="ca-invoice-row-cont">
                            <div style={{ width: "20%" }}>
                              <p style={{ color: "#7A7A7A" }}>DATE</p>
                              <div
                                class="profile-input-border"
                                style={{
                                  overflow: "inherit",
                                  paddingTop: 6,
                                  paddingBottom: 6,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  paddingRight: 5,
                                }}
                              >
                                <DatePicker
                                  calendarIcon={null}
                                  clearIcon={null}
                                  onChange={this.onChangeDate}
                                  value={this.state.date}
                                />
                                <img
                                  src={Images.calendar}
                                  className="profile-calendar-icon"
                                />
                              </div>
                            </div>
                            <div style={{ width: "20%", marginLeft: 8 }}>
                              <p style={{ color: "#7A7A7A" }}>DUE DATE</p>
                              <div
                                class="profile-input-border"
                                style={{
                                  overflow: "inherit",
                                  paddingTop: 6,
                                  paddingBottom: 6,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  paddingRight: 5,
                                }}
                              >
                                <DatePicker
                                  calendarIcon={null}
                                  clearIcon={null}
                                  onChange={this.onChangeDate}
                                  value={this.state.date}
                                />
                                <img
                                  src={Images.calendar}
                                  className="profile-calendar-icon"
                                />
                              </div>
                            </div>
                            <div className="ca-small-index">
                              <p style={{ color: "#7A7A7A" }}>LAST INVOICE #</p>
                              <div class="profile-input-border">
                                <input
                                  className="profile-input"
                                  placeholder=""
                                  type="text"
                                  onChange={this.myChangeHandler}
                                />
                              </div>
                            </div>
                            <div className="ca-small-index">
                              <p style={{ color: "#7A7A7A" }}>INVOICE #</p>
                              <div class="profile-input-border">
                                <input
                                  className="profile-input"
                                  placeholder=""
                                  type="text"
                                  onChange={this.myChangeHandler}
                                />
                              </div>
                            </div>
                            <div className="ca-small-index">
                              <p style={{ color: "#7A7A7A" }}>TYPE</p>
                              <div class="select-options">
                                <Select
                                  value={selectedOption}
                                  onChange={this.handleChange}
                                  options={options}
                                />
                              </div>
                            </div>
                            <div className="ca-small-index">
                              <p style={{ color: "#7A7A7A" }}>CLIENT TAGS</p>
                              <div class="select-options">
                                <Select
                                  value={selectedOption}
                                  onChange={this.handleChange}
                                  options={options}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="ca-white-cont">
                            <div className="ca-color-row">
                              <div
                                className="ca-color-row-index"
                                style={{ width: "10%" }}
                              >
                                <span className="ca-color-row-text">SR#</span>
                              </div>
                              <div
                                className="ca-color-row-index"
                                style={{ width: "50%" }}
                              >
                                <span className="ca-color-row-text">
                                  DESCRIPTION
                                </span>
                              </div>
                              <div
                                className="ca-color-row-index"
                                style={{ width: "20%" }}
                              >
                                <span className="ca-color-row-text">
                                  AMOUNT
                                </span>
                              </div>
                              <div
                                className="ca-color-row-index"
                                style={{ width: "20%" }}
                              >
                                <span className="ca-color-row-text">
                                  ACTION
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 10,
                                marginBottom: 15,
                              }}
                            >
                              <div
                                className="ca-color-row-index"
                                style={{ width: "10%" }}
                              >
                                <span className="ca-color-row-text">1</span>
                              </div>
                              <div
                                className="ca-color-row-index"
                                style={{ width: "50%" }}
                              >
                                <div
                                  class="profile-input-border"
                                  style={{ width: "100%", borderRadius: 2 }}
                                >
                                  <input
                                    className="profile-input"
                                    placeholder=""
                                    type="text"
                                    onChange={this.myChangeHandler}
                                  />
                                </div>
                              </div>
                              <div
                                className="ca-color-row-index"
                                style={{ width: "20%" }}
                              >
                                <div
                                  class="profile-input-border"
                                  style={{ borderRadius: 2 }}
                                >
                                  <input
                                    className="profile-input"
                                    placeholder=""
                                    type="text"
                                    onChange={this.myChangeHandler}
                                  />
                                </div>
                              </div>
                              <div
                                className="ca-color-row-index"
                                style={{ width: "20%" }}
                              >
                                <img
                                  src={Images.deleteGray}
                                  style={{ width: 20, height: 20 }}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              width: "47%",
                              display: "flex",
                              marginTop: 15,
                              marginBottom: 20,
                            }}
                          >
                            <div className="sus-cp-btn">
                              <span className="sus-cp-btn-text">
                                ADD NEW LINE
                              </span>
                            </div>
                          </div>

                          <div className="ca-input-cont">
                            <div>
                              <textarea
                                value={this.state.value}
                                onChange={this.handleChange}
                                rows={20}
                                className="ca-text-area"
                              />
                            </div>

                            <div className="ca-right-input-cont">
                              <div class="select-options">
                                <Select
                                  value={selectedOption}
                                  onChange={this.handleChange}
                                  options={options}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginTop: 10,
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  SUB TOTAL
                                </p>
                                <div
                                  class="emp-input-border"
                                  style={{ width: "80%" }}
                                >
                                  <input
                                    className="profile-input"
                                    placeholder=""
                                    type="text"
                                    onChange={this.myChangeHandler}
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginTop: 10,
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  CURRENCY
                                </p>
                                <div
                                  class="select-options"
                                  style={{ width: "80%" }}
                                >
                                  <Select
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={options}
                                  />
                                </div>
                              </div>

                              <div className="ca-checkbox-row">
                                <input
                                  type="checkbox"
                                  className="sus-checkbox"
                                  onChange={this.handleCheck}
                                  defaultChecked={this.state.checked}
                                />
                                <span
                                  className="cv-normal-text"
                                  style={{ color: "#6D6D6D" }}
                                >
                                  Tax Inclusive
                                </span>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginTop: 10,
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  TAX %
                                </p>
                                <div class="emp-input-border">
                                  <input
                                    className="profile-input"
                                    placeholder=""
                                    type="text"
                                    onChange={this.myChangeHandler}
                                  />
                                </div>
                              </div>

                              <div style={{ marginTop: 10 }}>
                                <div class="select-options">
                                  <Select
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={options}
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginTop: 10,
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  DISCOUNT %
                                </p>
                                <div class="emp-input-border">
                                  <input
                                    className="profile-input"
                                    placeholder=""
                                    type="text"
                                    onChange={this.myChangeHandler}
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginTop: 10,
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  DISCOUNT AMOUNT
                                </p>
                                <div class="emp-input-border">
                                  <input
                                    className="profile-input"
                                    placeholder=""
                                    type="text"
                                    onChange={this.myChangeHandler}
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginTop: 10,
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  TOTAL
                                </p>
                                <div class="emp-input-border">
                                  <input
                                    className="profile-input"
                                    placeholder=""
                                    type="text"
                                    onChange={this.myChangeHandler}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="ca-checkbox-row">
                            <input
                              type="checkbox"
                              className="sus-checkbox"
                              onChange={this.handleCheck}
                              defaultChecked={this.state.checked}
                            />
                            <span
                              className="cv-normal-text"
                              style={{ color: "#6D6D6D" }}
                            >
                              Send email to secondary address
                            </span>
                          </div>

                          <div style={{ display: "flex", marginTop: 20 }}>
                            <div className="ca-btn-blue">
                              <span className="ca-btn-blue-text">
                                SAVE INVOICE
                              </span>
                            </div>
                            <div
                              className="ca-btn-blue"
                              style={{ marginLeft: 10 }}
                            >
                              <span className="ca-btn-blue-text">
                                EXPORT TO PDF / EMAIL INVOICE
                              </span>
                            </div>
                            <div
                              className="ca-btn-blue"
                              style={{ marginLeft: 10 }}
                            >
                              <span className="ca-btn-blue-text">
                                PREVIEW / DOWNLOAD
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* ===== Design from supplier listng tab Screen ===== */}

                    {this.state.activeTab == "supplier" && (
                      <div>
                        <div className="ca-gray-cont">
                          <div className="ca-calendar-cont-row">
                            <div
                              class="profile-input-border"
                              style={{
                                overflow: "inherit",
                                width: "20%",
                                paddingTop: 6,
                                paddingBottom: 6,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingRight: 5,
                              }}
                            >
                              <DatePicker
                                calendarIcon={null}
                                clearIcon={null}
                                onChange={this.onChangeDate}
                                value={this.state.date}
                              />
                              <img
                                src={Images.calendar}
                                className="profile-calendar-icon"
                              />
                            </div>
                            <div
                              class="profile-input-border"
                              style={{
                                overflow: "inherit",
                                width: "20%",
                                paddingTop: 6,
                                paddingBottom: 6,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingRight: 5,
                              }}
                            >
                              <DatePicker
                                calendarIcon={null}
                                clearIcon={null}
                                onChange={this.onChangeDate}
                                value={this.state.date}
                              />
                              <img
                                src={Images.calendar}
                                className="profile-calendar-icon"
                              />
                            </div>
                            <div className="ca-show-add-btn-cont">
                              <div
                                class="select-options"
                                style={{ width: "55%" }}
                              >
                                <Select
                                  value={selectedOption}
                                  onChange={this.handleChange}
                                  options={options}
                                />
                              </div>
                              <div className="ca-show-add-cont">
                                <span className="ca-show-add-text">SHOW</span>
                              </div>
                              <div className="ca-show-add-cont">
                                <span className="ca-show-add-text">ADD</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="ca-gray-cont"
                          style={{ padding: 20, paddingBottom: 60 }}
                        >
                          <table className="ca-invoice-table-cont">
                            <tbody>
                              <tr style={{ backgroundColor: "#FFFFFF" }}>
                                <th></th>
                                <th className="ca-table-heading">DATE</th>
                                <th className="ca-table-heading">INVOICE#</th>
                                <th className="ca-table-heading">DUE DATE</th>
                                <th className="ca-table-heading">PAYMENT</th>
                                <th className="ca-table-heading">
                                  DUE/OVER DUE AMOUNT
                                </th>
                                <th className="ca-table-heading">VIEW</th>
                                <th></th>
                              </tr>
                              <tr style={{ backgroundColor: "#FFFFFF" }}>
                                <td className="ca-table-head-width">
                                  <div
                                    className="ca-green-cont"
                                    style={{ backgroundColor: "#FFA038" }}
                                  >
                                    <span className="ca-colored-box-text">
                                      Saved
                                    </span>
                                  </div>
                                </td>
                                <td className="ca-font-content-text">
                                  21/04/2020
                                </td>
                                <td className="ca-font-content-text">
                                  8HOSBK3
                                </td>
                                <td className="ca-font-content-text">
                                  29/04/2020
                                </td>
                                <td className="ca-font-content-text">0</td>
                                <td className="ca-font-content-text">624</td>
                                <td
                                  className="ca-font-content-text"
                                  style={{ color: "#0A02AD" }}
                                >
                                  VIEW INVOICE
                                </td>
                                <td
                                  className="ca-font-content-text"
                                  style={{ color: "#0A02AD" }}
                                >
                                  DELETE
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#FFFFFF" }}>
                                <td className="ca-table-head-width"></td>
                                <td className="ca-font-content-text"></td>
                                <td className="ca-font-content-text"></td>
                                <td className="ca-font-content-text"></td>
                                <td className="ca-font-content-text"></td>
                                <td className="ca-font-content-text"></td>
                                <td
                                  className="ca-font-content-text"
                                  style={{ color: "#0A02AD" }}
                                ></td>
                                <td
                                  className="ca-font-content-text"
                                  style={{ color: "#0A02AD" }}
                                ></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {this.state.loadingOutgoingPayments ? (
                      <div className={"spinner"}>
                        <Spin size="large" />
                      </div>
                    ) : this.state.activeTab === "outgoingPayment" ? (
                      <OutgoingPayment />
                    ) : null}

                    {/* ===== Design from Accounts Flow tab Screen ===== */}

                    {this.state.loadingAccountsFlow ? (
                      <div className={"spinner"}>
                        <Spin size="large" />
                      </div>
                    ) : this.state.activeTab === "accountsFlow" ? (
                      <AccountsFlow />
                    ) : null}

                    {/* ===== Design from Accounts Flow tab Screen ===== */}

                    {this.state.activeTab == "agentCommission" && (
                      <AgentCommission />
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginLeft: "20px",
                  width: activeKey ? "438px" : "115px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <ProfileSideBar
                    activeKey={activeKey}
                    onSetActiveKey={onSetActiveKey}
                  />
                </div>
              </div>
            </div>
            {/* <div
              style={{
                marginLeft: "20px",
                width: this.props.activeKey ? "438px" : "115px",
              }}
            >
              <div style={{ width: "100%" }}>
                <ProfileSideBar
                  activeKey={this.props.activeKey}
                  onSetActiveKey={this.props.onSetActiveKey}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientTab: state.employerManagReducer.clientTab,
    activeKey: state.employerManagReducer.activeKey,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    partnerProfileData: state.partnerReducer.partnerProfileData,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompany: bindActionCreators(getCompany, dispatch),
  onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
  onSetProfileData: bindActionCreators(setProfileData, dispatch),
  onGetPartner: bindActionCreators(getPartnerData, dispatch),
  onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
  onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
});
ClientAccount = connect(mapStateToProps, mapDispatchToProps)(ClientAccount);

export default withRouter(ClientAccount);
