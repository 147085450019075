import React from "react";
import "./AdmissionStyles.css";

import { Link } from "react-router-dom";
import { Images } from "../Themes";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import ProfileSideBar from "../Components/ProfileSideBar";
import moment from "moment";

import activityData from "../Components/ClientActivity/ActivityData";

import { message, Radio, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";

import ProgressBar from "../Components/Shared/Progressbar";

import AdmissionForm from "../Components/ClientAdmission/AdmissionForm";
import LinkVisaCard from "../Components/ClientAdmission/LinkVisaCard";

import ScheduleCommission from "../Components/ClientAdmission/ScheduleCommission";

import headOption from "../Components/Header/HeaderTabOptions";
// import Form from "antd/lib/form/Form";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

// const headOption = [
//   {
//     tabName: "Client Profile",
//     imageName: Images.clientProfile,
//     linkName: "/profile"
//   },
//   { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
//   { tabName: "Admission", imageName: Images.admission, linkName: "/admission" },
//   { tabName: "Documents", imageName: Images.documents, linkName: "/documents" },
//   { tabName: "Email", imageName: Images.email, linkName: "/client-email" },
//   {
//     tabName: "Activities",
//     imageName: Images.activities,
//     linkName: "/activities"
//   },
//   {
//     tabName: "File Notes",
//     imageName: Images.documents,
//     linkName: "/file-notes"
//   },
//   {
//     tabName: "Accounts",
//     imageName: Images.accounts,
//     linkName: "/client-account"
//   },
//   { tabName: "Questionnaire", imageName: Images.questionnare, linkName: "" },
//   {  tabName: "Chat", imageName: Images.supplier, linkName: "" },
//   { tabName: "Print Case", imageName: Images.print, linkName: "/print-case" }
// ];

var schoolTypes = [];

class Admission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "document",
      extend: false,
      showProgramForm: false,
      expendedView: true,
      showCommission: false,
      totalFee: null,
      linkMember: false,
      checkIndex: null,
      value: null,
      headerOptions: [],
      profileData: null,
      loadAdmission: true,
    };

    this.props.onSetActiveKey(null);
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      this.props.onGetClientProfile(clientProfileIdMain);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data);
    }

    if (clientprofileid) {
      this.props.onGetAdmissionStatuses();
      this.props
        .onGetAdmissionProgram()
        .then(() => {
          this.setState({ loadAdmission: false });
        })
        .catch(() => {
          this.setState({ loadAdmission: false });
        });
      this.props.onGetSchoolType();
      this.props.getCommissionRemindersListing(false, clientprofileid);
    } else {
      setTimeout(() => {
        this.setState({ loadAdmission: false });
      }, 1500);
    }
  }

  formRef = React.createRef();

  componentDidMount() {
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          // { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }
    // var data = JSON.parse(this.props.setProfileDataRdx);
    // this.setState({ profileData: data });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.admissionProgramData !== this.props.admissionProgramData) {
    }
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (
      prevProps.clientProfileData != this.props.clientProfileData &&
      memberType == "client"
    ) {
      this.setProfileDataRdx(this.props.clientProfileData);
    } else if (
      prevProps.partnerProfileData != this.props.partnerProfileData &&
      memberType == "spouse"
    ) {
      this.setProfileDataRdx(this.props.partnerProfileData);
    } else if (
      prevProps.clientFamilyData != this.props.clientFamilyData &&
      memberType == "child"
    ) {
      let findClient =
        this.props.clientFamilyData &&
        this.props.clientFamilyData.items.find(
          (obj) => obj.id == clientprofileid
        );
      if (findClient) {
        this.setProfileDataRdx(findClient);
      }
    }
    if (
      (prevProps.profileDataRdx && !this.state.profileData) ||
      prevProps.profileDataRdx != this.props.profileDataRdx
    ) {
      this.setState({ profileData: JSON.parse(this.props.profileDataRdx) });
    }
  }

  setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeTab = (value) => {
    this.setState({ activeInfoTab: value });
  };

  onChangeTopTab = (value) => {
    this.setState({ activeTab: value });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  onDeleteProgram = (program) => {
    let userId = localStorage.getItem("userId");
    let data = {
      id: program.id,
      delete: true,
    };

    this.props.onRemoveAdmissionProgram(data).then((res) => {
      message.success("Admission removed successfully");
      this.props.onGetAdmissionProgram();
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      if (profileData) {
        let data = {
          clientName: profileData.fullName,
          logMessage:
            program.enrolledProgram +
            " Admission with " +
            program.programStatusName +
            " removed by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Admission",
          invoiceId: "0",
        };
        activityData(data);
      }
    });
  };

  showRightMenu = () => {
    this.setState({ expendedView: false });
  };

  onChangeRadio = (e, programData) => {
    console.log("radio checked", e.target.value);
    this.setState({ value: e.target.value });
    var userId = localStorage.getItem("userId");
    let data = {
      id: programData.id,
      clientId: programData.clientId,
      schoolId: programData.schoolId,
      programId: programData.programId,
      programStatus: programData.programStatus,
      description: programData.description,
      appliedDate: programData.appliedDate,
      addmissionDate: programData.addmissionDate,
      enrolledProgram: programData.enrolledProgram,
      studentNo: programData.studentNo,
      fee: programData.fee,
      hideCMS: programData.hideCMS,
      date: programData.date || new Date(),
      caseStart: programData.caseStart,
      caseEnd: programData.caseEnd,
      courseEffectiveDate: programData.courseEffectiveDate,
      aipDate: programData.aipDate,
      aipSubmitted: programData.aipSubmitted,
      visaApprovedDate: programData.visaApprovedDate,
      ftsSubmitted: programData.ftsSubmitted,
      ftsDate: programData.ftsDate,
      visaId: parseInt(e.target.value),
      conditionalOfferDate: programData.conditionalOfferDate,
      formalOfferDate: programData.formalOfferDate,
      paidDate: programData.paidDate,
      declineDate: programData.declineDate,
      startDate: programData.startDate,
      deletedDate: programData.deletedDate || new Date(),
      commissionId: programData.commissionId,
      schoolTypeId: programData.schoolTypeId,
    };

    console.log(data);
    this.props
      .onUpdAdmissionForm(data)
      .then((res) => {
        message.success("Visa linked successfully successfuly");
        this.props.onGetAdmissionProgram();
      })
      .catch((err) => {
        message.error("Failed to link visa");
      });
  };

  onRemoveLink = (programData) => {
    // console.log("radio checked", e.target.value);
    // this.setState({ value: e.target.value });
    var userId = localStorage.getItem("userId");
    let data = {
      id: programData.id,
      clientId: programData.clientId,
      schoolId: programData.schoolId,
      programId: programData.programId,
      programStatus: programData.programStatus,
      description: programData.description,
      appliedDate: programData.appliedDate,
      addmissionDate: programData.addmissionDate,
      enrolledProgram: programData.enrolledProgram,
      studentNo: programData.studentNo,
      fee: programData.fee,
      hideCMS: programData.hideCMS,
      date: programData.date || new Date(),
      caseStart: programData.caseStart,
      caseEnd: programData.caseEnd,
      courseEffectiveDate: programData.courseEffectiveDate,
      aipDate: programData.aipDate,
      aipSubmitted: programData.aipSubmitted,
      visaApprovedDate: programData.visaApprovedDate,
      ftsSubmitted: programData.ftsSubmitted,
      ftsDate: programData.ftsDate,
      visaId: 0,
      conditionalOfferDate: programData.conditionalOfferDate,
      formalOfferDate: programData.formalOfferDate,
      paidDate: programData.paidDate,
      declineDate: programData.declineDate,
      startDate: programData.startDate,
      deletedDate: programData.deletedDate || new Date(),
      commissionId: programData.commissionId,
      schoolTypeId: programData.schoolTypeId,
    };

    this.props
      .onUpdAdmissionForm(data)
      .then((res) => {
        message.success("Visa linked successfully successfuly");
        this.props.onGetAdmissionProgram();
      })
      .catch((err) => {
        message.error("Failed to link visa");
      });
  };

  render() {
    const {
      selectedOption,
      showProgramForm,
      expendedView,
      showCommission,
      linkMember,
      checkIndex,
      headerOptions,
      value,
      profileData,
      loadAdmission,
    } = this.state;
    const {
      visaStatusData,
      visaAppData,
      onGetVisaApplication,
      onUpdateCaseStatus,
      schoolTypesData,
      onGetSchools,
      schoolsData,
      onGetSchoolLevel,
      schoolLevelData,
      onAddClientAdmission,
      admissionProgramData,
      onGetAdmissionProgram,
      admissionStatusData,
      onUpdAdmissionForm,
      onUpdAdmissionStatus,
      onAddCommissionReminder,
      commissionRemindersListing,
      onSetActiveKey,
      activeKey,
      remindersRes,
      onGetReminder,
      onGetVisaStatus,
      onGetAdmissionStatuses,
      getCommissionRemindersListing,
      onRemoveCommissionReminder,
    } = this.props;
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };
    return (
      <div>
        <Spin spinning={loadAdmission}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              {headerOptions.length > 0 && (
                <HeaderBarTabs data={headerOptions} activeTab="Admission" />
              )}
              <div
                className="cv-main-container"
                style={{ display: "flex", margin: 10, padding: 0 }}
              >
                <div
                  className={
                    activeKey
                      ? "content-width-open-sidebar"
                      : "content-width-close-sidebar"
                  }
                >
                  {profileData ? (
                    <div
                      class="form-cont"
                      style={{
                        paddingLeft: 10,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <p>
                          Client Name: {profileData && profileData.fullName}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div style={{ height: 30 }} />
                  )}
                  <div
                    className="cv-left-container"
                    style={{ width: "100%", marginTop: 2 }}
                  >
                    <div className="cv-top-btn-row">
                      <div
                        className="cv-btn-blue ad-button-width"
                        style={{ cursor: "pointer" }}
                        onClick={() => onSetActiveKey("4")}
                      >
                        <div>
                          <span className="cv-top-lbtn-text">
                            START NEW APPLICATION
                          </span>
                        </div>
                        <div>
                          <span className="cv-top-rbtn-text">Start</span>
                        </div>
                      </div>
                    </div>

                    {/* ===== Design from client admission Screen ===== */}

                    {admissionProgramData &&
                      admissionStatusData &&
                      admissionProgramData.items.map((program, index) => {
                        var findStatus = admissionStatusData.items.find(
                          (obj) => obj.id === program.programStatus
                        );
                        var linkVisaName = "";
                        if (visaAppData) {
                          var checkVisaLink = visaAppData.items.find(
                            (obj) => obj.id === program.visaId
                          );
                          if (checkVisaLink) {
                            linkVisaName = checkVisaLink.branchVisaTypeName;
                          }
                        }
                        return (
                          <div key={index}>
                            {/* <div className="cv-row">
                          <div style={{ margin: 10 }}>
                            
                          </div>
                        </div> */}
                            <div
                              className="cv-gray-cont"
                              style={{
                                paddingTop: 15,
                                paddingBottom:
                                  showProgramForm || linkMember ? 15 : 0,
                              }}
                            >
                              <div className="cv-row">
                                <div>
                                  <div
                                    className="cv-assist-cont"
                                    style={{ marginLeft: 15 }}
                                  >
                                    <span className="cv-bold-text">
                                      {program.schoolName}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      className="cv-normal-text"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Level:{" "}
                                      <span style={{ fontWeight: "400" }}>
                                        {program.commissionName}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="cv-assist-cont">
                                    <span
                                      className="cv-normal-text"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Program:{" "}
                                      <span style={{ fontWeight: "400" }}>
                                        {program.enrolledProgram}
                                      </span>
                                    </span>
                                  </div>
                                  {program.description && (
                                    <div className="cv-assist-cont">
                                      <span
                                        className="cv-normal-text"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Description:{" "}
                                        <span style={{ fontWeight: "400" }}>
                                          {program.description}
                                        </span>
                                      </span>
                                    </div>
                                  )}
                                  {program.studentNo && (
                                    <div
                                      className="cv-assist-cont"
                                      style={{ marginTop: 0 }}
                                    >
                                      <span
                                        className="cv-normal-text"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Student Number:
                                      </span>
                                      <span className="cv-normal-text">
                                        {program.studentNo}
                                      </span>
                                    </div>
                                  )}
                                  {linkVisaName && (
                                    <div className="linked-member-cont">
                                      <div className="button-first-second-row">
                                        <span
                                          className="linked-member-text"
                                          style={{ color: "#555555" }}
                                        >
                                          {linkVisaName}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div style={{ display: "flex" }}>
                                  <div
                                    className="cv-purpel-box"
                                    style={{
                                      backgroundColor:
                                        findStatus && findStatus.color,
                                      marginTop: 0,
                                    }}
                                  >
                                    <div className="cv-imm-cont">
                                      <span className="cv-imm-text">
                                        {findStatus && findStatus.name}
                                      </span>
                                    </div>
                                    {program.programStatus === 5 &&
                                    JSON.parse(
                                      decodeURIComponent(
                                        escape(
                                          window.atob(
                                            localStorage.getItem(
                                              "userSystemPermissions"
                                            )
                                          )
                                        )
                                      )
                                    ).find(
                                      (x) =>
                                        x.role.toLowerCase() ==
                                        "school management account"
                                    ).status == 1 ? (
                                      <div
                                        className="ad-date-row"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.setState({
                                            showCommission: true,
                                            checkIndex: index,
                                          })
                                        }
                                      >
                                        <span className="cv-imm-text">
                                          SCHEDULE COMMISSION
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="ad-date-row">
                                        <span className="ad-date-text">
                                          {moment(program.startDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </div>
                                    )}
                                    <div
                                      className="cv-icons-row"
                                      style={{
                                        marginTop: 10,
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.setState({
                                              linkMember: true,
                                              showProgramForm: false,
                                              checkIndex: index,
                                            })
                                          }
                                        >
                                          <img
                                            src={Images.multimedia}
                                            style={{ width: 15, height: 15 }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: 20,
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              showProgramForm: true,
                                              linkMember: false,
                                              checkIndex: index,
                                            })
                                          }
                                        >
                                          <DownOutlined
                                            style={{ color: "#FFFFFF" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="cv-delete-cont"
                                    style={{ marginTop: 0, cursor: "pointer" }}
                                    onClick={() =>
                                      this.onDeleteProgram(program)
                                    }
                                  >
                                    <img
                                      src={Images.deleteGray}
                                      className="cv-dlt-icon"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="cv-show-hide-cont"
                                style={{ marginTop: 10 }}
                              >
                                <div></div>
                                {(showProgramForm ||
                                  linkMember ||
                                  showCommission) &&
                                  checkIndex === index && (
                                    <div
                                      className="sus-show-btn"
                                      style={{ marginRight: 30 }}
                                      onClick={() =>
                                        this.setState({
                                          showProgramForm: false,
                                          showCommission: false,
                                          linkMember: false,
                                        })
                                      }
                                    >
                                      <span className="sus-save-btn-text">
                                        HIDE
                                      </span>
                                    </div>
                                  )}
                              </div>

                              {showProgramForm && checkIndex === index && (
                                <div key={index}>
                                  <AdmissionForm
                                    programData={program}
                                    visaAppData={visaAppData}
                                    onUpdAdmissionForm={onUpdAdmissionForm}
                                    onGetAdmissionProgram={
                                      onGetAdmissionProgram
                                    }
                                  />
                                </div>
                              )}

                              {linkMember && checkIndex === index && (
                                <div
                                  className="cv-lf-white-cont"
                                  style={{ paddingBottom: 20, height: "auto" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginTop: 20,
                                      marginLeft: 10,
                                    }}
                                  >
                                    <Radio.Group
                                      onChange={(e) =>
                                        this.onChangeRadio(e, program)
                                      }
                                      defaultValue={program.visaId}
                                    >
                                      {visaAppData &&
                                        visaAppData.items.map((visa, index) => {
                                          return (
                                            <Radio
                                              style={radioStyle}
                                              value={visa.id}
                                            >
                                              {visa.branchVisaTypeName}
                                            </Radio>
                                          );
                                        })}
                                    </Radio.Group>

                                    {program.visaId !== 0 && (
                                      <div
                                        className="button-blue-cont"
                                        style={{ width: 170, marginTop: 10 }}
                                      >
                                        <div
                                          className="ad-upd-button"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onRemoveLink(program)
                                          }
                                        >
                                          <span style={{ color: "#40B5CE" }}>
                                            Remove Visa Link
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    {/* disable static users */}
                                    {false && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: 15,
                                        }}
                                      >
                                        <div
                                          class="cm-profile-fill-cont"
                                          style={{ marginLeft: 20 }}
                                        >
                                          <img
                                            src={Images.personFill}
                                            class="cm-profile-fill"
                                          />
                                        </div>
                                        <div className="cv-lf-checkbox-label-cont">
                                          <span className="sus-checkbox-label">
                                            Sam Ban
                                          </span>
                                        </div>
                                        <div>
                                          <input
                                            type="checkbox"
                                            className="sus-checkbox"
                                            onChange={this.handleCheck}
                                            defaultChecked={this.state.checked}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {false && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: 15,
                                        }}
                                      >
                                        <div
                                          class="cm-profile-fill-cont"
                                          style={{ marginLeft: 20 }}
                                        >
                                          <img
                                            src={Images.personFill}
                                            class="cm-profile-fill"
                                          />
                                        </div>
                                        <div className="cv-lf-checkbox-label-cont">
                                          <span className="sus-checkbox-label">
                                            Lewis Jams
                                          </span>
                                        </div>
                                        <div>
                                          <input
                                            type="checkbox"
                                            className="sus-checkbox"
                                            onChange={this.handleCheck}
                                            defaultChecked={this.state.checked}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {showCommission && checkIndex === index && (
                                <ScheduleCommission
                                  toFee={program.fee}
                                  programData={program}
                                  onAddCommissionReminder={
                                    onAddCommissionReminder
                                  }
                                  onGetAdmissionProgram={onGetAdmissionProgram}
                                  commissionRemindersListing={
                                    commissionRemindersListing
                                  }
                                  getCommissionRemindersListing={
                                    getCommissionRemindersListing
                                  }
                                  onRemoveCommissionReminder={
                                    onRemoveCommissionReminder
                                  }
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                {/* {!expendedView && ( */}
                <div
                  className=""
                  style={{
                    width: activeKey ? "438px" : "115px",
                    marginLeft: 20,
                  }}
                >
                  {/* <span className="ad-right-sidebar-hide-text">HIDE</span> */}

                  <div style={{ width: "100%" }}>
                    <ProfileSideBar
                      onGetClientTag={this.props.onGetClientTag}
                      clientTagRes={this.props.clientTagRes}
                      getClientRes={this.props.getClientRes}
                      onGetProfileClientTag={this.props.onGetProfileClientTag}
                      onAddProfileClientTag={this.props.onAddProfileClientTag}
                      addProfielTagRes={this.props.addProfielTagRes}
                      onRemoveProfileClientTag={
                        this.props.onRemoveProfileClientTag
                      }
                      removeProfileTagRes={this.props.removeProfileTagRes}
                      // activeTabNewVisa={activeTabNewVisa}
                      // visaTypeData={countryVisaTypeData}
                      visaStatusData={visaStatusData}
                      onUpdateCaseStatus={onUpdateCaseStatus}
                      visaAppData={visaAppData}
                      onGetVisaApplication={onGetVisaApplication}
                      schoolTypesData={schoolTypesData}
                      onGetSchools={onGetSchools}
                      schoolsData={schoolsData}
                      onGetSchoolLevel={onGetSchoolLevel}
                      schoolLevelData={schoolLevelData}
                      onAddClientAdmission={onAddClientAdmission}
                      onGetAdmissionProgram={onGetAdmissionProgram}
                      onUpdAdmissionStatus={onUpdAdmissionStatus}
                      admissionStatusData={admissionStatusData}
                      admissionProgramData={admissionProgramData}
                      onSetActiveKey={onSetActiveKey}
                      activeKey={activeKey}
                      remindersRes={remindersRes && remindersRes.items}
                      onGetClientTask={this.props.onGetClientTask}
                      onUpdateTask={this.props.onUpdateTask}
                      onGetAllUsers={this.props.onGetAllUsers}
                      onAddTaskFollower={this.props.onAddTaskFollower}
                      onRemoveTasks={this.props.onRemoveTasks}
                      onAddDailyTasks={this.props.onAddDailyTasks}
                      onGetClientFamily={this.props.onGetClientFamily}
                      onUpdateCompletedTask={this.props.onUpdateCompletedTask}
                      onAddTaskFileNote={this.props.onAddTaskFileNote}
                      onAddTaskComment={this.props.onAddTaskComment}
                      onGetTaskComments={this.props.onGetTaskComments}
                      onGetTaskFollowers={this.props.onGetTaskFollowers}
                      onGetReminder={onGetReminder}
                      onGetVisaStatus={onGetVisaStatus}
                      onGetAdmissionStatuses={onGetAdmissionStatuses}
                    />
                  </div>
                </div>
                {/* )} */}
                {/* {expendedView && (
                <div className="right-nav-div">
                  <div
                    className="right-nav-contant"
                    style={{ cursor: "pointer" }}
                    onClick={this.showRightMenu}
                  >
                    <div>
                      <img src={rightNav} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Update Visa Status</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavTwo} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Update Admission</span>
                  </div>
                  <div
                    className="right-nav-contant"
                    style={{ cursor: "pointer" }}
                    onClick={this.showRightMenu}
                  >
                    <div>
                      <img src={rightNavThr} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">New Admission</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavThr} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Send SMS</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavFour} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Tasks</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavFiv} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Meetings</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavSix} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Reminders</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavSev} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Client Tags</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavEight} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Balance</span>
                  </div>
                  <div className="right-nav-contant">
                    <div>
                      <img src={rightNavNine} className="right-bar-icon" />
                    </div>
                    <span className="right-bar-text">Time Tracking</span>
                  </div>
                </div>
              )} */}
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default Admission;
