import React from "react";
import { Select, Button, Spin, Form, Modal, message, Row, Col } from "antd";

import QuestionnaireEmail from "./QuestionnaireEmail";
import FillQuestionRow from "./FillQuestionRow";
import UpdateQuestionnaire from "../../CustomQuestionnaire/UpdateQuestionnaire";
import { apiRefresh } from "../../services/api";
import moment from "moment";
import { saveAs } from "file-saver";
import axios from "axios";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const { Option } = Select;

// const headOption = [
//   {
//     tabName: "Client Profile",
//     imageName: Images.clientProfile,
//     linkName: "/profile"
//   },
//   { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
//   { tabName: "Admission", imageName: Images.admission, linkName: "/admission" },
//   { tabName: "Documents", imageName: Images.documents, linkName: "/documents" },
//   { tabName: "Email", imageName: Images.email, linkName: "" },
//   {
//     tabName: "Activities",
//     imageName: Images.activities,
//     linkName: "/activities"
//   },
//   {
//     tabName: "File Notes",
//     imageName: Images.documents,
//     linkName: "/file-notes"
//   },
//   {
//     tabName: "Accounts",
//     imageName: Images.accounts,
//     linkName: "/client-account"
//   },
//   {
//     tabName: "Open Case Management",
//     imageName: Images.caseManagement,
//     linkName: "case-management"
//   },
//   {
//     tabName: "Questionnaire",
//     imageName: Images.questionnare,
//     linkName: "/client-questionnaire"
//   },
//   {  tabName: "Chat", imageName: Images.supplier, linkName: "" },
//   { tabName: "Print Case", imageName: Images.print, linkName: "/print-case" }
// ];

var questionnaires = [];
var documentsArray = [];

var filledAns = [];
var postAns = [];
var answers = [];

var questionnaireIds = [
  1899,
  1901,
  1889,
  1890,
  1891,
  1892,
  1893,
  1894,
  1895,
  1896,
  1897,
  1898,
  2120,
  2133,
  2127,
  2125,
  2131,
  2128,
  2129,
  2130,
  2134,
  2123,
  2126,
  2439,
  2440,
  2441,
  2442,
  2443,
  2444,
  2445,
  2446,
  2447,
  2448,
  1759,
  1760,
  1761,
  1763,
  1764,
  1765,
  1766,
];
class ClientQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "questionnaire",
      extend: false,
      questionnaireArray: [],
      visible: false,
      loading: true,
      headerOptions: [],
      questionniareId: null,
      previewModal: false,
      visaFormArray: [],
      formName: "",
      questionnaireObj: null,
      activeIndex: 0,
      filledAllQuestionnaires: [],
      activeFillQuestionnaireIndex: null,
      groupedQuestionnaire: [],
      dynamicLinkState: null,
      isFilled: false,
      hostName: "",
      isSaveClicked: false,
      questionAnswers: [],
      postAnswers: [],
      groupId: 0,
      questionnaireName: "",
      attachmentsArray: [],
      clientEmail: "",
      visibleModal: false,
      visaTypeDataList: [],
    };
    // this.setState({ loading: true })

    this.getQuestionnaire();
  }

  componentDidMount() {}

  getQuestionnaire = () => {
    this.setState({ loading: false });
    this.props
      .onGetAllQuestionnaire()
      .then((res) => {})
      .catch((err) => {});
    this.props
      .onGetSignature()
      .then((res) => {})
      .catch((err) => {});
    this.props.onGetCountries();
    if (
      (this.props.isPotential || this.props.isEmployer) &&
      this.props.clientId != "00000000-0000-0000-0000-000000000000"
    ) {
      const getAttachmentOpt = {
        url: `v1/client/email/Subject/${this.props.clientId}`,
      };
      getAttachmentOpt.types = [
        "GET_ATTACHMENT_SUCCES",
        "GET_ATTACHMENT_FAILURE",
      ];

      apiRefresh
        .get(getAttachmentOpt)
        .then((res) => {
          this.setState({ clientEmail: res.email });
        })
        .catch((err) => {});
    }
    if (this.props.clientId)
      this.props
        .onGetAllFilledQuestionnaire(this.props.clientId)
        .then((res) => {
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    this.props
      .onGetQuestionnaireGroup()
      .then((res) => {})
      .catch((err) => {});
  };

  componentDidUpdate(prevProps) {
    if (this.props.tabKey) {
      if (prevProps.tabKey != this.props.tabKey && this.props.tabKey == "9") {
        this.getQuestionnaire();
      }
    }
    if (prevProps.dynamicLinkSuccess != this.props.dynamicLinkSuccess) {
      if (this.props.dynamicLinkSuccess) {
      }
    }
    if (this.props.visaTypeData && this.state.visaTypeDataList.length == 0) {
      let visa = [];
      this.props.visaTypeData.items.map((visaType, ind) => {
        visaType.id = visaType.id.toString();
        visa.push(visaType);
      });
      this.setState({ visaTypeDataList: visa });
    }
    if (
      prevProps.groupedQuestionnaireRes != this.props.groupedQuestionnaireRes
    ) {
      this.setState({
        groupedQuestionnaire: this.props.groupedQuestionnaireRes,
      });
    }
    if (
      prevProps.allFilledQuestionnaireRes !=
      this.props.allFilledQuestionnaireRes
    ) {
      var allFilledOptions = [];
      for (var i = 0; i < this.props.allFilledQuestionnaireRes.length; i++) {
        allFilledOptions.push(
          <Option key={this.props.allFilledQuestionnaireRes[i].questionnaireId}>
            {this.props.allFilledQuestionnaireRes[i].questionnaireName}
          </Option>
        );
      }
      this.setState({ filledAllQuestionnaires: allFilledOptions });
    }
    if (prevProps.filledQuestionnaireRes != this.props.filledQuestionnaireRes) {
      var questionnaireObject = null;
      if (this.props.filledQuestionnaireRes) {
        questionnaireObject = this.addMomentOnDates(
          this.props.filledQuestionnaireRes
        );
        this.setState({
          questionnaireObj: questionnaireObject,
          isFilled: true,
        });
      }
    }
    if (prevProps.questionnaireData != this.props.questionnaireData) {
      if (this.props.questionnaireData) {
        this.setState({ questionnaireObj: this.props.questionnaireData });
      }
    }
    if (prevProps.questionnaireGroupRes != this.props.questionnaireGroupRes) {
      var visaForms = [];
      for (var i = 0; i < this.props.questionnaireGroupRes.length; i++) {
        visaForms.push(
          <Option key={this.props.questionnaireGroupRes[i].id}>
            {this.props.questionnaireGroupRes[i].name}
          </Option>
        );
      }
      this.setState({ visaFormArray: visaForms });
    }
    if (prevProps.questionnaireList !== this.props.questionnaireList) {
      // this.setState({ questionnaireArray: [] });
      questionnaires = [];

      for (var i = 0; i < this.props.questionnaireList.length; i++) {
        if (this.props.isPotential) {
          if (this.props.questionnaireList[i].showInPotential) {
            questionnaires.push(
              <Option key={this.props.questionnaireList[i].questionnaireId}>
                {this.props.questionnaireList[i].name}
              </Option>
            );
          }
        } else if (this.props.isEmployer) {
          if (this.props.questionnaireList[i].showInEmployer) {
            questionnaires.push(
              <Option key={this.props.questionnaireList[i].questionnaireId}>
                {this.props.questionnaireList[i].name}
              </Option>
            );
          }
        }
        // {
        //   questionnaires.push(
        //     <Option key={this.props.questionnaireList[i].questionnaireId}>
        //       {this.props.questionnaireList[i].name}
        //     </Option>
        //   );
        // }
      }
      this.setState({ questionnaireArray: questionnaires });
    }
  }

  addMomentOnDates = (questionnaire) => {
    if (questionnaire.sections) {
      for (var i = 0; i < questionnaire.sections.length; i++) {
        if (questionnaire.sections[i].questions) {
          for (var j = 0; j < questionnaire.sections[i].questions.length; j++) {
            if (
              questionnaire.sections[i].questions[j].questionOptions &&
              questionnaire.sections[i].questions[j].questionOptions.length > 0
            ) {
              this.addDateOnOptQuestions(
                questionnaire.sections[i].questions[j].questionOptions
              );
            }
            if (
              questionnaire.sections[i].questions[j].answers &&
              questionnaire.sections[i].questions[j].answers.length > 0
            ) {
              for (
                var k = 0;
                k < questionnaire.sections[i].questions[j].answers.length;
                k++
              ) {
                if (
                  questionnaire.sections[i].questions[j].answerType == 2 ||
                  questionnaire.sections[i].questions[j].answerType == 6
                ) {
                  var isValidDate = false;
                  var varType = typeof questionnaire.sections[i].questions[j]
                    .answers[k].answer;
                  if (
                    questionnaire.sections[i].questions[j].answers[k].answer &&
                    varType == "string"
                  ) {
                    isValidDate = moment(
                      questionnaire.sections[i].questions[j].answers[k].answer,
                      "DD/MM/YYYY",
                      true
                    ).isValid();
                    if (isValidDate) {
                      var parts = questionnaire.sections[i].questions[
                        j
                      ].answers[k].answer.split("/");
                      var day = parseInt(parts[0], 10);
                      var month = parseInt(parts[1], 10);
                      var year = parseInt(parts[2], 10);
                      questionnaire.sections[i].questions[j].answers[
                        k
                      ].answer = `${year}/${month}/${day}`;
                    }
                  }
                  if (questionnaire.sections[i].questions[j].answerType == 2) {
                    if (
                      questionnaire.sections[i].questions[j].answers[k]
                        .answer == "" ||
                      moment(
                        questionnaire.sections[i].questions[j].answers[k].answer
                      ).format("YYYY-MM-DD") == "1900-01-01"
                    ) {
                      questionnaire.sections[i].questions[j].answers[k].answer =
                        "";
                    } else {
                      questionnaire.sections[i].questions[j].answers[
                        k
                      ].answer = moment(
                        questionnaire.sections[i].questions[j].answers[k].answer
                      );
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return questionnaire;
  };

  addDateOnOptQuestions = (options) => {
    for (var x = 0; x < options.length; x++) {
      if (
        options[x].optionalQuestions &&
        options[x].optionalQuestions.length > 0
      ) {
        for (var i = 0; i < options[x].optionalQuestions.length; i++) {
          if (
            options[x].optionalQuestions[i].questions &&
            options[x].optionalQuestions[i].questions.length > 0
          ) {
            for (
              var j = 0;
              j < options[x].optionalQuestions[i].questions.length;
              j++
            ) {
              if (
                options[x].optionalQuestions[i].questions[j].questionOptions &&
                options[x].optionalQuestions[i].questions[j].questionOptions
                  .length > 0
              ) {
                this.addDateOnOptQuestions(
                  options[x].optionalQuestions[i].questions[j].questionOptions
                );
              }
              if (
                options[x].optionalQuestions[i].questions[j].answers &&
                options[x].optionalQuestions[i].questions[j].answers.length > 0
              ) {
                for (
                  var k = 0;
                  k <
                  options[x].optionalQuestions[i].questions[j].answers.length;
                  k++
                ) {
                  if (
                    options[x].optionalQuestions[i].questions[j].answerType ==
                      2 ||
                    options[x].optionalQuestions[i].questions[j].answerType == 6
                  ) {
                    var isValidDate = false;
                    var varType = typeof options[x].optionalQuestions[i]
                      .questions[j].answers[k].answer;
                    if (
                      options[x].optionalQuestions[i].questions[j].answers[k]
                        .answer &&
                      varType == "string"
                    ) {
                      isValidDate = moment(
                        options[x].optionalQuestions[i].questions[j].answers[k]
                          .answer,
                        "DD/MM/YYYY",
                        true
                      ).isValid();
                      if (isValidDate) {
                        var parts = options[x].optionalQuestions[i].questions[
                          j
                        ].answers[k].answer.split("/");
                        var day = parseInt(parts[0], 10);
                        var month = parseInt(parts[1], 10);
                        var year = parseInt(parts[2], 10);
                        options[x].optionalQuestions[i].questions[j].answers[
                          k
                        ].answer = `${year}/${month}/${day}`;
                      }
                    }
                    if (
                      options[x].optionalQuestions[i].questions[j].answers[k]
                        .answer == "" ||
                      moment(
                        options[x].optionalQuestions[i].questions[j].answers[k]
                          .answer
                      ).format("YYYY-MM-DD") == "1900-01-01"
                    ) {
                      options[x].optionalQuestions[i].questions[j].answers[
                        k
                      ].answer = "";
                    } else {
                      options[x].optionalQuestions[i].questions[j].answers[
                        k
                      ].answer = moment(
                        options[x].optionalQuestions[i].questions[j].answers[k]
                          .answer
                      );
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  getAttachments = (filledId) => {
    const getAttachmentOpt = {
      url: `v1/questionnaire/GetAttachments/${filledId}`,
    };
    getAttachmentOpt.types = [
      "GET_ATTACHMENT_SUCCES",
      "GET_ATTACHMENT_FAILURE",
    ];

    apiRefresh
      .get(getAttachmentOpt)
      .then((res) => {
        this.setState({ attachmentsArray: res });
      })
      .catch((err) => {
        this.setState({ attachmentsArray: [] });
      });
  };

  onChange = (value) => {
    var userId = localStorage.getItem("userId");
    this.setState({
      loading: true,
      questionnaireId: value,
      isGrouped: null,
      isVisaForm: true,
    });
    var findQuestionnaire = this.props.questionnaireList.find(
      (obj) => obj.questionnaireId == value
    );
    if (findQuestionnaire) {
      this.setState({ questionnaireName: findQuestionnaire.name });
    }
    console.log(`selected ${value}`);
    let questionnaireData = {
      clientId: this.props.clientId,
      questionnaireId: parseInt(value),
      isGrouped: false,
      groupId: 0,
      branchId: localStorage.getItem("selectedBranchId"),
      isPotential: this.props.isPotential,
      isEmployer: this.props.isEmployer,
      userId: localStorage.getItem("userId"),
    };
    this.props
      .onCreateDynamicLink(questionnaireData)
      .then((res) => {
        // this.setState({
        //   dynamicLinkState: this.props.dynamicLinkSuccess,
        // });
        var name =
          window.location.host == "localhost:3000" ? "http://" : "https://";
        name += window.location.host;
        this.setState({
          dynamicLinkState: this.props.dynamicLinkSuccess,
          hostName: name,
        });
        var http =
          window.location.host == "localhost:3000" ? "http://" : "https://";
        let data = {
          templateName: "clientquestionnaire",
          parameters: [
            {
              key: "@ClientId",
              value: this.props.clientId,
            },
            {
              key: "@Content",
              value: "Questionnaire",
            },
            {
              key: "@UserId",
              value: userId,
            },
            {
              key: "ALink",
              value:
                http +
                window.location.host +
                "/c_q?para=" +
                this.props.dynamicLinkSuccess,
            },
          ],
        };
        this.props
          .onSetEmailTemplate(data)
          .then((res) => {
            this.setState({ loading: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
    let data = {
      id: value,
      clientId: this.props.clientId,
      // clientId: "00000000-0000-0000-0000-000000000000",
    };
    this.props
      .onGetFilledQuestionnaire(data)
      .then((res) => {
        this.setState({ loading: false, isFilled: true });
        var filledQuestionnaireId = 0;
        for (var i = 0; i < res.payload.sections.length; i++) {
          for (var j = 0; j < res.payload.sections[i].questions.length; j++) {
            filledQuestionnaireId =
              res.payload.sections[i].questions[j].answers[0]
                .filledQuestionnaireId;
            break;
          }
          if (filledQuestionnaireId != 0) {
            break;
          }
        }
        this.getAttachments(filledQuestionnaireId);
      })
      .catch((err) => {
        this.setState({ questionnaireObj: null });
        if (
          err.payload &&
          err.payload.response &&
          err.payload.response.status == 404
        ) {
        }
      });

    // ============
    // let data = {
    //   clientId: clientprofileid,
    //   branchId: selectedBranchId,
    //   questionnaireId: parseInt(value),
    // };

    // this.props
    //   .onCreateDynamicLink(data)
    //   .then((res) => {
    //     var http =
    //       window.location.host == "localhost:3000" ? "http://" : "https://";
    //     let data = {
    //       templateName: "clientquestionnaire",
    //       parameters: [
    //         {
    //           key: "@ClientId",
    //           value: clientprofileid,
    //         },
    //         {
    //           key: "@Content",
    //           value: "Questionnaire",
    //         },
    //         {
    //           key: "@UserId",
    //           value: userId,
    //         },
    //         {
    //           key: "ALink",
    //           value:
    //             http +
    //             window.location.host +
    //             "/c_q?para=" +
    //             this.props.dynamicLinkSuccess,
    //         },
    //       ],
    //     };
    //     this.props
    //       .onSetEmailTemplate(data)
    //       .then((res) => {
    //         this.setState({ loading: false });
    //       })
    //       .catch((err) => {
    //         this.setState({ loading: false });
    //       });
    //   })
    //   .catch((err) => {
    //     this.setState({ loading: false });
    //   });
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeTab = (value) => {
    this.setState({ activeTab: value });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  openQuestionnaire = () => {
    window.open("c_q?para=" + this.props.dynamicLinkSuccess, "_blank");
  };

  handleOk = () => {};

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCancelPreview = () => this.setState({ previewModal: false });

  confirmLoading = () => {};

  onChangeVisaForm = (val) => {
    let userId = localStorage.getItem("userId");
    this.setState({ loading: true, isVisaForm: false });
    var findForm = this.props.questionnaireGroupRes.find(
      (obj) => obj.id == val
    );
    if (findForm) {
      this.setState({ formName: findForm.name });
    }
    let questionnaireData = {
      clientId: this.props.clientId,
      branchId: "00000000-0000-0000-0000-000000000000",
      questionnaireId: 0,
      isGrouped: true,
      groupId: parseInt(val),
      branchId: localStorage.getItem("selectedBranchId"),
      isPotential: this.props.isPotential,
      isEmployer: this.props.isEmployer,
      userId: localStorage.getItem("userId"),
    };
    this.props
      .onCreateDynamicLink(questionnaireData)
      .then((res) => {
        this.setState({
          questionnaireObj: null,
          // dynamicLinkState: this.props.dynamicLinkSuccess,
        });

        var http =
          window.location.host == "localhost:3000" ? "http://" : "https://";
        var name =
          window.location.host == "localhost:3000" ? "http://" : "https://";
        name += window.location.host;
        this.setState({
          dynamicLinkState: this.props.dynamicLinkSuccess,
          hostName: name,
        });
        let data = {
          templateName: "clientquestionnaire",
          parameters: [
            {
              key: "@ClientId",
              value: this.props.clientId,
            },
            {
              key: "@Content",
              value: "Questionnaire",
            },
            {
              key: "@UserId",
              value: userId,
            },
            {
              key: "ALink",
              value:
                http +
                window.location.host +
                "/c_q?para=" +
                this.props.dynamicLinkSuccess,
            },
          ],
        };
        this.props
          .onSetEmailTemplate(data)
          .then((res) => {
            this.setState({ loading: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
    this.props
      .onGetGroupedQuestionnaire(val)
      .then((res) => {
        this.getQuestionnaires(res.payload[0].id, 0);
        // this.setState({ loading: false });
        this.setState({ isGrouped: val });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onChangeCurrentQuestionnaire = (val) => {
    this.setState({ loading: true });
    let userId = localStorage.getItem("userId");
    var findQuestionnaire = this.props.questionnaireList.find(
      (obj) => obj.questionnaireId == val
    );
    var name = "";
    if (val == "1819") {
      name = "WEB INQUIRY (DETAIL)";
    } else if (val == "1820") {
      name = "WEB INQUIRY (BASIC)";
    } else if (val == "1821") {
      name = "WEB ASSESMENT";
    } else if (val == "1836") {
      name = "GENERAL INFORMATION";
    } else if (val == "1838") {
      name = "BASIC FORM";
    }

    this.setState({ questionnaireName: name });
    let questionnaireData = {
      clientId: this.props.clientId,
      branchId: "00000000-0000-0000-0000-000000000000",
      questionnaireId: parseInt(val),
      isGrouped: false,
      groupId: 0,
      branchId: localStorage.getItem("selectedBranchId"),
      isPotential: this.props.isPotential,
      isEmployer: this.props.isEmployer,
      userId: localStorage.getItem("userId"),
    };
    let data = {
      id: val,
      clientId: this.props.clientId,
      // clientId: "00000000-0000-0000-0000-000000000000",
    };
    this.props
      .onGetFilledQuestionnaire(data)
      .then((res) => {
        this.setState({ loading: false, isFilled: true, isGrouped: null });
        var filledQuestionnaireId = 0;
        for (var i = 0; i < res.payload.sections.length; i++) {
          for (var j = 0; j < res.payload.sections[i].questions.length; j++) {
            filledQuestionnaireId =
              res.payload.sections[i].questions[j].answers[0]
                .filledQuestionnaireId;
            break;
          }
          if (filledQuestionnaireId != 0) {
            break;
          }
        }
        this.getAttachments(filledQuestionnaireId);
      })
      .catch((err) => {
        this.setState({
          questionnaireObj: null,
          isFilled: false,
          isGrouped: null,
        });
        if (
          err.payload &&
          err.payload.response &&
          err.payload.response.status == 404
        ) {
          this.props
            .onCreateDynamicLink(questionnaireData)
            .then((res) => {
              var name =
                window.location.host == "localhost:3000"
                  ? "http://"
                  : "https://";
              name += window.location.host;
              this.setState({
                dynamicLinkState: this.props.dynamicLinkSuccess,
                hostName: name,
                questionnaireObj: null,
              });

              var http =
                window.location.host == "localhost:3000"
                  ? "http://"
                  : "https://";
              let data = {
                templateName: "clientquestionnaire",
                parameters: [
                  {
                    key: "@ClientId",
                    value: this.props.clientId,
                  },
                  {
                    key: "@Content",
                    value: "Questionnaire",
                  },
                  {
                    key: "@UserId",
                    value: userId,
                  },
                  {
                    key: "ALink",
                    value:
                      http +
                      window.location.host +
                      "/c_q?para=" +
                      this.props.dynamicLinkSuccess,
                  },
                ],
              };
              this.props
                .onSetEmailTemplate(data)
                .then((res) => {
                  this.setState({ loading: false });
                })
                .catch((err) => {
                  this.setState({ loading: false });
                });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        }
      });
  };

  onChangeFiiledQuestionnaire = (val, index) => {
    this.setState({ loading: true, questionnaireObj: null });
    let userId = localStorage.getItem("userId");
    let questionnaireData = {
      clientId: this.props.clientId,
      branchId: "00000000-0000-0000-0000-000000000000",
      questionnaireId: parseInt(val),
      isGrouped: false,
      groupId: 0,
      branchId: localStorage.getItem("selectedBranchId"),
      isPotential: this.props.isPotential,
      isEmployer: this.props.isEmployer,
      userId: localStorage.getItem("userId"),
    };
    let data = {
      id: val,
      clientId: this.props.clientId,
      // clientId: "00000000-0000-0000-0000-000000000000",
    };
    this.props
      .onGetFilledQuestionnaire(data)
      .then((res) => {
        this.setState({
          activeFillQuestionnaireIndex: index,
          isFilled: true,
          questionnaireName: res.payload.name,
        });
        var filledQuestionnaireId = 0;
        for (var i = 0; i < res.payload.sections.length; i++) {
          for (var j = 0; j < res.payload.sections[i].questions.length; j++) {
            filledQuestionnaireId = res.payload.sections[i].questions[j]
              .answers[0]
              ? res.payload.sections[i].questions[j].answers[0]
                  .filledQuestionnaireId
              : 0;
            if (filledQuestionnaireId != 0) break;
          }
          if (filledQuestionnaireId != 0) {
            break;
          }
        }
        this.getAttachments(filledQuestionnaireId);
        this.getAnswersArray(res.payload);

        this.props
          .onCreateDynamicLink(questionnaireData)
          .then((res) => {
            var name =
              window.location.host == "localhost:3000" ? "http://" : "https://";
            name += window.location.host;
            this.setState({
              dynamicLinkState: this.props.dynamicLinkSuccess,
              hostName: name,
            });

            var http =
              window.location.host == "localhost:3000" ? "http://" : "https://";
            let data = {
              templateName: "clientquestionnaire",
              parameters: [
                {
                  key: "@ClientId",
                  value: this.props.clientId,
                },
                {
                  key: "@Content",
                  value: "Questionnaire",
                },
                {
                  key: "@UserId",
                  value: userId,
                },
                {
                  key: "ALink",
                  value:
                    http +
                    window.location.host +
                    "/c_q?para=" +
                    this.props.dynamicLinkSuccess,
                },
              ],
            };
            this.props
              .onSetEmailTemplate(data)
              .then((res) => {
                this.setState({ loading: false });
              })
              .catch((err) => {
                this.setState({ loading: false });
              });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
        // this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ questionnaireObj: null, isFilled: false });
        if (
          err.payload &&
          err.payload.response &&
          err.payload.response.status == 404
        ) {
          this.props
            .onCreateDynamicLink(questionnaireData)
            .then((res) => {
              var name =
                window.location.host == "localhost:3000"
                  ? "http://"
                  : "https://";
              name += window.location.host;
              this.setState({
                dynamicLinkState: this.props.dynamicLinkSuccess,
                hostName: name,
              });
              var http =
                window.location.host == "localhost:3000"
                  ? "http://"
                  : "https://";
              let data = {
                templateName: "clientquestionnaire",
                parameters: [
                  {
                    key: "@ClientId",
                    value: this.props.clientId,
                  },
                  {
                    key: "@Content",
                    value: "Questionnaire",
                  },
                  {
                    key: "@UserId",
                    value: userId,
                  },
                  {
                    key: "ALink",
                    value:
                      http +
                      window.location.host +
                      "/c_q?para=" +
                      this.props.dynamicLinkSuccess,
                  },
                ],
              };
              this.props
                .onSetEmailTemplate(data)
                .then((res) => {
                  this.setState({ loading: false });
                })
                .catch((err) => {
                  this.setState({ loading: false });
                });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        }
      });
  };

  getQuestionnaires = (id, index) => {
    this.setState({
      loading: true,
      activeIndex: index,
      questionnaireObj: null,
    });
    let data = {
      id: id,
      clientId: this.props.clientId,
      // clientId: "00000000-0000-0000-0000-000000000000",
    };
    this.props
      .onGetFilledQuestionnaire(data)
      .then((res) => {
        this.setState({ loading: false, isFilled: true });
        var filledQuestionnaireId = 0;
        for (var i = 0; i < res.payload.sections.length; i++) {
          for (var j = 0; j < res.payload.sections[i].questions.length; j++) {
            filledQuestionnaireId =
              res.payload.sections[i].questions[j].answers[0]
                .filledQuestionnaireId;
            break;
          }
          if (filledQuestionnaireId != 0) {
            break;
          }
        }
        this.getAttachments(filledQuestionnaireId);
      })
      .catch((err) => {
        this.setState({ questionnaireObj: null });
        if (
          err.payload &&
          err.payload.response &&
          err.payload.response.status == 404
        ) {
          this.props
            .onGetQuestionnaire(id)
            .then((resp) => {
              this.setState({ loading: false, isFilled: false });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        }
      });
  };

  sendForm = () => {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let data = {
      clientId: this.props.clientId,
      branchId: selectedBranchId,
      questionnaireGroupId: parseInt(this.state.isGrouped),
    };
    this.setState({ loading: true });
    const cmvFaqOpt = {
      url: `v1/questionnaire/ElasticsearchQuestionnaire`,
    };
    cmvFaqOpt.types = ["SEND_FORM_SUCCESS", "SEND_FORM_FAILURE"];

    apiRefresh
      .post(cmvFaqOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        message.success("Form is available on Ezyform chrome extension!");
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Failed!");
      });
  };

  onRecursiveOptions = (options) => {
    let answers = [];
    let filledAnswers = [];
    let postAnswers = [];
    for (var k = 0; k < options.length; k++) {
      if (options[k].optionalQuestions) {
        for (var l = 0; l < options[k].optionalQuestions.length; l++) {
          for (
            var m = 0;
            m < options[k].optionalQuestions[l].questions.length;
            m++
          ) {
            if (
              options[k].optionalQuestions[l].questions[m].questionOptions &&
              options[k].optionalQuestions[l].questions[m].questionOptions
                .length > 0
            ) {
              this.onRecursiveOptions(
                options[k].optionalQuestions[l].questions[m].questionOptions
              );
            }
            if (
              options[k].optionalQuestions[l].questions[m].answers &&
              options[k].optionalQuestions[l].questions[m].answers.length > 0
            ) {
              filledAnswers = [...filledAns];
              postAnswers = [...postAns];
              // filledAnswers = [...this.state.questionAnswers];
              // postAnswers = [...this.state.postAnswers];
              for (
                var n = 0;
                n < options[k].optionalQuestions[l].questions[m].answers.length;
                n++
              ) {
                if (
                  options[k].optionalQuestions[l].questions[m].answers[n]
                    .answerType == 8
                ) {
                  if (
                    options[k].optionalQuestions[l].questions[m].answers[n]
                      .answer &&
                    options[k].optionalQuestions[l].questions[m].answers[n]
                      .answer != "Check file in attachments"
                  ) {
                    documentsArray.push(
                      options[k].optionalQuestions[l].questions[m].answers[n]
                        .answer
                    );
                    // this.uploadDocument(
                    //   options[k].optionalQuestions[l].questions[m].answers[n].answer
                    // );
                  }
                  filledAnswers.push({
                    id: this.state.isFilled
                      ? options[k].optionalQuestions[l].questions[m].answers[n]
                          .id
                      : 0,
                    filledQuestionnaireId: this.state.isFilled
                      ? options[k].optionalQuestions[l].questions[m].answers[n]
                          .filledQuestionnaireId
                      : 0,
                    questionId: options[k].optionalQuestions[l].questions[m].id,
                    answer: options[k].optionalQuestions[l].questions[m]
                      .answers[n].answer
                      ? "Check file in attachments"
                      : "",
                    position: options[k].optionalQuestions[l].questions[m]
                      .answers[n].position
                      ? parseInt(
                          options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].position
                        )
                      : 0,
                    count: 0,
                  });
                }
                var date = "1900-01-01T00:00:00.000Z";
                if (
                  (options[k].optionalQuestions[l].questions[m].answerType ==
                    2 ||
                    options[k].optionalQuestions[l].questions[m].answerType ==
                      6) &&
                  options[k].optionalQuestions[l].questions[m].answers[n] &&
                  options[k].optionalQuestions[l].questions[m].answers[n].answer
                ) {
                  date = moment(
                    options[k].optionalQuestions[l].questions[m].answers[n]
                      .answer
                  ).format("YYYY-MM-DDT00:00:00+00:00");
                }
                if (options[k].optionalQuestions[l].questions[m].answers[n]) {
                  filledAnswers.push({
                    id:
                      options[k].optionalQuestions[l].questions[m].answers[n]
                        .id,
                    questionnaireId: this.state.questionnaireObj
                      .questionnaireId,
                    questionId: options[k].optionalQuestions[l].questions[m].id,
                    filledQuestionnaireId: this.state.isFilled
                      ? (this.state.questionnaireObj &&
                          this.state.questionnaireObj.filledQuestionnaireId) ||
                        0
                      : 0,
                    answer:
                      date == "1900-01-01T00:00:00.000Z" &&
                      (options[k].optionalQuestions[l].questions[m]
                        .answerType == 2 ||
                        options[k].optionalQuestions[l].questions[m]
                          .answerType == 6)
                        ? date
                        : options[k].optionalQuestions[l].questions[m]
                            .answerType == 2 ||
                          options[k].optionalQuestions[l].questions[m]
                            .answerType == 6
                        ? moment(
                            options[k].optionalQuestions[l].questions[m]
                              .answers[n].answer
                          ).format("YYYY-MM-DDT00:00:00+00:00")
                        : options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ] &&
                          options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].answer
                        ? options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].answer.toString()
                        : "",
                    position: options[k].optionalQuestions[l].questions[m]
                      .position
                      ? parseInt(
                          options[k].optionalQuestions[l].questions[m].position
                        )
                      : 0,
                    count: 0,
                  });
                } else {
                  filledAnswers.push({
                    id: 0,
                    questionnaireId: this.state.questionnaireObj
                      .questionnaireId,
                    questionId: options[k].optionalQuestions[l].questions[m].id,
                    filledQuestionnaireId: this.state.isFilled
                      ? (this.state.questionnaireObj &&
                          this.state.questionnaireObj.filledQuestionnaireId) ||
                        0
                      : 0,
                    answer:
                      (options[k].optionalQuestions[l].questions[m]
                        .answerType === 2 ||
                        options[k].optionalQuestions[l].questions[m]
                          .answerType === 6) &&
                      date == "1900-01-01T00:00:00.000Z"
                        ? date
                        : "",
                    position: options[k].optionalQuestions[l].questions[m]
                      .position
                      ? parseInt(
                          options[k].optionalQuestions[l].questions[m].position
                        )
                      : 0,
                    count: 0,
                  });
                }
              }
              postAnswers.push({ filledAnswers: filledAnswers });
              // this.setState({
              //   questionAnswers: filledAnswers,
              //   postAnswers: postAnswers,
              // });
              filledAns = filledAnswers;
              postAns = postAnswers;
              // answers.push(filledAnswers)
              // filledAnswers = [];
              // postAnswers = [];
            }
          }
        }
      }
    }
    // return answers;
  };

  onFinish = (values) => {
    console.log(values);
    this.setState({ isSaveClicked: true });
    var filledAnswers = [];
    var answers = [];
    let postAnswers = [];
    let postAnswersFilled = [];
    let answerArray = [];
    var userId = localStorage.getItem("userId");
    if (values.sections && values.sections.length > 0) {
      for (var i = 0; i < values.sections.length; i++) {
        if (values.sections[i].questions) {
          for (var j = 0; j < values.sections[i].questions.length; j++) {
            if (
              values.sections[i].questions[j].questionOptions &&
              values.sections[i].questions[j].questionOptions.length > 0
            ) {
              filledAnswers = this.onRecursiveOptions(
                values.sections[i].questions[j].questionOptions
              );
            }
            console.log("Answers", this.state.questionAnswers);
            filledAnswers = [...filledAns];
            answerArray = [...postAns];
            if (
              values.sections[i].questions[j].answers &&
              values.sections[i].questions[j].answers.length > 0
            ) {
              // isFilled = this.state.questionnaireObj.sections[i]
              for (
                var k = 0;
                k < values.sections[i].questions[j].answers.length;
                k++
              ) {
                var date = "1900-01-01T00:00:00.000Z";

                if (
                  (values.sections[i].questions[j].answerType == 2 ||
                    values.sections[i].questions[j].answerType == 6) &&
                  values.sections[i].questions[j].answers[k].answer
                ) {
                  date = moment(
                    values.sections[i].questions[j].answers[k].answer
                  ).format("YYYY-MM-DDT00:00:00+00:00");
                }
                if (values.sections[i].questions[j].answerType == 8) {
                  if (
                    values.sections[i].questions[j].answers[k].answer &&
                    values.sections[i].questions[j].answers[k].answer !=
                      "Check file in attachments"
                  ) {
                    documentsArray.push(
                      values.sections[i].questions[j].answers[k].answer
                    );
                    // this.uploadDocument(
                    //   values.sections[i].questions[j].answers[k].answer
                    // );
                  }
                  filledAnswers.push({
                    id: this.state.isFilled
                      ? values.sections[i].questions[j].answers[k].id
                      : 0,
                    filledQuestionnaireId: this.state.isFilled
                      ? values.sections[i].questions[j].answers[k]
                          .filledQuestionnaireId
                      : 0,
                    questionId: values.sections[i].questions[j].id,
                    answer: values.sections[i].questions[j].answers[k].answer
                      ? "Check file in attachments"
                      : "",
                    position: values.sections[i].questions[j].answers[k]
                      .position
                      ? parseInt(
                          values.sections[i].questions[j].answers[k].position
                        )
                      : 0,
                    count: 0,
                  });
                } else if (
                  values.sections[i].questions[j].answerType !== 0 &&
                  values.sections[i].questions[j].answers[k]
                ) {
                  filledAnswers.push({
                    id: values.sections[i].questions[j].answers[k].id,
                    filledQuestionnaireId: this.state.questionnaireObj
                      .questionnaireId,
                    questionId: values.sections[i].questions[j].id,
                    filledQuestionnaireId: this.state.isFilled
                      ? (this.state.questionnaireObj &&
                          this.state.questionnaireObj.filledQuestionnaireId) ||
                        0
                      : 0,
                    answer:
                      date == "1900-01-01T00:00:00.000Z" &&
                      (values.sections[i].questions[j].answerType == 2 ||
                        values.sections[i].questions[j].answerType == 6)
                        ? date
                        : values.sections[i].questions[j].answerType == 2 ||
                          values.sections[i].questions[j].answerType == 6
                        ? moment(
                            values.sections[i].questions[j].answers[k].answer
                          ).format("YYYY-MM-DDT00:00:00+00:00")
                        : values.sections[i].questions[j].answers[k] &&
                          values.sections[i].questions[j].answers[k].answer
                        ? values.sections[i].questions[j].answers[
                            k
                          ].answer.toString()
                        : "",
                    position: values.sections[i].questions[j].answers[k]
                      .position
                      ? parseInt(
                          values.sections[i].questions[j].answers[k].position
                        )
                      : 0,
                    count: 0,
                  });
                  answerArray.push({
                    filledAnswers: {
                      filledQuestionnaireId: this.state.questionnaireObj
                        .filledQuestionnaireId,
                      questionId: values.sections[i].questions[j].id,
                      answer: values.sections[i].questions[j].answers[k].answer
                        ? values.sections[i].questions[j].answers[
                            k
                          ].answer.toString()
                        : "",
                      position: values.sections[i].questions[j].answers[k]
                        .position
                        ? parseInt(
                            values.sections[i].questions[j].answers[k].position
                          )
                        : 0,
                      count: 0,
                    },
                  });
                } else {
                  filledAnswers.push({
                    id: 0,
                    filledQuestionnaireId: this.state.questionnaireObj
                      .filledQuestionnaireId,
                    questionId: values.sections[i].questions[j].id,
                    answer:
                      (values.sections[i].questions[j].answerType == 2 ||
                        values.sections[i].questions[j].answerType == 6) &&
                      date == "1900-01-01T00:00:00.000Z"
                        ? date
                        : "",
                    position: values.sections[i].questions[j].answers[k]
                      .position
                      ? parseInt(
                          values.sections[i].questions[j].answers[k].position
                        )
                      : 0,
                    count: 0,
                  });
                }
              }
              if (filledAnswers.length > 0) {
                for (var ans = 0; ans < filledAnswers.length; ans++) {
                  answers.push(filledAnswers[ans]);
                }
              }
              postAnswersFilled.push(answerArray);

              this.setState({ questionAnswers: [], postAnswers: [] });
              filledAnswers = [];
              answerArray = [];
            }
          }
        }
      }
    }
    // let data = {
    //   name: values.questionnaireName,
    //   branchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   allowAttachments: values.allowAttachments,
    //   deceleration: values.allowDecelration,
    //   showInPotential: true,
    //   showInClient: true,
    //   showInPublic: true,
    //   questionnaireEmail: "string",
    //   declarationText: "string",
    //   clientId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   parentQuestionnaireId: 0,
    //   isPotential: true,
    //   createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   sections: { ...values.sections }
    // };

    var userId = localStorage.getItem("userId");
    this.setState({ loading: true });
    let data = {
      id: 0,
      filledQuestionnaireId: 0,
      questionnaireId: this.state.questionnaireObj.questionnaireId,
      clientId: this.state.clientIdState,
      isPotential: this.state.questionnaireObj.isPotential,
      isEmployer: this.state.questionnaireObj.isEmployer,
      isPublic: this.state.questionnaireObj.showInPublic,
      clientName: values.fullName,
      createdBy: this.state.questionnaireObj.createdBy,
      answers: answers,
    };
    if (this.state.isFilled) {
      const VisaOpt = {
        url: `v1/questionnairefilledanswer`,
      };
      VisaOpt.types = [
        "UPDATE_SUBMIT_QUESTIONNAIRE_SUCCES",
        "UPDATE_SUBMIT_QUESTIONNAIRE_FAILURE",
      ];

      apiRefresh
        .put(VisaOpt, answers)
        .then((res) => {
          message.success("Questionnaire answers submited successfully");
          let data = {
            clientId: this.props.clientId,
            branchId: localStorage.getItem("selectedBranchId"),
            head: this.state.questionnaireObj.name,
            companyName: localStorage.getItem("selectedBranchName") || "",
            questionnaireName: this.state.questionnaireObj.name,
            id: this.state.questionnaireObj.questionnaireId,
            userId: localStorage.getItem("userId"),
            groupId: 0,
            filledAnsId: this.state.isFilled
              ? this.state.questionnaireObj.filledQuestionnaireId
              : 0,
            clientName: values.fullName,
          };
          if (this.props.isPotential) {
            const sendEmailOpt = {
              url: `v1/questionnaire/PSendQuestionnaireEmail`,
            };

            sendEmailOpt.types = [
              "SEND_QUESTIONNAIRE_EMAIL_SUCCESS",
              "SEND_QUESTIONNAIRE_EMAIL_FAILURE",
            ];

            apiRefresh.post(sendEmailOpt, data);
          } else {
            const sendEmailOpt = {
              url: `v1/questionnaire/ESendQuestionnaireEmail`,
            };

            sendEmailOpt.types = [
              "SEND_QUESTIONNAIRE_EMAIL_SUCCESS",
              "SEND_QUESTIONNAIRE_EMAIL_FAILURE",
            ];

            apiRefresh.post(sendEmailOpt, data);
          }
          this.setState({ loading: false, isSaveClicked: false });
        })
        .catch(() => {
          this.setState({ loading: false, isSaveClicked: false });
        });
    } else {
      this.props
        .onSubmitQuestionnaire(data)
        .then((res) => {
          message.success("Questionnaire answers submited successfully");
          this.setState({ loading: false, isSaveClicked: false });
          if (documentsArray.length > 0) {
            for (var i = 0; i < documentsArray.length; i++)
              this.uploadDocument(documentsArray[i], res.payload);
          }
        })
        .catch(() => {
          this.setState({ loading: false, isSaveClicked: false });
        });
    }
  };

  uploadDocument = (file, id) => {
    let formData = new FormData();
    let selectedFile = file;
    let imageValues = file.file.originFileObj;
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let userId = localStorage.getItem("userId");

    const JsonData = {
      BranchId: selectedBranchId,
      CompanyDocumentTypeId: 3,
      SizeInKB: imageValues && imageValues.size,
      Name: imageValues && imageValues.name,
      BlobUrl: "",
      BlobFileName: "",
      Extension: imageValues && imageValues.type,
      CreatedBy: userId,
      forCMV: false,
      forAgent: false,
    };
    formData.append("BranchId", selectedBranchId);
    formData.append("file", imageValues);
    formData.append("Data", JSON.stringify(JsonData));
    this.props
      .onAddDocWithName(formData)
      .then((res) => {
        let fileName = res.payload.fileURL.substring(
          res.payload.fileURL.lastIndexOf("/") + 1
        );
        let ext = fileName.split(".");
        let data = [
          {
            id: 0,
            questionnaireId: id,
            questionnaireType: "1",
            fileName: res.payload.fileName,
            url: res.payload.fileURL,
            isPotential: this.state.isPotentialState,
            docuementExtension: ext[ext.length - 1],
            createdBy: userId,
            createdDate: new Date(),
          },
        ];
        const addAttachmentOpt = {
          url: `v1/questionnaire/Attachment`,
        };
        addAttachmentOpt.types = [
          "ADD_ATTACHMENT_SUCCES",
          "ADD_ATTACHMENT_FAILURE",
        ];

        apiRefresh.post(addAttachmentOpt, data).then((resp) => {});
      })
      .catch((err) => {});
  };

  updateFromClient = () => {
    let branchId = localStorage.getItem("selectedBranchId");
    this.setState({ loading: true });
    if (this.state.isFilled) {
      let data = {
        clientId: this.props.clientId,
        branchId: branchId,
        questionnaireId: this.state.questionnaireObj.questionnaireId,
      };

      this.props
        .onUpdMergeFromClient(data)
        .then((res) => {
          this.getQuestionnaires(
            this.state.questionnaireObj.questionnaireId,
            this.state.activeFillQuestionnaireIndex
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      let data = {
        clientId: this.props.clientId,
        branchId: branchId,
        questionnaireId: this.state.questionnaireObj.questionnaireId,
        clientName: this.state.questionnaireObj.clientName || "",
        isPotential: this.state.questionnaireObj.isPotential,
        isEmployer: this.state.questionnaireObj.isEmployer,
        isPublic: this.state.questionnaireObj.isPublic,
        isVisaForm: this.state.isGrouped ? true : false,
      };
      this.props
        .onMergeFromClient(data)
        .then((res) => {
          this.getQuestionnaires(
            this.state.questionnaireObj.questionnaireId,
            this.state.activeFillQuestionnaireIndex
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  };

  mergeToClient = () => {
    let branchId = localStorage.getItem("selectedBranchId");
    this.setState({ loading: true });
    let data = {
      clientId: this.props.clientId,
      branchId: branchId,
      questionnaireId: this.state.questionnaireObj.questionnaireId,
      excluded: [0],
      isVisaForm: this.state.isGrouped ? true : false,
    };
    this.props
      .onUpdMergeToClient(data)
      .then((res) => {
        this.setState({ loading: false });
        this.handleCancelPopup();
        message.success("Updated!");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  saveAttachment = (value) => {
    this.setState({ loading: true });

    const getAttachmentOpt = {
      url: `v1/document/GetDocumentAttachmentBytes/${value.id}/${value.fileName}`,
    };
    getAttachmentOpt.types = [
      "UPDATE_SUBMIT_QUESTIONNAIRE_SUCCES",
      "UPDATE_SUBMIT_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .getFile(getAttachmentOpt)
      .then((res) => {
        this.setState({ loading: false });
        this.downlodDocument(res, value);
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${value.fileName}`);
  };

  handleCancelPopup = () => {
    this.setState({ visibleModal: false });
  };

  getAnswersArray = (values) => {
    var filledAnswers = [];
    // var answers = [];
    let answerArray = [];
    if (values.sections && values.sections.length > 0) {
      for (var i = 0; i < values.sections.length; i++) {
        if (values.sections[i].questions) {
          for (var j = 0; j < values.sections[i].questions.length; j++) {
            if (
              values.sections[i].questions[j].questionOptions &&
              values.sections[i].questions[j].questionOptions.length > 0
            ) {
              this.onRecursiveOptions(
                values.sections[i].questions[j].questionOptions
              );
              // filledAnswers = [...filledAns];
              // answerArray = [...postAns];
            }
            // filledAnswers = [...this.state.questionAnswers];
            // answerArray = [...this.state.postAnswers];
            if (
              values.sections[i].questions[j].answers &&
              values.sections[i].questions[j].answers.length > 0
            ) {
              // isFilled = this.state.questionnaire.sections[i]

              filledAnswers = [...filledAns];
              answerArray = [...postAns];
              for (
                var k = 0;
                k < values.sections[i].questions[j].answers.length;
                k++
              ) {
                var date = "1900-01-01T00:00:00.000Z";

                if (
                  (values.sections[i].questions[j].answerType == 2 ||
                    values.sections[i].questions[j].answerType == 6) &&
                  values.sections[i].questions[j].answers[k].answer
                ) {
                  date = moment(
                    values.sections[i].questions[j].answers[k].answer
                  ).format("YYYY-MM-DDT00:00:00+00:00");
                }

                if (values.sections[i].questions[j].answerType == 8) {
                  if (
                    values.sections[i].questions[j].answers[k] &&
                    values.sections[i].questions[j].answers[k].answer
                  ) {
                    // for (
                    //   var f = 0;
                    //   f <
                    //   values.sections[i].questions[j].answers[k].answer.fileList
                    //     .length;
                    //   f++
                    // ) {
                    //   documentsArray.push(
                    //     values.sections[i].questions[j].answers[k].answer
                    //       .fileList[f].originFileObj
                    //   );
                    // }

                    // this.uploadDocument(
                    //   values.sections[i].questions[j].answers[k].answer
                    // );

                    filledAnswers.push({
                      questionId: values.sections[i].questions[j].id,
                      answer: values.sections[i].questions[j].answers[k].answer
                        ? "Check file in attachments"
                        : "",
                    });
                  } else {
                    filledAnswers.push({
                      questionId: values.sections[i].questions[j].id,
                      answer: "Check file in attachments",
                    });
                  }
                } else {
                  if (values.sections[i].questions[j].answers[k]) {
                    filledAnswers.push({
                      questionId: values.sections[i].questions[j].id,
                      answer:
                        date == "1900-01-01T00:00:00.000Z" &&
                        (values.sections[i].questions[j].answerType == 2 ||
                          values.sections[i].questions[j].answerType == 6)
                          ? date
                          : values.sections[i].questions[j].answerType == 2 ||
                            values.sections[i].questions[j].answerType == 6
                          ? moment(
                              values.sections[i].questions[j].answers[k].answer
                            ).format("YYYY-MM-DDT00:00:00+00:00")
                          : values.sections[i].questions[j].answers[k] &&
                            values.sections[i].questions[j].answers[k].answer
                          ? values.sections[i].questions[j].answers[
                              k
                            ].answer.toString()
                          : "",
                    });
                  } else {
                    filledAnswers.push({
                      questionId: values.sections[i].questions[j].id,
                      answer: date == "1900-01-01T00:00:00.000Z" ? date : "",
                    });
                  }
                }
              }
              if (filledAnswers.length > 0) {
                for (var ans = 0; ans < filledAnswers.length; ans++) {
                  answers.push(filledAnswers[ans]);
                }
              }

              // this.setState({ questionAnswers: [], postAnswers: [] });
              filledAns = [];
              filledAnswers = [];
              answerArray = [];
            }
          }
        }
      }
    }
  };

  onSaveEzyform = () => {
    let data = {
      questionnaireId: this.state.questionnaireObj.questionnaireId,
      branchId: this.state.questionnaireObj.branchId,
      clientName: (this.props.userDataEmp && this.props.userDataEmp.name) || "",
      clientEmail:
        (this.props.userDataEmp && this.props.userDataEmp.email) || "",
      createdBy: this.state.questionnaireObj.createdBy,
      answers: answers,
      position: "0",
      count: "0",
    };
    this.setState({ loading: true });
    const baseURL = "https://ezyforms.azurewebsites.net/";
    axios({
      method: "POST",
      url: `${baseURL}api/ezymigrate-integration`,
      data: data,
      headers: {
        "api-key": this.state.apiKey && this.state.apiKey.apiKey,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        message.success("Answers save successfully on ezyforms!");
      })
      .catch((err) => {
        message.error(
          err.response && err.response.data && err.response.data.message
        );
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      questionnaireArray,
      visible,
      loading,
      headerOptions,
      questionnaireId,
      previewModal,
      visaFormArray,
      formName,
      questionnaireObj,
      activeIndex,
      activeFillQuestionnaireIndex,
      groupedQuestionnaire,
      dynamicLinkState,
      hostName,
      isSaveClicked,
      isGrouped,
      questionnaireName,
      attachmentsArray,
      isVisaForm,
      visibleModal,
      visaTypeDataList,
    } = this.state;
    const {
      questionnaireList,
      dynamicLinkSuccess,
      setEmailData,
      onSendEmailLink,
      countriesData,
      onGetQuestionnaire,
      onUpdateQuestionnaire,
      onGetMappingParent,
      onGetMappingChild,
      questionnaireData,
      mappParentData,
      mappChildData,
      groupedQuestionnaireRes,
      onGetFilledQuestionnaire,
      filledQuestionnaireRes,
      allFilledQuestionnaireRes,
      clientName,
      signatureRes,
    } = this.props;
    // console.log("grouped questionnaire", groupedQuestionnaireRes);
    return (
      <div>
        <Spin spinning={loading}>
          <div>
            <div className="page-container">
              {/* {headerOptions.length > 0 && (
                <HeaderBarTabs data={headerOptions} activeTab="Questionnaire" />
              )} */}
              {!this.props.isPotential && (
                <p
                  style={{
                    marginLeft: "40px !important",
                    fontWeight: 600,
                    fontSize: "14px",
                    marginTop: "26px",
                    marginLeft: "25px",
                    fontFamily: "Poppins",
                  }}
                >{`Name: ${this.props.userDataEmp &&
                  this.props.userDataEmp.name}`}</p>
              )}
              <Modal
                title="Confirmation"
                visible={visibleModal}
                onCancel={this.handleCancelPopup}
                footer={null}
                maskClosable={false}
              >
                <Row>
                  <Col span={24}>
                    <Row>
                      {" "}
                      Are you sure, you want to merge questionnaire, all fields
                      values will be updated?
                    </Row>
                  </Col>
                </Row>
                <Row style={{ display: "flex", marginTop: "40px" }}>
                  <Col span={4} offset={16}>
                    <Button
                      onClick={() => {
                        this.handleCancelPopup();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      style={{ marginLeft: "12px" }}
                      className={"button"}
                      onClick={() => {
                        this.mergeToClient();
                      }}
                    >
                      Ok
                    </Button>
                  </Col>
                </Row>
              </Modal>
              <div>
                <div>
                  <div>
                    <div
                      className="pciq-top-row"
                      style={{ display: "inline-block" }}
                    >
                      {!this.props.isEmployer && (
                        <div
                          style={{ display: "inline-block", marginLeft: 10 }}
                        >
                          <div>
                            <div
                              className="pciq-org-box"
                              style={{ width: 200, paddingRight: 0 }}
                            >
                              <span className="pciq-org-box-text">
                                Static Questionnaire
                              </span>
                            </div>
                          </div>
                          <div style={{ marginTop: 8 }}>
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              placeholder="Select..."
                              optionFilterProp="children"
                              onChange={this.onChangeCurrentQuestionnaire}
                              onSearch={this.onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option key="1819">WEB INQUIRY (DETAIL)</Option>
                              <Option key="1820">WEB INQUIRY (BASIC)</Option>
                              <Option key="1821">WEB ASSESMENT</Option>
                              <Option key="1836">GENERAL INFORMATION</Option>
                              <Option key="1838">BASIC FORM</Option>
                            </Select>
                          </div>
                        </div>
                      )}
                      <div style={{ display: "inline-block", marginLeft: 10 }}>
                        <div>
                          <div
                            className="pciq-org-box"
                            style={{ width: 200, paddingRight: 0 }}
                          >
                            <span className="pciq-org-box-text">
                              Visa Forms
                            </span>
                          </div>
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select..."
                            optionFilterProp="children"
                            onChange={this.onChangeVisaForm}
                            onSearch={this.onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {visaFormArray}
                          </Select>
                        </div>
                      </div>
                      <div style={{ display: "inline-block", marginLeft: 10 }}>
                        <div>
                          <div
                            className="pciq-org-box"
                            style={{ width: 200, paddingRight: 0 }}
                          >
                            <span className="pciq-org-box-text">
                              Custom Questionnaires
                            </span>
                          </div>
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select..."
                            optionFilterProp="children"
                            onChange={this.onChange}
                            onSearch={this.onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {questionnaireArray}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "inline-block",
                        padding: 20,
                        paddingTop: 10,
                      }}
                    >
                      {allFilledQuestionnaireRes &&
                        allFilledQuestionnaireRes.length > 0 &&
                        allFilledQuestionnaireRes.map(
                          (groupQuestonnaire, ind) => {
                            var findQuestionnaire = questionnaireIds.find(
                              (obj) => obj == groupQuestonnaire.questionnaireId
                            );
                            return (
                              <>
                                {!findQuestionnaire && (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      margin: 5,
                                    }}
                                  >
                                    <div>
                                      <div className="pciq-org-box">
                                        <span className="pciq-org-box-text">
                                          {groupQuestonnaire.questionnaireId ==
                                            1819 ||
                                          groupQuestonnaire.questionnaireId ==
                                            1820 ||
                                          groupQuestonnaire.questionnaireId ==
                                            1821 ||
                                          groupQuestonnaire.questionnaireId ==
                                            1836 ||
                                          groupQuestonnaire.questionnaireId ==
                                            1838
                                            ? "Static Questionnaire"
                                            : "Custom Questionnaire"}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        activeFillQuestionnaireIndex == ind
                                          ? "pc-active-inner-tab"
                                          : "pc-inactive-inner-tab"
                                      }
                                      style={{
                                        marginTop: 0,
                                        marginLeft: 0,
                                        marginRight: 0,
                                        minWidth: 150,
                                      }}
                                      onClick={() =>
                                        this.onChangeFiiledQuestionnaire(
                                          groupQuestonnaire.questionnaireId,
                                          ind
                                        )
                                      }
                                    >
                                      <span className="pc-text-inner-tab">
                                        {groupQuestonnaire.questionnaireName}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          }
                        )}
                    </div>

                    {dynamicLinkState && (
                      <div>
                        <div className="pc-normal-text-cont">
                          <span
                            className="cv-normal-text"
                            style={{ color: "#797979" }}
                          >
                            Please send below link to client
                          </span>
                        </div>
                        <div className="pc-bottom-org-box-cont">
                          <div
                            className="pciq-org-box"
                            style={{ borderRadius: 5 }}
                          >
                            <span className="pciq-org-box-text">
                              Web Inquiry EN
                            </span>
                          </div>
                        </div>

                        <div className="pc-link-main-cont">
                          <div
                            className="pc-link-color-box"
                            onClick={this.openQuestionnaire}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="pc-link-text">
                              {hostName + "/c_q?para=" + dynamicLinkState}
                            </span>
                          </div>
                          <div style={{ marginLeft: 20, cursor: "pointer" }}>
                            {/* <CopyOutlined
                              onClick={() => {
                                navigator.clipboard.writeText(data.host);
                                message.info("Copied!");
                              }}
                            /> */}
                            <Button
                              style={{
                                fontSize: 10,
                                fontWeight: "500",
                                padding: 5,
                                borderRadius: 5,
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  hostName + "/c_q?para=" + dynamicLinkState
                                );
                                message.info("Copied!");
                              }}
                            >
                              Copy Link
                            </Button>
                          </div>
                        </div>
                        <div className="pc-language-cont">
                          {/* <div>
                            <span
                              className="cv-normal-text"
                              style={{ color: "#797979" }}
                            >
                              Language
                            </span>
                          </div> */}
                          {/* <div style={{ display: "flex" }}>
                            <div className="pc-radio-cont">
                              <input
                                type="radio"
                                value="english"
                                name="language"
                              />
                              <span
                                className="cv-normal-text"
                                style={{ color: "#797979" }}
                              >
                                English
                              </span>
                            </div>
                            <div className="pc-radio-cont">
                              <input
                                type="radio"
                                value="chinese"
                                name="language"
                              />
                              <span
                                className="cv-normal-text"
                                style={{ color: "#797979" }}
                              >
                                Chinese
                              </span>
                            </div>
                          </div> */}
                          <div style={{ display: "flex" }}>
                            <div>
                              <Button
                                type="primary"
                                className="cq-primary-btn"
                                style={{ marginTop: 20, marginRight: 10 }}
                                onClick={() => this.setState({ visible: true })}
                              >
                                SEND
                              </Button>
                            </div>
                            {this.props.isEmployer &&
                              questionnaireObj &&
                              questionnaireObj.showInEzyform && (
                                <div style={{ marginLeft: 5 }}>
                                  <Button
                                    type="primary"
                                    className="cq-primary-btn button-blue"
                                    style={{ marginTop: 20, marginRight: 10 }}
                                    onClick={this.onSaveEzyform}
                                  >
                                    Save on Ezyforms
                                  </Button>
                                </div>
                              )}
                            {/* {isVisaForm && (
                              <div style={{ marginLeft: 5 }}>
                                <Button
                                  type="primary"
                                  className="cq-primary-btn"
                                  style={{ marginTop: 20, marginRight: 10 }}
                                  onClick={() =>
                                    this.setState({ previewModal: true })
                                  }
                                >
                                  Preview & Edit
                                </Button>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        margin:
                          isGrouped &&
                          groupedQuestionnaire &&
                          groupedQuestionnaire.length > 0
                            ? 40
                            : 0,
                      }}
                    >
                      {isGrouped && (
                        <div>
                          <div>
                            <span>{formName}</span>
                          </div>
                        </div>
                      )}

                      <div style={{ display: "inline-block" }}>
                        {isGrouped &&
                          groupedQuestionnaire &&
                          groupedQuestionnaire.map((questionnaire, index) => {
                            return (
                              <div
                                onClick={() =>
                                  this.getQuestionnaires(
                                    questionnaire.id,
                                    index
                                  )
                                }
                                className={
                                  activeIndex == index
                                    ? "cq-active-inner-tab"
                                    : "cq-inactive-inner-tab"
                                }
                              >
                                <span
                                  className="pc-text-inner-tab"
                                  style={{
                                    color:
                                      activeIndex == index
                                        ? "#FFFFFF"
                                        : "#555555",
                                  }}
                                >
                                  {questionnaire.questionnaireName}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {questionnaireObj && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: 40,
                          marginRight: 40,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          {!this.props.isEmployer && (
                            <Button
                              type="primary"
                              className="cq-primary-btn"
                              style={{ marginTop: 20, marginRight: 10 }}
                              onClick={() =>
                                this.setState({ visibleModal: true })
                              }
                            >
                              {groupedQuestionnaire.length > 0
                                ? "Merge to Client"
                                : "Merge"}
                            </Button>
                          )}
                          {groupedQuestionnaire.length > 0 && (
                            <Button
                              type="primary"
                              className="cq-primary-btn"
                              style={{ marginTop: 20, marginRight: 10 }}
                              onClick={() => this.updateFromClient()}
                            >
                              Update From Client
                            </Button>
                          )}
                        </div>
                        {groupedQuestionnaire.length > 0 && (
                          <Button
                            type="primary"
                            className="cq-primary-btn"
                            style={{ marginTop: 20, marginRight: 10 }}
                            // onClick={() => this.setState({ visible: true })}
                          >
                            Merge From Existing Questionnaire
                          </Button>
                        )}
                      </div>
                    )}

                    {false && (
                      <div>
                        <span>INQUIRY FORM</span>
                      </div>
                    )}
                    <div style={{ margin: 20 }}>
                      {questionnaireObj && (
                        <Form
                          initialValues={questionnaireObj}
                          onFinish={this.onFinish}
                        >
                          <div className="title-container">
                            <span className="heading-text">
                              {questionnaireObj.name}
                            </span>
                          </div>
                          {attachmentsArray.length > 0 && (
                            <>
                              <div
                                className="cs-attachment-haeding"
                                style={{ marginLeft: 30 }}
                              >
                                Attachment(s)
                              </div>
                              <div
                                className="cs-attachment-body"
                                style={{ marginLeft: 0, marginRight: 0 }}
                              >
                                {attachmentsArray.map((attachment, index) => {
                                  return (
                                    <div
                                      onClick={() =>
                                        this.saveAttachment(attachment)
                                      }
                                      style={{ padding: 5, cursor: "pointer" }}
                                    >
                                      <span>{attachment.fileName}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                          <Form.List name="sections">
                            {(fields, { add, remove }) => {
                              return (
                                <div>
                                  {fields.map((field, index) => {
                                    return (
                                      <div key={index}>
                                        <div
                                          className="cq-form-cont"
                                          style={{
                                            width: "100%",
                                            marginLeft: 0,
                                            marginTop: 0,
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                            paddingBottom: 30,
                                          }}
                                        >
                                          <div
                                            className="title-container"
                                            style={{
                                              justifyContent: "flex-start",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span className="heading-text">
                                              {
                                                questionnaireObj.sections[index]
                                                  .name
                                              }
                                            </span>
                                          </div>
                                          <div
                                            className="form-cont"
                                            style={{
                                              justifyContent: "space-between",
                                              paddingTop: 20,
                                              paddingBottom: 0,
                                              display: "block",
                                              backgroundColor: "#FFFFFF",
                                              borderRadius: 5,
                                            }}
                                          >
                                            <Form.List
                                              name={[field.name, "questions"]}
                                              key={[field.key, "questions"]}
                                            >
                                              {(questions, { add, remove }) => {
                                                return (
                                                  <div>
                                                    {questions.map(
                                                      (question, quesInd) => (
                                                        <div
                                                          key={quesInd}
                                                          style={{
                                                            marginTop: 15,
                                                          }}
                                                        >
                                                          <Form.Item
                                                            required={false}
                                                            key={question.key}
                                                          >
                                                            <FillQuestionRow
                                                              question={
                                                                question
                                                              }
                                                              countriesData={
                                                                countriesData
                                                                  ? countriesData
                                                                  : []
                                                              }
                                                              questionData={
                                                                questionnaireObj
                                                                  .sections[
                                                                  index
                                                                ].questions[
                                                                  quesInd
                                                                ]
                                                              }
                                                              visaTypeData={
                                                                visaTypeDataList
                                                              }
                                                              index={quesInd}
                                                              onChange={
                                                                this.onChange
                                                              }
                                                              questionsLength={
                                                                questionnaireObj
                                                                  .sections[
                                                                  index
                                                                ].questions
                                                                  .length
                                                              }
                                                              sectionsLength={
                                                                questionnaireObj
                                                                  .sections
                                                                  .length
                                                              }
                                                              isSaveClicked={
                                                                isSaveClicked
                                                              }
                                                            />
                                                          </Form.Item>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                );
                                              }}
                                            </Form.List>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }}
                          </Form.List>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="cq-primary-btn"
                                loading={loading}
                                style={{ marginTop: 20, marginRight: 10 }}
                              >
                                SAVE
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      )}
                    </div>
                    <Modal
                      visible={previewModal}
                      title="Questionnaire"
                      footer={null}
                      onCancel={this.handleCancelPreview}
                      width="1000px"
                    >
                      <UpdateQuestionnaire
                        questionnaireId={questionnaireId}
                        onGetQuestionnaire={onGetQuestionnaire}
                        onUpdateQuestionnaire={onUpdateQuestionnaire}
                        onGetMappingParent={onGetMappingParent}
                        onGetMappingChild={onGetMappingChild}
                        questionnaireData={questionnaireData}
                        mappParentData={mappParentData}
                        mappChildData={mappChildData}
                      />
                    </Modal>

                    {false && (
                      <div>
                        <div
                          className="pc-bottom-org-box-cont"
                          style={{ marginTop: 10 }}
                        >
                          <div
                            className="pciq-org-box"
                            style={{ borderRadius: 5 }}
                          >
                            <span className="pciq-org-box-text">
                              Web Inquiry CH
                            </span>
                          </div>
                        </div>

                        <div className="pc-link-main-cont">
                          <div className="pc-link-color-box">
                            <span className="pc-link-text">
                              https://ezymigrate.co.nz/Questionnaire/Migrationquirey?para=
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <QuestionnaireEmail
              handleCancel={this.handleCancel}
              visible={visible}
              emailContent={setEmailData && setEmailData.htmlTemplate}
              onSendEmail={onSendEmailLink}
              clientEmail={this.state.clientEmail}
              clientprofileid={this.props.clientId}
              isPotentialClient={this.props.isPotential}
              isEmployer={this.props.isEmployer}
              questionnaireName={isGrouped ? formName : questionnaireName}
              signatureText={signatureRes && signatureRes.signatureText}
              clientName={clientName}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default ClientQuestionnaire;
