import React from "react";
import {
  message,
  Spin,
  Button,
  Upload,
  Form,
  Checkbox,
  Input,
  Space,
  DatePicker,
  Select,
  Radio,
  Modal,
  Row,
  Col,
} from "antd";
import queryString from "query-string";

import "./QuestionnaireStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";

import Section from "../Components/Questionnaire/Section";
import FillQuestionRow from "../Components/Questionnaire/FillQuestionRow";
import Header from "../Components/Questionnaire/Header";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";
import { api, apiRefresh } from "../services/api";
import { TramRounded } from "@material-ui/icons";
import moment from "moment";
import { saveAs } from "file-saver";
import activityData from "../Components/ClientActivity/ActivityData";

import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

var stopLoader = false;
const { Option } = Select;

var questionnaireDuplicate = null;

var documentsArray = [];
var countAttachment = 0;

var count = 0;
var clickedIndex = 0;

var responseId = 0;

var filledAns = [];
var postAns = [];

class LinkQuestionnaire extends React.Component {
  constructor(props) {
    const initialQueryValue = queryString.parse(
      props.location && props.location.search
    );
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      show: false,
      loading: true,
      imageUpdateId: "",
      loadUploadImage: false,
      imageUrl: "",
      addUserImageUrl: "",
      exportLoading: false,

      fullName: "",
      email: "",
      password: "",

      questionNumbers: 1,
      sectionData: null,
      questionnaire: null,
      questionAnswers: [],
      isFilled: false,
      clientIdState:
        this.props.notLink && this.props.clientprofileid
          ? this.props.clientprofileid
          : "00000000-0000-0000-0000-000000000000",
      postAnswers: [],
      groupsArray: [],
      activeIndex: 0,
      isSaveClicked: false,
      idsArrayState: [],
      clickedButton: "",
      activePageNumber: 1,
      branchIdState: this.props.notLink
        ? localStorage.getItem("selectedBranchId")
        : "00000000-0000-0000-0000-000000000000",
      isPotentialState: false,
      attachmentsArray: [],
      decelerationBasic: false,
      decelerationDetail: false,
      decelerationWebAssessment: false,
      customDeceleration: false,
      decelerationBankInfo: false,
      isEmployerState: false,
      userIdState: this.props.notLink
        ? localStorage.getItem("userId")
        : "00000000-0000-0000-0000-000000000000",
      groupId: 0,
      disableButton: false,
      quesEmail: "",
      visibleModal: false,
      visaTypeDataList: [],
      declarationText: "",
      fileList: [],
    };

    var questionnaireId =
      initialQueryValue && initialQueryValue.para ? initialQueryValue.para : 0;

    if (this.props.notLink) {
      let data = null;
      this.setState({
        clientIdState:
          this.props.clientprofileid || "00000000-0000-0000-0000-000000000000",
        branchIdState: localStorage.getItem("selectedBranchId"),
        isPotentialState: this.props.isPotential ? true : false,
        isEmployerState: this.props.isEmployer ? true : false,
        userIdState: localStorage.getItem("userId"),
      });
      this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));

      const getDeclaration = {
        url: `v1/questionnaire/QuestionnaireSetting/${localStorage.getItem(
          "selectedBranchId"
        )}`,
      };
      getDeclaration.types = [
        "GET_DECLARATION_SUCCES",
        "GET_DECLARATION_FAILURE",
      ];

      apiRefresh
        .get(getDeclaration)
        .then((res) => {
          if (res && res.length > 0) {
            this.setState({ declarationText: res[0].customDeceleration });
          }
        })
        .catch((err) => {});
      const getQuestionnaireSettingOpt = {
        url: `v1/questionnaire/QuestionnaireSetting/${localStorage.getItem(
          "selectedBranchId"
        )}`,
      };
      getQuestionnaireSettingOpt.types = [
        "GET_QUESTIONNAIRE_SETTING_SUCCES",
        "GET_QUESTIONNAIRE_SETTING_FAILURE",
      ];

      apiRefresh.get(getQuestionnaireSettingOpt).then((respns) => {
        this.setState({
          quesEmail: respns && respns.length > 0 ? respns[0].qEmail : "",
        });
      });
      data = {
        id: this.props.questionnaireId,
        clientId:
          this.props.clientprofileid || "00000000-0000-0000-0000-000000000000",
      };

      if (this.props.isPotential && this.props.filledId) {
        data.filledId = this.props.filledId;
        const getPotentialQuestionnaireOpt = {
          url: `v1/questionnaire/GetPotentialQuestionnaire?Id=${data.id}&ClientId=${data.clientId}&FilledId=${data.filledId}`,
        };
        getPotentialQuestionnaireOpt.types = [
          "GET_FILLED_POTENTIAL_QUESTIONNAIRE_SUCCES",
          "GET_FILLED_POTENTIAL_QUESTIONNAIRE_FAILURE",
        ];
        var questionnaireOb = null;

        apiRefresh
          .get(getPotentialQuestionnaireOpt)
          .then((res) => {
            questionnaireObj = this.addMomentOnDates(res);
            this.getAttachments(this.props.filledId);
            this.setState({
              loading: false,
              questionnaire: questionnaireObj,
              isFilled: true,
            });
          })
          .catch((err) => {
            this.setState({ loading: false, questionnaire: null });
          });
      } else if (this.props.isStaticQuestionnaire && this.props.isPotential) {
        const getStaticQuestionnaireOpt = {
          url: `v1/questionnaire/GetFilledQuestionnaireStatic?Id=${this.props.filledQuestionnaireId}`,
        };
        getStaticQuestionnaireOpt.types = [
          "GET_STATIC_QUESTIONNAIRE_SUCCES",
          "GET_STATIC_QUESTIONNAIRE_FAILURE",
        ];

        var questionnaireObj = null;

        apiRefresh
          .get(getStaticQuestionnaireOpt)
          .then((res) => {
            questionnaireObj = this.addMomentOnDates(res);
            this.setState({
              loading: false,
              questionnaire: questionnaireObj,
              isFilled: true,
            });
          })
          .catch((err) => {
            this.setState({ loading: false, questionnaire: null });
          });
      } else {
        this.props
          .onGetFilledQuestionnaire(data)
          .then((response) => {
            this.setState({ isFilled: true, loading: false });

            this.getAttachments(
              response.payload.sections[0].questions[0].answers[0]
                .filledQuestionnaireId
            );
          })
          .catch((err) => {
            if (
              err.payload &&
              err.payload.response &&
              err.payload.response.status == 404
            ) {
              this.setState({ isFilled: false });
              this.props
                .onGetQuestionnaire(data)
                .then((res) => {
                  this.setState({ loading: false });
                })
                .catch((err) => {
                  this.setState({ loading: false });
                });
            } else {
              this.setState({ loading: false });
            }
          });
      }
    } else {
      this.props
        .onGetDynamicLink(questionnaireId)
        .then((res) => {
          localStorage.setItem("companyLogo", res.payload.branch.logo);
          sessionStorage.setItem("selectedBranchId", res.payload.branch.id);
          let data = null;
          let idsArray = [];
          if (res.payload.isGrouped) {
            if (res.payload.groups && res.payload.groups.length > 0) {
              for (var i = 0; i < res.payload.groups.length; i++) {
                idsArray.push({
                  id: i + 1,
                  value: res.payload.groups[i].id,
                });
              }

              this.setState({
                groupsArray: res.payload.groups,
                clientIdState: res.payload.clientId,
                branchIdState: res.payload.branchId,
                isPotentialState: res.payload.isPotential,
                isEmployerState: res.payload.isEmployer,
                userIdState: res.payload.userId,
                idsArrayState: idsArray,
                groupId: res.payload.groupId || 0,
              });
              data = {
                id: res.payload.groups[0].questionnaireId,
                clientId: res.payload.clientId,
              };
            }
          } else {
            this.setState({
              clientIdState: res.payload.clientId,
              branchIdState: res.payload.branchId,
              isPotentialState: res.payload.isPotential,
              isEmployerState: res.payload.isEmployer,
              userIdState: res.payload.userId,
            });

            data = {
              id: res.payload.questionnaireId,
              clientId: res.payload.clientId,
            };
          }

          const getDeclaration = {
            url: `v1/questionnaire/QuestionnaireSetting/${res.payload.branchId}`,
          };
          getDeclaration.types = [
            "GET_DECLARATION_SUCCES",
            "GET_DECLARATION_FAILURE",
          ];

          apiRefresh
            .get(getDeclaration)
            .then((res) => {
              if (res && res.length > 0) {
                this.setState({ declarationText: res[0].customDeceleration });
              }
            })
            .catch((err) => {});
          this.props.onGetVisaType(res.payload.branchId);
          const getQuestionnaireSettingOpt = {
            url: `v1/questionnaire/QuestionnaireSetting/${res.payload.branchId}`,
          };
          getQuestionnaireSettingOpt.types = [
            "GET_QUESTIONNAIRE_SETTING_SUCCES",
            "GET_QUESTIONNAIRE_SETTING_FAILURE",
          ];

          apiRefresh.get(getQuestionnaireSettingOpt).then((respns) => {
            this.setState({
              quesEmail: respns && respns.length > 0 ? respns[0].qEmail : "",
            });
          });
          if (res.payload.clientId == "00000000-0000-0000-0000-000000000000") {
            this.setState({ isFilled: false });
            this.props
              .onGetQuestionnaire(data)
              .then((res) => {
                this.setState({ loading: false });
              })
              .catch((err) => {
                this.setState({ loading: false });
              });
          } else {
            this.props
              .onGetFilledQuestionnaire(data)
              .then((response) => {
                this.setState({ isFilled: true, loading: false });

                this.getAttachments(
                  response.payload.sections[0].questions[0].answers[0]
                    .filledQuestionnaireId
                );
              })
              .catch((err) => {
                if (
                  err.payload &&
                  err.payload.response &&
                  err.payload.response.status == 404
                ) {
                  this.setState({ isFilled: false });
                  this.props
                    .onGetQuestionnaire(data)
                    .then((res) => {
                      this.setState({ loading: false });
                    })
                    .catch((error) => {
                      this.setState({ loading: false });
                    });
                } else {
                  this.setState({ loading: false });
                }
              });
          }

          // setTimeout(() => {}, 1000);
        })
        .catch((err) => {
          console.log(err.response);
          if (
            err &&
            err.payload &&
            err.payload.response &&
            err.payload.response.data === "Invalid URL"
          ) {
            message.error("Link is not valid!");
            this.setState({ loading: false });
          } else {
            message.error("Failed");
            this.setState({ loading: false });
          }
        });
    }
    this.props.onGetCountries();

    // this.props
    //   .onGetTeamMember()
    //   .then(() => {})
    //   .catch(err => {
    //     console.log(this.props.teamMemberError);
    //   });
    // this.props.onGetBranch();
  }
  formRef = React.createRef();

  componentDidMount() {}

  getAttachments = (filledId) => {
    const getAttachmentOpt = {
      url: `v1/questionnaire/GetAttachments/${filledId}`,
    };
    getAttachmentOpt.types = [
      "GET_ATTACHMENT_SUCCES",
      "GET_ATTACHMENT_FAILURE",
    ];

    apiRefresh
      .get(getAttachmentOpt)
      .then((res) => {
        this.setState({ attachmentsArray: res });
      })
      .catch((err) => {});
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filledQuestionnaireRes != this.props.filledQuestionnaireRes) {
      var questionnaireObject = null;
      if (this.props.filledQuestionnaireRes) {
        questionnaireObject = this.addMomentOnDates(
          this.props.filledQuestionnaireRes
        );
        this.setState({
          questionnaire: questionnaireObject,
          isFilled: true,
        });
      }
    }

    if (prevProps.visaTypeData !== this.props.visaTypeData) {
      let visa = [];
      if (this.props.visaTypeData) {
        this.props.visaTypeData.items.map((visaType, ind) => {
          visaType.id = visaType.id.toString();
          visa.push(visaType);
        });
        this.setState({ visaTypeDataList: visa });
      }
    }

    if (prevProps.questionnaireSetRes != this.props.questionnaireSetRes) {
      if (
        this.props.questionnaireSetRes &&
        this.props.questionnaireSetRes.length > 0
      ) {
        if (this.props.questionnaireId && this.props.questionnaireId == 1821) {
          this.setState({
            decelerationWebAssessment: this.props.questionnaireSetRes[0]
              .qDeceleration,
          });
        }
      }
    }
    if (prevProps.questionnaireData !== this.props.questionnaireData) {
      questionnaireDuplicate = this.props.questionnaireData;
      // for (var i = 0; i < questionnaireDuplicate.sections.length; i++) {
      //   if (questionnaireDuplicate.sections[i].questions) {
      //     this.changeQuestion(questionnaireDuplicate.sections[i].questions);
      //   }
      // }

      this.setState({ questionnaire: questionnaireDuplicate });
      // for (var i = 0; i < questionnaireDuplicate.sections.length; i++) {
      //   for (
      //     var j = 0;
      //     j < questionnaireDuplicate.sections[i].questions.length;
      //     j++
      //   ) {
      //     for (
      //       var k = 0;
      //       k <
      //       questionnaireDuplicate.sections[i].questions[j].questionOptions
      //         .length;
      //       k++
      //     ) {
      //       if (
      //         questionnaireDuplicate.sections[i].questions[j].questionOptions[k]
      //           .optionalQuestions.length > 0
      //       ) {
      //         for (
      //           var l = 0;
      //           l <
      //           questionnaireDuplicate.sections[i].questions[j].questionOptions[
      //             k
      //           ].optionalQuestions.length;
      //           l++
      //         ) {
      //           if (
      //             questionnaireDuplicate.sections[i].questions[j]
      //               .questionOptions[k].optionalQuestions[l].questions.length >
      //             0
      //           ) {
      //             for (
      //               var m = 0;
      //               m <
      //               questionnaireDuplicate.sections[i].questions[j]
      //                 .questionOptions[k].optionalQuestions[l].questions.length;
      //               m++
      //             ) {
      //               this.findQuestion(
      //                 questionnaireDuplicate.sections[i].questions[j]
      //                   .questionOptions[k].optionalQuestions[l].questions[m],
      //                 questionnaireDuplicate.sections[i].questions[j]
      //                   .questionOptions
      //               );
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  addMomentOnDates = (questionnaire) => {
    if (questionnaire.sections) {
      for (var i = 0; i < questionnaire.sections.length; i++) {
        if (questionnaire.sections[i].questions) {
          for (var j = 0; j < questionnaire.sections[i].questions.length; j++) {
            if (
              questionnaire.sections[i].questions[j].questionOptions &&
              questionnaire.sections[i].questions[j].questionOptions.length > 0
            ) {
              this.addDateOnOptQuestions(
                questionnaire.sections[i].questions[j].questionOptions
              );
            }
            if (
              questionnaire.sections[i].questions[j].answers &&
              questionnaire.sections[i].questions[j].answers.length > 0
            ) {
              for (
                var k = 0;
                k < questionnaire.sections[i].questions[j].answers.length;
                k++
              ) {
                if (
                  (questionnaire.sections[i].questions[j].answerType == 2 ||
                    questionnaire.sections[i].questions[j].answerType == 6) &&
                  questionnaire.sections[i].questions[j].answers[k]
                ) {
                  var isValidDate = false;
                  var varType = typeof questionnaire.sections[i].questions[j]
                    .answers[k].answer;
                  if (
                    questionnaire.sections[i].questions[j].answers[k].answer &&
                    varType == "string"
                  ) {
                    isValidDate = moment(
                      questionnaire.sections[i].questions[j].answers[k].answer,
                      "DD/MM/YYYY",
                      true
                    ).isValid();
                    if (isValidDate) {
                      var parts = questionnaire.sections[i].questions[
                        j
                      ].answers[k].answer.split("/");
                      var day = parseInt(parts[0], 10);
                      var month = parseInt(parts[1], 10);
                      var year = parseInt(parts[2], 10);
                      questionnaire.sections[i].questions.answers[
                        k
                      ].answer = `${year}/${month}/${day}`;
                    }
                  }
                  if (
                    questionnaire.sections[i].questions[j].answers[k].answer ==
                      "" ||
                    moment(
                      questionnaire.sections[i].questions[j].answers[k].answer
                    ).format("YYYY-MM-DD") == "1900-01-01"
                  ) {
                    questionnaire.sections[i].questions[j].answers[k].answer =
                      "";
                  } else {
                    questionnaire.sections[i].questions[j].answers[
                      k
                    ].answer = moment(
                      questionnaire.sections[i].questions[j].answers[k].answer
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
    return questionnaire;
  };

  addDateOnOptQuestions = (options) => {
    for (var x = 0; x < options.length; x++) {
      if (
        options[x].optionalQuestions &&
        options[x].optionalQuestions.length > 0
      ) {
        for (var i = 0; i < options[x].optionalQuestions.length; i++) {
          if (
            options[x].optionalQuestions[i].questions &&
            options[x].optionalQuestions[i].questions.length > 0
          ) {
            for (
              var j = 0;
              j < options[x].optionalQuestions[i].questions.length;
              j++
            ) {
              if (
                options[x].optionalQuestions[i].questions[j].questionOptions &&
                options[x].optionalQuestions[i].questions[j].questionOptions
                  .length > 0
              ) {
                this.addDateOnOptQuestions(
                  options[x].optionalQuestions[i].questions[j].questionOptions
                );
              }
              if (
                options[x].optionalQuestions[i].questions[j].answers &&
                options[x].optionalQuestions[i].questions[j].answers.length > 0
              ) {
                for (
                  var k = 0;
                  k <
                  options[x].optionalQuestions[i].questions[j].answers.length;
                  k++
                ) {
                  if (
                    (options[x].optionalQuestions[i].questions[j].answerType ==
                      2 ||
                      options[x].optionalQuestions[i].questions[j].answerType ==
                        6) &&
                    options[x].optionalQuestions[i].questions[j].answers[k]
                  ) {
                    var isValidDate = false;
                    var varType = typeof options[x].optionalQuestions[i]
                      .questions[j].answers[k].answer;
                    if (
                      options[x].optionalQuestions[i].questions[j].answers[k]
                        .answer &&
                      varType == "string"
                    ) {
                      isValidDate = moment(
                        options[x].optionalQuestions[i].questions[j].answers[k]
                          .answer,
                        "DD/MM/YYYY",
                        true
                      ).isValid();
                      if (isValidDate) {
                        var parts = options[x].optionalQuestions[
                          i
                        ].questions.answers[k].answer.split("/");
                        var day = parseInt(parts[0], 10);
                        var month = parseInt(parts[1], 10);
                        var year = parseInt(parts[2], 10);
                        options[x].optionalQuestions[i].questions.answers[
                          k
                        ].answer = `${year}/${month}/${day}`;
                      }
                    }
                    if (
                      options[x].optionalQuestions[i].questions[j].answers[k]
                        .answer == "" ||
                      moment(
                        options[x].optionalQuestions[i].questions[j].answers[k]
                          .answer
                      ).format("YYYY-MM-DD") == "1900-01-01"
                    ) {
                      options[x].optionalQuestions[i].questions[j].answers[
                        k
                      ].answer = "";
                    } else {
                      options[x].optionalQuestions[i].questions[j].answers[
                        k
                      ].answer = moment(
                        options[x].optionalQuestions[i].questions[j].answers[k]
                          .answer
                      );
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  changeQuestion = (questions) => {
    if (questions.length > 0) {
      for (var i = 0; i < questions.length; i++) {
        if (questions[i].questionOptions) {
          for (var j = 0; j < questions[i].questionOptions.length; j++) {
            if (questions[i].questionOptions[j].optionalQuestions) {
              for (
                var k = 0;
                k < questions[i].questionOptions[j].optionalQuestions.length;
                k++
              ) {
                if (
                  questions[i].questionOptions[j].optionalQuestions[k].questions
                ) {
                  this.changeQuestion(
                    questions[i].questionOptions[j].optionalQuestions[k]
                      .questions
                  );
                }
              }
            }
          }
        }

        questions[i].filledAnswers = questions[i].filledAnswer.filledAnswers;
      }
    }
  };

  findQuestion = (question, optionsArray) => {
    for (var i = 0; i < optionsArray.length; i++) {
      if (
        optionsArray[i].optionalQuestions &&
        optionsArray[i].optionalQuestions.length > 0
      ) {
        for (var j = 0; j < optionsArray[i].optionalQuestions.length; j++) {
          if (
            optionsArray[i].optionalQuestions[j].questions &&
            optionsArray[i].optionalQuestions[j].questions.length > 0
          ) {
            for (
              var k = 0;
              k < optionsArray[i].optionalQuestions[j].questions.length;
              k++
            ) {
              if (
                question.id !==
                  optionsArray[i].optionalQuestions[j].questions[k].id &&
                question ===
                  optionsArray[i].optionalQuestions[j].questions[k].question
              ) {
              }
            }
          }
        }
      }
    }
    const data = optionsArray;

    // const r = data.filter(d => d.optionalQuestions.filter(c => console.log("in filter",  c)));
    // console.log(r);
    //
  };

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  addQuestion = (value) => {
    this.setState({ questionNumbers: value });
  };

  saveAttachment = (value) => {
    this.setState({ loading: true });

    const getAttachmentOpt = {
      url: `v1/document/GetDocumentAttachmentBytes/${value.id}/${value.fileName}`,
    };
    getAttachmentOpt.types = [
      "UPDATE_SUBMIT_QUESTIONNAIRE_SUCCES",
      "UPDATE_SUBMIT_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .getFile(getAttachmentOpt)
      .then((res) => {
        this.downlodDocument(res, value);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${value.fileName}`);
  };

  onRecursiveOptions = (options) => {
    let answers = [];
    let filledAnswers = [];
    let postAnswers = [];
    for (var k = 0; k < options.length; k++) {
      if (options[k].optionalQuestions) {
        for (var l = 0; l < options[k].optionalQuestions.length; l++) {
          for (
            var m = 0;
            m < options[k].optionalQuestions[l].questions.length;
            m++
          ) {
            if (
              options[k].optionalQuestions[l].questions[m].questionOptions &&
              options[k].optionalQuestions[l].questions[m].questionOptions
                .length > 0
            ) {
              this.onRecursiveOptions(
                options[k].optionalQuestions[l].questions[m].questionOptions
              );
              // filledAnswers = [...filledAns];
              // postAnswers = [...postAns];
            }
            if (
              options[k].optionalQuestions[l].questions[m].answers &&
              options[k].optionalQuestions[l].questions[m].answers.length > 0
            ) {
              filledAnswers = [...filledAns];
              postAnswers = [...postAns];
              // filledAnswers = [...this.state.questionAnswers];
              // postAnswers = [...this.state.postAnswers];
              for (
                var n = 0;
                n < options[k].optionalQuestions[l].questions[m].answers.length;
                n++
              ) {
                if (
                  options[k].optionalQuestions[l].questions[m].answerType == 8
                ) {
                  if (options[k].optionalQuestions[l].questions[m].answers[n]) {
                    if (
                      options[k].optionalQuestions[l].questions[m].answers[n]
                        .answer &&
                      options[k].optionalQuestions[l].questions[m].answers[n]
                        .answer != "Check file in attachments" &&
                      options[k].optionalQuestions[l].questions[m].answers[n]
                        .answer.fileList
                    ) {
                      for (
                        var f = 0;
                        f <
                        options[k].optionalQuestions[l].questions[m].answers[n]
                          .answer.fileList.length;
                        f++
                      ) {
                        documentsArray.push(
                          options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].answer.fileList[f].originFileObj
                        );
                      }
                      // this.uploadDocument(
                      //   options[k].optionalQuestions[l].questions[m].answers[k].answer
                      // );
                    }
                    filledAnswers.push({
                      id: this.state.isFilled
                        ? options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].id
                        : 0,
                      filledQuestionnaireId: this.state.isFilled
                        ? options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].filledQuestionnaireId
                        : 0,
                      questionId:
                        options[k].optionalQuestions[l].questions[m].id,
                      answer: options[k].optionalQuestions[l].questions[m]
                        .answers[n].answer
                        ? "Check file in attachments"
                        : "",
                      position:
                        options[k].optionalQuestions[l].questions[m].answers[
                          n
                        ] &&
                        options[k].optionalQuestions[l].questions[m].answers[n]
                          .position
                          ? parseInt(
                              options[k].optionalQuestions[l].questions[m]
                                .answers[n].position
                            )
                          : 0,
                      count: 0,
                    });
                  } else {
                    filledAnswers.push({
                      id: this.state.isFilled
                        ? options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].id
                        : 0,
                      filledQuestionnaireId:
                        (this.state.questionnaire &&
                          this.state.questionnaire.filledQuestionnaireId) ||
                        (this.props.questionnaireData &&
                          this.props.questionnaireData.filledQuestionnaireId),
                      questionId:
                        options[k].optionalQuestions[l].questions[m].id,
                      answer: "Check file in attachments",
                      position: 0,
                      count: 0,
                    });
                  }
                }
                var date = "1900-01-01T00:00:00.000Z";
                if (
                  (options[k].optionalQuestions[l].questions[m].answerType ==
                    2 ||
                    options[k].optionalQuestions[l].questions[m].answerType ==
                      6) &&
                  options[k].optionalQuestions[l].questions[m].answers[n] &&
                  options[k].optionalQuestions[l].questions[m].answers[n].answer
                ) {
                  date = moment(
                    options[k].optionalQuestions[l].questions[m].answers[n]
                      .answer
                  ).format("YYYY-MM-DDT00:00:00+00:00");
                }
                if (options[k].optionalQuestions[l].questions[m].answers[n]) {
                  filledAnswers.push({
                    id: this.state.isFilled
                      ? options[k].optionalQuestions[l].questions[m].answers[n]
                        ? options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].id || 0
                        : 0
                      : 0,
                    filledQuestionnaireId: this.state.isFilled
                      ? (this.state.questionnaire &&
                          this.state.questionnaire.filledQuestionnaireId) ||
                        0
                      : 0,
                    questionId: options[k].optionalQuestions[l].questions[m].id,
                    answer:
                      date == "1900-01-01T00:00:00.000Z" &&
                      (options[k].optionalQuestions[l].questions[m]
                        .answerType == 2 ||
                        options[k].optionalQuestions[l].questions[m]
                          .answerType == 6)
                        ? date
                        : options[k].optionalQuestions[l].questions[m]
                            .answerType == 2 ||
                          options[k].optionalQuestions[l].questions[m]
                            .answerType == 6
                        ? moment(
                            options[k].optionalQuestions[l].questions[m]
                              .answers[n].answer
                          ).format("YYYY-MM-DDT00:00:00+00:00")
                        : options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ] &&
                          options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].answer
                        ? options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].answer.toString()
                        : "",
                    position:
                      options[k].optionalQuestions[l].questions[m].answers[n] &&
                      options[k].optionalQuestions[l].questions[m].answers[n]
                        .position
                        ? parseInt(
                            options[k].optionalQuestions[l].questions[m]
                              .answers[n].position
                          )
                        : l !== 0 &&
                          options[k].optionalQuestions[l - 1].id ===
                            options[k].optionalQuestions[l].id
                        ? l
                        : 0,
                    count: 0,
                  });
                } else {
                  filledAnswers.push({
                    id: this.state.isFilled
                      ? options[k].optionalQuestions[l].questions[m].answers[n]
                        ? options[k].optionalQuestions[l].questions[m].answers[
                            n
                          ].id || 0
                        : 0
                      : 0,
                    filledQuestionnaireId: this.state.isFilled
                      ? (this.state.questionnaire &&
                          this.state.questionnaire.filledQuestionnaireId) ||
                        0
                      : 0,
                    questionId: options[k].optionalQuestions[l].questions[m].id,
                    answer: date == "1900-01-01T00:00:00.000Z" ? "" : date,
                    position: 0,
                    count: 0,
                  });
                }
              }
              postAnswers.push({ filledAnswers: filledAnswers });
              // this.setState({
              //   questionAnswers: filledAnswers,
              //   postAnswers: postAnswers,
              // });
              filledAns = filledAnswers;
              postAns = postAnswers;
              // answers.push(filledAnswers)
              // filledAnswers = [];
              // postAnswers = [];
            }
          }
        }
      }
    }
  };

  onFinish = (values) => {
    this.setState({ isSaveClicked: true, fullName: values.clientName });
    var filledAnswers = [];
    var answers = [];
    let answerArray = [];
    var userId = localStorage.getItem("userId");
    // let clientprofileid = sessionStorage.getItem("clientprofileid");

    if (values.sections && values.sections.length > 0) {
      for (var i = 0; i < values.sections.length; i++) {
        if (values.sections[i].questions) {
          for (var j = 0; j < values.sections[i].questions.length; j++) {
            if (
              values.sections[i].questions[j].questionOptions &&
              values.sections[i].questions[j].questionOptions.length > 0
            ) {
              this.onRecursiveOptions(
                values.sections[i].questions[j].questionOptions
              );
              // filledAnswers = [...filledAns];
              // answerArray = [...postAns];
            }
            filledAnswers = [...filledAns];
            answerArray = [...postAns];
            // filledAnswers = [...this.state.questionAnswers];
            // answerArray = [...this.state.postAnswers];
            if (
              values.sections[i].questions[j].answers &&
              values.sections[i].questions[j].answers.length > 0
            ) {
              // isFilled = this.state.questionnaire.sections[i]

              for (
                var k = 0;
                k < values.sections[i].questions[j].answers.length;
                k++
              ) {
                var date = "1900-01-01T00:00:00.000Z";

                if (
                  (values.sections[i].questions[j].answerType == 2 ||
                    values.sections[i].questions[j].answerType == 6) &&
                  values.sections[i].questions[j].answers[k] &&
                  values.sections[i].questions[j].answers[k].answer
                ) {
                  date = moment(
                    values.sections[i].questions[j].answers[k].answer
                  ).format("YYYY-MM-DDT00:00:00+00:00");
                }

                if (values.sections[i].questions[j].answerType == 8) {
                  if (
                    values.sections[i].questions[j].answers[k] &&
                    values.sections[i].questions[j].answers[k].answer &&
                    values.sections[i].questions[j].answers[k].answer.fileList
                  ) {
                    for (
                      var f = 0;
                      f <
                      values.sections[i].questions[j].answers[k].answer.fileList
                        .length;
                      f++
                    ) {
                      documentsArray.push(
                        values.sections[i].questions[j].answers[k].answer
                          .fileList[f].originFileObj
                      );
                    }

                    // this.uploadDocument(
                    //   values.sections[i].questions[j].answers[k].answer
                    // );

                    filledAnswers.push({
                      id: this.state.isFilled
                        ? values.sections[i].questions[j].answers[k].id
                        : 0,
                      filledQuestionnaireId: this.state.isFilled
                        ? (this.state.questionnaire &&
                            this.state.questionnaire.filledQuestionnaireId) ||
                          0
                        : 0,
                      questionId: values.sections[i].questions[j].id,
                      answer: values.sections[i].questions[j].answers[k].answer
                        ? "Check file in attachments"
                        : "",
                      position:
                        values.sections[i].questions[j].answers[k] &&
                        values.sections[i].questions[j].answers[k].position
                          ? parseInt(
                              values.sections[i].questions[j].answers[k]
                                .position
                            )
                          : i !== 0 &&
                            values.sections[i - 1].id === values.sections[i].id
                          ? i
                          : 0,
                      count: 0,
                    });
                  } else {
                    filledAnswers.push({
                      id: 0,
                      filledQuestionnaireId: this.state.isFilled
                        ? (this.state.questionnaire &&
                            this.state.questionnaire.filledQuestionnaireId) ||
                          0
                        : 0,
                      questionId: values.sections[i].questions[j].id,
                      answer: "Check file in attachments",
                      position: 0,
                      count: 0,
                    });
                  }
                } else {
                  if (values.sections[i].questions[j].answers[k]) {
                    filledAnswers.push({
                      id: this.state.isFilled
                        ? values.sections[i].questions[j].answers[k].id
                        : 0,
                      filledQuestionnaireId: this.state.isFilled
                        ? (this.state.questionnaire &&
                            this.state.questionnaire.filledQuestionnaireId) ||
                          0
                        : 0,
                      questionId: values.sections[i].questions[j].id,
                      answer:
                        date == "1900-01-01T00:00:00.000Z" &&
                        (values.sections[i].questions[j].answerType == 2 ||
                          values.sections[i].questions[j].answerType == 6)
                          ? date
                          : values.sections[i].questions[j].answerType == 2 ||
                            values.sections[i].questions[j].answerType == 6
                          ? moment(
                              values.sections[i].questions[j].answers[k].answer
                            ).format("YYYY-MM-DDT00:00:00+00:00")
                          : values.sections[i].questions[j].answers[k] &&
                            values.sections[i].questions[j].answers[k].answer
                          ? values.sections[i].questions[j].answers[
                              k
                            ].answer.toString()
                          : "",
                      position:
                        values.sections[i].questions[j].answers[k] &&
                        values.sections[i].questions[j].answers[k].position
                          ? parseInt(
                              values.sections[i].questions[j].answers[k]
                                .position
                            )
                          : i !== 0 &&
                            values.sections[i - 1].id === values.sections[i].id
                          ? i
                          : 0,
                      count: 0,
                    });
                  } else {
                    filledAnswers.push({
                      id: 0,
                      filledQuestionnaireId:
                        this.props.questionnaireData &&
                        this.props.questionnaireData.filledQuestionnaireId,
                      questionId: values.sections[i].questions[j].id,
                      answer: date == "1900-01-01T00:00:00.000Z" ? "" : date,
                      position: 0,
                      count: 0,
                    });
                  }
                }
              }
              if (filledAnswers.length > 0) {
                for (var ans = 0; ans < filledAnswers.length; ans++) {
                  answers.push(filledAnswers[ans]);
                }
              }

              // this.setState({ questionAnswers: [], postAnswers: [] });
              filledAns = [];
              filledAnswers = [];
              answerArray = [];
            }
          }
        }
      }
    }

    var userId = localStorage.getItem("userId");
    this.setState({ loading: true });
    let data = {
      id: 0,
      filledQuestionnaireId: 0,
      questionnaireId: this.state.questionnaire.questionnaireId,
      clientId: this.state.clientIdState,
      isPotential:
        this.state.clientIdState == "00000000-0000-0000-0000-000000000000"
          ? false
          : this.state.isPotentialState,
      isEmployer: this.state.isEmployerState,
      isPublic: this.state.questionnaire.isPublic,
      clientName: values.clientName,
      createdBy: this.state.questionnaire.createdBy,
      branchId: this.state.branchIdState,
      answers: answers,
    };
    // this.setState({ loading: false });

    if (this.state.isFilled) {
      const VisaOpt = {
        url: `v1/questionnairefilledanswer`,
      };
      VisaOpt.types = [
        "UPDATE_SUBMIT_QUESTIONNAIRE_SUCCES",
        "UPDATE_SUBMIT_QUESTIONNAIRE_FAILURE",
      ];

      apiRefresh
        .put(VisaOpt, answers)
        .then((res) => {
          responseId = res.payload;
          message.success("Questionnaire answers submited successfully");
          this.setState({
            loading: false,
            isSaveClicked: false,
            disableButton: false,
          });
          this.changeFormTab(false, true);

          // this.uploadDocument(documentsArray[i], answer);
        })
        .catch(() => {
          this.setState({
            loading: false,
            isSaveClicked: false,
            disableButton: false,
          });
        });
    } else {
      this.props
        .onSubmitQuestionnaire(data)
        .then((res) => {
          responseId = res.payload;
          message.success("Questionnaire answers submited successfully");
          // this.sendEmail();
          if (this.state.fileList && this.state.fileList.length > 0) {
            for (var i = 0; i < this.state.fileList.length; i++) {
              documentsArray.push(this.state.fileList[i].originFileObj);
            }
          }
          if (documentsArray.length > 0) {
            for (var i = 0; i < documentsArray.length; i++)
              this.uploadDocument(documentsArray[i], res.payload);
          } else {
            this.setState({
              loading: false,
              isSaveClicked: false,
              disableButton: false,
            });
            this.changeFormTab();
          }
          // let userName = localStorage.getItem("userName");
          // var profileData = JSON.parse(sessionStorage.getItem("profileData"));
          // let myData = {
          //   clientName: profileData.fullName,
          //   logMessage:
          //     "\n Questionnaire " +
          //     this.state.questionnaire.name +
          //     " uploaded " +
          //     " by " +
          //     userName,
          //   date: moment(new Date()).format("DD/MM/YYYY"),
          //   logType: "Client Documents",
          //   invoiceId: "0",
          // };
          // activityData(myData);
        })
        .catch(() => {
          this.setState({ loading: false, isSaveClicked: false });
        });
    }
  };

  uploadDocument = (file, id) => {
    let formData = new FormData();
    let imageValues = file.file || file;

    const JsonData = {
      BranchId: this.state.branchIdState,
      CompanyDocumentTypeId: 3,
      SizeInKB: imageValues && imageValues.size,
      Name: imageValues && imageValues.name,
      BlobUrl: "",
      BlobFileName: "",
      Extension: imageValues && imageValues.type,
      CreatedBy: this.state.userIdState,
      forCMV: false,
      forAgent: false,
    };
    formData.append("BranchId", this.state.branchIdState);
    formData.append("file", imageValues);
    formData.append("Data", JSON.stringify(JsonData));
    this.props
      .onAddDocWithName(formData)
      .then((res) => {
        let fileName = res.payload.fileUrl.substring(
          res.payload.fileUrl.lastIndexOf("/") + 1
        );
        let ext = fileName.split(".");
        let data = [
          {
            id: 0,
            questionnaireId: id,
            questionnaireType: "1",
            fileName: res.payload.fileName,
            url: res.payload.fileUrl,
            isPotential: this.state.isPotentialState,
            isEmployer: this.state.isEmployerState,
            docuementExtension: ext[ext.length - 1],
            createdBy: this.state.userIdState,
            createdDate: new Date(),
          },
        ];

        const addAttachmentOpt = {
          url: `v1/questionnaire/Attachment`,
        };
        addAttachmentOpt.types = [
          "ADD_ATTACHMENT_SUCCES",
          "ADD_ATTACHMENT_FAILURE",
        ];

        apiRefresh.post(addAttachmentOpt, data).then((resp) => {
          countAttachment++;
          if (documentsArray.length === countAttachment) {
            this.changeFormTab();
            this.setState({ loading: false, isSaveClicked: false });
          }
        });
      })
      .catch((err) => {});
  };

  sendEmail = () => {
    let data = {
      clientId: this.state.clientIdState,
      branchId: this.state.branchIdState,
      head: this.state.questionnaire.name,
      companyName: localStorage.getItem("selectedBranchName") || "",
      questionnaireName: this.state.questionnaire.name,
      id: this.state.questionnaire.questionnaireId,
      userId: this.state.userIdState,
      groupId: this.state.groupId,
      filledAnsId: this.state.isFilled
        ? this.state.questionnaire.filledQuestionnaireId
        : 0,
    };
    if (this.state.clientIdState == "00000000-0000-0000-0000-000000000000") {
      data.filledAnsId = responseId;
      data.clientName = this.state.fullName;

      const sendEmailOpt = {
        url: `v1/questionnaire/WebSendQuestionnaireEmail`,
      };
      sendEmailOpt.types = ["ADD_ATTACHMENT_SUCCES", "ADD_ATTACHMENT_FAILURE"];

      apiRefresh.post(sendEmailOpt, data);
    } else if (this.state.isPotentialState) {
      const sendEmailOpt = {
        url: `v1/questionnaire/PSendQuestionnaireEmail`,
      };
      sendEmailOpt.types = ["ADD_ATTACHMENT_SUCCES", "ADD_ATTACHMENT_FAILURE"];

      apiRefresh.post(sendEmailOpt, data);
    } else if (this.state.isEmployerState) {
      const sendEmailOpt = {
        url: `v1/questionnaire/ESendQuestionnaireEmail`,
      };
      sendEmailOpt.types = ["ADD_ATTACHMENT_SUCCES", "ADD_ATTACHMENT_FAILURE"];

      apiRefresh.post(sendEmailOpt, data);
    } else {
      const sendEmailOpt = {
        url: `v1/questionnaire/SendQuestionnaireEmail`,
      };
      sendEmailOpt.types = ["ADD_ATTACHMENT_SUCCES", "ADD_ATTACHMENT_FAILURE"];

      apiRefresh.post(sendEmailOpt, data);
    }
    if (!this.props.notLink)
      setTimeout(() => {
        window.location.href = "/AgreementBuilder/Thanks.htm";
      }, 1000);
  };

  changeFormTab = (onBackClick, isUpdate) => {
    if (this.state.clickedButton == "back" || onBackClick) {
      var findQuestionnaire = this.state.idsArrayState.find(
        (obj) => obj.id == this.state.activePageNumber - 1
      );
      if (findQuestionnaire) {
        this.getQuestionnaires(
          findQuestionnaire.value,
          this.state.activeIndex - 1
        );
      }
    } else if (this.state.clickedButton == "continue") {
      var findQuestionnaire = this.state.idsArrayState.find(
        (obj) => obj.id == this.state.activePageNumber + 1
      );
      if (findQuestionnaire) {
        this.getQuestionnaires(
          findQuestionnaire.value,
          this.state.activeIndex + 1
        );
      }
    } else {
      this.sendEmail();
    }
  };

  addSection = (sectionIndex, sectionData) => {
    // add();
    // this.props.questionnaireData.sections.push(sectionData);
    this.props.questionnaireData.sections.splice(
      sectionIndex + 1,
      0,
      sectionData
    );
    setTimeout(() => {
      this.setState({
        questionnaire: this.props.questionnaireData,
      });
    }, 3000);

    this.setState({ sectionData: sectionData });
  };

  onChange = (value, index, add, questionnaire) => {
    // const fields = this.formRef.current.getFieldsValue();
    // fields.sections.push(value);
    // let questionnaire = this.state.questionnaire;
    if (count == 0 || clickedIndex != index) {
      count = 1;
      clickedIndex = index;
      index += count;
    } else {
      count++;
      index += count;
    }

    if (this.state.isFilled && value.questions && value.questions.length > 0) {
      value.questions.map((question, ind) => {
        if (question.answers && question.answers.length > 0) {
          question.answers.map((answer, index) => {
            if (question.questionOptions.length > 0) {
              question.questionOptions.map((options, ind) => {
                if (options.optionalQuestions.length > 0) {
                  options.optionalQuestions.map((optQuestion, i) => {
                    if (optQuestion.questions.length > 0) {
                      optQuestion.questions.map((ques, indx) => {
                        ques.answers.map((ans, indd) => {
                          ans.id = 0;
                          ans.answer = "";
                        });
                      });
                    }
                  });
                }
              });
            }
            answer.id = 0;
            answer.answer = "";
          });
        }
      });
    }

    add(value, index);
    if (questionnaire.sections) {
      // questionnaire.sections.push(value);
      questionnaire.sections.splice(index, 0, value);
      this.setState({ questionnaire: questionnaire });
    } else {
      questionnaire.optionalQuestions.push(value);
    }

    //this.formRef.current.setFieldsValue({ fields });
  };

  getQuestionnaires = (id, index) => {
    this.setState({
      loading: true,
      activeIndex: index,
      questionnaire: null,
      activePageNumber: index + 1,
    });
    let data = {
      id: id,
      clientId: this.state.clientIdState,
      // clientId: "00000000-0000-0000-0000-000000000000",
    };
    this.props
      .onGetFilledQuestionnaire(data)
      .then((res) => {
        this.setState({ loading: false, isFilled: true });

        this.getAttachments(
          res.payload.sections[0].questions[0].answers[0].filledQuestionnaireId
        );
      })
      .catch((err) => {
        this.setState({ questionnaire: null });

        if (
          err.payload &&
          err.payload.response &&
          err.payload.response.status == 404
        ) {
          this.props
            .onGetQuestionnaire(data)
            .then((resp) => {
              this.setState({ loading: false, isFilled: false });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        }
      });
  };

  handleCancelPopup = () => {
    this.setState({ visibleModal: false });
  };

  mergeToClient = () => {
    let branchId =
      sessionStorage.getItem("selectedBranchId") ||
      localStorage.getItem("selectedBranchId");
    this.setState({ loading: true });
    let data = {
      clientId: this.props.clientprofileid,
      branchId: branchId,
      questionnaireId: this.state.questionnaire.questionnaireId,
      excluded: [0],
    };
    this.props
      .onUpdMergeToPClient(data)
      .then((res) => {
        this.setState({ loading: false });
        this.handleCancelPopup();
        message.success("Updated!");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onHandleChange = (info) => {
    if (
      info.fileList &&
      info.fileList[info.fileList.length - 1] &&
      info.fileList[info.fileList.length - 1].size > 10 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 10MB");
    } else {
      this.setState({ fileList: info.fileList });
    }

    // this.setState({ fileList: info.fileList });
    // setImageValues(info.file.originFileObj);
    // getBase64(
    //   info.file.originFileObj,
    //   (imageUrl) => setImageUrl(imageUrl),
    //   setLoading(false)
    // );
    // }
  };

  getQuestionnaires = (id, index) => {
    this.setState({
      loading: true,
      activeIndex: index,
      questionnaire: null,
      activePageNumber: index + 1,
    });
    let data = {
      id: id,
      clientId: this.state.clientIdState,
      // clientId: "00000000-0000-0000-0000-000000000000",
    };
    this.props
      .onGetFilledQuestionnaire(data)
      .then((res) => {
        this.setState({ loading: false, isFilled: true });

        this.getAttachments(
          res.payload.sections[0].questions[0].answers[0].filledQuestionnaireId
        );
      })
      .catch((err) => {
        this.setState({ questionnaire: null });

        if (
          err.payload &&
          err.payload.response &&
          err.payload.response.status == 404
        ) {
          this.props
            .onGetQuestionnaire(data)
            .then((resp) => {
              this.setState({ loading: false, isFilled: false });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        }
      });
  };

  handleCancelPopup = () => {
    this.setState({ visibleModal: false });
  };

  mergeToClient = () => {
    let branchId =
      sessionStorage.getItem("selectedBranchId") ||
      localStorage.getItem("selectedBranchId");
    this.setState({ loading: true });
    let data = {
      clientId: this.props.clientprofileid,
      branchId: branchId,
      questionnaireId: this.state.questionnaire.questionnaireId,
      excluded: [0],
    };
    this.props
      .onUpdMergeToPClient(data)
      .then((res) => {
        this.setState({ loading: false });
        this.handleCancelPopup();
        message.success("Updated!");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onHandleChange = (info) => {
    debugger;
    if (
      info.fileList &&
      info.fileList[info.fileList.length - 1] &&
      info.fileList[info.fileList.length - 1].size > 10 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 10MB");
    } else {
      this.setState({ fileList: info.fileList });
    }

    // this.setState({ fileList: info.fileList });
    // setImageValues(info.file.originFileObj);
    // getBase64(
    //   info.file.originFileObj,
    //   (imageUrl) => setImageUrl(imageUrl),
    //   setLoading(false)
    // );
    // }
  };

  render() {
    const {
      questionnaire,
      loading,
      groupsArray,
      activeIndex,
      isSaveClicked,
      activePageNumber,
      attachmentsArray,
      disableButton,
      visibleModal,
      visaTypeDataList,
      fileList,
    } = this.state;
    const {
      countriesData,
      questionnaireData,
      dynamicLinkData,
      notLink,
      visaTypeData,
    } = this.props;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div>
        <Spin spinning={loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div
                className={notLink ? "" : "sus-container"}
                style={{ paddingBottom: 80 }}
              >
                <div
                  style={{
                    paddingTop: notLink ? 0 : 20,
                    margin: notLink ? 0 : 20,
                  }}
                >
                  <div style={{ margin: 40, marginBottom: 0 }}>
                    {dynamicLinkData && (
                      <Header
                        branchData={dynamicLinkData.branch}
                        stateData={this.state}
                      />
                    )}
                    <div>
                      {groupsArray &&
                        groupsArray.map((questionnaire, index) => {
                          return (
                            <div
                              onClick={() =>
                                this.getQuestionnaires(questionnaire.id, index)
                              }
                              className={
                                activeIndex == index
                                  ? "cq-active-inner-tab"
                                  : "cq-inactive-inner-tab"
                              }
                            >
                              <span
                                className="pc-text-inner-tab"
                                style={{
                                  fontSize: 12,
                                  color:
                                    activeIndex == index
                                      ? "#FFFFFF"
                                      : "#555555",
                                }}
                              >
                                {index +
                                  1 +
                                  ". " +
                                  questionnaire.questionnaireName}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="title-container">
                    <span className="heading-text">
                      {questionnaire && questionnaire.name}
                    </span>
                  </div>
                  {this.props.potentialMerge && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        type="primary"
                        className="cq-primary-btn"
                        style={{
                          marginTop: 10,
                          marginRight: 40,
                          marginBottom: 20,
                        }}
                        onClick={() => this.setState({ visibleModal: true })}
                      >
                        Merge
                      </Button>
                    </div>
                  )}
                  <Modal
                    title="Confirmation"
                    visible={visibleModal}
                    onCancel={this.handleCancelPopup}
                    footer={null}
                    maskClosable={false}
                  >
                    <Row>
                      <Col span={24}>
                        <Row>
                          {" "}
                          Are you sure, you want to merge questionnaire, all
                          fields values will be updated?
                        </Row>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", marginTop: "40px" }}>
                      <Col span={4} offset={16}>
                        <Button
                          onClick={() => {
                            this.handleCancelPopup();
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          style={{ marginLeft: "12px" }}
                          className={"button"}
                          onClick={() => {
                            this.mergeToClient();
                          }}
                        >
                          Ok
                        </Button>
                      </Col>
                    </Row>
                  </Modal>
                  {attachmentsArray.length > 0 && (
                    <>
                      <div
                        className="cs-attachment-haeding"
                        style={{ marginLeft: 30 }}
                      >
                        Attachment(s)
                      </div>
                      <div className="cs-attachment-body">
                        {attachmentsArray.map((attachment, index) => {
                          return (
                            <div
                              onClick={() => this.saveAttachment(attachment)}
                              style={{ padding: 5, cursor: "pointer" }}
                            >
                              <span>{attachment.fileName}</span>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                  {questionnaire && (
                    <Form
                      ref={this.formRef}
                      onFinish={(v) => {
                        this.setState({ loading: true });
                        setTimeout(() => {
                          this.onFinish(v);
                        }, 300);
                      }}
                      // onFinish={(v) => {
                      //
                      //   this.setState({ loading: true }, () => {
                      //     this.onFinish(v);
                      //   });
                      // }}
                      initialValues={questionnaire}
                    >
                      <div className="cq-container">
                        <div
                          className="cq-form-cont full-name-row-padding"
                          style={{
                            width: "100%",
                            marginLeft: 0,
                            marginTop: 0,
                            // paddingLeft: 30,
                            // paddingRight: 30,
                            // paddingBottom: 30,
                          }}
                        >
                          <div
                            className="form-cont"
                            style={{
                              justifyContent: "space-between",
                              paddingTop: 20,
                              paddingBottom: 0,
                              display: "block",
                            }}
                          >
                            <div
                              style={{
                                marginTop: 15,
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <div className="row-style">
                                  <p className="cq-label-text">FULL NAME</p>
                                  <div
                                    className="profile-input-border questionnaire-full-name-input"
                                    // style={{
                                    //   width: "60%",
                                    //   border: 0,
                                    // }}
                                  >
                                    <Form.Item
                                      name="clientName"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required!",
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="profile-input"
                                        placeholder="FULL NAME"
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Form.List name="sections">
                          {(fields, { add, remove }) => {
                            return (
                              <div>
                                {fields.map((field, index) => (
                                  <div key={index}>
                                    <div
                                      className="title-container"
                                      style={{
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span className="heading-text">
                                        {questionnaire.sections[index].name}
                                      </span>
                                      {index === 0 &&
                                      this.state.questionnaire.sections[index]
                                        .allowMultiple ? (
                                        <div
                                          className="cq-add-button"
                                          style={{ marginLeft: 20 }}
                                          onClick={() =>
                                            this.onChange(
                                              this.state.questionnaire.sections[
                                                index
                                              ],
                                              index,
                                              add,
                                              this.state.questionnaire
                                            )
                                          }
                                        >
                                          <img
                                            src={Images.plusIcon}
                                            style={{ width: 20 }}
                                          />
                                        </div>
                                      ) : (
                                        index != 0 &&
                                        this.state.questionnaire.sections[
                                          index - 1
                                        ].id !==
                                          this.state.questionnaire.sections[
                                            index
                                          ].id &&
                                        this.state.questionnaire.sections[index]
                                          .allowMultiple && (
                                          <div
                                            className="cq-add-button"
                                            style={{ marginLeft: 20 }}
                                            onClick={() =>
                                              this.onChange(
                                                this.state.questionnaire
                                                  .sections[index],
                                                index,
                                                add,
                                                this.state.questionnaire
                                              )
                                            }
                                          >
                                            <img
                                              src={Images.plusIcon}
                                              style={{ width: 20 }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div
                                      className="cq-form-cont full-name-row-padding"
                                      style={{
                                        width: "100%",
                                        marginLeft: 0,
                                        marginTop: 0,
                                        // paddingLeft: 30,
                                        // paddingRight: 30,
                                        // paddingBottom: 30,
                                      }}
                                    >
                                      <div
                                        className="form-cont"
                                        style={{
                                          justifyContent: "space-between",
                                          paddingTop: 20,
                                          paddingBottom: 0,
                                          display: "block",
                                        }}
                                      >
                                        <Form.List
                                          name={[field.name, "questions"]}
                                          key={[field.key, "questions"]}
                                        >
                                          {(questions, { add, remove }) => {
                                            return (
                                              <div>
                                                {questions.map(
                                                  (question, quesInd) => (
                                                    <div
                                                      key={quesInd}
                                                      style={{
                                                        marginTop: 15,
                                                      }}
                                                    >
                                                      <Form.Item
                                                        required={false}
                                                        key={question.key}
                                                      >
                                                        <FillQuestionRow
                                                          question={question}
                                                          countriesData={
                                                            countriesData
                                                              ? countriesData.items
                                                              : []
                                                          }
                                                          visaTypeData={
                                                            visaTypeDataList
                                                          }
                                                          questionData={
                                                            questionnaire
                                                              .sections[index]
                                                              .questions[
                                                              quesInd
                                                            ]
                                                          }
                                                          questionsLength={
                                                            questionnaire
                                                              .sections[index]
                                                              .questions.length
                                                          }
                                                          sectionsLength={
                                                            questionnaire
                                                              .sections.length
                                                          }
                                                          isSaveClicked={
                                                            isSaveClicked
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            );
                                          }}
                                        </Form.List>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            );
                          }}
                        </Form.List>

                        {questionnaire && questionnaire.deceleration && (
                          <div style={{ marginTop: 10 }}>
                            <div
                              className="cq-form-cont"
                              style={{
                                width: "100%",
                                marginLeft: 0,
                                marginTop: 0,
                                paddingLeft: 30,
                                paddingRight: 30,
                                paddingBottom: 30,
                              }}
                            >
                              <div
                                className="form-cont"
                                style={{
                                  justifyContent: "space-between",
                                  paddingTop: 20,
                                  paddingBottom: 0,
                                  display: "block",
                                }}
                              >
                                <Form.Item
                                  name="declaration"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required!",
                                    },
                                  ]}
                                  valuePropName="checked"
                                >
                                  <Checkbox>
                                    {this.state.declarationText ? (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: this.state.declarationText,
                                        }}
                                      />
                                    ) : (
                                      <span
                                        style={{ marginLeft: 5, fontSize: 12 }}
                                      >
                                        I hereby declare that the information
                                        given by me in this application is true
                                        and correct to the best of my
                                        knowledge.I understand and agree that
                                        any false information,
                                        misrepresentation, or omission of facts
                                        in this application and the application
                                        process may be justification for
                                        refusal/ decline.I further understand
                                        and agree that all information furnished
                                        in this application and the application
                                        process may be verified as may be
                                        necessary.
                                      </span>
                                    )}
                                  </Checkbox>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        )}

                        {questionnaire && questionnaire.allowAttachments && (
                          <div>
                            <div
                              className="cq-form-cont"
                              style={{
                                width: "100%",
                                marginLeft: 0,
                                marginTop: 10,
                                paddingLeft: 30,
                                paddingRight: 30,
                                paddingBottom: 30,
                              }}
                            >
                              <div
                                className="form-cont"
                                // style={{
                                //   justifyContent: "center",
                                //   paddingTop: 20,
                                //   paddingBottom: 0,
                                //   display: "flex",
                                // }}
                              >
                                <Upload
                                  listType="picture-card"
                                  fileList={fileList}
                                  beforeUpload={() => false}
                                  onChange={this.onHandleChange}
                                  multiple
                                >
                                  {uploadButton}
                                </Upload>
                                {/* <span style={{ marginLeft: 5, fontSize: 12 }}>
                                  Drop or click here to attach CV and other
                                  documents. Max 10 files
                                </span> */}
                              </div>
                            </div>
                          </div>
                        )}

                        {groupsArray && groupsArray.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Form.Item>
                                <Button
                                  type="primary"
                                  // htmlType="submit"
                                  className="cq-primary-btn"
                                  // loading={loading}
                                  style={{ marginTop: 20, marginRight: 10 }}
                                  disabled={activePageNumber == 1}
                                  onClick={() => {
                                    this.setState({ clickedButton: "back" });
                                    this.changeFormTab(true);
                                  }}
                                >
                                  Back
                                </Button>
                              </Form.Item>

                              <Form.Item>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="cq-primary-btn"
                                  // loading={loading}
                                  style={{ marginTop: 20, marginRight: 10 }}
                                  onClick={() =>
                                    this.setState({ clickedButton: "continue" })
                                  }
                                >
                                  Save & Continue
                                </Button>
                              </Form.Item>

                              <Form.Item>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="cq-primary-btn"
                                  // loading={loading}
                                  style={{ marginTop: 20, marginRight: 10 }}
                                  onClick={() =>
                                    this.setState({ clickedButton: "submit" })
                                  }
                                >
                                  Submit
                                </Button>
                              </Form.Item>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="cq-primary-btn"
                                loading={loading}
                                disabled={disableButton}
                                style={{ marginTop: 20, marginRight: 10 }}
                              >
                                Submit
                              </Button>
                            </Form.Item>
                          </div>
                        )}
                      </div>
                    </Form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default LinkQuestionnaire;
